import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from '@microsoft/signalr';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI, ThunkDispatch } from '@reduxjs/toolkit';
import { API_PREFIX } from 'API/PosApi';
import { RootState } from 'stores';
import { handleEftStatusNotification } from 'stores/Eft';
import {
  EftStatusChangeMessage,
  EtaConfirmationNotifierRefreshMessage,
  NotificationMessage,
  StoreEtaUpdateRefreshMessage,
  StoreOrderingOptionRefreshMessage,
} from 'typings/Notifications';
import { updateLastDineInRefreshMessage } from '.';
import { disconnectNotifications, setNotificationsConnectionId } from './notifications.slice';
import { getUseNotifications } from '../Config/config.selector';
import { storeApi } from '../Store/store.api';
import { getSelectedStore } from '../Store/store.selectors';
import { notificationsApi } from './notifications.api';

// Global state, but we should refactor this file into a top level hook instead of a redux middleware
let currentConnection: {
  connection: HubConnection | null;
  storeId: number | null;
  touchpointId: string | null;
} = {
  connection: null,
  storeId: null,
  touchpointId: null,
};

const startSignalRConnection = async (
  connection: HubConnection,
  apiDispatcher: MiddlewareAPI<ThunkDispatch<any, any, any>, any>,
  storeId: number,
  touchpointId: string,
) => {
  try {
    await connection.start();

    if (connection.connectionId) {
      apiDispatcher.dispatch(setNotificationsConnectionId(connection.connectionId));
    }

    connection.on('ReceiveDineInRefreshMessage', (message: NotificationMessage) => {
      if (storeId === message.storeId && touchpointId !== message.touchpointId) {
        apiDispatcher.dispatch(
          updateLastDineInRefreshMessage({
            storeId: message.storeId,
            touchpointId: message.touchpointId,
            tabId: message.body.tabId,
          }),
        );
      }
    });

    connection.on('ReceiveEftStatusMessage', (message: EftStatusChangeMessage) => {
      const state = apiDispatcher.getState() as RootState;
      const transactionId = state.eft.eftPaymentId;

      if (transactionId && transactionId === message.transactionId && storeId === message.storeId) {
        apiDispatcher.dispatch(handleEftStatusNotification(message));
      }
    });

    connection.on(
      'ReceiveEtaConfirmationNotifierRefreshMessage',
      (message: EtaConfirmationNotifierRefreshMessage) => {
        if (message.storeId !== storeId) {
          return;
        }
        apiDispatcher.dispatch(
          notificationsApi.endpoints.getNoOfOrdersToConfirmEta.initiate(undefined, { forceRefetch: true }),
        );
      },
    );

    connection.on('ReceiveStoreEtaUpdateRefreshMessage', (message: StoreEtaUpdateRefreshMessage) => {
      if (message.storeId !== storeId) {
        return;
      }
      apiDispatcher.dispatch(
        storeApi.endpoints.getEtaConfiguration.initiate(message.storeId, { forceRefetch: true }),
      );
    });

    connection.on('ReceiveStoreOrderingOptionRefreshMessage', (message: StoreOrderingOptionRefreshMessage) => {
      if (message.storeId !== storeId) {
        return;
      }
      apiDispatcher.dispatch(
        storeApi.endpoints.getEtaConfiguration.initiate(message.storeId, { forceRefetch: true }),
      );
    });

    connection.on('Disconnect', async () => {
      try {
        await connection.stop();
        apiDispatcher.dispatch(disconnectNotifications());
      } catch (error) {
        console.error(`SignalR disconnection error`, error);
      }
    });
  } catch (error) {
    console.error('Connection failed: ', error);
  }
};

const connectToSignalR = (api: MiddlewareAPI<Dispatch<AnyAction>, any>, storeId: number, touchpointId: string) => {
  if (
    currentConnection.storeId === storeId &&
    currentConnection.touchpointId === touchpointId &&
    currentConnection.connection
  ) {
    // No changes detected, reusing existing connection;
    return;
  }

  // Clean up the existing connection if it exists
  if (currentConnection.connection) {
    currentConnection.connection.stop().catch((error) => {
      console.error('Error stopping existing connection: ', error);
    });
  }

  const connectionHub = `${API_PREFIX}/hubs/notifications`;
  const protocol = new JsonHubProtocol();
  // eslint-disable-next-line no-bitwise
  const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;
  const options = {
    transport,
    logMessageContent: true,
    logger: LogLevel.Error,
    withCredentials: false,
  };
  // create the connection instance
  const connection = new HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .withAutomaticReconnect()
    .withHubProtocol(protocol)
    .build();

  // Update the global state
  currentConnection = {
    connection,
    storeId,
    touchpointId,
  };

  startSignalRConnection(connection, api, storeId, touchpointId);
};

const SignalRMiddleware: Middleware<ThunkDispatch<any, any, any>> = (api) => (next) => (action) => {
  const { type } = (action as AnyAction) || {};
  if (
    // Store is selected
    storeApi.endpoints.getSelectedStore.matchFulfilled(action) ||
    // Page is refreshed
    // this never executes correctly, as when this is triggered store and touchpoint id is not yet in app state
    // not sure what to do with it. The other condition seems to cover it, so potentially this can be removed,
    // or dispatched in a way that we already have what we need in the state
    // We should re-visit it when implementing "select existing touchpoint" feature
    type === '[NOTIFY]/reconnectToNotifications'
  ) {
    const combinedState = api.getState() as RootState;
    const selectedStore = getSelectedStore(combinedState);
    const storeId = selectedStore?.id;
    const touchpointId = combinedState.authorization.onsiteMachine?.touchpointId;

    const useNotifications = getUseNotifications(combinedState);
    if (useNotifications && storeId && touchpointId) {
      connectToSignalR(api, storeId, touchpointId);
    }
  }

  return next(action);
};

export default SignalRMiddleware;
