import React, { useEffect } from 'react';
import { Button, Grid2 as Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { useResetPincodeMutation } from 'stores/Cashier/cashier.api';
import { ResetPincodeContext, ResetCredentialStates } from 'typings/Cashier';

interface ResetPincode {
  lastNameInput: string;
  currentPincodeInput: string;
  newPincodeInput: string;
}

interface ResetPincodeInputElement {
  inputName: keyof ResetPincode;
  class?: string;
  placeholder: string;
  label: string;
  format?: string;
  formatErrorMessage?: string;
  maxLength?: number;
  type: 'text' | 'password';
}

const formId = 'reset-pincode';

const ResetPincodeForm: React.FC = () => {
  const [t] = useTranslation('authentication');
  const [resetPincode] = useResetPincodeMutation();
  const { handleSubmit, formState, control, clearErrors, setError } = useForm<ResetPincode>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const { resetCredentialState } = useAppSelector(({ cashier }) => cashier);

  const { errors } = formState;

  useEffect(() => {
    switch (resetCredentialState) {
      case ResetCredentialStates.oldCredentialMismatch: {
        const message = t('The entered surname or PIN does not match. Please check and try again.');
        setError('lastNameInput', { type: 'custom', message });
        setError('currentPincodeInput', { type: 'custom', message });
        break;
      }
      case ResetCredentialStates.sameNewPin: {
        setError('newPincodeInput', {
          type: 'custom',
          message: t('The new PIN must be different from previously used ones.'),
        });
        break;
      }
      case ResetCredentialStates.invalidPin:
      case ResetCredentialStates.genericError: {
        setError('newPincodeInput', {
          type: 'custom',
          message: t('Invalid PIN'),
        });
        break;
      }
      default:
        break;
    }
  }, [resetCredentialState]);

  const resetPincodeInputs: ResetPincodeInputElement[] = [
    {
      inputName: 'lastNameInput',
      label: t('Surname'),
      placeholder: t('Enter your Surname *'),
      type: 'text',
    },
    {
      inputName: 'currentPincodeInput',
      label: t('Old PIN'),
      placeholder: t('Enter your old PIN *'),
      format: '^\\d+$',
      formatErrorMessage: t('Invalid PIN: Please enter numbers only.'),
      maxLength: 8,
      type: 'password',
    },
    {
      inputName: 'newPincodeInput',
      label: t('New PIN'),
      placeholder: t('Enter your new PIN *'),
      format: '^\\d+$',
      formatErrorMessage: t('Invalid PIN: Please enter numbers only.'),
      maxLength: 8,
      type: 'password',
    },
  ];

  function generateFields(): JSX.Element[] {
    return resetPincodeInputs.map((inputData) => {
      return (
        <Controller
          rules={{
            required: {
              value: true,
              message: t('Field required'),
            },
            pattern: {
              value: inputData.format ? RegExp(inputData.format) : /.*/,
              message: inputData.formatErrorMessage || t('Incorrect value'),
            },
          }}
          control={control}
          name={inputData.inputName}
          key={inputData.inputName}
          render={({ field }) => (
            <TextField
              {...field}
              required
              autoComplete="off"
              fullWidth
              label={field.value && inputData.label}
              placeholder={inputData.placeholder}
              type={inputData.type}
              variant="outlined"
              error={errors[inputData.inputName] && true}
              helperText={errors[inputData.inputName] && errors[inputData.inputName]?.message}
              inputProps={{
                'data-testid': `reset-pincode-form__input--${inputData.inputName}`,
                maxLength: inputData.maxLength || -1,
              }}
            />
          )}
        />
      );
    });
  }

  function onSubmit(data: ResetPincode): void {
    clearErrors();

    const newPincode = {
      lastName: data.lastNameInput,
      oldPincode: data.currentPincodeInput,
      newPincode: data.newPincodeInput,
    } as ResetPincodeContext;
    resetPincode(newPincode);
  }

  return (
    <form name={formId} id={formId} autoComplete="off" onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Grid container spacing={3}>
        {generateFields()}

        <Button variant="contained" fullWidth type="submit">
          {t('Set new pin')}
        </Button>
      </Grid>
    </form>
  );
};

export default ResetPincodeForm;
