import React, { useCallback, useEffect, useState } from 'react';
import { Drawer, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActiveIntakeTab, setIntakeStatus } from 'stores/Intake';
import { PaymentSuccessfulResult } from 'typings/Payments';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import uuidGenerator from 'utils/GuidGenerator';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { postFinalizeDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import { useFormContext } from 'react-hook-form';
import { restoreTransferredIntakeState } from 'stores/HybridMode/hybridMode.thunk-actions';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { Receipt } from './Receipt/Receipt';
import buildClasses from './Intake.css';
import { AvailableIntakeContents, IntakeStatuses, PickUpTypesValues } from './IntakeConsts';
import { isBigScreenMediaQuery } from './Receipt/Receipt.css';
import { useCustomerSearch } from './useCustomerSearch';
import { IntakeContent } from './IntakeContent';

const Intake: React.FC = () => {
  const { searchCustomer, clearCustomerSearch } = useCustomerSearch();
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const [receiptVisible, setReceiptVisible] = useState<boolean>(true);
  const isBigScreen = useMediaQuery(isBigScreenMediaQuery);
  const { activeDeliveryType, activeIntakeTab, manualPriceOverride, intakeStatus, editMode, activePaymentMethod } =
    useAppSelector((state) => state.intake);
  const { selectedDineInOrder } = useAppSelector((state) => state.dineIn);
  const { productToCustomize } = useAppSelector((state) => state.products);
  const { basketData } = useAppSelector((state) => state.basket);
  const selectedStore = useAppSelector(getSelectedStore);
  const { transferredIntake } = useAppSelector((store) => store.hybridMode);
  const { trigger } = useFormContext<CheckoutDetailsForm>();

  useEffect(() => {
    if (transferredIntake && selectedStore) {
      dispatch(restoreTransferredIntakeState(transferredIntake));
    }
  }, [transferredIntake, selectedStore, dispatch]);

  useEffect(() => {
    if (productToCustomize) {
      dispatch(setIntakeStatus(IntakeStatuses.productsSection));
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
  }, [dispatch, productToCustomize]);

  const closeSettlePaymentForm = useCallback((): void => {
    dispatch(clearSettlePayment());
  }, [dispatch]);

  const paymentSuccessfulHandler = useCallback(
    (result: PaymentSuccessfulResult): void => {
      if (selectedDineInOrder) {
        dispatch(
          postFinalizeDineInOrder({
            tabId: selectedDineInOrder.id,
            newOrderId: result.orderId,
            config: {
              paymentMethodCode: result.paymentMethodCode,
              eftPaymentOperation: result.eftPaymentOperation,
              manualPriceOverride,
            },
            refreshConfig:
              activeIntakeTab === AvailableIntakeContents.DineInOpenOrders ||
              activeIntakeTab === AvailableIntakeContents.Tables,
          }),
        );
      }

      closeSettlePaymentForm();
    },
    [selectedDineInOrder, manualPriceOverride, activeIntakeTab, dispatch, closeSettlePaymentForm],
  );

  const onHideReceipt = useCallback(() => {
    if (!isBigScreen) setReceiptVisible(false);
  }, [isBigScreen]);

  const finishButtonClickedHandler = useCallback(async (): Promise<void> => {
    const isCheckoutFormValid = await trigger();

    if (!isCheckoutFormValid) {
      return;
    }

    if (
      activeDeliveryType === PickUpTypesValues.dineIn &&
      basketData?.summary &&
      selectedDineInOrder !== undefined
    ) {
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId: uuidGenerator(),
          openTabId: selectedDineInOrder.id,
          deliveryType: activeDeliveryType,
          closeCallback: closeSettlePaymentForm,
          successCallback: paymentSuccessfulHandler,
          totalToPay: basketData.summary.total,
          preselectedPaymentMethod: activePaymentMethod,
          isLocalOrder: true,
          cancelOnClose: false,
        }),
      );
    } else {
      dispatch(
        setIntakeStatus(
          editMode || selectedStore?.upsellScreenActive === false
            ? IntakeStatuses.finalizeOrder
            : IntakeStatuses.preFinalize,
        ),
      );
    }
  }, [
    trigger,
    activeDeliveryType,
    basketData?.summary,
    selectedDineInOrder,
    dispatch,
    closeSettlePaymentForm,
    paymentSuccessfulHandler,
    activePaymentMethod,
    editMode,
    selectedStore?.upsellScreenActive,
  ]);

  return (
    <div id="intake-window" className={classes.intakeWindow}>
      <IntakeContent receiptVisible={receiptVisible} setReceiptVisible={setReceiptVisible} />
      <Drawer className={classes.receiptDrawer} anchor="right" open={receiptVisible} variant="persistent">
        <Receipt
          intakeStatus={intakeStatus}
          onFinishOrderButtonClick={finishButtonClickedHandler}
          onSearchCustomer={searchCustomer}
          onIntakeWithoutProfile={clearCustomerSearch}
          canChangePaymentMethod={false}
          onHideReceipt={onHideReceipt}
          canEdit
        />
      </Drawer>
    </div>
  );
};

export default Intake;
