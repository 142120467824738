import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const selectSelf = (state: RootState) => state;

const selectLocalBasket = createSelector(selectSelf, (state) => state.basket);

export const getBasketItems = createSelector(selectLocalBasket, (basket) => basket.basketItems);

export const getBasketCoupons = createSelector(selectLocalBasket, (basket) => basket.basketCoupons);

export const getLastBasketClearTimestamp = createSelector(
  selectLocalBasket,
  (basket) => basket.lastBasketClearTimestamp,
);

export const getBasketData = createSelector(selectLocalBasket, (intake) => intake.basketData);

export const getBasketDataSummary = createSelector(getBasketData, (basketData) => basketData?.summary);

export const getBasketDataDeliveryCharge = createSelector(
  getBasketData,
  (basketData) => basketData?.deliveryCharge,
);

export const getVirtualReceipt = createSelector(selectLocalBasket, (basket) => basket.virtualReceipt);

export const getIsAnySetOnReceipt = createSelector(getVirtualReceipt, (virtualReceipt) =>
  virtualReceipt.receiptDiscounts.some((rd) => rd.isSet === true),
);

export const getRecalculateInProgress = createSelector(
  selectLocalBasket,
  (basket) => basket.recalculateInProgress,
);

export const getManualDeliveryCharge = createSelector(selectLocalBasket, (basket) => basket.manualDeliveryCharge);

export const getValidationResult = createSelector(selectLocalBasket, (basket) => basket.validationResult);

export const getBasketHasItems = createSelector(
  [getVirtualReceipt],
  (virtualReceipt) => virtualReceipt.receiptProducts.length > 0 || virtualReceipt.receiptDiscounts.length > 0,
);
