import { Box } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOnSiteSettingsQuery } from 'stores/Intake/intake.api';
import { CashierRoute } from 'components/Auth/CashierRoute';
import routes, { DefaultRoute } from '../../Routes/routes-list';
import buildClasses from '../MainContainer.css';

export const CashierRoutes = memo(() => {
  const { classes } = buildClasses();
  const navigate = useNavigate();
  const { isFetching: onSiteSettingsFetching, isSuccess: onSiteSettingsSuccess } = useGetOnSiteSettingsQuery();
  const onSiteSettingsAvailable = !onSiteSettingsFetching && onSiteSettingsSuccess;

  const redirectToBase = useCallback(() => {
    navigate(DefaultRoute.path);
  }, [navigate]);

  return (
    <Box component="main" className={classes.content}>
      <div className={classes.menuHeader} id="application_main_bar_container" />
      {onSiteSettingsAvailable &&
        routes.map((route) => {
          return <CashierRoute routeDetails={route} key={route.path} onRedirectToBase={redirectToBase} />;
        })}
    </Box>
  );
});

CashierRoutes.displayName = 'CashierRoutes';
