import React, { memo } from 'react';
import SettlePayment from 'components/Shared/SettlePayment/SettlePayment';
import OrderPayment from 'components/Shared/OrderPayment/OrderPayment';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentModalToShow } from 'stores/OrderPayment/orderPayment.selector';

export const PaymentModal = memo(() => {
  const paymentModalToShow = useAppSelector(getPaymentModalToShow);
  return (
    <>
      {paymentModalToShow === 'oldModal' && <SettlePayment />}
      {paymentModalToShow === 'newModal' && <OrderPayment />}
    </>
  );
});

PaymentModal.displayName = 'PaymentModal';
