import { ButtonBase } from '@mui/material';
import { LastPageOutlined } from '@mui/icons-material';
import React, { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import DineInReceiptHeader from 'components/Intake/Receipt/DineIn/DineInReceiptHeader';
import IntakeCustomer from 'components/Intake/Receipt/Customer/IntakeCustomer';
import { setIntakeStatus } from 'stores/Intake';
import { removeCustomerFromOrder } from 'stores/combined.actions';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCanEditBasket } from 'stores/Intake/intake.selector';
import {
  CheckoutDetailsForm,
  emptyDetailsForm,
} from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { getSelectedDineInOrder } from 'stores/DineIn/dineIn.selector';
import buildClasses from '../Receipt.css';
import { AvailableIntakeContents, IntakeStatuses } from '../../IntakeConsts';
import { ReceiptControls } from '../ReceiptControls/ReceiptControls';
import ReceiptDineInContent from '../DineIn/ReceiptDineInContent';
import { ReceiptItems } from '../Items/ReceiptItems';

type ReceiptDataSectionProps = {
  intakeStatus: IntakeStatuses;
  canEdit?: boolean;
  onHideReceipt?: () => void;
  isCustomerSearchEnabled: boolean;
  showCustomerSearch: () => void;
};

export const ReceiptDataSection: React.FC<ReceiptDataSectionProps> = memo(
  ({ intakeStatus, canEdit, onHideReceipt, isCustomerSearchEnabled, showCustomerSearch }) => {
    const { classes } = buildClasses();
    const dispatch = useAppDispatch();
    const basketEdition = useAppSelector(getCanEditBasket);
    const selectedDineInOrder = useAppSelector(getSelectedDineInOrder);

    const { selectedOrderCustomer, activeIntakeTab } = useAppSelector(({ intake }) => intake);

    const { reset } = useFormContext<CheckoutDetailsForm>();

    const editOrderHandler = useCallback((): void => {
      canEdit && dispatch(setIntakeStatus(IntakeStatuses.productsSection));
    }, [canEdit, dispatch]);

    const onCustomerRemoveClicked = useCallback((): void => {
      reset(emptyDetailsForm);
      dispatch(removeCustomerFromOrder());
      dispatch(recalculateBasket({}));
    }, [dispatch, reset]);

    return (
      <>
        <div className={classes.topButtonsContainer}>
          {basketEdition && (
            <ReceiptControls
              editOrder={editOrderHandler}
              canEditOrder={intakeStatus === IntakeStatuses.productsSection}
            />
          )}
          {(activeIntakeTab === AvailableIntakeContents.Tables ||
            activeIntakeTab === AvailableIntakeContents.DineInOpenOrders) && (
            <ButtonBase className={classes.hideReceiptButton} onClick={onHideReceipt}>
              <LastPageOutlined />
            </ButtonBase>
          )}
        </div>
        {selectedDineInOrder && <DineInReceiptHeader dineInOrder={selectedDineInOrder} />}
        {(isCustomerSearchEnabled || selectedOrderCustomer?.profile?.id) && !selectedDineInOrder && (
          <IntakeCustomer
            selectedOrderCustomer={selectedOrderCustomer}
            onSearchCustomerClick={showCustomerSearch}
            onCustomerRemoveClick={onCustomerRemoveClicked}
          />
        )}
        {selectedDineInOrder ? (
          <ReceiptDineInContent dineInOrder={selectedDineInOrder} />
        ) : (
          <ReceiptItems canRemoveItems={intakeStatus === IntakeStatuses.productsSection} />
        )}
      </>
    );
  },
);

ReceiptDataSection.displayName = 'ReceiptDataSection';
