import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';
import { useWindowSize } from 'usehooks-ts';
import SwiperPagination from 'components/Shared/Pagination/Pagination';
import 'swiper/swiper.min.css';
import { RewardCardItem, RewardCardViewItem } from 'typings/Loyalty';
import RewardCard from 'components/Intake/Loyalty/RewardCard';
import LoyaltyTierSeparator from 'components/Intake/Loyalty/LoyaltyTierSeparator';
import buildClasses from './RewardsCatalog.css';

export type OverflowableRewardsCatalogProps = {
  rewardsCardItems: RewardCardItem[];
};

interface RewardsPagination {
  activePage: number;
  pageRewards: RewardCardViewItem[][];
}

const sizes = {
  rewardCardHeight: 101,
  rewardCardWidth: 242,
  tierSeparatorHeight: 32,
  gapHeight: 8,
  pagerHeight: 40,
};

const PagedRewardsCatalog: React.FC<OverflowableRewardsCatalogProps> = ({ rewardsCardItems }) => {
  const { classes } = buildClasses();
  const productGridRef = useRef<HTMLElement>(null);
  const [pagination, setProductPagination] = useState<RewardsPagination>({
    activePage: 0,
    pageRewards: [[]],
  });
  const { width, height } = useWindowSize();

  const spreadItemsUponPages = (): void => {
    const rewardsPages: RewardCardViewItem[][] = [];
    const gridHeight = productGridRef.current?.clientHeight ?? 0;
    const gridWidth = productGridRef.current?.clientWidth ?? 0;
    const rewardsPerRow = Math.floor(gridWidth / sizes.rewardCardWidth);
    const tierIds: string[] = [];
    let calculatedContentHeight = sizes.tierSeparatorHeight + sizes.rewardCardHeight;
    let numberOfTiersInPage = 0;
    let rewardsInRowCounter = 0;

    let addTierSeparator = false;
    let currentPage: RewardCardViewItem[] = [];

    rewardsCardItems.forEach((item) => {
      if (!tierIds.includes(item.tier.id)) {
        tierIds.push(item.tier.id);

        numberOfTiersInPage += 1;
        calculatedContentHeight += sizes.tierSeparatorHeight;
        calculatedContentHeight +=
          numberOfTiersInPage > 1 ? sizes.rewardCardHeight + sizes.tierSeparatorHeight + sizes.gapHeight : 0;

        addTierSeparator = true;
      }

      if (item.showReward) {
        rewardsInRowCounter += 1;
        // Add reward card height if new row is populated
        if (rewardsInRowCounter > rewardsPerRow) {
          rewardsInRowCounter = 0;
          calculatedContentHeight += sizes.rewardCardHeight;
        }
      }

      // new elements should go to new page
      if (calculatedContentHeight + sizes.pagerHeight > gridHeight) {
        rewardsPages.push(currentPage);

        addTierSeparator = true;
        calculatedContentHeight = sizes.tierSeparatorHeight + sizes.rewardCardHeight;
        currentPage = [];
      }

      if (addTierSeparator) {
        numberOfTiersInPage += 1;
        calculatedContentHeight += sizes.gapHeight;

        calculatedContentHeight +=
          numberOfTiersInPage > 1 ? sizes.rewardCardHeight + sizes.tierSeparatorHeight + sizes.gapHeight : 0;

        currentPage.push({
          ...item,
          displayTierSeparator: true,
        } as RewardCardViewItem);
      }

      if (item.showReward) {
        currentPage.push({
          ...item,
          displayTierSeparator: false,
        } as RewardCardViewItem);
      }
      addTierSeparator = false;
    });

    rewardsPages.push(currentPage);

    setProductPagination({
      activePage: 0,
      pageRewards: rewardsPages,
    });
  };

  useEffect(() => {
    spreadItemsUponPages();
  }, [width, height]);

  useEffect(() => {
    spreadItemsUponPages();
  }, [rewardsCardItems]);

  return (
    <Box sx={{ mt: 3, flex: 1, overflow: 'hidden', marginTop: '0px' }} ref={productGridRef}>
      <Swiper
        modules={[Pagination, Mousewheel]}
        className={classes.swiper}
        mousewheel
        wrapperClass={classes.swiperWrapper}
        onSlideChange={(swiper) => {
          setProductPagination({
            ...pagination,
            activePage: swiper.activeIndex,
          });
        }}
      >
        {pagination.pageRewards.map((page, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide className={classes.swiperSlide} key={`SWIPER_PAGE_${index}`}>
              {page.map((rewardCardViewItem) => {
                if (rewardCardViewItem.displayTierSeparator) {
                  return (
                    <LoyaltyTierSeparator
                      key={`tier-separator-${rewardCardViewItem.tier.id}`}
                      tier={rewardCardViewItem.tier}
                    />
                  );
                }

                return (
                  <RewardCard
                    reward={rewardCardViewItem.reward}
                    key={`reward-card-${rewardCardViewItem.reward.id}`}
                  />
                );
              })}
            </SwiperSlide>
          );
        })}
        <SwiperPagination pageItems={pagination.pageRewards} />
      </Swiper>
    </Box>
  );
};

export default PagedRewardsCatalog;
