import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { isPaymentCancellableOnlyOnDevice } from 'stores/EftPayment/eftPayment.selector';

const EftPending: React.FC = () => {
  const [t] = useTranslation('common');
  const {
    abandonInProgress,
    cancelationInProgress,
    eftAcceptanceInProgress,
    manualConfirmInProgress,
    startInProgress,
    eftStatusResponse,
  } = useAppSelector(({ eftPayment }) => eftPayment);
  const isCancellableOnlyOnDevice = useAppSelector(isPaymentCancellableOnlyOnDevice);

  function getPendingMessage(): string {
    if (eftStatusResponse?.status) {
      return eftStatusResponse.status.toString();
    }
    if (abandonInProgress) return 'Abandon in progress';
    if (cancelationInProgress) return 'Cancellation in progress';
    if (eftAcceptanceInProgress) return 'Eft Acceptance in progress';
    if (manualConfirmInProgress) return 'Manual confirmation in progress';
    if (startInProgress) return 'Starting eft payment';

    return 'Eft transaction in progress';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '374px',
        minHeight: '456px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Typography
        color="black.main"
        variant="h5"
        sx={{ m: 1 }}
        data-testid="settle-payment__label--eft-in-progress"
      >
        {t(getPendingMessage())}
      </Typography>
      <CircularProgress sx={{ mt: 3 }} color="primary" size="150px" />
      {isCancellableOnlyOnDevice && (
        <Typography
          color="black.main"
          variant="subtitle1"
          sx={{ m: 1, position: 'absolute', bottom: '20px' }}
          data-testid="settle-payment__label--cancellable-only-on-device"
        >
          {t('Please follow the instructions on the terminal')}
        </Typography>
      )}
    </Box>
  );
};
export default EftPending;
