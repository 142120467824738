import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box, Button, Grid2 as Grid, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OrderDetailsSection from 'components/Orders/OrderDetails/OrderDetailsSection';
import OrderStatusLabel from 'components/Orders/OrderDetails/OrderStatusLabel';
import OrderDetailsDataField from 'components/Orders/OrderDetails/OrderDetailsDataField';
import { getDateTimeFormatter, getPaymentFormatter } from 'stores/Payments/payment.selector';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import BasketItem from 'components/Shared/Basket/BasketItem';
import BasketDiscount from 'components/Shared/Basket/BasketDiscount';
import { addInternalComment, getOrderDetails } from 'stores/AllOrders';
import { formatTimeSpan } from 'utils/localisation/dateTimeUtils';

interface OrderDetailsOverviewProps {
  showCommentInput: boolean;
  onAddCommentClick: () => void;
  onCloseCommentEditClick: () => void;
}

const OrderDetailsOverview: React.FC<OrderDetailsOverviewProps> = ({
  showCommentInput,
  onAddCommentClick,
  onCloseCommentEditClick,
}) => {
  const { selectedOrderDetails } = useAppSelector((state) => state.allOrders);
  const [t] = useTranslation('orders');
  const formatDateTime = useAppSelector(getDateTimeFormatter);
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [commentContent, setCommentContent] = useState('');
  const commentInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    commentInputRef?.current?.focus();
    commentInputRef?.current?.scrollIntoView();
  }, [showCommentInput]);

  if (!selectedOrderDetails) return null;

  function handleAddComment() {
    if (onAddCommentClick) onAddCommentClick();
  }

  function handleCancelComment() {
    if (onCloseCommentEditClick) onCloseCommentEditClick();
  }

  function handleSaveComment() {
    if (selectedOrderDetails)
      dispatch(
        addInternalComment({
          comment: commentContent,
          orderId: selectedOrderDetails?.id,
          successCallback: () => {
            dispatch(getOrderDetails({ doFetch: true, publicId: selectedOrderDetails?.id as string }));
            onCloseCommentEditClick();
          },
        }),
      );
  }

  const {
    customerDetails: { name, surname, telephoneNumber, emailAddress },
    deliveryAddressFormatted,
    companyName,
    status,
    isEatIn,
    pickupType,
    paymentMethod,
    createdAt,
    externalReference,
    ticketNumber,
    collectionTimestamp,
    systemName,
    remarks,
    virtualReceipt: { receiptProducts, receiptDiscounts },
    priceSummary: { additionalTransactionCost, originalTotal, total },
    deliveryCharge,
    internalComments,
    isRefunded,
  } = selectedOrderDetails;

  return (
    <>
      <OrderDetailsSection name="Customer details">
        <Grid container spacing={0}>
          <Grid size={{ xs: 6 }}>
            <OrderDetailsDataField name="Name" value={`${name} ${surname}`} />
            <OrderDetailsDataField name="Address" value={deliveryAddressFormatted} />
            <OrderDetailsDataField name="Attached company" value={companyName} />
          </Grid>
          <Grid size={{ xs: 6 }}>
            <OrderDetailsDataField name="Phone" value={telephoneNumber} />
            <OrderDetailsDataField name="Email" value={emailAddress} />
          </Grid>
        </Grid>
      </OrderDetailsSection>
      <OrderDetailsSection name="Order details">
        <Grid container spacing={0}>
          <Grid size={{ xs: 6 }}>
            <OrderStatusLabel status={status} isOrderRefunded={isRefunded} />
            <OrderDetailsDataField name="Delivery type" value={isEatIn ? t('EatIn') : pickupType.toString()} />
            <OrderDetailsDataField name="Payment method" value={paymentMethod?.description} />
            <OrderDetailsDataField name="Ordered on" value={formatDateTime(new Date(createdAt))} />
            {externalReference && <OrderDetailsDataField name="External reference" value={externalReference} />}
          </Grid>
          <Grid size={{ xs: 6 }}>
            <OrderDetailsDataField name="Ticket number" value={ticketNumber?.toString() ?? 'Unknown'} />
            <OrderDetailsDataField name="Waiting time" value={formatTimeSpan(selectedOrderDetails.waitTime, t)} />
            <OrderDetailsDataField name="Pickup date time" value={formatDateTime(new Date(collectionTimestamp))} />
            <OrderDetailsDataField name="Channel" value={systemName} />
          </Grid>
        </Grid>
      </OrderDetailsSection>
      <OrderDetailsSection name="Order products">
        {remarks && (
          <Box
            sx={{ px: 2, py: 1, mb: 1, backgroundColor: palette.grey[50] }}
            data-testid="all-orders__order_details_field-order-remark"
          >
            <Typography variant="caption" sx={{ mb: 1 }} color="grey.800">
              {t('Order remark')}
            </Typography>
            <Typography variant="body2" color="grey.800">
              {remarks}
            </Typography>
          </Box>
        )}
        <Box sx={{ backgroundColor: palette.grey[50], mb: 2 }}>
          {receiptProducts.map((rp, index) => (
            <BasketItem receiptProduct={rp} key={rp.basketItemGuid} index={index} />
          ))}
          {receiptDiscounts.map((rd) => (
            <BasketDiscount receiptDiscount={rd} key={rd.couponId} />
          ))}
          {additionalTransactionCost > 0 && (
            <Box
              sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-testid="all-orders__order_details_field-additional-transaction-cost"
            >
              <Typography variant="body2" color="grey.800">
                {t('Additional transaction cost')}
              </Typography>
              <Typography variant="subtitle1" color="grey.800">
                {formatToDisplay(additionalTransactionCost)}
              </Typography>
            </Box>
          )}
          {deliveryCharge > 0 && (
            <Box
              sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-testid="all-orders__order_details_field-delivery-charge"
            >
              <Typography variant="body2" color="grey.800">
                {t('Delivery charge')}
              </Typography>
              <Typography variant="subtitle1" color="grey.800">
                {formatToDisplay(deliveryCharge)}
              </Typography>
            </Box>
          )}

          <Box sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="grey.800">
              {t('Order amount')}
            </Typography>
            <Typography
              variant="subtitle1"
              color="grey.800"
              data-testid="all-orders__order_details_field-order-amount"
            >
              {formatToDisplay(originalTotal)}
            </Typography>
          </Box>
          <Box sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2" color="black.main">
              {t('Total amount')}
            </Typography>
            <Typography variant="h6" color="black.main" data-testid="all-orders__order_details_field-total-amount">
              {formatToDisplay(total)}
            </Typography>
          </Box>
        </Box>
      </OrderDetailsSection>
      <OrderDetailsSection name="Comment">
        {internalComments.map((ic) => (
          <Box sx={{ marginBottom: 1 }} key={new Date(ic.createdAt).valueOf()}>
            <Box
              sx={{ py: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              data-testid="all-orders__order_details_field-comment"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <AccountCircleIcon sx={{ marginRight: 1, color: palette.grey[600] }} />
                <Typography variant="body1" color="grey.600">
                  {ic.userName}
                </Typography>
              </Box>
              <Typography variant="body2" color="grey.600">
                {formatDateTime(new Date(ic.createdAt))}
              </Typography>
            </Box>
            <Box sx={{ px: 2, py: 1, backgroundColor: palette.grey[50], display: 'flex', alignItems: 'center' }}>
              <CommentOutlinedIcon sx={{ marginRight: 1, color: palette.grey[400] }} />
              <Typography variant="body2" color="grey.800" sx={{ whiteSpace: 'pre-line' }}>
                {ic.comment}
              </Typography>
            </Box>
          </Box>
        ))}
        {!showCommentInput && (
          <Button
            fullWidth
            variant="outlined"
            color="black"
            startIcon={<AddCommentOutlinedIcon />}
            onClick={handleAddComment}
            data-testid="all-orders__order_details_button-comment-add"
          >
            {t('COMMENT')}
          </Button>
        )}
        {showCommentInput && (
          <Box>
            <TextField
              fullWidth
              type="text"
              multiline
              rows={3}
              inputRef={commentInputRef}
              placeholder={t('Add comment to the order')}
              data-testid="all-orders__order_details_text_input-comment"
              variant="outlined"
              onChange={(event): void => {
                setCommentContent(event.target.value);
              }}
              sx={{ p: 0 }}
              InputProps={{
                sx: { p: 0 },
              }}
            />
            <Box
              sx={{
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              <Button
                variant="text"
                color="black"
                onClick={handleCancelComment}
                data-testid="all-orders__order_details_button-comment-cancel"
              >
                {t('CANCEL')}
              </Button>
              <Button
                variant="outlined"
                color="black"
                onClick={handleSaveComment}
                data-testid="all-orders__order_details_button-save"
              >
                {t('ADD')}
              </Button>
            </Box>
          </Box>
        )}
      </OrderDetailsSection>
    </>
  );
};

export default OrderDetailsOverview;
