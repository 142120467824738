import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from 'containers/AllOrders/AllOrderConsts';

interface OrderStatusIndicatorProps {
  status: string;
  isOrderRefunded: boolean;
}

const OrderStatusIndicator: React.FC<OrderStatusIndicatorProps> = ({ status, isOrderRefunded }) => {
  const [t] = useTranslation('orders');
  const { spacing, palette } = useTheme();

  function getStatusColor(status: string): string {
    switch (status.toUpperCase()) {
      case OrderStatus.ToPrepare.toUpperCase():
        return palette.orderStatus.toPrepare;
      case OrderStatus.InPreparation.toUpperCase():
        return palette.orderStatus.inPreparation;
      case OrderStatus.Canceled.toUpperCase():
        return palette.orderStatus.canceled;
      case OrderStatus.Completed.toUpperCase():
        return palette.orderStatus.completed;
      case OrderStatus.OutForDelivery.toUpperCase():
        return palette.orderStatus.inDelivery;
      case OrderStatus.ReadyForDispatch.toUpperCase(): {
        return palette.orderStatus.outForDelivery;
      }
      default:
        return palette.grey[100];
    }
  }

  function getDisplayStatus() {
    if (status.toUpperCase() === OrderStatus.Canceled.toUpperCase() && isOrderRefunded) {
      return 'CanceledAndRefunded';
    }

    return status;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: getStatusColor(status),
          width: spacing(1),
          height: spacing(1),
          borderRadius: spacing(1),
          marginRight: spacing(1.5),
        }}
      />
      <Typography variant="body2">{t(getDisplayStatus())}</Typography>
    </Box>
  );
};

export default OrderStatusIndicator;
