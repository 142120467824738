import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, typography }) => {
  return {
    chip: {
      color: palette.black.main,
      fill: palette.black.main,
      '&:hover': {
        backgroundColor: '#DBE1E3 !important',
      },
      '&:active': {
        boxShadow: 'none',
      },
      backgroundColor: palette.white,
      border: `1px solid #DBE1E3`,
      fontFamily: typography.fontFamily,
      fontWeight: typography.fontWeightRegular,
      fontSize: typography.body1.fontSize,
      lineHeight: typography.body1.lineHeight,
    },
    activeChip: {
      '&:hover': {
        backgroundColor: `${palette.products?.groups?.active?.background ?? palette.grey[200]} !important`,
      },
      backgroundColor: palette.products?.groups?.active?.background ?? palette.grey[200],
      border: `1px solid ${palette.products?.groups?.active?.border ?? palette.grey[500]}`,
    },
  };
});

export default buildClasses;
