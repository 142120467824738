import { useEffect } from 'react';

export const useWindowResizeEvents = () => {
  useEffect(() => {
    const setInnerHeightCssProperty = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setInnerHeightCssProperty();

    window.addEventListener('resize', setInnerHeightCssProperty);
    window.addEventListener('orientationchange', setInnerHeightCssProperty);

    return () => {
      window.removeEventListener('resize', setInnerHeightCssProperty);
      window.removeEventListener('orientationchange', setInnerHeightCssProperty);
    };
  }, []);
};
