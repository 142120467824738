import { createApi } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import { FdmOrdersFiscalizationStatusView } from 'typings/Fiscalization/fiscalizationTypes';
import { posBaseQuery } from '../base.queries';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getNoOfOrdersToConfirmEta: builder.query<number, number | undefined>({
      query: () => posApiUrls.OSM_HISTORY_ORDERS_TO_CONFIRM,
    }),
    getOrdersFiscalStatus: builder.query<FdmOrdersFiscalizationStatusView, void>({
      query: () => posApiUrls.OSM_ORDER_FISCALIZATION_STATUS,
    }),
  }),
});
export const { useGetOrdersFiscalStatusQuery, useGetNoOfOrdersToConfirmEtaQuery } = notificationsApi;
