import React from 'react';
import { AppBar, Tab, Tabs, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useAppSelector } from 'hooks/useRedux';
import { getAvailableDeliveryTypes } from 'stores/Intake/intake.selector';

export interface PickupTypeBarProps {
  activeDeliveryType: PickUpTypesValues;
  onChange: (selectedDeliveryType: PickUpTypesValues) => void;
}

const PickUpTypeBar: React.FC<PickupTypeBarProps> = ({ activeDeliveryType, onChange }): JSX.Element => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const availableDeliveryTypes = useAppSelector(getAvailableDeliveryTypes);

  return (
    availableDeliveryTypes && (
      <AppBar
        position="static"
        sx={{ background: palette.appBar?.subMain ?? palette.primary.dark, boxShadow: 'none' }}
      >
        <Tabs
          value={activeDeliveryType}
          onChange={(_, value) => onChange(value)}
          variant="scrollable"
          scrollButtons
          sx={{
            '& .MuiTabs-indicator': {
              height: spacing(0.5),
              background: palette.appBar?.subMainText ?? palette.primary.contrastText,
            },
          }}
          textColor="secondary"
          aria-label="scrollable force tabs example"
        >
          {availableDeliveryTypes.map((deliveryOption) => (
            <Tab
              key={deliveryOption.value}
              label={t(deliveryOption.value)}
              sx={{
                minWidth: '160px',
                color: palette.appBar?.subMainText ?? palette.primary.contrastText,
                fill: palette.appBar?.subMainText ?? palette.primary.contrastText,
                '&.Mui-selected': {
                  fontWeight: 700,
                  color: `${palette.appBar?.subMainText ?? palette.primary.contrastText} !important`,
                  fill: `${palette.appBar?.subMainText ?? palette.primary.contrastText} !important`,
                },
              }}
              icon={<deliveryOption.icon color="white" />}
              value={deliveryOption.value}
              data-testid={`finalize-order-type__button--${deliveryOption.value}`}
            />
          ))}
        </Tabs>
      </AppBar>
    )
  );
};

export default PickUpTypeBar;
