import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';

import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { getCurrentGiftCardActivation } from 'stores/GiftCardActivation/giftCardActivation.selector';
import { useTheme } from '@mui/system';
import MultipleCardsIndicator from 'components/Shared/GiftCardActivation/MultipleCardsIndicator';

export type GiftCardActivationStep = 'scanOrManual' | 'enterCardNumber' | 'success' | 'failure';

const ActivationInProgress: React.FC = () => {
  const [t] = useTranslation('common');
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const currentGiftCard = useAppSelector(getCurrentGiftCardActivation);

  if (!currentGiftCard) return null;

  return (
    <PaymentFrame>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <MultipleCardsIndicator />
        <Typography color="black.main" variant="h5">
          {t('Please wait...')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={spacing(20)} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h5" color="black.main">
          {t('Activating and loading:')}
        </Typography>
        <Typography
          variant="h2"
          color="black.main"
          sx={{ p: 2, background: palette.grey[50], borderRadius: spacing(1) }}
        >
          {formatToDisplay(currentGiftCard.amount)}
        </Typography>
      </Box>
    </PaymentFrame>
  );
};
export default ActivationInProgress;
