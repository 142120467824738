import React, { memo, useCallback, useState } from 'react';
import { CheckCircle } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import noOptionImage from 'assets/images/no-option-image-grey.png';
import { Option } from 'typings/Products';
import buildClasses from './ProductOptions.css';

type ProductOptionProps = {
  option: Option;
  isActive: boolean;
  onClick: (optionId: number) => void;
  activeCategoryCode: string;
};

const optionImageUrl = (url: string): string => {
  if (!url) {
    return noOptionImage;
  }
  return url;
};

export const ProductOption: React.FC<ProductOptionProps> = memo(
  ({ option, isActive, onClick, activeCategoryCode }) => {
    const { classes, cx } = buildClasses();

    const [showFallbackImage, setShowFallbackImage] = useState(false);

    const onImageError = useCallback((): void => {
      setShowFallbackImage(true);
    }, []);

    const click = useCallback((): void => {
      onClick(option.id);
    }, [onClick, option.id]);

    return (
      <ButtonBase
        className={cx(classes.buttonBox, { [classes.activeButtonBox]: isActive })}
        onClick={click}
        data-testid={`product-options__button--${option.title}`}
      >
        {option.imageUrl && !showFallbackImage ? (
          <img
            className={classes.image}
            src={optionImageUrl(option.imageUrl)}
            alt={option.title}
            onError={onImageError}
          />
        ) : (
          <div
            className={cx(classes.buttonBoxSubCategory, {
              [classes.activeButtonBoxSubCategory]: isActive,
            })}
          >
            <span>{activeCategoryCode}</span>
          </div>
        )}
        <span className={classes.optionButton}>{option.name}</span>
        {isActive && <CheckCircle className={classes.activeIcon} />}
      </ButtonBase>
    );
  },
);

ProductOption.displayName = 'ProductOption';
