import React from 'react';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { Add, Face, RemoveCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OrderCustomerProfile } from 'stores/Intake/intakeTypes';
import { useAppSelector } from 'hooks/useRedux';
import { getIntakeSettings } from 'stores/Config/config.selector';
import buildClasses from './IntakeCustomer.css';

interface IntakeCustomerProps {
  selectedOrderCustomer?: OrderCustomerProfile;
  onSearchCustomerClick?: () => void;
  onCustomerRemoveClick?: () => void;
}

const IntakeCustomer: React.FC<IntakeCustomerProps> = ({
  selectedOrderCustomer,
  onSearchCustomerClick,
  onCustomerRemoveClick,
}) => {
  const { classes, cx } = buildClasses();
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');

  const intakeSettings = useAppSelector(getIntakeSettings);

  const isCustomerAssigned = selectedOrderCustomer?.profile?.id;
  const showEmptyBasketTitle = intakeSettings?.hideCustomerSearch === true;

  const customerIconClassName = cx(classes.customerIcon, {
    [classes.customerSelected]: isCustomerAssigned !== undefined && isCustomerAssigned > 0,
  });

  if (showEmptyBasketTitle) {
    if (isCustomerAssigned)
      return (
        <Box
          sx={{ float: 'left', margin: spacing(2), display: 'flex', alignItems: 'center' }}
          data-testid="receipt-customer-field"
        >
          <Face className={customerIconClassName} />
          <Typography data-testid="receipt-customer-field__customer--name" variant="body1">
            {`${selectedOrderCustomer?.name} ${selectedOrderCustomer?.surname}`}
          </Typography>
          <IconButton
            data-testid="-receipt-customer-search__button--remove-selected-customer"
            onClick={onCustomerRemoveClick}
            sx={{ marginLeft: 'auto' }}
          >
            <RemoveCircle />
          </IconButton>
        </Box>
      );
    return null;
  }

  return (
    <Box
      sx={{ float: 'left', margin: spacing(2), display: 'flex', alignItems: 'center' }}
      data-testid="receipt-customer-field"
    >
      <Face className={customerIconClassName} />
      <Typography data-testid="receipt-customer-field__customer--name" variant="body1">
        {isCustomerAssigned
          ? `${selectedOrderCustomer?.name} ${selectedOrderCustomer?.surname}`
          : t('Unknown customer')}
      </Typography>
      {isCustomerAssigned ? (
        <IconButton
          data-testid="-receipt-customer-search__button--remove-selected-customer"
          onClick={onCustomerRemoveClick}
          sx={{ marginLeft: 'auto' }}
        >
          <RemoveCircle />
        </IconButton>
      ) : (
        <Button
          color="black"
          data-testid="receipt-customer-search__button--search"
          onClick={onSearchCustomerClick}
          aria-label={t('Search')}
          startIcon={<Add />}
          sx={{ marginLeft: 'auto' }}
        >
          {t('Customer')}
        </Button>
      )}
    </Box>
  );
};

export default IntakeCustomer;
