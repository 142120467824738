import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentMethodGroup from 'components/Intake/Receipt/ToggleGroup/PaymentMethodsGroup';
import FormPart from '../FormPart/FormPart';

const PaymentMethodToggleGroup: React.FC = () => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('intake');

  return (
    <FormPart>
      <Box sx={{ minHeight: spacing(9.5) }}>
        <Typography variant="h6" gutterBottom sx={{ color: palette.black.main, marginBottom: spacing(3) }}>
          {t('Payment type')}
        </Typography>
        <PaymentMethodGroup />
      </Box>
    </FormPart>
  );
};

export default PaymentMethodToggleGroup;
