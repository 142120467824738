import React from 'react';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { ToppingDisplayModel } from 'typings/Products';

interface ReceiptProductToppingsProps {
  addedToppings?: ToppingDisplayModel[];
  removedToppings?: ToppingDisplayModel[];
}

const ReceiptProductToppings: React.FC<ReceiptProductToppingsProps> = ({ addedToppings, removedToppings }) => {
  const { spacing, palette } = useTheme();

  return (
    <div>
      {addedToppings && addedToppings.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: spacing(1),
            '&:last-child': {
              paddingBottom: spacing(0),
            },
          }}
          data-testid="receipt-item__details-toppingsAdded"
        >
          <AddCircleOutline sx={{ color: palette.green[600], marginRight: spacing(1) }} />
          <Typography variant="body2">
            {addedToppings
              .map((addedTopping) => `${addedTopping.toppingName} x${addedTopping.quantity}`)
              .join(', ')}
          </Typography>
        </Box>
      )}
      {removedToppings && removedToppings.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: spacing(1),
            '&:last-child': {
              paddingBottom: spacing(0),
            },
          }}
          data-testid="receipt-item__details-toppingsRemoved"
        >
          <RemoveCircleOutline sx={{ color: palette.error.main, marginRight: spacing(1) }} />
          <Typography variant="body2">
            {removedToppings.map((removedTopping) => `${removedTopping.toppingName}`).join(' , ')}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default ReceiptProductToppings;
