import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CouponState, MealCoupon } from 'typings/Coupons';
import { isCouponComplete } from 'utils/intake/couponsUtils';
import {
  getCouponsForCurrentBasket,
  getCustomizationCouponMealSettings,
  getRecalculatedSet,
} from './coupons.thunk-actions';

const initialState: CouponState = {
  couponsForCurrentBasket: [],
  pendingGetCouponsRequest: false,
};

const couponsSlice = createSlice({
  name: '[COUPONS]',
  initialState,
  reducers: {
    resetCouponsState: () => initialState,
    setCouponToCustomize: (state, action: PayloadAction<MealCoupon | undefined>) => {
      if (!action.payload) {
        return {
          ...state,
          couponToCustomize: action.payload,
        };
      }
      return {
        ...state,
        couponToCustomize: action.payload,
        complete: isCouponComplete(action.payload.meals),
      };
    },
    updateCouponToCustomize: (state, action: PayloadAction<Partial<MealCoupon>>) => {
      if (!state.couponToCustomize) {
        return state;
      }

      const couponToCustomize = {
        ...state.couponToCustomize,
        ...action.payload,
        complete: isCouponComplete(action.payload.meals ?? state.couponToCustomize.meals),
      };

      return {
        ...state,
        couponToCustomize,
      };
    },
    clearCouponError: (state) => {
      return { ...state, couponCustomizationError: undefined };
    },
  },
  extraReducers(builder) {
    builder.addCase(getCustomizationCouponMealSettings.pending, (state) => ({
      ...state,
      pendingGetCouponsRequest: true,
    }));
    builder.addCase(getCustomizationCouponMealSettings.fulfilled, (state, action) => ({
      ...state,
      couponToCustomize: action.payload,
      pendingGetCouponsRequest: false,
    }));
    builder.addCase(getCustomizationCouponMealSettings.rejected, (state, action) => {
      return {
        ...state,
        couponCustomizationError: action.error.message,
        pendingGetCouponsRequest: false,
      };
    });
    builder.addCase(getCouponsForCurrentBasket.pending, (state) => ({
      ...state,
      pendingGetCouponsRequest: true,
    }));
    builder.addCase(getCouponsForCurrentBasket.fulfilled, (state, action) => ({
      ...state,
      couponsForCurrentBasket: action.payload,
      pendingGetCouponsRequest: false,
    }));
    builder.addCase(getRecalculatedSet.pending, (state) => ({
      ...state,
      pendingGetCouponsRequest: true,
    }));
    builder.addCase(getRecalculatedSet.fulfilled, (state, action) => ({
      ...state,
      couponToCustomize: action.payload,
      pendingGetCouponsRequest: false,
    }));
  },
});

const { reducer } = couponsSlice;
export const { setCouponToCustomize, updateCouponToCustomize, resetCouponsState, clearCouponError } =
  couponsSlice.actions;

export default reducer;
