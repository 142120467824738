import React, { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import StartupWrapper from 'App/Routes/DefaultCompontent';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import './styleOverride.css';
import AlertModal from 'containers/Shared/AlertModal/AlertModal';
import AlertSnackbar from 'containers/Shared/Alert/AlertSnackbar';
import getThemeByKey from 'services/ThemeService';
import { useAppSelector } from 'hooks/useRedux';
import { DevModeProvider } from 'App/DevMode/DevModeProvider';
import { useGetConfigQuery } from 'stores/Config/config.api';
import { getThemeKey } from 'stores/Config/config.selector';
import AppInitializationWrapper from './AppInitializationWrapper/AppInitializationWrapper';
import Routes from './Routes/Routes';
import { useGlobalInterceptors } from './useGlobalInterceptors';
import { ConnectionDialogs } from './HybridMode/ConnectionDialogs';

const reactPlugin = new ReactPlugin();

const App = (): JSX.Element => {
  useGlobalInterceptors();
  const { isSuccess: configLoaded } = useGetConfigQuery();
  const [theme, setTheme] = React.useState<Theme>(getThemeByKey('S4D'));
  const themeKey = useAppSelector(getThemeKey);

  useEffect(() => {
    if (configLoaded && themeKey) {
      setTheme(getThemeByKey(themeKey));
    }
  }, [configLoaded, themeKey]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router
        // enable relevant stable future feature flags already to take advantage of a more responsive UI, remove warnings and ease transition to v7
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        {theme && (
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <DevModeProvider>
                <CssBaseline />
                <AppInitializationWrapper fallback={<StartupWrapper />}>
                  <Routes />
                  <AlertSnackbar />
                  <AlertModal />
                  <ConnectionDialogs />
                </AppInitializationWrapper>
              </DevModeProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        )}
      </Router>
    </AppInsightsContext.Provider>
  );
};

export default App;
