import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  abandonEftPayment,
  acceptEftPayment,
  cancelEftPayment,
  confirmEftManually,
  retryEftPayment,
  startEftPayment,
} from 'stores/EftPayment/eftPayment.actions';
import { EftPaymentOperation } from 'typings/EftPayment/eftPayment.types';
import { manualExitOrderPayment } from 'stores/OrderPayment/orderPayment.actions';
import {
  getCurrentlyPossibleOperations,
  isPaymentCancellableOnlyOnDevice,
} from 'stores/EftPayment/eftPayment.selector';
import { useFormContext } from 'react-hook-form';
import { getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import uuidGenerator from 'utils/GuidGenerator';
import { automaticFinalization, clearEftPaymentData } from 'stores/EftPayment/eftPayment.slice';
import { clearActivePayment } from 'stores/OrderPayment/orderPayment.slice';
import { getActivePayment, getFinalizationTimeDataSelector } from 'stores/OrderPayment/orderPayment.selector';

const EftOperationButtons: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { orderId, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const {
    abandonInProgress,
    cancelationInProgress,
    manualConfirmInProgress,
    eftAcceptanceInProgress,
    eftPaymentId,
    automaticFinalizationInProgress,
  } = useAppSelector(({ eftPayment }) => eftPayment);
  const { getValues } = useFormContext<OrderPaymentForm>();

  const possibleEftOperations = useAppSelector(getCurrentlyPossibleOperations);
  const stringToNumber = useAppSelector(getStringToNumberFormatter);
  const currentCashInput = stringToNumber(getValues().cashInput);
  const activePayment = useAppSelector(getActivePayment);
  const getFinalizationTimeData = useAppSelector(getFinalizationTimeDataSelector);
  const [timeToFinalization, setTimeToFinalization] = useState<number>();
  const automaticAcceptanceInterval = useRef<any>(null);
  const isCancellableOnlyOnDevice = useAppSelector(isPaymentCancellableOnlyOnDevice);

  useEffect(() => {
    if (possibleEftOperations.includes(EftPaymentOperation.Accept)) {
      tryStartAutomaticAcceptanceTimer();
    }
  }, [possibleEftOperations]);

  useEffect(() => {
    return () => {
      clearAutomaticAcceptanceTimer();
    };
  }, []);

  useEffect(() => {
    if (timeToFinalization === 0) {
      dispatch(automaticFinalization());
      acceptPayment();
      clearAutomaticAcceptanceTimer();
    }
  }, [timeToFinalization]);

  function tryStartAutomaticAcceptanceTimer(): void {
    if (automaticAcceptanceInterval?.current) return;

    const finalizationTime = getFinalizationTimeData();
    if (!finalizationTime.finalizationTime) return;

    setTimeToFinalization(finalizationTime.finalizationTime);
    automaticAcceptanceInterval.current = setInterval(
      () => setTimeToFinalization((current) => current && current - 1),
      1000,
    );
  }

  function clearAutomaticAcceptanceTimer(): void {
    clearInterval(automaticAcceptanceInterval?.current);
  }

  function cancelPayment(): void {
    dispatch(cancelEftPayment());
  }

  function abandonPayment(): void {
    dispatch(abandonEftPayment());
  }

  function confirmManualEft(): void {
    dispatch(confirmEftManually());
  }

  function acceptPayment(): void {
    clearAutomaticAcceptanceTimer();
    dispatch(acceptEftPayment());
  }

  function retryPayment(): void {
    if (eftPaymentId) {
      dispatch(retryEftPayment());
      return;
    }

    const toPayAmount = currentCashInput < totalLeftAmount ? currentCashInput : totalLeftAmount;
    dispatch(
      startEftPayment({
        orderAmount: toPayAmount,
        paymentId: uuidGenerator(),
        orderId: orderId as string,
        tipAmount: activePayment?.tipValue,
      }),
    );
  }

  function goBack(): void {
    dispatch(clearEftPaymentData());
    dispatch(clearActivePayment());
  }

  function exit(): void {
    dispatch(manualExitOrderPayment());
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: 2,
      }}
    >
      {possibleEftOperations.map((possibleOperation) => {
        switch (possibleOperation) {
          case EftPaymentOperation.Cancel:
            return (
              <Button
                onClick={() => {
                  cancelPayment();
                }}
                key="EftPaymentOperation.Cancel"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={cancelationInProgress}
                size="large"
                sx={{ py: 2, px: 3, display: `${isCancellableOnlyOnDevice ? 'none' : ''}` }}
                data-testid="eftPayment-button_Cancel"
              >
                {t('Cancel')}
              </Button>
            );
          case EftPaymentOperation.Abandon:
            return (
              <Button
                onClick={() => {
                  abandonPayment();
                }}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={abandonInProgress}
                size="large"
                sx={{ py: 2, px: 3 }}
                key="EftPaymentOperation.Abandon"
                data-testid="eftPayment-button_Abandon"
              >
                {t('Abandon')}
              </Button>
            );
          case EftPaymentOperation.ConfirmManually:
            return (
              <Button
                onClick={() => {
                  confirmManualEft();
                }}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={manualConfirmInProgress}
                size="large"
                sx={{ py: 2, px: 3 }}
                key="EftPaymentOperation.ConfirmManually"
                data-testid="eftPayment-button_ConfirmManually"
              >
                {t('Confirm manually')}
              </Button>
            );
          case EftPaymentOperation.Accept:
            return (
              <Button
                onClick={() => {
                  acceptPayment();
                }}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={eftAcceptanceInProgress || automaticFinalizationInProgress}
                size="large"
                sx={{ py: 2, px: 3 }}
                key="EftPaymentOperation.Accept"
                data-testid="eftPayment-button_Accept"
              >
                {timeToFinalization
                  ? `${t('Payment confirmation in')} ${timeToFinalization} ${t('sec')}`
                  : t('Confirm payment')}
              </Button>
            );
          case EftPaymentOperation.Exit:
            return (
              <>
                <Button
                  onClick={() => {
                    exit();
                  }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="large"
                  sx={{ py: 2, px: 3 }}
                  key="EftPaymentOperation.Exit"
                  data-testid="eftPayment-button_Exit"
                >
                  {t('Exit')}
                </Button>
                {totalLeftAmount > 0 && (
                  <Button
                    onClick={() => {
                      goBack();
                    }}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    sx={{ py: 2, px: 3 }}
                    key="EftPaymentOperation.GoBack"
                    data-testid="eftPayment-button_GoBack"
                  >
                    {t('Go back')}
                  </Button>
                )}
              </>
            );
          case EftPaymentOperation.RetryStart:
            return (
              <Button
                onClick={() => {
                  retryPayment();
                }}
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                sx={{ py: 2, px: 3 }}
                key="EftPaymentOperation.RetryStart"
                data-testid="eftPayment-button_RetryStart"
              >
                {t('Try again')}
              </Button>
            );

          default:
            return null;
        }
      })}
    </Box>
  );
};
export default EftOperationButtons;
