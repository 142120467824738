import React, { useCallback, useEffect, useState } from 'react';
import { Typography, ButtonBase, useTheme, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { Coupon, Meal } from 'typings/Coupons';
import MealPart from 'components/Intake/Coupon/MealCoupon/MealCoupon';
import ProductCustomization from 'containers/Intake/Products/ProductCustomization/ProductCustomization';
import { getAvailableProductOptions, isCustomizableProductComplete } from 'utils/intake/productCustomizationUtils';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { CustomizationProduct, ProductSetCustomization } from 'typings/Products';
import { addCouponToTheBasket, editCouponInBasket } from 'stores/Basket/basket.thunk-actions';
import uuidGenerator from 'utils/GuidGenerator';
import { getRecalculatedSet, setCouponToCustomize, updateCouponToCustomize } from 'stores/Coupons';
import { createSliceCustomizationsWithDisplayModel } from 'utils/intake/xTastyUtils';
import { mapToCustomization } from 'utils/intake/productSetUtils';
import { ErrorBoundary, type ErrorBoundaryPropsWithRender } from 'react-error-boundary';
import ErrorCoupon from 'containers/Intake/Coupons/ErrorCoupon/ErrorCoupon';
import buildClasses from './MealConfigurator.css';

const MealConfigurator = () => {
  const [t] = useTranslation('intake');
  const { palette } = useTheme();
  const { classes, cx } = buildClasses();
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const { options } = useAppSelector((state) => state.products);
  const { couponToCustomize } = useAppSelector((state) => state.coupons);
  const dispatch = useAppDispatch();

  const [mealValid, setMealsValid] = useState<boolean[]>([]);

  const [customizationMealProduct, setCustomizationMealProduct] = useState<{
    index: number;
    product: CustomizationProduct;
  }>();

  useEffect(() => {
    const validMeals: boolean[] = [];
    couponToCustomize?.meals?.forEach((meal, index) => {
      validMeals[index] = isCustomizableProductComplete(meal.selectedProduct);
    });
    setMealsValid(validMeals);
  }, [couponToCustomize]);

  const fallbackRender: ErrorBoundaryPropsWithRender['fallbackRender'] = useCallback(
    ({ resetErrorBoundary }) => {
      return (
        <ErrorCoupon
          title={t('error with coupon')}
          description={t('sorry coupon invalid')}
          onClose={() => {
            dispatch(setCouponToCustomize());
            resetErrorBoundary();
          }}
        />
      );
    },
    [dispatch, t],
  );

  if (!couponToCustomize) return null;

  function addCouponToBasket(coupon2add: Coupon): void {
    if (coupon2add.couponId) {
      dispatch(editCouponInBasket(coupon2add));
    } else {
      dispatch(addCouponToTheBasket({ ...coupon2add, couponId: uuidGenerator() }));
    }

    dispatch(setCouponToCustomize());
  }

  function customizeMealProduct(mealIndex: number, selectedProduct: CustomizationProduct) {
    const meal = couponToCustomize?.meals[mealIndex];

    setCustomizationMealProduct({
      index: mealIndex,
      product: {
        ...selectedProduct,
        restrictions: meal?.customizationRestrictions,
        stepSelections: meal?.selectedProduct?.stepSelections,
      },
    });
  }

  async function selectProductOnMeal(mealIndex: number, selectedProduct: CustomizationProduct) {
    if (!isCustomizableProductComplete(selectedProduct)) {
      customizeMealProduct(mealIndex, selectedProduct);
    } else if (couponToCustomize) {
      const meals = couponToCustomize?.meals ?? [];
      const meal = meals[mealIndex];
      const updatedMeals = [...meals];
      const newProduct = { ...selectedProduct };
      const availableOptions = getAvailableProductOptions(
        { ...selectedProduct, restrictions: meal.customizationRestrictions },
        options,
      );
      const defaultOption = availableOptions.find(
        (opt) => opt.id === selectedProduct.originalOptionId ?? meal.defaultOptionId,
      );
      const fallbackOption = availableOptions.length > 0 ? availableOptions[0] : undefined;

      const customization = selectedProduct.stepSelections
        ? mapToCustomization(selectedProduct, {
            selections: selectedProduct.stepSelections,
          } as ProductSetCustomization)
        : undefined;

      updatedMeals[mealIndex] = {
        ...updatedMeals[mealIndex],
        selectedProduct: {
          ...newProduct,
          optionName: defaultOption?.name ?? fallbackOption?.name,
          originalOptionId: defaultOption?.id ?? fallbackOption?.id ?? meal?.defaultOptionId ?? 0,
          sliceCustomizations: customization
            ? createSliceCustomizationsWithDisplayModel([customization], newProduct.baseProduct.defaultToppings)
            : newProduct.sliceCustomizations,
        },
      };
      const updatedCoupon = { couponCode: couponToCustomize.couponCode, meals: updatedMeals };
      dispatch(updateCouponToCustomize(updatedCoupon));
      dispatch(getRecalculatedSet(updatedCoupon));
    }
  }

  function isMealCustomizable(meal: Meal): boolean {
    if (!meal.selectedProduct) {
      return false;
    }

    const product = meal.availableProducts.find((prd) => prd.id === meal.selectedProduct?.baseProduct.id);

    if (product) {
      return product.isCustomizable;
    }

    return false;
  }

  const isMealValid = mealValid.every((isValid) => isValid === true);

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      {customizationMealProduct ? (
        <div className={classes.customizationRoot}>
          <ProductCustomization
            productToCustomize={customizationMealProduct.product}
            onCancelClick={(): void => {
              setCustomizationMealProduct(undefined);
            }}
            onAddItem={(productToAdd): void => {
              selectProductOnMeal(customizationMealProduct.index, productToAdd);
              setCustomizationMealProduct(undefined);
            }}
          />
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.navigation}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 0 }}
              data-testid="coupon-customization__button--cancel-coupon-customization"
              startIcon={<ArrowBack />}
              onClick={() => dispatch(setCouponToCustomize())}
            >
              {t('Cancel coupon configuration')}
            </Button>
            <Typography color="textPrimary" variant="h5">
              {couponToCustomize.title}
            </Typography>
          </div>
          <div className={classes.content}>
            {couponToCustomize.meals.map((meal, index) => (
              <MealPart
                // eslint-disable-next-line react/no-array-index-key
                key={`${meal.categoryName}_${index}`}
                meal={meal}
                isMealCustomizable={isMealCustomizable(meal)}
                onMealProductSelected={(product): void => {
                  selectProductOnMeal(index, product);
                }}
                customizeMealProduct={(product): void => {
                  customizeMealProduct(index, product);
                }}
                isMealValid={mealValid[index]}
              />
            ))}
          </div>
          <ButtonBase
            data-testid="coupon-customization__button--confirm-customization"
            onClick={(): void => {
              addCouponToBasket(couponToCustomize);
            }}
            className={cx(classes.summaryBar, {
              [classes.disabled]: !isMealValid,
            })}
          >
            <Typography color={palette.text.secondary} variant="h5">
              {t('Add')}
            </Typography>
            {couponToCustomize.price && (
              <Typography
                color={palette.text.secondary}
                variant="h5"
                data-testid="coupon-customization__label--calculated-price"
              >
                {formatToDisplay(couponToCustomize.price)}
              </Typography>
            )}
          </ButtonBase>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default MealConfigurator;
