import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AnimatePresence } from 'framer-motion';
import { setActivePaymentStep, setActivePaymentTip } from 'stores/OrderPayment/orderPayment.slice';
import { TipAmountType } from 'components/Shared/OrderPayment/OrderPayment.consts';
import { getActivePayment, getIsActivePaymentCashType } from 'stores/OrderPayment/orderPayment.selector';

const TipSelectionButtons: React.FC = () => {
  const [t] = useTranslation('common');
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const activePayment = useAppSelector(getActivePayment);
  const isActiveCashPayment = useAppSelector(getIsActivePaymentCashType);

  const { changeAmount, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);

  const [tipAmountType, setTipAmountType] = useState<TipAmountType>(isActiveCashPayment ? 'no-tip' : 'full-tip');
  const customerPaysWith = activePayment?.customerPaidWith ?? 0;

  const availableTipTypes: TipAmountType[] = isActiveCashPayment
    ? ['no-tip', 'full-tip', 'adjust-tip']
    : ['full-tip'];

  useEffect(() => {
    if (changeAmount === undefined || !activePayment) return;
    if (changeAmount === 0 && activePayment.tipValue > 0) {
      setTipAmountType('full-tip');
      return;
    }
    if (changeAmount > 0 && activePayment.tipValue > 0) {
      setTipAmountType('adjust-tip');
      return;
    }
    setTipAmountType('no-tip');
  }, [changeAmount]);

  return (
    <AnimatePresence initial={false}>
      {availableTipTypes.length > 1 && (
        <Typography key="tip-buttons-header" variant="body1" sx={{ color: palette.black.main, mb: 1 }}>
          {t('Keep the change as tip?')}
        </Typography>
      )}
      <ToggleButtonGroup value={tipAmountType} exclusive sx={{ width: '100%', gap: 1, mb: 3 }}>
        {availableTipTypes.map((item) => (
          <ToggleButton
            value={item}
            key={`tip-buttons-${item}`}
            disabled={['full-tip', 'adjust-tip'].some((el) => el === item) && customerPaysWith <= totalLeftAmount}
            onClick={() => {
              switch (item) {
                case 'adjust-tip':
                  dispatch(setActivePaymentStep('provideTip'));
                  break;
                case 'full-tip':
                  changeAmount &&
                    changeAmount > 0 &&
                    dispatch(setActivePaymentTip((changeAmount as number) + (activePayment?.tipValue ?? 0)));
                  dispatch(setActivePaymentStep('confirm'));
                  break;
                default:
                  dispatch(setActivePaymentTip(0));
                  dispatch(setActivePaymentStep('confirm'));
              }
            }}
            sx={{
              background: palette.grey[50],
              borderRadius: '4px !important',
              border: 'none !important',
              width: '100%',
              textTransform: 'none',
              px: 2,
              p: 1.25,
              '&.Mui-selected': {
                border: `1px solid${palette.grey[300]} !important`,
              },
            }}
            data-testid={`tip-amount__button--${item}`}
          >
            <Typography
              variant="body2"
              sx={{
                color: tipAmountType === item ? palette.black.main : palette.grey[600],
              }}
            >
              {t(item)}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </AnimatePresence>
  );
};
export default TipSelectionButtons;
