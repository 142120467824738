import React, { useCallback, memo } from 'react';
import { MenuItem, OutlinedInput } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SwitchStore, StoreInfo } from 'typings/Store';
import { useTranslation } from 'react-i18next';
import buildClasses from './StoreSelector.css';

type StoreSelectorProps = {
  handleStoreChange: (storeId: string) => void;
  availableStores: SwitchStore[];
  selectedStore: StoreInfo;
  switchedStore?: SwitchStore;
};

export const StoreSelector: React.FC<StoreSelectorProps> = memo(
  ({ handleStoreChange, availableStores, selectedStore, switchedStore }) => {
    const { classes } = buildClasses();

    const [t] = useTranslation('common');

    const onSelectChange = useCallback(
      (event: SelectChangeEvent) => {
        handleStoreChange(event.target.value);
      },
      [handleStoreChange],
    );

    const getRenderValueById = useCallback(
      (storeId: string) => {
        return availableStores.find((store) => `${store.id}` === storeId)?.name;
      },
      [availableStores],
    );

    return (
      <Select
        className={classes.storeSelector}
        value={selectedStore.id.toString()}
        onChange={onSelectChange}
        data-testid="store-panel-selected-store"
        input={<OutlinedInput className={classes.storeSelectorInner} />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        renderValue={getRenderValueById}
      >
        {availableStores.map((store) => {
          return (
            <MenuItem
              key={store.id}
              value={store.id}
              className={classes.selectItem}
              data-testid={`switch-store-list-item_${store.id}`}
            >
              <div className={classes.storeNameWrapper}>
                <Typography variant="body1" className={classes.storeName}>{`${store.name}`}</Typography>
                {store.isDefaultStore && (
                  <Typography variant="body2" className={classes.myStore}>
                    {`(${t('My store')})`}
                  </Typography>
                )}
              </div>
              {store.id === switchedStore?.id && <CheckRoundedIcon color="success" />}
            </MenuItem>
          );
        })}
      </Select>
    );
  },
);

StoreSelector.displayName = 'StoreSelector';
