import { OrderCustomer, OrderDeliveryAddress, OrderDeliveryTime } from 'stores/Intake/intakeTypes';
import { EftPaymentOperation } from 'typings/Payments';
import { format } from 'date-fns';

export interface CheckoutDetailsForm extends OrderDeliveryAddress, OrderDeliveryTime, OrderCustomer {
  orderId: string;
  EftPaymentOperation?: EftPaymentOperation;
  translations?: Translations;
}

export interface Translations {
  asap?: string;
}

export const emptyAddress: OrderDeliveryAddress = {
  addressRemark: undefined,
  addressType: undefined,
  apartmentNumber: undefined,
  buildingName: undefined,
  city: undefined,
  district: undefined,
  entranceNumber: undefined,
  region: undefined,
  street: undefined,
  streetNumber: undefined,
  streetNumberAddition: undefined,
  streetType: undefined,
  suggestionBox: undefined,
  zipcode: undefined,
};

export const emptyCustomerData: OrderCustomer = {
  name: '',
  surname: '',
  telephoneNumber: '',
  emailAddress: '',
};

export const emptyDeliveryTime: OrderDeliveryTime = {
  date: format(new Date(), 'yyyy-MM-dd'),
  time: undefined,
};

export const emptyDetailsForm = {
  ...emptyAddress,
  ...emptyCustomerData,
  ...emptyDeliveryTime,
};
