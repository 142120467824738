import React, { useCallback, useEffect, useRef, useState } from 'react';
import ProductCard from 'components/Intake/Product/Card/ProductCard';
import { useAppSelector } from 'hooks/useRedux';
import { Option, Product } from 'typings/Products';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { Mousewheel, Pagination } from 'swiper';
import { useWindowSize } from 'usehooks-ts';
import SwiperPagination from 'components/Shared/Pagination/Pagination';
import 'swiper/swiper.min.css';
import { getSelectedStore } from 'stores/Store/store.selectors';
import buildClasses from './ProductsCatalog.css';
import { useCalculatePageCount } from './useCalculatePageCount';

type ProductCatalogProps = {
  products: Product[];
  onProductClick: (product: Product) => void;
  selectedOption: Option | undefined;
};

export interface ProductPagination {
  activePage: number;
  pageProducts: Product[][];
}

const PagedCatalog: React.FC<ProductCatalogProps> = ({ products, onProductClick, selectedOption }) => {
  const { classes } = buildClasses();
  const productGridRef = useRef<HTMLElement>(null);
  const { width, height } = useWindowSize();
  const selectedStore = useAppSelector(getSelectedStore);
  const [pagination, setProductPagination] = useState<ProductPagination>({
    activePage: 0,
    pageProducts: [[]],
  });

  const calculatePageCount = useCalculatePageCount(productGridRef, products.length);

  const recalculatePagination = useCallback(() => {
    const { pagesCount, itemsPerPage } = calculatePageCount();
    const productsPages = [];
    const sortedProducts = products.sort((a, b) => a.sortOrder - b.sortOrder);
    for (let i = 0; i < pagesCount; i += 1) {
      productsPages.push(sortedProducts.slice(i * itemsPerPage, (i + 1) * itemsPerPage));
    }
    setProductPagination({
      activePage: 0,
      pageProducts: productsPages,
    });
  }, [calculatePageCount, products]);

  useEffect(() => {
    recalculatePagination();
  }, [width, height, recalculatePagination]);

  useEffect(() => {
    recalculatePagination();
  }, [products, recalculatePagination]);

  const onSlideChange = useCallback(
    (swiper: SwiperClass) => {
      setProductPagination({
        ...pagination,
        activePage: swiper.activeIndex,
      });
    },
    [pagination],
  );

  return (
    <Box sx={{ flex: 1, overflow: 'hidden' }} ref={productGridRef}>
      <Swiper
        modules={[Pagination, Mousewheel]}
        className={classes.swiper}
        mousewheel
        wrapperClass={classes.swiperWrapper}
        onSlideChange={onSlideChange}
      >
        {pagination.pageProducts.map((page, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide className={classes.swiperSlide} key={`SWIPER_PAGE_${index}`}>
              {page.map((product) => (
                <ProductCard
                  product={product}
                  selectedOption={selectedOption}
                  onProductSelected={onProductClick}
                  hideOptionName
                  key={`PRODUCT_${product.id}`}
                  displayImage={selectedStore?.displayProductImagesDuringIntake}
                />
              ))}
            </SwiperSlide>
          );
        })}
        <SwiperPagination pageItems={pagination.pageProducts} />
      </Swiper>
    </Box>
  );
};

export default PagedCatalog;
