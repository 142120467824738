import React from 'react';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { Typography, Zoom } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { Done } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface EftSuccessProps {
  toPay: number;
}

const EftSuccess: React.FC<EftSuccessProps> = ({ toPay }) => {
  const { t } = useTranslation('common');
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  return (
    <div>
      <Typography variant="h5" sx={{ m: 1 }} data-testid="settle-payment__label--eft-succes">
        {t('EftSuccess')}
      </Typography>
      <br />
      <Typography variant="body1" sx={{ m: 0 }} data-testid="settle-payment__label--eft-success-amount">
        {t('Eft amount')}
        {': '}
        {formatToDisplay(toPay)}
      </Typography>
      <br />
      <Zoom in style={{ transitionDelay: '1000ms' }}>
        <Done sx={{ fontSize: 150 }} color="primary" />
      </Zoom>
    </div>
  );
};

export default EftSuccess;
