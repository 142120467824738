import { Button, Tooltip } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDiscount } from 'assets/icons';
import { CashierPermission } from 'typings/Cashier';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { setManualPriceOverride } from 'stores/Intake';
import { numberFromMoney } from 'utils/payment/PaymentUtils';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { getPaymentConfig } from 'stores/Config/config.selector';
import { getCustomerCreditToUse } from 'stores/Customer/customer.selector';
import ManualPriceOverride from '../../ManualPriceOverride/ManualPriceOverride';

export const PriceOverrideButton = memo(() => {
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();
  const customerCreditToUse = useAppSelector(getCustomerCreditToUse);
  const canUserAccess = useAppSelector(getPermissionChecker);
  const paymentSettings = useAppSelector(getPaymentConfig);
  const [isManualPriceOverrideModal, setManualPriceOverrideOpen] = useState(false);

  const priceOverrideDisabled = !canUserAccess(CashierPermission.ChangeTotalPrice) || !!customerCreditToUse;

  const openManualPriceOverrideModal = useCallback(() => setManualPriceOverrideOpen(true), []);
  const closeManualPriceOverrideModal = useCallback(() => setManualPriceOverrideOpen(false), []);

  const saveManualDiscount = useCallback(
    (value?: string): void => {
      dispatch(setManualPriceOverride(value ? numberFromMoney(value, paymentSettings) : undefined));
      dispatch(recalculateBasket({}));
    },
    [dispatch, paymentSettings],
  );

  return (
    <>
      <Tooltip enterDelay={500} title={t('Order total override').toString()} arrow>
        <span>
          <Button
            variant="iconButton"
            disabled={priceOverrideDisabled}
            onClick={openManualPriceOverrideModal}
            data-testid="receipt-footer__button--manual-discount"
          >
            <OrderDiscount style={{ opacity: priceOverrideDisabled ? 0.5 : 1 }} />
          </Button>
        </span>
      </Tooltip>
      {isManualPriceOverrideModal && (
        <ManualPriceOverride
          open={isManualPriceOverrideModal}
          onClose={closeManualPriceOverrideModal}
          onSuccess={saveManualDiscount}
        />
      )}
    </>
  );
});

PriceOverrideButton.displayName = 'PriceOverrideButton';
