import React from 'react';
import { TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ModalContainer from '../Modal/ModalContainer';

interface FormsData {
  textField: string;
}

interface TextFieldDialogPros {
  showDialog: boolean;
  headerKey: string;
  i18NameSpace: string;
  onClose: () => void;
  onSubmit: (textFieldValue: string) => void;
  errorMessageKey?: string;
  defaultValue?: string;
  formId?: string | undefined;
  maxLength: number;
  placeholder: string;
  primaryButtonText?: string;
  disablePrimaryButton?: boolean | undefined;
}

const TextFieldDialog: React.FC<TextFieldDialogPros> = ({
  formId,
  showDialog,
  onClose,
  onSubmit,
  i18NameSpace,
  errorMessageKey,
  headerKey,
  defaultValue,
  maxLength,
  placeholder,
  primaryButtonText,
  disablePrimaryButton,
}) => {
  const { spacing, sizing } = useTheme();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<FormsData>({ mode: 'onSubmit', shouldUnregister: true });
  const [t] = useTranslation(i18NameSpace);

  const requiredMessage = t(errorMessageKey ?? '');

  function MaxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('intake:max.')} ${max})`;
  }

  const { ref, ...rest } = register('textField', {
    required: requiredMessage,
    maxLength: { value: maxLength, message: MaxLengthMessage(maxLength) },
  });

  return (
    <ModalContainer
      onClose={onClose}
      title={t(headerKey)}
      open={showDialog}
      testId="text-field-modal"
      primaryButton={{
        label: primaryButtonText ?? t('OK'),
        testId: 'text-field-modal__button--ok',
        type: 'submit',
        form: formId,
        disabled: disablePrimaryButton,
      }}
      secondaryButton={{
        action: onClose,
        testId: 'text-field-modal__button--cancel',
        label: t('common:CANCEL'),
      }}
    >
      <form name={formId} onSubmit={handleSubmit((data) => onSubmit(data.textField))} id={formId}>
        <TextField
          sx={{ padding: `${spacing(2)} ${spacing(3)}`, width: sizing.full }}
          multiline
          defaultValue={defaultValue}
          inputRef={ref}
          {...rest}
          variant="outlined"
          rows="8"
          error={!!errors.textField}
          helperText={errors.textField && errors.textField.message}
          inputProps={{ 'data-testid': 'text-field-modal__input--text' }}
          placeholder={t(placeholder)}
          autoFocus
        />
      </form>
    </ModalContainer>
  );
};

export default TextFieldDialog;
