import { Coupon, BestSellerCoupon } from 'typings/Coupons';

export interface ProductsState {
  groups: Group[];
  options: Option[];
  subOptions: SubOption[];
  products: Product[];
  categories: Category[];
  productToCustomize?: CustomizationProduct;
  bestSellerProducts: BestSellerProduct[];
  productOptionPrices: ProductOptionPrice[];
  toppings: Topping[];
  toppingCategories: ToppingCategory[];
  coupons: Coupon[];
  bestSellerCoupons: BestSellerCoupon[];
  activeCategoryCode: string;
  activeOptionId: number | null;
  activeSubOptionId: number | null;
  customizationProductPrice?: number;
  productDataLoaded: boolean;
}

export interface Group {
  code: string;
  name: string;
}

export interface SubOption {
  id: number;
  description: string;
}

export interface Option {
  id: number;
  title: string;
  name: string;
  imageUrl: string;
  optionType: string;
  optionSubTypeId: number;
  sortOrder: number;
}

export interface OptionPrice extends Option {
  productPrice: { deliveryPrice: number; pickupPrice: number };
}

export interface ProductImage {
  imageUrl: string;
  labelId: ImageLabel;
}

export enum ImageLabel {
  Homepage = 1,
  HomepageB = 2,
  ProductCategory = 3,
  CreateYourOwn = 4,
  CreateYourOwnHalf = 5,
  Upsell = 6,
  Lightbox = 7,
  Menu = 8,
  Tip = 9,
  StoreIntake = 10,
  Kiosk = 11,
}

export interface Product {
  id: number;
  name: string;
  title: string;
  description: string;
  images: ProductImage[];
  categoryImageUrl?: string;
  groupCode: string;
  categoryCode: string;
  options: ProductOption[];
  defaultToppings: ProductTopping[];
  forbiddenToppings: ProductTopping[];
  allowedXTastyProducts: AllowedXTastyProduct[];
  xTastySettings?: {
    numberOfSlices: number;
    surcharge: number;
  };
  isXTasty?: boolean;
  isCYO?: boolean;
  hasToppingsSelectionSteps: boolean;
  toppingSelectionSteps?: ProductSetStep[];
  isCustomizable: boolean;
  canChangeToppings: boolean;
  properties: {
    imageUrl: string;
    propertyName: string;
  }[];
  isAvailable: boolean;
  sortOrder: number;
}

export interface Category {
  code: string;
  name: string;
  imageUrl: string;
  useSubOptionsSelector: boolean;
  products: Product[];
}

export interface CategoriesWithProducts {
  productsWithOptions: Product[];
  sortedFilledCategories: Category[];
}

export interface BestSellerItem {
  product?: Product;
  coupon?: Coupon;
  productOption?: ProductOption;
  positionNumber: number;
}

export interface BestSellerProduct {
  product: Product;
  optionId: number;
  positionNumber: number;
}

interface ProductOption {
  productOptionId: number;
  default: boolean;
  basePrice: { deliveryPrice: number; pickupPrice: number };
  isPriceAvailable: boolean;
  isOptionAvailable: boolean;
  webCode?: string;
}

export interface RequestProductOptionPrices {
  storeId: number;
  pickupType: string;
  productId: number;
}

export interface ProductOptionPrice {
  optionId: number;
  price: number;
}

export interface CustomizationProduct {
  originalOptionId: number;
  quantity: number;
  basketItemIndex?: number; // new product will not have index yet;
  basketItemGuid?: string; // new product will not have guid yet;
  baseProduct: Product;
  remark?: string;
  sliceCustomizations?: SliceCustomization[];
  optionName?: string;
  restrictions?: CustomizationRestrictions;
  stepSelections?: ProductSetStepSelection[];
}

export interface SliceCustomization {
  sliceProductId: number;
  sliceProductName: string;
  toppingsConfiguration: ProductTopping[];
  addedToppings?: ToppingDisplayModel[];
  removedToppings?: ToppingDisplayModel[];
}

export interface CustomizationRestrictions {
  canChangeProduct: boolean;
  canChangeOption: boolean;
  hideQuantity: boolean;
  allowedOptionsIds?: number[];
}

export interface ProductSetStepSelection {
  order: number;
  title: string;
  selectedToppings: ToppingSelection[];
}

export interface ToppingsCounter {
  enabled: boolean;
  maxNumberOfToppings: number;
  maxNumberOfDuplicateToppings: number;
}

export interface AvailableTopping {
  toppingId: number;
}

export interface ToppingConfiguration {
  toppingId: number;
  quantity: number;
}

export interface ToppingDisplayModel extends ToppingConfiguration {
  toppingName: string;
}

export interface ToppingSelection extends Topping {
  quantity?: number;
}

export interface ToppingSelectionSimplified {
  id: number;
  name: string;
  quantity: number;
  calculationWeight: number;
  toppingCategoryCode: string;
}

export interface Topping {
  id: number;
  name: string;
  title: string;
  sortOrder: number;
  calculationWeight: number;
  toppingCategoryCode: string;
  toppingOptionPrices: ToppingOption[];
}

export interface ToppingCategory {
  code: string;
  name: string;
  description: string;
  sortOrder: number;
}
export interface ToppingGroup {
  code: string;
  name: string;
  sortOrder: number;
  toppings: ToppingSelection[];
  isSingleSelectGroup: boolean;
}

interface ProductOption {
  productOptionId: number;
  default: boolean;
  basePrice: { deliveryPrice: number; pickupPrice: number };
  isPriceAvailable: boolean;
}

export interface ProductTopping {
  quantity: number;
  toppingId: number;
}

export interface AllowedXTastyProduct {
  productId: number;
}

export interface ToppingOption {
  toppingId: number;
  optionId: number;
  price: number;
}

export interface ProductSetStep {
  title: string;
  order: number;
  requiredAmountOfToppings: number;
  maximumAmountOfToppings: number;
  availableToppings: AvailableTopping[];
}

export interface Customization {
  sliceNumber: number;
  selectedPizza?: Product;
  selectedToppings?: ToppingSelectionSimplified[];
  stepSelections?: ProductSetStepSelection[];
}

export enum ProductCustomizationState {
  CustomizeProduct,
  CreateYourOwn,
  XTasty,
  ProductSet,
}

export interface ProductSetCustomization {
  selections: ProductSetStepSelection[];
  selectedOptionId?: number;
  quantity: number;
}
