import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import i18n from 'i18next';
import { API_BASE_URL, POS_HEADERS, requestRequiresLocale } from 'API/PosApi';

const innerPosBaseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  credentials: 'include',
  headers: new Headers(POS_HEADERS),
  prepareHeaders: (headers) => {
    headers.set('Accept-Language', `${i18n.language}`);
    return headers;
  },
});

type PosQueryOptions = {
  errorHandler?: (error: FetchBaseQueryError, request?: Request) => void;
};

// needed to avoid circular dependencies
export const posQueryOptions: PosQueryOptions = {
  errorHandler: undefined,
};

export const posBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let modifiedArgs = args;

  const locale = i18n.language;
  // Check if the request needs the locale parameter
  if (typeof modifiedArgs === 'string' && requestRequiresLocale(modifiedArgs)) {
    const url = new URL(modifiedArgs, API_BASE_URL);
    url.searchParams.set('locale', locale);
    modifiedArgs = url.toString();
  } else if (typeof modifiedArgs === 'object' && requestRequiresLocale(modifiedArgs.url)) {
    modifiedArgs.params = {
      locale,
      ...modifiedArgs.params,
    };
  }

  const result = await innerPosBaseQuery(modifiedArgs, api, extraOptions);

  if (result.error && posQueryOptions.errorHandler) {
    posQueryOptions.errorHandler(result.error, result.meta?.request);
  }
  return result;
};
