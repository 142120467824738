import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns/esm';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getLocalOrdersList } from 'stores/LocalOrders';
import { Box, useTheme } from '@mui/material';
import OrdersTable from 'containers/LocalOrders/Overview/Table/LocalOrdersTable';
import { getSelectedStore } from 'stores/Store/store.selectors';
import Filters from './Filters/LocalOrdersTableFilters';
import { HeaderOptions } from '../LocalOrdersConsts';

interface OverviewProps {
  selectedOption: string;
}

const Overview: React.FC<OverviewProps> = ({ selectedOption }) => {
  const { palette, sizingBig } = useTheme();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [deliveryType, setDeliveryType] = useState<PickUpTypesValues | undefined>();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [ticketNr, setTicketNr] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const dispatch = useAppDispatch();
  const selectedStore = useAppSelector(getSelectedStore);
  const { reloadOrdersTrigger } = useAppSelector((store) => store.localOrders);

  const loadDataRows = useCallback(() => {
    let pickupType: PickUpTypesValues | undefined;
    let dateFrom: string | undefined;
    let dateTo: string | undefined;
    let ticketNumber: number | undefined;
    let orderStatus: string | undefined;

    if (selectedOption === HeaderOptions.open) {
      pickupType = PickUpTypesValues.pickUp;
      dateFrom = format(new Date(), 'yyyy-MM-dd');
      dateTo = format(new Date(), 'yyyy-MM-dd');
      orderStatus = 'ToPrepare';
    } else {
      pickupType = deliveryType;
      dateFrom = startDate ? format(startDate, 'yyyy-MM-dd') : undefined;
      dateTo = endDate ? format(endDate, 'yyyy-MM-dd') : undefined;
      ticketNumber = ticketNr ? Number(ticketNr) : undefined;
    }

    const filters = {
      PickupType: pickupType,
      OrderStatus: orderStatus,
      PaymentMethodCode: paymentMethod,
      DateFrom: dateFrom,
      DateTo: dateTo,
      TicketNumber: ticketNumber,
      Search: searchInputValue && searchInputValue,
    };

    dispatch(getLocalOrdersList(filters));
  }, [
    deliveryType,
    paymentMethod,
    startDate,
    endDate,
    ticketNr,
    searchInputValue,
    selectedOption,
    selectedStore,
    dispatch,
  ]);

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, [selectedOption]);

  useEffect(() => {
    loadDataRows();
  }, [
    deliveryType,
    paymentMethod,
    searchInputValue,
    dispatch,
    loadDataRows,
    selectedOption,
    selectedStore,
    reloadOrdersTrigger,
  ]);

  return (
    <Box
      sx={{
        m: 4,
        mt: 0,
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12)',
      }}
    >
      <Filters
        loadDataRows={loadDataRows}
        setSearchInputValue={setSearchInputValue}
        setDeliveryType={setDeliveryType}
        setPaymentMethod={setPaymentMethod}
        setTicketNr={setTicketNr}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedOption={selectedOption}
      />
      <Box sx={{ backgroundColor: palette.white, minHeight: sizingBig(20) }}>
        <OrdersTable />
      </Box>
    </Box>
  );
};

export default Overview;
