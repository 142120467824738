import React, { useState } from 'react';
import { Grid2 as Grid, Typography, useTheme } from '@mui/material';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OrderDeliveryTime } from 'stores/Intake';
import { isDeliveryDateToday } from 'utils/localisation/dateTimeUtils';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import DateSelect from './DateSelect/DateSelect';
import TimeSelect from './TimeSelect/TimeSelect';
import FormPart from '../FormPart/FormPart';

export interface DeliveryTimeErrors {
  date: FieldError;
  time: FieldError;
}

interface DeliveryTimeProps {
  isPickup: boolean;
  preselectedDateTime?: OrderDeliveryTime; // this is used on edit order flow
}

const DeliveryTimeForm: React.FC<DeliveryTimeProps> = ({ isPickup, preselectedDateTime }) => {
  const [t] = useTranslation('intake');
  const { register, setValue, getValues } = useFormContext<CheckoutDetailsForm>();
  const { date, time } = getValues();
  const [isToday, setIsToday] = useState(date && date.length > 0 ? isDeliveryDateToday(date) : true);
  const { palette, spacing } = useTheme();

  return (
    <FormPart>
      <Typography variant="h6" gutterBottom sx={{ color: palette.black.main, marginBottom: spacing(3) }}>
        {isPickup ? t('Pickup Time Info') : t('Delivery Time Info')}
      </Typography>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 9 }}>
          <DateSelect
            preselectedDate={preselectedDateTime?.date ?? date}
            formRef={register}
            isPickup={isPickup}
            isToday={(value): void => setIsToday(value)}
            setValue={setValue}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 3 }}>
          <TimeSelect
            formRef={register}
            isPickup={isPickup}
            isToday={isToday}
            preselectedTime={preselectedDateTime?.time ?? time}
          />
        </Grid>
      </Grid>
    </FormPart>
  );
};

export default DeliveryTimeForm;
