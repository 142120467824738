import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Box, Typography } from '@mui/material';
import { restartOrder } from 'stores/combined.actions';
import { logOutCashier } from 'stores/Cashier/cashier.thunk-actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getClockInRequired } from 'stores/Cashier/cashier.selector';
import { setClockInRequired } from 'stores/Cashier/cashier.slice';
import { RoutePath } from 'App/Routes/routes-paths';

export const ClockInConfirmationModal = memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation('common');

  const clockInRequired = useAppSelector(getClockInRequired);

  const onSubmit = useCallback(() => {
    dispatch(logOutCashier());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(setClockInRequired(false));
    dispatch(restartOrder());
    navigate(RoutePath.Intake);
  }, [dispatch, navigate]);

  return clockInRequired ? (
    <ModalContainer
      onClose={onClose}
      title={t('Clock in confirmation')}
      open={clockInRequired}
      testId="clockin-modeal-text-field"
      secondaryButton={{
        label: t('Cancel'),
        testId: 'clockin-confirmation-modal__button--cancel',
        action: onClose,
      }}
      primaryButton={{
        label: t('OK'),
        testId: 'clockin-confirmation-modal__button--ok',
        type: 'submit',
        action: onSubmit,
      }}
    >
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle2">
          {t('To perform this action, the current user needs to be clocked-in')}
        </Typography>
      </Box>
    </ModalContainer>
  ) : null;
});

ClockInConfirmationModal.displayName = 'ClockInConfirmationModal';
