import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OrderDetailsSectionProps {
  children: React.ReactNode;
  name: string;
}

const OrderDetailsSection: React.FC<OrderDetailsSectionProps> = ({ name, children }) => {
  const { t } = useTranslation('orders');
  const { palette } = useTheme();
  return (
    <Box>
      <Typography variant="subtitle1" color={palette.black.main} sx={{ mb: 2 }}>
        {t(name)}
      </Typography>
      {children}
    </Box>
  );
};

export default OrderDetailsSection;
