import { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setPhoneNumberSearchQuery } from 'stores/Intake';
import { clearPhoneNumberCalling } from 'stores/HybridMode';
import { restartOrder } from 'stores/combined.actions';

export function usePhoneCallLogic() {
  const [basketIsEmpty, setBasketIsEmpty] = useState(true);
  const [showAcceptPhonecallDialog, setShowAcceptPhonecallDialog] = useState(false);

  const basketItems = useAppSelector((state) => state.basket.basketItems);
  const basketCoupons = useAppSelector((state) => state.basket.basketCoupons);

  const { selectedOrderCustomer } = useAppSelector(({ intake }) => intake);
  const { phoneNumberCalling } = useAppSelector((state) => state.hybridMode);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setBasketIsEmpty(basketItems.length === 0 && basketCoupons.length === 0 && !selectedOrderCustomer);
  }, [basketItems, basketCoupons, selectedOrderCustomer]);

  useEffect(() => {
    if (phoneNumberCalling && basketIsEmpty) {
      dispatch(setPhoneNumberSearchQuery(phoneNumberCalling));
      dispatch(clearPhoneNumberCalling());
    }
  }, [phoneNumberCalling, basketIsEmpty, dispatch]);

  useEffect(() => {
    setShowAcceptPhonecallDialog(phoneNumberCalling !== undefined && !basketIsEmpty);
  }, [phoneNumberCalling, basketIsEmpty]);

  const acceptPhoneCall = useCallback(
    (phoneNumberAccepted: string) => {
      dispatch(restartOrder());
      dispatch(setPhoneNumberSearchQuery(phoneNumberAccepted));
      dispatch(clearPhoneNumberCalling());
    },
    [dispatch],
  );

  const ignorePhoneCall = useCallback(() => {
    dispatch(clearPhoneNumberCalling());
  }, [dispatch]);

  return { showAcceptPhonecallDialog, acceptPhoneCall, ignorePhoneCall };
}
