import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { manualExitOrderPayment } from 'stores/OrderPayment/orderPayment.actions';
import { AuthorizationDto, ClockOutData, Credentials, EmployeeCashBalance } from 'typings/Cashier';
import { AxiosError, AxiosResponse } from 'axios';
import { isValidAxiosResponse } from 'typings/type-guards';
import { RootState } from 'stores';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { appInsights } from 'App/AppInitializationWrapper/useAppInsights';

export const clockOutCashier = createAsyncThunk<AuthorizationDto, ClockOutData>(
  '[CASHIER]/clockOut',
  async ({ secret, employeeId }, { rejectWithValue }) => {
    const response = await posApi.post<AuthorizationDto>(posApiUrls.ACCOUNT_CLOCK_OUT_EMPLOYEE(employeeId), {
      Secret: secret,
    });
    if (isValidAxiosResponse(response)) {
      return response.data;
    }

    return rejectWithValue({ ...(response as AxiosError), employeeId });
  },
);

export const getEmployeeCashBalance = createAsyncThunk<EmployeeCashBalance | null, string>(
  '[CASHIER]/cashBalance',
  async (pinCode, { rejectWithValue, dispatch }) => {
    const response = await posApi.put<EmployeeCashBalance>(posApiUrls.ACCOUNT_EMPLOYEE_CASH_BALANCE(), {
      Secret: pinCode,
    });
    if (isValidAxiosResponse(response)) {
      if (response.data && response.data.requiresClockOutConfirmation) return response.data;

      dispatch(clockOutCashier({ secret: pinCode }));
      return null;
    }

    return rejectWithValue(response);
  },
);

export const automaticCashierLogOut = createAsyncThunk<void, void, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/automaticCashierLogOut',
  async (_, { getState, dispatch }) => {
    const {
      orderPayment,
      eft: { eftPaymentId },
    } = getState();
    if (eftPaymentId) {
      appInsights.trackEvent({
        name: 'EFT unauthorized(401) api response during eft payment, skipping log out cashier',
      });
      return;
    }

    if (orderPayment.orderId) {
      await dispatch(manualExitOrderPayment());
    }

    dispatch(logOutCashier());
  },
);

export const logOutCashier = createAsyncThunk<void, void, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/logOutCashier',
  async () => {
    await posApi.post<Credentials, AxiosResponse<AuthorizationDto>>(posApiUrls.ACCOUNT_LOGOUT);

    const hybridModeCommunicator = getHybridModeCommunicator();

    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.CashierLoggedOut);
  },
);

export const startBreak = createAsyncThunk<void, void, { state: RootState; rejectValue: unknown }>(
  '[CASHIER]/startBreak',
  async (_, { dispatch }) => {
    const response = await posApi.post(posApiUrls.ACCOUNT_START_BREAK);
    if (isValidAxiosResponse(response)) {
      dispatch(logOutCashier());
    }
  },
);

export const revalidatePin = createAsyncThunk<
  { success: boolean; revalidateFor: string },
  { pinCode: string; revalidateFor: string }
>('[CASHIER]/revalidatePin', async ({ pinCode, revalidateFor }, { rejectWithValue }) => {
  const response = await posApi.put(posApiUrls.USER_REVALIDATE, {
    Secret: pinCode,
  });

  if (isValidAxiosResponse(response)) {
    return { revalidateFor, success: true };
  }

  return rejectWithValue(revalidateFor);
});

export const validateCashDrawerPermission = createAsyncThunk<
  void,
  { secret: string; successCallback: () => void },
  { state: RootState }
>('[CASHIER]/validateCashDrawerPermission', async ({ secret, successCallback }) => {
  const response = await posApi.put(posApiUrls.CASH_DRAWER_TRANSACTION_VALIDATE_ACCESS, {
    Secret: secret,
  });

  if (response.status !== 200) {
    throw Error('Cannot allow user to cash drawer');
  }

  successCallback();
});
