import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, sizing, spacing }) => {
  return {
    wrapper: {
      height: `calc(100vh - ${sizing.appBarHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      position: 'relative',
      backgroundColor: palette.background.default,
      padding: spacing(4),
      gap: spacing(1),
    },
    header: {
      justifyContent: 'space-around',
    },
    loader: {
      position: 'absolute',
      width: '100%',
      zIndex: 1101,
      height: '100%',
      background: 'rgba(0,0,0,0.2)',
    },
    manualCode: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    applicableCoupons: {
      padding: `${spacing(1)} ${spacing(4)}`,
      border: `1px solid ${palette.secondary.dark}`,
      borderLeft: '0',
      borderRight: '0',
      background: palette.secondary.main,
    },
    applicableCouponsGrid: {
      margin: `${spacing(3)} 0px`,
    },
    swiper: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      height: '100%',
      width: '100%',
    },
    swiperSlide: {
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
    swiperWrapper: {
      flex: 1,
      position: 'relative',
      width: ' 100%',
      zIndex: 1,
      display: 'flex',
      transitionProperty: 'transform',
      transitionTimingFunction: 'var(--swiper-wrapper-transition-timing-function,initial)',
      boxSizing: 'content-box',
    },
    scrollableWrapper: {
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
  };
});
export default buildClasses;
