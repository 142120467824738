import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const selectSelf = (state: RootState) => state;

const selectCustomer = createSelector(selectSelf, (state) => state.customer);

export const getCustomerCanPayOnAccount = createSelector(
  selectCustomer,
  (customer) => customer.customerCanPayOnAccount,
);

export const getChargedCompanyWhenPayingOnAccount = createSelector(
  selectCustomer,
  (customer) => customer.chargedCompany,
);

export const getCustomerCreditToUse = createSelector(selectCustomer, (customer) => customer.customerCreditToUse);
