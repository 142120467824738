import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/Shared/Inputs/SearchInput';
import ProductCategories from 'components/Intake/Product/Categories/ProductCategories';
import ProductSubOptions from 'components/Intake/Product/SubOptions/ProductSubOptions';
import ProductOptions from 'components/Intake/Product/Options/ProductOptions';
import {
  filterOptionsByActiveSubOption,
  filterProductsByActiveOption,
  filterProductsBySearchString,
  filterProductsSubOptions,
  removeOptionsWithoutProducts,
} from 'stores/Intake/IntakeStoreUtils';
import { clearActiveProductOption, setActiveProductOption, setActiveProductSubOption } from 'stores/Products';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Option, Product, SubOption } from 'typings/Products';
import { Box, useTheme } from '@mui/material';
import {
  getActiveCategory,
  getActiveCategoryCode,
  getActiveOptionId,
  getActiveSubOptionId,
  getGroups,
  getOptions,
  getProducts,
  getSubOptions,
  getToppings,
} from 'stores/Products/products.selector';
import BestSellersCatalog from 'containers/Intake/Products/ProductCatalog/BestSellersCatalog';
import { ProductCatalog } from 'containers/Intake/Products/ProductCatalog/ProductCatalog';

const ProductsView: React.FC = memo(() => {
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();
  const { palette, spacing } = useTheme();
  const [searchString, setSearchString] = useState('');

  const activeOptionId = useAppSelector(getActiveOptionId);
  const activeSubOptionId = useAppSelector(getActiveSubOptionId);
  const activeCategory = useAppSelector(getActiveCategory);
  const activeCategoryCode = useAppSelector(getActiveCategoryCode);
  const options = useAppSelector(getOptions);
  const subOptions = useAppSelector(getSubOptions);
  const products = useAppSelector(getProducts);
  const toppings = useAppSelector(getToppings);
  const groups = useAppSelector(getGroups);

  useEffect(() => {
    if (searchString.length > 0 && activeOptionId !== null) {
      dispatch(clearActiveProductOption());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchString]);

  const handleSearchChange = useCallback((val: string) => {
    setSearchString(val);
  }, []);

  const setActiveOption = useCallback(
    (optionId: number): void => {
      dispatch(setActiveProductOption(optionId));
    },
    [dispatch],
  );

  const setActiveSubOption = useCallback(
    (subOptionId: number): void => {
      dispatch(setActiveProductSubOption(subOptionId));
    },
    [dispatch],
  );

  const searchResult = useMemo(() => {
    let filteredOptions: Option[] = [];
    let filteredProducts: Product[] = [];
    let filteredSubOptions: SubOption[] = [];

    if (searchString.length === 0) {
      if (activeCategory?.useSubOptionsSelector) {
        filteredOptions = filterOptionsByActiveSubOption(options, activeSubOptionId);
        if (activeCategory) {
          filteredSubOptions = filterProductsSubOptions(products, options, subOptions, activeCategoryCode);
          filteredProducts = filterProductsByActiveOption(products, activeOptionId);
          filteredOptions = removeOptionsWithoutProducts(filteredOptions, activeCategory.products);
        }
      } else {
        filteredProducts = activeCategory ? activeCategory.products : [];
      }
    } else {
      filteredProducts = filterProductsBySearchString(products, toppings, options, subOptions, searchString);
    }

    return {
      filteredProducts,
      filteredOptions,
      filteredSubOptions,
    };
  }, [
    activeCategory,
    activeCategoryCode,
    activeOptionId,
    activeSubOptionId,
    options,
    products,
    searchString,
    subOptions,
    toppings,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        padding: spacing(4),
        paddingBottom: spacing(2),
        gap: spacing(1),
        backgroundColor: palette.background.default,
      }}
    >
      <ProductCategories />
      <SearchInput
        value={searchString}
        fullWidth
        placeholder={t('searchPlaceholder')}
        changeFunc={handleSearchChange}
      />
      {activeCategory?.useSubOptionsSelector && searchString.length === 0 && (
        <ProductSubOptions
          subOptions={searchResult.filteredSubOptions}
          activeSubOptionId={activeSubOptionId}
          onSubOptionChange={setActiveSubOption}
        />
      )}
      {activeCategory?.useSubOptionsSelector && searchResult.filteredOptions != null && (
        <ProductOptions
          options={searchResult.filteredOptions}
          activeOptionId={activeOptionId}
          onOptionChange={setActiveOption}
          activeCategoryCode={activeCategoryCode}
        />
      )}
      {activeCategoryCode === 'BSL' && !searchString ? (
        <BestSellersCatalog />
      ) : (
        <ProductCatalog products={searchResult.filteredProducts} groups={groups} />
      )}
    </Box>
  );
});

ProductsView.displayName = 'ProductsView';

export default ProductsView;
