import React from 'react';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useAppSelector } from 'hooks/useRedux';
import { getDateTimeFormatter, getPaymentFormatter } from 'stores/Payments/payment.selector';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { CustomerCreditDetails } from 'stores/Intake/intakeTypes';
import { useTranslation } from 'react-i18next';
import { ErrorOutlineOutlined } from '@mui/icons-material';

interface UseCustomerCreditModalProps {
  showDialog: boolean;
  customerCreditDetails?: CustomerCreditDetails;
  abort: () => void;
  onConfirm: () => void;
}

const UseCustomerCreditModal: React.FC<UseCustomerCreditModalProps> = ({
  showDialog,
  abort,
  onConfirm,
  customerCreditDetails,
}) => {
  const [t] = useTranslation('intake');
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const formatDateTime = useAppSelector(getDateTimeFormatter);
  const { manualPriceOverride } = useAppSelector(({ intake }) => intake);

  const { palette, sizingBig } = useTheme();
  if (!customerCreditDetails) return null;

  return (
    <ModalContainer
      onClose={abort}
      open={showDialog}
      title={t('Customer credit available')}
      primaryButton={{
        label: t('Use credit'),
        testId: 'use-customer-credit-proceed',
        action: onConfirm,
      }}
      secondaryButton={{
        label: t('Cancel'),
        testId: 'use-customer-credit-cancel',
        action: abort,
      }}
      testId="use-customer-credit-modal"
      sx={{ width: sizingBig(22), maxWidth: sizingBig(22) }}
      maxWidth="sm"
    >
      <Box
        sx={{
          px: 3,
          paddingTop: 3,
          maxHeight: sizingBig(13),
          overflowY: 'auto',
        }}
      >
        {customerCreditDetails.creditHistory.map((customerCreditOperation) => (
          <Box
            key={customerCreditOperation.createdDate?.toString()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              paddingRight: 1,
              marginBottom: 3,
              '&:last-child': {
                marginBottom: 0,
              },
            }}
          >
            {customerCreditOperation.type === 'Add' ? (
              <TrendingUpIcon sx={{ color: palette.green[600], alignSelf: 'flex-start', marginRight: 1 }} />
            ) : (
              <TrendingDownIcon sx={{ color: palette.red[700], alignSelf: 'flex-start', marginRight: 1 }} />
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography variant="body2" color="palette.grey">
                {formatDateTime(new Date(customerCreditOperation.createdDate))}
              </Typography>
              <Typography variant="body1" color="black.main">
                {customerCreditOperation.reason}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="black.main">
              {formatToDisplay(customerCreditOperation.amount)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          px: 3,
          paddingBottom: 3,
        }}
      >
        <Box
          sx={{
            p: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: palette.grey[50],
            marginTop: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="body1" color="black.main">
            {t('Total credit')}
          </Typography>
          <Typography variant="h6" color="black.main">
            {formatToDisplay(customerCreditDetails.total)}
          </Typography>
        </Box>
        {manualPriceOverride && (
          <Typography sx={{ mt: 2 }} variant="h5" gutterBottom data-testid="development-machine-alert">
            <Alert severity="warning" icon={<ErrorOutlineOutlined fontSize="inherit" />}>
              {`${t('Current manual price override')} (${formatToDisplay(manualPriceOverride)}) ${t(
                'will be removed',
              )}`}
            </Alert>
          </Typography>
        )}
      </Box>
    </ModalContainer>
  );
};

export default UseCustomerCreditModal;
