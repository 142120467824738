import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/useRedux';
import { cmsAPI } from 'stores/Cms/cms.api';

export const useLanguageChange = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleLanguageChanged = () => {
      cmsAPI.util.invalidateTags(['Translations']);
    };
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [dispatch, i18n]);
};
