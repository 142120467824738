import React, { useEffect, useState } from 'react';
import { Box, Chip, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  getActivePayment,
  getActivePaymentQuickValues,
  getIsSplitPaymentAvailableFor,
} from 'stores/OrderPayment/orderPayment.selector';
import { AnimatePresence, motion } from 'framer-motion';
import {
  getDefaultPaymentQuickValues,
  getPaymentFormatter,
  getStringToNumberFormatter,
  getStringToPaymentFormatter,
} from 'stores/Payments/payment.selector';
import { Controller, useFormContext } from 'react-hook-form';
import Validators from 'utils/validation/ValidationConstants';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import { PaymentConfiguration } from 'stores/Config/configTypes';
import CloseIcon from '@mui/icons-material/Close';
import NumericKeypad, { KeyboardKey } from 'components/Shared/NumericKeypad/NumericKeypad';
import { Keypad, PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import { calculateChange } from 'stores/OrderPayment/orderPayment.actions';
import { getPayOnAccountCode, getPaymentConfig } from 'stores/Config/config.selector';
import { PaymentMethodCode } from 'typings/Payments';

const CashAmountEdition: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { spacing, palette, typography } = useTheme();
  const [quickCashDenominations, setQuickCashDenominations] = useState<number[]>([]);
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const formatStringToPayment = useAppSelector(getStringToPaymentFormatter);
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter);
  const payOnAccountMethodCode = useAppSelector(getPayOnAccountCode);
  const activePayment = useAppSelector(getActivePayment);
  const isSplitPaymentAvailable = useAppSelector(getIsSplitPaymentAvailableFor);
  const { changeAmount, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const payment = useAppSelector(getPaymentConfig);
  const activePaymentQuickValues = useAppSelector(getActivePaymentQuickValues);
  const defaultPaymentQuickValues = useAppSelector(getDefaultPaymentQuickValues);
  const [quickCash, setQuickCash] = useState<boolean>(activePaymentQuickValues().length > 0);
  const { control, reset, setValue, getValues, formState, watch, trigger } = useFormContext<OrderPaymentForm>();

  const { errors } = formState;

  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);

  const isPayOnAccount = activePayment?.paymentMethod?.code === payOnAccountMethodCode;
  const editionDisabled = isPayOnAccount; // this will be more generic

  const quickPaymentValues = activePayment?.paymentMethod
    ? activePaymentQuickValues()
    : defaultPaymentQuickValues();

  useEffect(() => {
    trigger().then((isValid) => {
      isValid &&
        dispatch(
          calculateChange({ paymentAmount: cashInputNumberValue, tipAmount: activePayment?.tipValue ?? 0 }),
        );
    });
  }, [cashInputValue]);

  const normalPaymentKeys = Keypad.concat({
    displayName: t('QUICK VALUES'),
    numSx: { background: palette.grey[200], px: 2, ...typography.button },
    action: () => {
      setQuickCash(true);
    },
  } as KeyboardKey);

  const IsValidForSplitPayment = (val: string) =>
    (isSplitPaymentAvailable ? true : formatStringToNumber(val) >= totalLeftAmount) ||
    t('Value can not be lower than order total');

  const isValidForGiftCard = (val: string) =>
    activePayment?.paymentMethod?.code === PaymentMethodCode.GiftcardQr ||
    activePayment?.paymentMethod?.code === PaymentMethodCode.GiftCard
      ? formatStringToNumber(val) <= totalLeftAmount || t('Value can not be higher than order total')
      : true;

  const quickCashKeys = quickPaymentValues
    .map((qc) => {
      return {
        displayName: formatToDisplay(qc),
        value: qc.toString(),
        numSx: { height: '64px' },
      } as KeyboardKey;
    })
    .concat({
      displayName: t('NUM PAD'),
      numSx: { height: '64px', background: palette.grey[200], px: 2, ...typography.button },
      action: () => {
        reset();
        setQuickCash(false);
        setQuickCashDenominations([]);
      },
    } as KeyboardKey);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          mb: 2,
          gap: 2,
        }}
      >
        <Controller
          rules={{
            maxLength: {
              value: 10,
              message: t('Value is too long'),
            },
            validate: {
              isValidForSplitPayment: (val: string): boolean | string => IsValidForSplitPayment(val),
              isMoney: (val: string): boolean | string =>
                Validators.Money.Validator(payment as PaymentConfiguration).test(val) || Validators.Money.Message,
              isValidForGiftCard: (val: string): boolean | string => isValidForGiftCard(val),
            },
          }}
          defaultValue=""
          control={control}
          name="cashInput"
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              disabled={quickCash || editionDisabled}
              error={errors.cashInput && true}
              helperText={errors.cashInput && errors.cashInput.message}
              InputProps={{
                sx: { px: 2, py: 2, fontSize: 20, fontWeight: 500, color: palette.grey[600] },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ display: 'flex', alignItems: 'center', mt: '0px !important' }}
                  >
                    <Typography variant="h6" sx={{ color: palette.grey[500] }}>
                      {payment?.currencySymbol ?? ''}
                    </Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setQuickCashDenominations([]);
                        reset({ cashInput: formatToDisplay(totalLeftAmount, true, false) });
                      }}
                    />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: '10',
                  autoFocus: true,
                  style: {
                    padding: '0',
                    color: cashInputNumberValue !== totalLeftAmount ? 'black' : 'inherit',
                    WebkitTextFillColor: cashInputNumberValue !== totalLeftAmount ? 'black' : 'inherit',
                  },
                  'data-testid': `order-payment-cash-amount-input`,
                },
              }}
            />
          )}
        />
        <Box
          sx={{
            px: 2,
            py: 1.5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            background: palette.grey[50],
            borderRadius: spacing(1),
            width: '33%',
            overflow: 'hidden',
          }}
        >
          <Typography variant="caption">{t('Change')}</Typography>
          <Typography
            variant={changeAmount && formatToDisplay(changeAmount).length > 8 ? 'body1' : 'h6'}
            data-testid="settle-payment__label--change-value"
          >
            {changeAmount !== undefined && changeAmount >= 0 ? formatToDisplay(changeAmount) : '--'}
          </Typography>
        </Box>
      </Box>
      <AnimatePresence initial={false}>
        {quickCash ? (
          <>
            <motion.div
              key="quickCashPaymentNumpad"
              variants={PaymentModalAnimations.variants}
              initial="slideUp"
              animate="onPlace"
              transition={PaymentModalAnimations.transition}
            >
              <NumericKeypad
                keys={quickCashKeys}
                onChange={(clickedDenomination) => {
                  const numberDenomination = formatStringToNumber(clickedDenomination);
                  const newDenominationValues = [...quickCashDenominations, numberDenomination];
                  setQuickCashDenominations([...quickCashDenominations, numberDenomination]);
                  const totalValue = newDenominationValues.reduce((prev, current) => {
                    return prev + current;
                  }, 0);

                  setValue('cashInput', formatToDisplay(totalValue, true, false), { shouldDirty: true });
                }}
              />
            </motion.div>
            <Typography variant="body2" sx={{ mb: 1, mt: 1.5, color: palette.black.main }}>
              {t('Coins and notes received:')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {quickCashDenominations.map((qcd, i) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${qcd}-${i}`}
                  sx={{ background: palette.grey[100] }}
                  data-testid={`quick-cash__denomination-chip-${qcd}-${i}`}
                  label={
                    <Typography
                      variant="body1"
                      sx={{ display: 'flex', flexWrap: 'nowrap', color: palette.black.main }}
                    >
                      {formatToDisplay(qcd)}
                    </Typography>
                  }
                  variant="outlined"
                  onDelete={() => {
                    const newDenominations = [...quickCashDenominations];
                    newDenominations.splice(i, 1);
                    setQuickCashDenominations(newDenominations);
                    const totalValue = newDenominations.reduce((prev, current) => {
                      return prev + current;
                    }, 0);

                    setValue('cashInput', formatToDisplay(totalValue, true, false), {
                      shouldDirty: true,
                    });
                  }}
                />
              ))}
            </Box>
          </>
        ) : (
          <motion.div
            key="normalPaymentNumpad"
            variants={PaymentModalAnimations.variants}
            initial="slideDown"
            animate="onPlace"
            transition={PaymentModalAnimations.transition}
          >
            <NumericKeypad
              keys={normalPaymentKeys}
              disabled={editionDisabled}
              onChange={(clickedValue) => {
                if ((clickedValue === '0' || clickedValue === '00') && !formState.isDirty) return;
                const currentValue = getValues().cashInput;
                const newValue = formState.isDirty ? currentValue + clickedValue : clickedValue;
                if (newValue.length > 7) return;
                const numberValue = formatStringToPayment(newValue);
                setValue('cashInput', numberValue, { shouldValidate: true, shouldDirty: true });
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default CashAmountEdition;
