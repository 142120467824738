import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, CircularProgress, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActivePaymentMethod } from 'stores/Intake';
import { PaymentMethod, PaymentMethodCode } from 'typings/Payments';
import { getPaymentMethodsToShow } from 'stores/Intake/intake.selector';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { setSelectedPaymentMethodGroupName } from 'stores/OrderPayment/orderPayment.slice';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import buildClasses from './PaymentMethodsGroup.css';

interface PaymentMethodGroupProps {
  placeRemarkOnTop?: boolean;
}

const PaymentMethodGroup: React.FC<PaymentMethodGroupProps> = ({ placeRemarkOnTop }) => {
  const { classes } = buildClasses();
  const { sizing, palette, typography, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();
  const { activePaymentMethod, activeDeliveryType, selectedOrderCustomer } = useAppSelector(
    (store) => store.intake,
  );
  const [selectedPaymentMethodCode, setSelectedPaymentMethodCode] = useState<string>();
  const paymentMethodsToShow: PaymentMethod[] = useAppSelector(getPaymentMethodsToShow);
  const shouldPaymentMethodsBeGrouped =
    activeDeliveryType === PickUpTypesValues.eatIn || activeDeliveryType === PickUpTypesValues.takeAway;

  const paymentMethods = useMemo(
    () =>
      shouldPaymentMethodsBeGrouped
        ? paymentMethodsToShow.reduce((acc: PaymentMethod[], pm) => {
            if (!pm.groupName || !acc.find((item) => item.groupName === pm.groupName)) {
              acc.push(pm);
            }
            return acc;
          }, [])
        : paymentMethodsToShow,
    [paymentMethodsToShow, shouldPaymentMethodsBeGrouped],
  );

  const paymentMethodChange = useCallback(
    (value: PaymentMethodCode): void => {
      const pm = paymentMethods.find((paymentMethod) => paymentMethod.code === value);
      if (pm === undefined) {
        return;
      }

      setSelectedPaymentMethodCode(value);

      if (pm.groupName && shouldPaymentMethodsBeGrouped) {
        dispatch(setSelectedPaymentMethodGroupName(pm.groupName));
      }

      dispatch(setActivePaymentMethod(pm.code));
    },
    [dispatch, paymentMethods, shouldPaymentMethodsBeGrouped],
  );

  const handleChange = useCallback(
    (e: React.MouseEvent, v: PaymentMethodCode): void => {
      e.stopPropagation();

      if (v) {
        paymentMethodChange(v);
      }
    },
    [paymentMethodChange],
  );

  const nameToShow = (paymentMethod: PaymentMethod): string => {
    if (!paymentMethod.groupName || !shouldPaymentMethodsBeGrouped) {
      return paymentMethod.code;
    }
    return paymentMethod.groupName;
  };

  useEffect(() => {
    if (selectedOrderCustomer?.companyId) {
      dispatch(recalculateBasket({}));
    }

    if (selectedPaymentMethodCode === undefined && !!activePaymentMethod) {
      setSelectedPaymentMethodCode(activePaymentMethod);
      return;
    }

    if (!paymentMethods.find((pm) => pm.code === selectedPaymentMethodCode) && !!activePaymentMethod) {
      setSelectedPaymentMethodCode(activePaymentMethod);
    }
  }, [activePaymentMethod]);

  return (
    <Box
      sx={{
        px: 1,
        py: 1,
        border: `1px solid ${palette.grey[200]}`,
      }}
    >
      <ToggleButtonGroup
        value={selectedPaymentMethodCode}
        size="small"
        sx={{ width: sizing.full, gap: 1 }}
        exclusive
        onChange={handleChange}
      >
        {paymentMethods !== undefined &&
          paymentMethods.map((paymentOption) => (
            <ToggleButton
              key={paymentOption.code}
              value={paymentOption.code}
              sx={{
                height: spacing(5.5),
                px: 2,
                py: 1.25,
                borderRadius: `4px !important`,
                border: '0 !important',
                flexBasis: '100%',
                textAlign: 'center',
                color: palette.grey[600],
                background: palette.grey[100],
                textTransform: 'capitalize',
                ...typography.body2,
                '&.Mui-selected': {
                  color: palette.black.main,
                  fill: palette.grey[800],
                  fontWeight: 500,
                  border: `1px solid ${palette.grey[300]} !important`,
                },
                ':hover': {
                  backgroundColor: palette.grey[200],
                },
              }}
              classes={{ selected: classes.activeToggleButton }}
              data-testid={`payment-methods__button--${paymentOption.code}`}
              data-info={`${paymentOption.description} ${paymentOption.groupName} ${paymentOption.id}`}
            >
              {t(nameToShow(paymentOption))}
              {paymentOption.methodRemark && (
                <Chip
                  className={
                    placeRemarkOnTop
                      ? classes.paymentMethodToogleButtonTopRemark
                      : classes.paymentMethodToogleButtonBottomRemark
                  }
                  size="small"
                  label={paymentOption.methodRemark}
                />
              )}
            </ToggleButton>
          ))}
        {paymentMethods === undefined && (
          <ToggleButton
            key="processing"
            value="undefined"
            sx={{ borderRadius: 0, width: sizing.full, pointerEvents: 'none' }}
            data-testid="payment-methods__button--processing"
          >
            <CircularProgress color="primary" size={20} />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Box>
  );
};

export default PaymentMethodGroup;
