/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { SliceCustomization } from 'typings/Products';
import AddedRemovedToppings from 'components/Shared/Basket/AddedRemovedToppings';
import { Slice_1_2, Slice_1_4, Slice_2_2, Slice_2_4, Slice_3_4, Slice_4_4 } from 'assets/icons';

interface BasketItemDetailsProps {
  customizations: SliceCustomization[] | undefined;
}

const BasketItemDetails: React.FC<BasketItemDetailsProps> = ({ customizations }) => {
  if (!customizations || customizations?.length === 0) return null;
  const slicesCount = customizations.length;

  function getXTastyIcon(index: number): JSX.Element {
    if (slicesCount === 2) {
      if (index === 0) return <Slice_1_2 />;
      if (index === 1) return <Slice_2_2 />;
    }
    if (slicesCount === 4) {
      if (index === 0) return <Slice_1_4 />;
      if (index === 1) return <Slice_2_4 />;
      if (index === 2) return <Slice_3_4 />;
      if (index === 3) return <Slice_4_4 />;
    }

    return (
      <Box sx={{ p: 1 }}>
        <Typography variant="body1">{index + 1}</Typography>
      </Box>
    );
  }

  return (
    <>
      {customizations.map((slice, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`${index}-${slice.sliceProductId}`}>
          <Box sx={{ display: 'flex', alignItems: 'center', py: 0.75 }}>
            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 0.25 }}>
              {getXTastyIcon(index)}
            </Box>
            <Typography
              variant="body2"
              color="black.main"
              data-testid="basket-item__customization-x-tasty--slice-product-name"
            >
              {slice.sliceProductName}
            </Typography>
          </Box>
          <Box sx={{ ml: 4 }}>
            <AddedRemovedToppings addedToppings={slice.addedToppings} removedToppings={slice.removedToppings} />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BasketItemDetails;
