import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useRedux';
import {
  CancelPaymentError,
  PaymentProviderEventType,
  StartPaymentError,
  pushCurrentPaymentProviderEvent,
  pushNextCancelationResult,
  pushNextStartPaymentResult,
} from 'stores/EftPayment/eftPayment.actions';
import { ExternalCallResult } from 'typings/EftPayment/eftPayment.types';

const EftDeveloper: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        border: '1px solid grey',
        borderRadius: '8px',
        p: 1,
        my: 1,
      }}
    >
      <Typography variant="h5">{'EFT Development'}</Typography>
      <Box>
        <Typography variant="body2" data-testid="settle-payment__label--eft-success">
          {t('Push next start payment response')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, p: 1 }}>
          <Button
            onClick={() => {
              dispatch(
                pushNextStartPaymentResult({
                  callResult: ExternalCallResult.Success,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Success')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                pushNextStartPaymentResult({
                  callResult: ExternalCallResult.Timeout,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Timeout')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                pushNextStartPaymentResult({
                  callResult: ExternalCallResult.Error,
                  error: StartPaymentError.ProviderConnectionInterrupted,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Error')}
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2" data-testid="settle-payment__label--eft-success">
          {t('Push next cancelation response')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, p: 1 }}>
          <Button
            onClick={() => {
              dispatch(
                pushNextCancelationResult({
                  callResult: ExternalCallResult.Success,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Success')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                pushNextCancelationResult({
                  callResult: ExternalCallResult.Timeout,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Timeout')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                pushNextCancelationResult({
                  callResult: ExternalCallResult.Error,
                  errorCode: CancelPaymentError.CannotCancelPayment,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Error')}
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2" data-testid="settle-payment__label--eft-success">
          {t('Push next payment status event')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, p: 1 }}>
          <Button
            onClick={() => {
              dispatch(
                pushCurrentPaymentProviderEvent({
                  eventType: PaymentProviderEventType.PaymentSucceeded,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Succeeded')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                pushCurrentPaymentProviderEvent({
                  eventType: PaymentProviderEventType.PaymentFailed,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Failed')}
          </Button>
          <Button
            onClick={() => {
              dispatch(
                pushCurrentPaymentProviderEvent({
                  eventType: PaymentProviderEventType.PaymentCanceled,
                }),
              );
            }}
            variant="outlined"
            size="small"
          >
            {t('Canceled')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default EftDeveloper;
