import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ProductCard from 'components/Intake/Product/Card/ProductCard';
import CouponCard from 'components/Intake/Coupon/CouponCard/CouponCard';
import { Box, Card, useTheme } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import uuidGenerator from 'utils/GuidGenerator';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination } from 'swiper';
import SwiperPagination, { ProductPagination } from 'components/Shared/Pagination/Pagination';

import 'swiper/swiper.min.css';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCustomizeProduct } from 'stores/Products';
import { BestSellerItem, Product } from 'typings/Products';
import { getCustomizationCouponMealSettings } from 'stores/Coupons';
import { Coupon } from 'typings/Coupons';
import { useWindowSize } from 'usehooks-ts';
import { addCouponToTheBasket, addToBasket, editCouponInBasket } from 'stores/Basket/basket.thunk-actions';
import { getUsePagination } from 'stores/Config/config.selector';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { getBestSellerProducts, getOptions } from 'stores/Products/products.selector';
import { getBasketCoupons, getBasketItems } from 'stores/Basket/basket.selector';
import { getActiveDeliveryType } from 'stores/Intake/intake.selector';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import { useGetBestSellerCouponsQuery } from 'stores/Coupons/coupons.api';
import { isEmpty } from 'lodash';
import buildClasses from './BestSellersCatalog.css';
import { useCalculatePageCount } from './useCalculatePageCount';

const BestSellersCatalog: React.FC = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const bestSellerPageRef = useRef<HTMLDivElement>(null);
  const { sizing } = useTheme();
  const { width, height } = useWindowSize();

  const options = useAppSelector(getOptions);
  const bestSellerProducts = useAppSelector(getBestSellerProducts);
  const usePagination = useAppSelector(getUsePagination);
  const selectedStore = useAppSelector(getSelectedStore);
  const basketItems = useAppSelector(getBasketItems);
  const basketCoupons = useAppSelector(getBasketCoupons);
  const activeDeliveryType = useAppSelector(getActiveDeliveryType);

  const {
    isSuccess: couponsLoaded,
    isFetching: couponsLoading,
    isUninitialized: couponsUninitialized,
    data: bestSellerCoupons,
  } = useGetBestSellerCouponsQuery(
    {
      storeId: selectedStore?.id ?? 0,
      basketItems,
      activeDeliveryType,
      basketCoupons,
    },
    {
      skip: !selectedStore,
    },
  );

  const pending = couponsLoading || !couponsLoaded || couponsUninitialized;

  const [pagination, setProductPagination] = useState<ProductPagination>({
    pageItems: [],
  });

  const items = useMemo(() => {
    if (pending) {
      return [];
    }
    const numberOfBoardSlots = sizing.productCard.lineCount.md * sizing.bestSellerBoard.numberOfLines;
    const bestSellerItems: BestSellerItem[] = [];
    for (let i = 0; i <= numberOfBoardSlots; i += 1) {
      const bestSellerProduct = bestSellerProducts.find((el) => el.positionNumber === i);
      const bestSellerCoupon = bestSellerCoupons?.find((el) => el.positionNumber === i);
      if (bestSellerProduct) {
        bestSellerItems.push({
          product: bestSellerProduct.product,
          positionNumber: i,
          productOption: bestSellerProduct.product.options.find(
            (opt) => opt.productOptionId === bestSellerProduct.optionId,
          ),
        });
      } else if (bestSellerCoupon) {
        bestSellerItems.push({
          coupon: bestSellerCoupon.coupon,
          positionNumber: i,
        });
      } else {
        bestSellerItems.push({ positionNumber: i });
      }
    }
    return bestSellerItems;
  }, [
    bestSellerCoupons,
    bestSellerProducts,
    pending,
    sizing.bestSellerBoard.numberOfLines,
    sizing.productCard.lineCount.md,
  ]);

  const dispatch = useAppDispatch();

  const addProductToBasket = useCallback(
    (product: Product, optionId: number): void => {
      dispatch(
        addToBasket({
          itemId: product.id,
          itemName: product.name,
          optionId,
          quantity: 1,
        }),
      );
    },
    [dispatch],
  );

  const customizeProduct = useCallback(
    (product: Product, optionId: number): void => {
      dispatch(
        setCustomizeProduct({
          baseProduct: product,
          quantity: 1,
          originalOptionId: optionId,
        }),
      );
    },
    [dispatch],
  );

  const productSelectedHandler = useCallback(
    (product: Product, bestSellerItem: BestSellerItem): void => {
      const optionId = (bestSellerItem.productOption?.productOptionId ??
        bestSellerItem.product?.options[0].productOptionId) as number;

      if (product.isXTasty || product.isCYO) {
        customizeProduct(product, optionId);
      } else if (product.options.length > 1) {
        customizeProduct(product, optionId);
      } else if (product.hasToppingsSelectionSteps) {
        customizeProduct(product, optionId);
      } else {
        addProductToBasket(product, optionId);
      }
    },
    [addProductToBasket, customizeProduct],
  );

  const addCouponToBasket = useCallback(
    (coupon2add: Coupon): void => {
      if (coupon2add.couponId) {
        dispatch(editCouponInBasket(coupon2add));
      } else {
        dispatch(addCouponToTheBasket({ ...coupon2add, couponId: uuidGenerator() }));
      }
    },
    [dispatch],
  );

  const handleCouponCardClicked = useCallback(
    (coupon2add: Coupon): void => {
      if (coupon2add.useMealConfigurator) {
        dispatch(getCustomizationCouponMealSettings(coupon2add));
      } else {
        addCouponToBasket(coupon2add);
      }
    },
    [addCouponToBasket, dispatch],
  );

  const calculatePageCount = useCalculatePageCount(bestSellerPageRef, items.length);

  const recalculatePagination = useCallback(() => {
    if (!usePagination) {
      setProductPagination({
        pageItems: [[...items]],
      });
      return;
    }
    const { pagesCount, itemsPerPage } = calculatePageCount();
    const itemsPages = [];
    for (let i = 0; i < pagesCount; i += 1) {
      itemsPages.push(items.slice(i * itemsPerPage, (i + 1) * itemsPerPage));
    }
    setProductPagination({
      pageItems: itemsPages,
    });
  }, [calculatePageCount, items, usePagination]);

  useEffect(() => {
    recalculatePagination();
  }, [width, height, recalculatePagination]);

  useEffect(() => {
    recalculatePagination();
  }, [items, recalculatePagination]);

  const onSlideChange = useCallback(() => {
    setProductPagination({
      ...pagination,
    });
  }, [pagination]);

  const noItems = !pending && isEmpty(items);
  if (noItems)
    return (
      <div className={classes.noItemsContainer}>
        <div>
          <SentimentDissatisfiedIcon className={classes.noItemsIcon} />
          <div>
            <span>{t('No products found')}</span>
          </div>
        </div>
      </div>
    );

  return (
    <Box sx={{ flex: 1, overflow: usePagination?.productsPage ? 'hidden' : 'auto' }} ref={bestSellerPageRef}>
      {pending ? (
        <PartialLoader />
      ) : (
        <Swiper
          modules={[Pagination, Mousewheel]}
          className={classes.swiper}
          mousewheel
          wrapperClass={classes.swiperWrapper}
          onSlideChange={onSlideChange}
        >
          {pagination.pageItems.map((page, pageIndex) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide className={classes.swiperSlide} key={`SWIPER_PAGE_${pageIndex}`}>
                {(page as BestSellerItem[]).map((item, itemIndex) => {
                  const selectedOption = options.find((opt) => opt.id === item.productOption?.productOptionId);
                  if (item.coupon)
                    return (
                      <CouponCard
                        key={`COUPON_${item.coupon?.couponCode ?? itemIndex}`}
                        coupon={item.coupon as Coupon}
                        onCouponSelected={handleCouponCardClicked}
                        classOverwrite={classes.cardRoot}
                      />
                    );
                  if (item.product)
                    return (
                      <ProductCard
                        product={item.product}
                        selectedOption={selectedOption}
                        onProductSelected={(product: Product) => {
                          productSelectedHandler(product, item);
                        }}
                        key={`PRODUCT_${item.product.id}`}
                        displayImage={selectedStore?.displayProductImagesDuringIntake}
                      />
                    );
                  if (!item.coupon && !item.product)
                    // eslint-disable-next-line react/no-array-index-key
                    return <Card className={classes.cardRoot} key={`EMPTY_SLOT_${pageIndex}_${itemIndex}`} />;

                  return null;
                })}
              </SwiperSlide>
            );
          })}
          <SwiperPagination pageItems={pagination.pageItems} />
        </Swiper>
      )}
    </Box>
  );
};

export default BestSellersCatalog;
