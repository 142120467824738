import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntakeStatuses } from 'containers/Intake/IntakeConsts';
import FinalizeAlerts from 'components/Intake/Finalize/FinalizeAlerts/FinalizeAlerts';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { changeDeliveryType, setIntakeStatus } from 'stores/Intake';
import { getAvailableDeliveryTypes } from 'stores/Intake/intake.selector';
import { Button, SvgIcon } from '@mui/material';
import buildClasses from './PreFinalize.css';

const PreFinalize = () => {
  const { classes } = buildClasses();
  const deliveryOptions = useAppSelector(getAvailableDeliveryTypes);
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();

  return (
    <div className={classes.wrapper}>
      <FinalizeAlerts hideMOVAlerts />
      <div className={classes.buttonGroup}>
        {deliveryOptions.length > 0 &&
          deliveryOptions.map((option) => (
            <Button
              key={option.value}
              variant="outlined"
              color="black"
              className={classes.button}
              onClick={(): void => {
                dispatch(changeDeliveryType(option.value));
                dispatch(setIntakeStatus(IntakeStatuses.finalizeOrder));
              }}
              data-testid={`pre-finalize__button--order-type-${option.value}`}
            >
              <SvgIcon className={classes.icon} component={option.icon} />
              <span>{t(option.value)}</span>
            </Button>
          ))}
      </div>
    </div>
  );
};

export default PreFinalize;
