import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import { getConnectivityStatus, getFeatureFlags } from 'stores/Config/config.selector';
import { getIsDineInOrder } from 'stores/DineIn/dineIn.selector';
import { getBasketHasItems } from 'stores/Basket/basket.selector';
import { IntakeStatuses } from '../../IntakeConsts';
import { ReceiptCustomerSearchSection } from './ReceiptCustomerSearchSection';
import { ReceiptDataSection } from './ReceiptDataSection';

interface ReceiptBodyProps {
  intakeStatus: IntakeStatuses;
  canEdit?: boolean;
  onSearchCustomer?: (query: string) => void;
  onIntakeWithoutProfile?: () => void;
  onHideReceipt?: () => void;
}

export const ReceiptBody: React.FC<ReceiptBodyProps> = memo(
  ({ intakeStatus, canEdit, onSearchCustomer, onIntakeWithoutProfile, onHideReceipt }) => {
    const featureFlags = useAppSelector(getFeatureFlags);
    const connectivityStatus = useAppSelector(getConnectivityStatus);
    const { selectedOrderCustomer } = useAppSelector(({ intake }) => intake);
    const { basketData } = useAppSelector((store) => store.basket);
    const basketHasItems = useAppSelector(getBasketHasItems);
    const isDineInOrder = useAppSelector(getIsDineInOrder);

    const [forceShowCustomerSearch, setForceShowCustomerSearch] = useState<boolean>(false);

    // This will keep "restart order" button visible even when user removes manually all products.
    // Order has to be either restarted or finished to show the default view
    // For now lets hide it under fiscalization ff, if there won't be any issues we may detach it from FF as it seems it provides better UX than previous behavior
    const orderHasBeenStarted = basketData !== null && featureFlags.OfflineModule_Fiscalization === true;

    const isCustomerSearchEnabled = connectivityStatus === ConnectivityStatusEnum.Connected;

    const isCustomerSearchVisible =
      forceShowCustomerSearch ||
      (!basketHasItems &&
        isCustomerSearchEnabled &&
        !selectedOrderCustomer?.profile?.id &&
        !isDineInOrder &&
        !orderHasBeenStarted);

    const showCustomerSearch = useCallback(() => setForceShowCustomerSearch(true), []);
    const hideCustomerSearch = useCallback(() => setForceShowCustomerSearch(false), []);

    const shouldHideSearch = forceShowCustomerSearch && selectedOrderCustomer !== undefined;
    useEffect(() => {
      if (shouldHideSearch) {
        hideCustomerSearch();
      }
    }, [hideCustomerSearch, shouldHideSearch]);

    return isCustomerSearchVisible ? (
      <ReceiptCustomerSearchSection
        onIntakeWithoutProfile={onIntakeWithoutProfile}
        onSearchCustomer={onSearchCustomer}
        onHideCustomerSearch={hideCustomerSearch}
      />
    ) : (
      <ReceiptDataSection
        onHideReceipt={onHideReceipt}
        canEdit={canEdit}
        intakeStatus={intakeStatus}
        isCustomerSearchEnabled={isCustomerSearchEnabled}
        showCustomerSearch={showCustomerSearch}
      />
    );
  },
);
ReceiptBody.displayName = 'ReceiptBody';
