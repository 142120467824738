import React, { useState } from 'react';
import { Box, CardActionArea, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import { CouponValidity } from 'containers/Intake/IntakeConsts';
import { useTranslation } from 'react-i18next';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import ReceiptSetDiscountDetails from 'components/Intake/Receipt/Item/ReceiptSetDiscount/ReceiptSetDiscountDetails';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { BasketItem, DiscountType, ReceiptDiscount } from 'typings/Basket';
import { removeFromBasket } from 'stores/Basket/basket.thunk-actions';
import { getBasketCoupons } from 'stores/Basket/basket.selector';
import ReceiptDiscountDetails from './ReceiptDiscount/ReceiptDiscountDetails';
import buildClass from './ReceiptDiscount.css';

interface ReceiptDiscountProps {
  discount: ReceiptDiscount;
  removeItem?: (discount: ReceiptDiscount) => void;
  customizeDiscount?: (discount: ReceiptDiscount) => void;
  customizeProduct: (productToCustomize: BasketItem) => void;
  canRemoveItem: boolean;
  canRemoveDiscount: boolean;
  changeAutoAddedItemQuantity?: (itemId: number, quantity: number) => void;
}

const discountTypesWithValueShown = [DiscountType.CustomerCredit, DiscountType.FixedPrice];

export const ReceiptDiscountItem: React.FC<ReceiptDiscountProps> = ({
  discount,
  removeItem,
  customizeDiscount,
  customizeProduct,
  canRemoveItem,
  canRemoveDiscount,
  changeAutoAddedItemQuantity,
}) => {
  const { classes, cx } = buildClass();
  const [t] = useTranslation('intake');
  const { palette } = useTheme();

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const basketCoupons = useAppSelector(getBasketCoupons);
  const mealConfiguratorBasketCoupon = basketCoupons.find(
    (coupon) => coupon.couponCode && coupon.couponCode === discount.code,
  );
  const canRemoveCouponProduct =
    !mealConfiguratorBasketCoupon || !mealConfiguratorBasketCoupon.useMealConfigurator;

  const dispatch = useAppDispatch();

  function getDescription(): JSX.Element {
    if (discount.couponValidity === CouponValidity.Active) {
      return (
        <Typography
          variant="caption"
          className={classes.discountDescription}
          data-testid="receipt-item-discount__label--description"
        >
          {t('Discount applied successfully')}
        </Typography>
      );
    }
    return (
      <Box className={classes.errorDescription}>
        <span>{t(discount.couponValidity)}</span>
      </Box>
    );
  }

  function getDiscountColor(): string {
    if (discount.discountType === 'CustomerCredit') return palette.virtualReceipt.items.discount.active.background;

    if (discount.couponValidity === CouponValidity.Active)
      return palette.virtualReceipt.items.discount.active.background;

    return palette.virtualReceipt.items.discount.inactive.background;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: getDiscountColor(),
        }}
        className={classes.root}
        data-testid="receipt-discount__container"
      >
        <CardActionArea
          className={classes.itemClickZone}
          onClick={(): void => {
            customizeDiscount && customizeDiscount(discount);
          }}
        >
          <LocalActivityIcon
            className={cx(classes.discountIcon, {
              [classes.inactiveIcon]: discount.couponValidity !== CouponValidity.Active, // temp, will do it better
            })}
          />
          <Box className={classes.discountDetails}>
            <Typography
              variant="subtitle2"
              className={classes.discountName}
              data-testid="receipt-item__label--name"
            >
              {discount.description}
            </Typography>
            {getDescription()}
          </Box>
          {!!discount.value && discountTypesWithValueShown.some((d) => d === discount.discountType) && (
            <div>
              <span className={classes.discountTotalPrice}>{formatToDisplay(discount.value)}</span>
            </div>
          )}
        </CardActionArea>
        {discount.discountProducts && discount.discountProducts.length > 0 && (
          <IconButton
            onClick={(): void => {
              setShowDetails(!showDetails);
            }}
            data-testid="receipt-item-discount__button--customize-discount"
          >
            <ExpandMore className={cx(classes.rotate, { [classes.rotateActive]: showDetails })} />
          </IconButton>
        )}
        {canRemoveDiscount && (
          <IconButton
            onClick={(): void => {
              removeItem && removeItem(discount);
            }}
            className={classes.deleteIcon}
            data-testid="receipt-item-discount__button--delete-discount"
          >
            <DeleteOutline />
          </IconButton>
        )}
      </Box>
      {discount.discountProducts && discount.discountProducts.length > 0 && (
        <Collapse in={!!showDetails}>
          <Box className={classes.listItemDetails} data-testid="receipt-item__discount--discount-details">
            <Box className={classes.listItemDetailsArrow} />
            {discount.isSet ? (
              <ReceiptSetDiscountDetails
                canRemoveItem={canRemoveItem && canRemoveCouponProduct}
                customizeProductHandler={customizeProduct}
                discount={discount}
                discountProducts={discount.discountProducts}
                onRemoveFromBasket={(id) => dispatch(removeFromBasket(id))}
                changeAutoAddedItemQuantity={changeAutoAddedItemQuantity}
              />
            ) : (
              <ReceiptDiscountDetails discount={discount} discountProducts={discount.discountProducts} />
            )}
          </Box>
        </Collapse>
      )}
    </>
  );
};
