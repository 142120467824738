import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getSelectedStoreId } from 'stores/Store/store.selectors';
import { useGetNoOfOrdersToConfirmEtaQuery } from 'stores/Notifications/notifications.api';
import { useAppSelector } from 'hooks/useRedux';
import { getEtaConfirmationNotifierEnabled } from 'stores/Config/config.selector';

const transparentColor = 'rgba(0, 0, 0, 0)';
const pollingIntervalInSec = 60;

export const EtaConfirmationNotifier = memo(() => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('common');
  const navigate = useNavigate();

  const etaConfirmationNotifierEnabled = useAppSelector(getEtaConfirmationNotifierEnabled);
  const storeId = useAppSelector(getSelectedStoreId);

  const { isLoading, data: noOfOrdersToConfirmEta } = useGetNoOfOrdersToConfirmEtaQuery(storeId, {
    pollingInterval: pollingIntervalInSec * 1000,
    skipPollingIfUnfocused: true,
    refetchOnReconnect: true,
    skip: !storeId || !etaConfirmationNotifierEnabled,
  });

  const animationVariants = {
    active: {
      backgroundColor: palette.yellow[600],
    },
    inactive: {
      backgroundColor: transparentColor,
      transition: {
        repeat: 0,
      },
    },
  };

  const goToMakescreen = useCallback(() => {
    navigate('/makescreen?init=eta-confirm');
  }, [navigate]);

  return etaConfirmationNotifierEnabled ? (
    <Box sx={{ borderRight: `1px solid ${palette.white}`, mr: 2, pr: 2 }}>
      <motion.div
        variants={animationVariants}
        initial={{ borderRadius: spacing(0.5), backgroundColor: transparentColor }}
        animate={noOfOrdersToConfirmEta ? 'active' : 'inactive'}
        transition={{ repeatType: 'reverse', repeat: Infinity, duration: 0.8 }}
      >
        <Box
          data-testid="eta-confirmation-conatainer"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: spacing(0.5),
            color: palette.white,
          }}
        >
          <Box sx={{ pl: 0.5, display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                px: 1,
                background: 'red',
                borderRadius: spacing(0.5),
                textAlign: 'center',
              }}
            >
              {isLoading ? '-' : `${noOfOrdersToConfirmEta ?? 0}`}
            </Typography>
          </Box>
          <Button onClick={goToMakescreen} sx={{ px: 1, py: 1.5, color: palette.white }}>
            {t('NEW ORDERS')}
          </Button>
        </Box>
      </motion.div>
    </Box>
  ) : null;
});

EtaConfirmationNotifier.displayName = 'EtaConfirmationNotifier';
