/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { assignCreditToCustomer } from 'stores/AllOrders';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Box, TextField, useTheme } from '@mui/material';
import InputUnit from 'components/Shared/Inputs/InputUnit';
import { useTranslation } from 'react-i18next';
import { formatToEdit, numberFromMoney } from 'utils/payment/PaymentUtils';
import { useForm } from 'react-hook-form';
import Validators from 'utils/validation/ValidationConstants';
import { getMaxCustomerCreditAssignment, getPaymentConfig } from 'stores/Config/config.selector';

interface AssignCustomerCreditDialogProps {
  showDialog: boolean;
  orderId: string;
  abort: () => void;
  onConfirm: () => void;
}

const AssignCustomerCreditDialog: React.FC<AssignCustomerCreditDialogProps> = ({
  showDialog,
  orderId,
  abort,
  onConfirm,
}) => {
  const formId = 'assign-customer-credit-form';

  const maxCustomerCreditAssignment = useAppSelector(getMaxCustomerCreditAssignment);

  const [t] = useTranslation('orders');
  const dispatch = useAppDispatch();
  const { spacing, sizing } = useTheme();
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const paymentSettings = useAppSelector(getPaymentConfig);

  const submit = useCallback(
    (data: any) => {
      trigger().then((result) => {
        if (result) {
          dispatch(
            assignCreditToCustomer({
              reason: data.reason,
              amount: numberFromMoney(data.inputValue, paymentSettings),
              orderId,
            }),
          );
          onConfirm();
        }
      });
    },
    [dispatch, onConfirm, orderId, paymentSettings, trigger],
  );

  function maxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('intake:max.')} ${max})`;
  }

  const { ref, ...rest } = register('reason', {
    required: t('Reason is required'),
    maxLength: { value: 255, message: maxLengthMessage(255) },
  });

  return (
    <ModalContainer
      onClose={abort}
      open={showDialog}
      title={t('Assign customer credit')}
      primaryButton={{
        type: 'button',
        action: () => {
          submit(getValues());
        },
        label: t('Credit value'),
        testId: 'assign-customer-credit-modal__button--assign',
      }}
      testId="assign-customer-credit-modal"
    >
      <Box component="form" name={formId} id={formId} onSubmit={handleSubmit((data) => submit(data))}>
        <TextField
          sx={{ padding: `${spacing(3)} ${spacing(3)}`, width: sizing.full }}
          inputRef={ref}
          {...rest}
          multiline
          variant="outlined"
          rows="4"
          error={!!errors.reason}
          helperText={`${errors.reason?.message || ''}`}
          inputProps={{ 'data-testid': 'assign-customer-credit-modal__input--credit-reason' }}
          placeholder={t('Reason')}
        />
        <Box sx={{ padding: `${spacing(2)} ${spacing(3)}` }}>
          <InputUnit
            inputValidation={{
              errors,
              formRef: register,
              required: true,
              validators: {
                isMoney: (val: string): boolean | string =>
                  Validators.Money.Validator(paymentSettings).test(val) || t(Validators.Money.Message),
                isAboveValue: (val: string): boolean | string =>
                  Validators.IsAboveGivenValue.Validator(numberFromMoney(val, paymentSettings), 0) ||
                  t('Value must be greater than 0'),
                isBelowLimit: (val: string): boolean | string =>
                  Validators.IsBelowOrderTotal.Validator(
                    numberFromMoney(val, paymentSettings),
                    Number(maxCustomerCreditAssignment),
                  ) || `${t('Value must be below')} ${maxCustomerCreditAssignment}`,
              },
            }}
            defaultValue={formatToEdit(0, paymentSettings)}
            testId="assign-customer-credit-modal__input--credit-value"
            label={t('Credit value')}
          />
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default AssignCustomerCreditDialog;
