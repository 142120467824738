import { isEmpty } from 'lodash';
import { FetchBaseQueryError, QueryReturnValue } from '@reduxjs/toolkit/query/react';

export const isNotNullish = <T>(v: T | null | undefined): v is T => v != null;

export const isNotEmpty = <T>(argument: T | undefined | null): argument is T => !isEmpty(argument);

export type MaybePromise<T> = T | PromiseLike<T>;

export type QueryResult<T> = MaybePromise<QueryReturnValue<T, FetchBaseQueryError, object>>;
