import React, { memo, useCallback } from 'react';
import { ReceiptProductItem } from 'components/Intake/Receipt/Item/ReceiptProductItem';
import { ReceiptDiscountItem } from 'components/Intake/Receipt/Item/ReceiptDiscountItem';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getCustomizationProductSettings } from 'stores/Products';
import { MealCoupon } from 'typings/Coupons';
import { setCouponToCustomize } from 'stores/Coupons';
import {
  recalculateBasket,
  removeCouponFromTheBasket,
  removeFromBasket,
} from 'stores/Basket/basket.thunk-actions';
import { changeAutoItemQuantity } from 'stores/Basket/basket.slice';
import { BasketItem, ReceiptDiscount, ReceiptProduct } from 'typings/Basket';
import { clearCustomerCreditUsage } from 'stores/Customer';
import { getCanEditBasket } from 'stores/Intake/intake.selector';
import { getVirtualReceipt, getBasketCoupons } from 'stores/Basket/basket.selector';
import buildClasses from './ReceiptItems.css';

interface ReceiptItemsProps {
  canRemoveItems: boolean;
}

export const ReceiptItems: React.FC<ReceiptItemsProps> = memo(({ canRemoveItems }) => {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const { receiptProducts, receiptDiscounts } = useAppSelector(getVirtualReceipt);
  const basketCoupons = useAppSelector(getBasketCoupons);
  const basketEdition = useAppSelector(getCanEditBasket);

  const customizeProductHandler = useCallback(
    (basketItem: BasketItem): void => {
      dispatch(getCustomizationProductSettings({ basketItem, searchType: 'itemId' }));
    },
    [dispatch],
  );

  const customizeDiscountHandler = useCallback(
    (discountItem: ReceiptDiscount): void => {
      const couponToCustomize = basketCoupons.find((bc) => bc.couponId === discountItem.couponId);

      if (couponToCustomize?.useMealConfigurator) {
        const mealCoupon = couponToCustomize as MealCoupon;
        dispatch(setCouponToCustomize(mealCoupon));
      }
    },
    [basketCoupons, dispatch],
  );

  const changeAutoAddedItemQuantity = useCallback(
    (itemId: number, quantity: number): void => {
      dispatch(changeAutoItemQuantity({ itemId, quantity }));
      dispatch(recalculateBasket({}));
    },
    [dispatch],
  );

  const removeProduct = useCallback(
    (product: ReceiptProduct) => {
      dispatch(removeFromBasket(product.id));
    },
    [dispatch],
  );

  const removeDiscount = useCallback(
    (discount: ReceiptDiscount) => {
      if (discount.discountType === 'CustomerCredit') {
        dispatch(clearCustomerCreditUsage());
        dispatch(recalculateBasket({}));
        return;
      }
      discount.couponId && dispatch(removeCouponFromTheBasket(discount.couponId));
    },
    [dispatch],
  );

  return (
    <div
      className={`${classes.itemsContainer} withripple ${
        canRemoveItems ? classes.itemsContainerWithToggleGroup : classes.itemsContainerWithoutToggleGroup
      }`}
    >
      {receiptProducts.map((receiptProduct: ReceiptProduct) => (
        <ReceiptProductItem
          isBasketEditable={basketEdition}
          key={receiptProduct.id}
          receiptProduct={receiptProduct}
          removeItem={removeProduct}
          customizeProduct={customizeProductHandler}
          canRemoveItem={canRemoveItems}
          changeAutoAddedItemQuantity={changeAutoAddedItemQuantity}
        />
      ))}
      {receiptDiscounts &&
        receiptDiscounts.map((discount: ReceiptDiscount) => (
          <ReceiptDiscountItem
            discount={discount}
            key={discount.couponId ?? discount.description}
            canRemoveItem={canRemoveItems}
            canRemoveDiscount={(canRemoveItems && discount.canDelete) ?? false}
            customizeProduct={customizeProductHandler}
            customizeDiscount={customizeDiscountHandler}
            removeItem={removeDiscount}
            changeAutoAddedItemQuantity={changeAutoAddedItemQuantity}
          />
        ))}
    </div>
  );
});

ReceiptItems.displayName = 'ReceiptItems';
