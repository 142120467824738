import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { MachineToCreate, MachineType } from 'typings/Auth';
import { Controller, useForm } from 'react-hook-form';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { createMachineProfile } from 'stores/Auth';
import uuidGenerator from 'utils/GuidGenerator';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { getDefaultStoreOnSiteMachineType } from 'stores/Config/config.selector';

const MachineTypesArray: MachineType[] = [
  MachineType.Registry,
  MachineType.Makescreen,
  MachineType.Dispatch,
  MachineType.ExecutiveDashboard,
  MachineType.CallCenters,
  MachineType.ToGo,
  MachineType.PackScreen,
];
const formId = 'create-machine-profile';

interface MachineForm {
  machineName: string;
  machineType: MachineType;
}

const maxMachineNameLength = 25;

const CreateMachineProfile: React.FC<{
  storeId: number;
  onMachineCreated: (createdMachine: MachineToCreate) => void;
}> = ({ storeId, onMachineCreated }) => {
  const [t] = useTranslation('configuration');
  const [terror] = useTranslation('errorCodes');
  const defaultStoreOnSiteMachineType = useAppSelector(getDefaultStoreOnSiteMachineType);

  const defaultValues = useMemo(
    () => ({
      machineType: defaultStoreOnSiteMachineType
        ? Number(MachineType[defaultStoreOnSiteMachineType.valueOf()])
        : MachineTypesArray[0],
    }),
    [defaultStoreOnSiteMachineType],
  );

  const { register, handleSubmit, formState, control, reset } = useForm<MachineForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const { errors } = formState;

  const [showDevUI, setShowDevUI] = useState<boolean>(false);
  const [isDevelopmentMachine, setIsDevelopmentMachine] = useState<boolean>(false);

  const { creatingMachineStatus, createdMachine, createMachineError } = useAppSelector(
    (state) => state.authorization,
  );

  const dispatch = useAppDispatch();

  function createMachine(data: MachineForm): void {
    const newMachineProfile = {
      storeId,
      isDevelopmentMachine,
      machineName: data.machineName,
      machineType: data.machineType,
      touchpointId: uuidGenerator(),
    } as MachineToCreate;

    dispatch(createMachineProfile(newMachineProfile));
  }

  function MaxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('intake:max.')} ${max})`;
  }

  useEffect(() => {
    const handleDevShortcut = (e: KeyboardEvent) => {
      if (e?.key === 'ArrowDown' && e?.ctrlKey) {
        setShowDevUI(!showDevUI);
      }
    };
    document.addEventListener('keydown', handleDevShortcut);

    return () => {
      document.removeEventListener('keydown', handleDevShortcut);
    };
  }, [showDevUI]);

  useEffect(() => {
    !!createdMachine && onMachineCreated(createdMachine);
  }, [createdMachine, onMachineCreated]);

  const { ref, ...rest } = register('machineName', {
    required: t('Machine name is required'),
    maxLength: { value: maxMachineNameLength, message: MaxLengthMessage(maxMachineNameLength) },
  });

  return (
    <>
      <Typography variant="h5">{t('Create new machine profile')}</Typography>
      <form name={formId} onSubmit={handleSubmit((data) => createMachine(data))} id={formId}>
        <Typography variant="body2" sx={{ mb: 0.5, mt: 2 }}>
          {t('Machine name')}
        </Typography>
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          defaultValue=""
          inputRef={ref}
          {...rest}
          variant="outlined"
          rows="8"
          error={!!errors.machineName}
          helperText={errors.machineName && errors.machineName.message}
          inputProps={{ 'data-testid': 'text-field-modal__input--text' }}
        />

        <Typography variant="body2" sx={{ mb: 0.5 }}>
          {t('Machine type')}
        </Typography>
        <Controller
          render={({ field }) => (
            <Select {...field} fullWidth variant="outlined">
              {MachineTypesArray.map((machineType) => {
                return (
                  <MenuItem key={machineType} value={machineType}>
                    {t(MachineType[machineType])}
                  </MenuItem>
                );
              })}
            </Select>
          )}
          name="machineType"
          rules={{ required: t('Device type must be selected!') as string }}
          control={control}
        />
        <FormHelperText>{errors.machineType && errors.machineType.message}</FormHelperText>

        {showDevUI && (
          <Box sx={{ display: 'flex', mt: 2, alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              {t('Development machine')}
            </Typography>
            <Checkbox
              sx={{ px: 0 }}
              checked={isDevelopmentMachine}
              onChange={(event) => {
                setIsDevelopmentMachine(event.target.checked);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        )}

        {isDevelopmentMachine && (
          <Typography sx={{ mt: 2 }} variant="h5" gutterBottom data-testid="development-machine-alert">
            <Alert severity="warning" icon={<ErrorOutlineOutlined fontSize="inherit" />}>
              {t('The machine will be set for Developer use')}
            </Alert>
          </Typography>
        )}

        {creatingMachineStatus === 'ERROR' && createMachineError && (
          <Typography sx={{ mt: 2 }} variant="h5" gutterBottom data-testid="development-machine-alert">
            <Alert severity="error" icon={<ErrorOutlineOutlined fontSize="inherit" />}>
              {terror(createMachineError)}
            </Alert>
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          type="submit"
          fullWidth
          disabled={creatingMachineStatus === 'PENDING' || !formState.isValid}
        >
          {creatingMachineStatus === 'PENDING' ? (
            <Box sx={{ display: 'flex' }}>
              <JumpingDotsLoader />
              <Typography variant="body2">{t('Creating machine...')}</Typography>
            </Box>
          ) : (
            <>{t('Create machine profile')}</>
          )}
        </Button>
      </form>
    </>
  );
};

export default CreateMachineProfile;
