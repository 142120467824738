import React from 'react';
import { Button, Snackbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { clearAlert } from 'stores/Alerts';
import buildClasses from './AlertSnackbar.css';

const AlertSnackbar = () => {
  const { classes, cx } = buildClasses();
  const { alert } = useAppSelector((state) => state.alerts);

  const dispatch = useAppDispatch();
  const [t] = useTranslation('alerts');

  if (!alert) return null;

  const { header, message, messageAddon, secondLine, variant, dismissible, position, secondLineAddon } = alert;

  const getContainerStyle = () => {
    switch (variant) {
      case 'success':
        return classes.containerSuccess;
      case 'error':
        return classes.containerError;
      case 'info':
        return classes.containerInfo;
      case 'warning':
        return classes.containerWarning;
      default:
        return '';
    }
  };

  return (
    <Snackbar
      open={!!alert}
      autoHideDuration={dismissible ? null : 7000}
      onClose={(): void => {
        // eslint-disable-next-line no-console
        console.log('authide triggered');
        dispatch(clearAlert());
      }}
      anchorOrigin={position ?? { horizontal: 'left', vertical: 'bottom' }}
    >
      <div className={cx(classes.container, getContainerStyle())}>
        <Typography variant="subtitle2" data-testid="toast__label--header">
          {t(header)}
        </Typography>
        <div data-testid="toast__label--details">
          {message && (
            <div className={classes.messageContainer}>
              <Typography variant="body2">{t(message)}</Typography>
              {messageAddon && <Typography variant="body2">{t(messageAddon)}</Typography>}
            </div>
          )}
          {secondLine && (
            <div className={classes.messageContainer}>
              <Typography variant="body2">{t(secondLine)}</Typography>
              {secondLineAddon && <Typography variant="body2">{t(secondLineAddon)}</Typography>}
            </div>
          )}
        </div>
        {dismissible && (
          // <div className={classes.dismissContainer}>
          <Button className={classes.dismissButton} onClick={() => dispatch(clearAlert())}>
            {t('Dismiss')}
          </Button>
          // </div>
        )}
      </div>
    </Snackbar>
  );
};

export default AlertSnackbar;
