import Axios, { AxiosError } from 'axios';
import { DineInOrderStatus, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import i18n from 'i18n';
import { PaymentTarget } from 'typings/Payments';
import { escapeRegExp } from 'lodash';
import { format } from 'date-fns';
import { getBaseUrl } from './utils';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || getBaseUrl();

export const POS_HEADERS = {
  'Content-type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': 'true',
  'X-Origin-Module': 'offline-pos',
  'X-System-Type': 'Pos',
};

const posApi = Axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: POS_HEADERS,
});

posApi.interceptors.request.use(
  (request) => {
    if (request.url && requestRequiresLocale(request.url)) {
      request.params = {
        locale: i18n.resolvedLanguage,
        ...request.params,
      };
    }
    return request;
  },
  (error: AxiosError) => {
    return error;
  },
);

export default posApi;

export const API_PREFIX = '/api';

const API_CONTROLLERS = {
  ACCOUNT: `${API_PREFIX}/account`,
  BASKET: `${API_PREFIX}/basket`,
  CONFIGURATION: `${API_PREFIX}/configuration`,
  DEVELOPMENT: `${API_PREFIX}/dev`,
  HISTORY: `${API_PREFIX}/history`,
  ORDERS: `${API_PREFIX}/orders`,
  PAYMENT: `${API_PREFIX}/payment`,
  PAYMENT_V2: `${API_PREFIX}/payment/v2`,
  PAYMENT_METHODS: `${API_PREFIX}/payment-methods`,
  STORES: `${API_PREFIX}/stores`,
  PRODUCTS: `${API_PREFIX}/products`,
  SYNCHRONIZATION: `${API_PREFIX}/sync`,
  TOPPINGS: `${API_PREFIX}/toppings`,
  DISCOUNTS: `${API_PREFIX}/discounts`,
  BEST_SELLERS: `${API_PREFIX}/best-seller`,
  CUSTOMER: `${API_PREFIX}/customer`,
  DINEIN_ORDERS: `${API_PREFIX}/open-tabs`,
  PRICES: `${API_PREFIX}/prices`,
  RESTAURANT: `${API_PREFIX}/restaurant`,
  NOTIFICATIONS: `${API_PREFIX}/notifications`,
  USER: `${API_PREFIX}/user`,
  INITIAL_SETUP: `${API_PREFIX}/initial-setup`,
  OSM: `${API_PREFIX}/osm`,
  LOCALIZATION: `${API_PREFIX}/localization`,
  ONSITE: `${API_PREFIX}/onsite`,
  VERSION_UPGRADE: `${API_PREFIX}/version-upgrade`,
  LOYALTY: `${API_PREFIX}/loyalty`,
  FISCALIZATION: `${API_PREFIX}/fiscalization`,
};

export const posApiUrls = {
  ACCOUNT_LOGIN: `${API_CONTROLLERS.ACCOUNT}/login`,
  ACCOUNT_LOGOUT: `${API_CONTROLLERS.ACCOUNT}/logout`,
  ACCOUNT_RESET_PINCODE: `${API_CONTROLLERS.ACCOUNT}/reset-pincode`,
  ACCOUNT_CLOCK_IN: `${API_CONTROLLERS.ACCOUNT}/timekeeping/clock-in`,
  ACCOUNT_CLOCK_OUT_EMPLOYEE(forEmployeeId: number | undefined | string): string {
    if (!forEmployeeId) return `${API_CONTROLLERS.ACCOUNT}/timekeeping/clock-out`;
    return `${API_CONTROLLERS.ACCOUNT}/timekeeping/clock-out?employeeId=${forEmployeeId}`;
  },
  ACCOUNT_CLOCKED_IN_USERS: `${API_CONTROLLERS.ACCOUNT}/timekeeping/clocked-users`,
  ACCOUNT_START_BREAK: `${API_CONTROLLERS.ACCOUNT}/timekeeping/start-break`,
  ACCOUNT_END_BREAK: `${API_CONTROLLERS.ACCOUNT}/timekeeping/end-break`,
  ACCOUNT_EMPLOYEE_CASH_BALANCE(date: Date | undefined = undefined): string {
    return `${API_CONTROLLERS.ACCOUNT}/timekeeping/employee-cash-balance?localDateTime=${format(
      date || new Date(),
      "yyyy-MM-dd'T'HH:mm:ss",
    )}`;
  },
  ACCOUNT_MACHINE_LOGOUT: `${API_CONTROLLERS.ACCOUNT}/machine/logout`,
  ACCOUNT_MACHINE_UPDATE_LAST_ACTIVITY: `${API_CONTROLLERS.ACCOUNT}/machine/heartbeat`,
  BASKET_RECALCULATE: `${API_CONTROLLERS.BASKET}/recalculate`,
  BASKET_VALIDATE: `${API_CONTROLLERS.BASKET}/validate`,
  BASKET_ITEM_RECALCULATE: `${API_CONTROLLERS.BASKET}/item/recalculate`,
  SET_RECALCULATE: `${API_CONTROLLERS.BASKET}/set/recalculate`,
  CONFIGURATION_PRINTERS: `${API_CONTROLLERS.CONFIGURATION}/system/printers`,
  CONFIGURATION_PRINTING: `${API_CONTROLLERS.CONFIGURATION}/printing`,
  CONFIGURATION_PRINTING_VALIDATE: `${API_CONTROLLERS.CONFIGURATION}/printing/validate`,
  CONFIGURATION_INTAKE: `${API_CONTROLLERS.CONFIGURATION}/business-settings?key=Pos.Sales.Intake`,
  ONSITE_SETTINGS: `${API_CONTROLLERS.ONSITE}/machine-settings`,
  DEV_APP_VERSION: `${API_CONTROLLERS.DEVELOPMENT}/version`,
  POS_UI_CONFIG: `${API_CONTROLLERS.CONFIGURATION}/ui/pos`,
  CONFIGURATION_FEATURE_FLAGS: `${API_CONTROLLERS.CONFIGURATION}/feature-flags`,
  DINEIN_ORDERS: `${API_CONTROLLERS.DINEIN_ORDERS}`,
  DINEIN_ORDERS_TAB(tabId: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${tabId}`;
  },
  DINEIN_ORDERS_UPDATE(tabId: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${tabId}/update`;
  },
  DINE_IN_ORDERS_COUPON_REMOVE(tabId: string, coupondId: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${tabId}/remove-coupon?couponId=${coupondId}`;
  },
  DINEIN_ORDERS_CANCEL(tabId: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${tabId}/cancel`;
  },
  DINEIN_ORDERS_ITEM_CANCEL(tabId: string, dineInItemId: number): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${tabId}/cancel-item?itemId=${dineInItemId}`;
  },
  DINEIN_ORDERS_SWAP_TABLES(originTabId: string, targetTabId: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${originTabId}/swap-tables?targetTabId=${targetTabId}`;
  },
  DINEIN_ORDERS_PAGE(status: DineInOrderStatus, sortBy: string, pageIndex: number, pageSize: number): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}?status=${status}&sortBy=${sortBy}&index=${pageIndex}&pageSize=${pageSize}`;
  },
  DINEIN_ORDER_FINALIZE(tabId: string, newOrderId?: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/${tabId}/finalize?orderId=${newOrderId}`;
  },
  DINEIN_ORDER_FINALIZE_V2(tabId: string): string {
    return `${API_CONTROLLERS.DINEIN_ORDERS}/v2/${tabId}/finalize`;
  },
  ORDER_TAKEAWAY: `${API_CONTROLLERS.ORDERS}/v2/take-away`,
  ORDER_TAKEAWAY_V3: `${API_CONTROLLERS.ORDERS}/v3/take-away`,
  ORDER_TAKEAWAY_PAYMENT_ACCEPT(id: string): string {
    return `${API_CONTROLLERS.ORDERS}/${id}/accept-payment`;
  },
  ORDER_PICKUP: `${API_CONTROLLERS.ORDERS}/pickup`,
  ORDER_DELIVERY: `${API_CONTROLLERS.ORDERS}/delivery`,
  ORDER_CANCEL(id: string): string {
    return `${API_CONTROLLERS.ORDERS}/${id}/cancel`;
  },
  ORDER_FINALIZE(id: string): string {
    return `${API_CONTROLLERS.ORDERS}/pickup/${id}/finalize`;
  },
  ORDER_REFUND_ELIGIBILITY(orderId: string): string {
    return `${API_CONTROLLERS.ORDERS}/${orderId}/is-refundable`;
  },
  HISTORY_ORDERS: `${API_CONTROLLERS.HISTORY}/orders/`,
  HISTORY_ORDER(id: string): string {
    return `${API_CONTROLLERS.HISTORY}/orders/${id}`;
  },
  HISTORY_ORDER_REPRINT(id: string): string {
    return `${API_CONTROLLERS.HISTORY}/orders/${id}/reprint`;
  },
  OPTION_PRICES(pickupType: string, productId: number): string {
    return `${API_CONTROLLERS.PRICES}/options?pickupType=${pickupType}&productId=${productId}`;
  },
  PAYMENT_CHANGE: `${API_CONTROLLERS.PAYMENT}/change`,
  PAYMENT_METHODS: `${API_CONTROLLERS.PAYMENT_METHODS}`,
  PRODUCTS_GET: `${API_CONTROLLERS.PRODUCTS}`,
  PRODUCTS_GROUPS_GET: `${API_CONTROLLERS.PRODUCTS}/groups`,
  PRODUCTS_OPTIONS_GET: `${API_CONTROLLERS.PRODUCTS}/options`,
  PRODUCTS_SUBTYPES_GET: `${API_CONTROLLERS.PRODUCTS}/options/subtypes`,
  PRODUCTS_CATEGORIES_GET: `${API_CONTROLLERS.PRODUCTS}/categories`,
  COUPONS_GET: `${API_CONTROLLERS.DISCOUNTS}/store`,
  SELECTED_STORE_GET: `${API_CONTROLLERS.STORES}/selected`,
  SWITCH_STORES: `${API_CONTROLLERS.STORES}/switch`,
  STORES_GET: `${API_CONTROLLERS.STORES}`,
  ETA_CONFIGURATION(storeId: number) {
    return `${API_CONTROLLERS.STORES}/${storeId}/eta-configuration`;
  },
  SYNCHRONIZATION_HISTORY: `${API_CONTROLLERS.SYNCHRONIZATION}/download/history`,
  SYNCHRONIZATION_RUN: `${API_CONTROLLERS.SYNCHRONIZATION}/download/run`,
  SYNCHRONIZATION_STATUS: `${API_CONTROLLERS.SYNCHRONIZATION}/download/status`,
  VERSION_UPGRADE_STATUS: `${API_CONTROLLERS.VERSION_UPGRADE}/status`,
  VERSION_UPGRADE_RETRY: `${API_CONTROLLERS.VERSION_UPGRADE}/retry`,
  SYNCHRONIZATION_CONNECTIVITY_STATUS: `${API_CONTROLLERS.SYNCHRONIZATION}/connectivity-status`,
  SYNCHRONIZATION_ETA(storeId: number) {
    return `${API_CONTROLLERS.SYNCHRONIZATION}/stores/${storeId}/eta-configuration`;
  },
  TOPPINGS_GET: API_CONTROLLERS.TOPPINGS,
  TOPPINGS_CATEGORIES: `${API_CONTROLLERS.TOPPINGS}/categories`,
  TOPPINGS_SAUCES: `${API_CONTROLLERS.TOPPINGS}/categories/sauce`,
  TOPPINGS_CRUSTS: `${API_CONTROLLERS.TOPPINGS}/categories/crust`,
  DISCOUNT_MEAL_SETTINGS(couponCode: string): string {
    const params = encodeURI(couponCode);
    return `${API_CONTROLLERS.DISCOUNTS}/${params}/meal-settings`;
  },
  BEST_SELLER_PRODUCTS_GET: `${API_CONTROLLERS.BEST_SELLERS}/products`,
  BEST_SELLER_COUPONS_GET: `${API_CONTROLLERS.BEST_SELLERS}/coupons`,
  CUSTOMER_SEARCH(query: string, pageIndex: number): string {
    return `${API_CONTROLLERS.CUSTOMER}/search?query=${query}&pageIndex=${pageIndex}`;
  },
  CUSTOMER_ADDRESSES(id: number, profiletype: string): string {
    return `${API_CONTROLLERS.CUSTOMER}/${id}/addresses?profileType=${profiletype}`;
  },
  CUSTOMER_ADDRESSES_SEARCH(phoneNumber: string): string {
    return `${API_CONTROLLERS.CUSTOMER}/addresses/search?phoneNumber=${phoneNumber}`;
  },

  CUSTOMER_PAY_ON_ACCOUNT_VALIDATION(
    customerId: number,
    orderValue: number,
    pickupType: PickUpTypesValues,
    addressId?: number,
  ): string {
    let endpointUrl = `${API_CONTROLLERS.CUSTOMER}/${customerId}/pay-on-account/validate?orderValue=${orderValue}&pickupType=${pickupType}`;

    if (addressId) {
      endpointUrl += `&addressId=${addressId}`;
    }

    return endpointUrl;
  },
  CUSTOMER_BLACKLIST: `${API_CONTROLLERS.CUSTOMER}/blacklist`,
  CUSTOMER_ADD_CREDIT: `${API_CONTROLLERS.CUSTOMER}/add-credit`,
  CUSTOMER_CREDIT_DETAILS(customerId: string): string {
    return `${API_CONTROLLERS.CUSTOMER}/credits?customerId=${customerId}`;
  },
  GET_CUSTOMER_COMPANY_ADDRESSES(customerId: number): string {
    return `${API_CONTROLLERS.CUSTOMER}/${customerId}/company-addresses`;
  },
  EFT_PAYMENT(identifier: string, amount: number, orderId: string, paymentTarget: PaymentTarget): string {
    return `${API_CONTROLLERS.PAYMENT}/eft/${identifier}?amount=${amount}&orderId=${orderId}&paymentTarget=${paymentTarget}`;
  },
  EFT_PAYMENT_STATUS(identifier: string, orderId: string, paymentTarget: PaymentTarget): string {
    return `${API_CONTROLLERS.PAYMENT}/eft/${identifier}/status?orderId=${orderId}&paymentTarget=${paymentTarget}`;
  },
  EFT_PAYMENT_TERMINAL(checkCtmp: boolean): string {
    return `${API_CONTROLLERS.PAYMENT}/eft/terminal?checkCtmp=${checkCtmp}`;
  },
  EFT_PAYMENT_TERMINAL_CTMP: `${API_CONTROLLERS.PAYMENT}/eft/ctmp`,
  EFT_PAYMENT_TERMINAL_STATUS: `${API_CONTROLLERS.PAYMENT}/eft/terminal/status`,
  EFT_CANCEL_PAYMENT(orderId: string, paymentTarget: PaymentTarget, paymentId?: string) {
    return `${API_CONTROLLERS.PAYMENT}/eft/cancel?orderId=${orderId}&paymentTarget=${paymentTarget}&paymentId=${paymentId}`;
  },
  PAYMENT_REFUND(orderId: string) {
    return `${API_CONTROLLERS.PAYMENT_V2}/refund/${orderId}`;
  },
  PAYMENT_REFUND_STATUS(orderId: string) {
    return `${API_CONTROLLERS.PAYMENT_V2}/refund/${orderId}/status`;
  },
  CASH_DRAWER_OPEN: `${API_CONTROLLERS.PAYMENT}/cash-drawer/open`,
  CASH_DRAWER_TRANSACTION: `${API_CONTROLLERS.PAYMENT}/cash-drawer/transaction`,
  CASH_DRAWER_TRANSACTION_VALIDATE_ACCESS: `${API_CONTROLLERS.PAYMENT}/cash-drawer/transaction/validate-access`,
  RESTAURANT_FLOOR_PLAN: `${API_CONTROLLERS.RESTAURANT}/floor-plan`,
  RESTAURANT_FLOOR_PLAN_BACKGROUND_UPLOAD(floorId: string): string {
    return `${API_CONTROLLERS.RESTAURANT}/floor-plan/${floorId}/background`;
  },
  NOTIFICATIONS_ADD_TO_STORE(connectionId: string, storeId: number): string {
    return `${API_CONTROLLERS.NOTIFICATIONS}/store/${storeId}?connectionId=${connectionId}`;
  },
  INITIAL_SETUP_MACHINE_SESSION: `${API_CONTROLLERS.INITIAL_SETUP}/central/machine-session`,
  INITIAL_SETUP_SHOW_FIRST_TIME_LAUNCH: `${API_CONTROLLERS.INITIAL_SETUP}/in-store/should-run`,
  INITIAL_SETUP_INITIAL: `${API_CONTROLLERS.INITIAL_SETUP}/in-store/configuration`,
  INITIAL_SETUP_STORES: `${API_CONTROLLERS.INITIAL_SETUP}/stores`,
  INITIAL_SETUP_MACHINE_DATA: `${API_CONTROLLERS.INITIAL_SETUP}/machine-data`,
  MACHINE_PROFILES: `${API_CONTROLLERS.INITIAL_SETUP}/central/machine-profiles`,
  USER_GET_DATA: `${API_CONTROLLERS.USER}/current`,
  USER_REVALIDATE: `${API_CONTROLLERS.USER}/access/revalidate`,
  OSM_ORDER_DELIVERY_INFO: (id: string): string => `${API_CONTROLLERS.OSM}/${id}/delivery-info`,
  OSM_ORDER_INTERNAL_COMMENTS: (id: string): string => `${API_CONTROLLERS.OSM}/${id}/internal-comments`,
  OSM_HISTORY_ORDERS: `${API_CONTROLLERS.OSM}/orders/`,
  OSM_HISTORY_ORDERS_DOWNLOAD: `${API_CONTROLLERS.OSM}/orders/download`,
  OSM_HISTORY_ORDERS_TO_CONFIRM: `${API_CONTROLLERS.OSM}/orders/today-to-confirm`,
  OSM_ORDER_DETAILS(id: string, doFetch?: boolean): string {
    return `${API_CONTROLLERS.OSM}/orders/${id}?doFetch=${doFetch || false}`;
  },
  OSM_ORDER_CANCEL(id: string, reason: string, performedByUserId?: number, doFetch?: boolean): string {
    return `${API_CONTROLLERS.OSM}/${id}/cancel?performedBy=${performedByUserId}&reason=${reason}&doFetch=${
      doFetch || true
    }`;
  },
  OSM_VALIDATE_USER(): string {
    return `${API_CONTROLLERS.OSM}/validate-user`;
  },
  OSM_ORDER_FINALIZE_PICKUP(id: string): string {
    return `${API_CONTROLLERS.OSM}/orders/pickup/${id}/finalize`;
  },
  OSM_ORDER_FINALIZE_PICKUP_V2(id: string): string {
    return `${API_CONTROLLERS.OSM}/v2/orders/pickup/${id}/finalize`;
  },
  OSM_ORDER_FISCALIZE(id: string): string {
    return `${API_CONTROLLERS.OSM}/orders/${id}/fiscalize`;
  },
  OSM_ORDER_FINALIZE_PAID(id: string): string {
    return `${API_CONTROLLERS.OSM}/orders/pickup/${id}/finalize`;
  },
  OSM_ORDER_FILTERS: `${API_CONTROLLERS.OSM}/orders/filters`,
  OSM_ORDER_PRINT(id: string): string {
    return `${API_CONTROLLERS.OSM}/orders/${id}/print`;
  },
  OSM_ORDER_FISCALIZATION_STATUS: `${API_CONTROLLERS.OSM}/orders/fiscalization-status`,
  LOCALIZATION_PLACE_DETAILS(zipCode: string, houseNumber: string): string {
    return `${API_CONTROLLERS.LOCALIZATION}/details?zipCode=${zipCode}&houseNumber=${houseNumber}`;
  },
  LOCALIZATION_ADDRESS_SUGGESTIONS(partialAddress: string, languageCode: string): string {
    return `${API_CONTROLLERS.LOCALIZATION}/suggestions?PartialAddress=${partialAddress}&LanguageCode=${languageCode}`;
  },
  LOCALIZATION_SUGGESTION_DETAILS(placeId: string): string {
    return `${API_CONTROLLERS.LOCALIZATION}/suggestion-details?PlaceId=${placeId}`;
  },
  LOCALIZATION_DELIVERY_AREA_DETAILS: `${API_CONTROLLERS.LOCALIZATION}/delivery-area-stores`,
  EFT_PAYMENT_V2: `${API_CONTROLLERS.PAYMENT_V2}/eft`,
  EFT_PAYMENT_V2_RETRY(id: string): string {
    return `${API_CONTROLLERS.PAYMENT_V2}/eft/${id}/retry-start`;
  },
  EFT_PAYMENT_V2_ACCEPT(id: string): string {
    return `${API_CONTROLLERS.PAYMENT_V2}/eft/${id}/accept`;
  },
  EFT_PAYMENT_V2_CANCEL(id: string): string {
    return `${API_CONTROLLERS.PAYMENT_V2}/eft/${id}/cancel`;
  },
  EFT_PAYMENT_V2_ABANDON(id: string): string {
    return `${API_CONTROLLERS.PAYMENT_V2}/eft/${id}/abandon`;
  },
  EFT_PAYMENT_V2_MANUAL_CONFIRM(id: string): string {
    return `${API_CONTROLLERS.PAYMENT_V2}/eft/${id}/confirm-manually`;
  },
  EFT_PAYMENT_V2_CTMP: `${API_CONTROLLERS.PAYMENT_V2}/eft/ctmp`,
  EFT_PAYMENT_V2_TERMINAL_STATUS: `${API_CONTROLLERS.PAYMENT_V2}/eft/terminal/status`,
  PAYMENT_V2_ACCEPT_MANUAL_PAYMENT: `${API_CONTROLLERS.PAYMENT_V2}/manual/accept`,
  PAYMENT_V2_PAYMENT_STATUS: `${API_CONTROLLERS.PAYMENT_V2}/orders`,
  PAYMENT_V2_FINALIZE: `${API_CONTROLLERS.PAYMENT_V2}/orders/finish`,
  PAYMENT_V2_DEV_PAYMENT_CANCEL: `${API_CONTROLLERS.PAYMENT_V2}/dev/eft/responses/cancel-payment`,
  PAYMENT_V2_DEV_PAYMENT_START: `${API_CONTROLLERS.PAYMENT_V2}/dev/eft/responses/start-payment`,
  PAYMENT_V2_DEV_PAYMENT_STATUS: `${API_CONTROLLERS.PAYMENT_V2}/dev/eft/responses/payment-status`,
  PAYMENT_V2_DEV_PAYMENT_EVENT: `${API_CONTROLLERS.PAYMENT_V2}/dev/eft/events`,
  GIFT_CARDS_CHECK: `${API_CONTROLLERS.PAYMENT_V2}/gift-cards`,
  GIFT_CARD_ACTIVATE: (id: string) => `${API_CONTROLLERS.PAYMENT_V2}/gift-cards/${id}/activate`,
  GIFT_CARD_CHECK: (id: string) => `${API_CONTROLLERS.PAYMENT_V2}/gift-cards/${id}`,
  REWARDS: `${API_CONTROLLERS.LOYALTY}/rewards`,
  FDM_DEVICE_STATUS: `${API_CONTROLLERS.FISCALIZATION}/fdm/device-status`,
};

const REQUESTS_WITH_LOCALES: string[] = [
  posApiUrls.PRODUCTS_GET,
  posApiUrls.PRODUCTS_GROUPS_GET,
  posApiUrls.PRODUCTS_CATEGORIES_GET,
  posApiUrls.PRODUCTS_OPTIONS_GET,
  posApiUrls.PRODUCTS_SUBTYPES_GET,
  posApiUrls.TOPPINGS_GET,
  posApiUrls.TOPPINGS_CATEGORIES,
  posApiUrls.COUPONS_GET,
  posApiUrls.TOPPINGS_SAUCES,
  posApiUrls.TOPPINGS_CRUSTS,
  posApiUrls.BEST_SELLER_COUPONS_GET,
  posApiUrls.BEST_SELLER_PRODUCTS_GET,
  posApiUrls.BASKET_RECALCULATE,
];

export const requestRequiresLocale = (requestUrl: string) => {
  return REQUESTS_WITH_LOCALES.includes(requestUrl);
};

const userRelevantEndpointsRegexTerms = [API_CONTROLLERS.ACCOUNT, API_CONTROLLERS.USER]
  .map((controller) => escapeRegExp(`/${controller}/`.replace('//', '/')))
  .join('|');

const userRelevantEndpointsRegex = new RegExp(`^.*(${userRelevantEndpointsRegexTerms}).+`);

export const endpointIsCashierAuthRelevant = (requestUrl: string) => {
  return userRelevantEndpointsRegex.test(requestUrl);
};
