import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import dateFnsLng from 'utils/i18n/dateFnsLang';
import { differenceInDays, addDays } from 'date-fns';
import FiltersChipsOverview from 'containers/AllOrders/Overview/Filters/FiltersChipsOverview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandedTableFilters from 'containers/AllOrders/Overview/Filters/ExpandedTableFilters';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { resetTableOptions } from 'stores/AllOrders';
import { setAlert } from 'stores/Alerts';
import { OrderFilters } from 'containers/AllOrders/AllOrdersTypes';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAvailableSwitchStores, getSelectedStore } from 'stores/Store/store.selectors';
import buildClasses from './TableFilters.css';
import {
  MAX_SEARCH_DAYS_RANGE,
  availableFilters,
  defaultOrdersFormValues,
  submitAllOrdersForm,
} from '../../AllOrderConsts';

const TableFilters: React.FC = () => {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const [t] = useTranslation('orders');
  const selectedStore = useAppSelector(getSelectedStore);
  const availableSwitchStores = useAppSelector(getAvailableSwitchStores);
  const { availablePaymentMethods, availableChannels } = useAppSelector((state) => state.allOrders);
  const [showExpandedFilters, setShowExpandedFilters] = useState(false);
  const { control, reset, watch, formState, setValue } = useFormContext<OrderFilters>();

  const now = useMemo(() => new Date(), []);

  const dateTo = watch('dateTo', now);
  const dateFrom = watch('dateFrom', now);

  const appliedFilters = watch();
  const filterValues = Object.values(appliedFilters);
  filterValues.splice(1, 2);
  const anyFilterApplied = filterValues.some((el) => el.length > 0);

  const dateChanged = dateTo.getUTCDate() !== now.getUTCDate() || dateFrom.getUTCDate() !== now.getUTCDate();

  const filters = useMemo(
    () =>
      availableFilters.map((af) => {
        if (af.name === 'storeName')
          return {
            ...af,
            type: {
              ...af.type,
              options:
                availableSwitchStores && availableSwitchStores.length > 0
                  ? availableSwitchStores.map((store) => {
                      return { key: store.id, displayName: store.name };
                    })
                  : [{ key: selectedStore?.id ?? '', displayName: selectedStore?.name ?? 'Current store' }],
            },
          };
        if (af.name === 'paymentMethod')
          return {
            ...af,
            type: {
              ...af.type,
              options: availablePaymentMethods?.map((payment) => {
                return { key: payment.code, displayName: payment.code };
              }),
            },
          };
        if (af.name === 'channel')
          return {
            ...af,
            type: {
              ...af.type,
              options: availableChannels?.map((channel) => {
                return { key: channel.id, displayName: channel.name };
              }),
            },
          };
        return af;
      }),
    [availableSwitchStores, availablePaymentMethods, availableChannels, selectedStore],
  );

  const applyFilter = useCallback(() => {
    dispatch(resetTableOptions());
    setShowExpandedFilters(false);
    submitAllOrdersForm();
  }, [dispatch]);

  return (
    <Box className={classes.filtersWrapper}>
      <Box p={2} sx={{ display: 'flex' }} data-testid="all-orders-table__filters-wrapper">
        <Controller
          control={control}
          name="searchGeneric"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              placeholder={t('Order or ticket number, customer, employee...')}
              variant="outlined"
              InputProps={{
                inputProps: {
                  'data-testid': 'all-orders-table__filters-input-search-generic',
                  className: classes.searchInput,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          sx={{ marginLeft: 1, marginRight: 2 }}
          variant="contained"
          data-testid="all-orders-table__filters_button--search"
          disabled={!formState.isValid}
          onClick={() => {
            dispatch(resetTableOptions());
            submitAllOrdersForm();
          }}
        >
          {t('SEARCH')}
        </Button>
        <Controller
          name="dateFrom"
          control={control}
          defaultValue={now}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLng()}>
              <div data-testid="all-orders-table__filters-input-date-from">
                <DesktopDatePicker
                  {...field}
                  label={t('from')}
                  className={classes.datePickerWrapper}
                  maxDate={dateTo}
                  format="dd/MM/yyyy"
                  sx={{ color: 'red' }}
                  onChange={(event) => {
                    field.onChange(event);
                    if (event) {
                      const newDaysDiff = differenceInDays(dateTo, event);
                      if (newDaysDiff > MAX_SEARCH_DAYS_RANGE) {
                        const newDateTo = addDays(event, MAX_SEARCH_DAYS_RANGE);
                        setValue('dateTo', newDateTo);
                        dispatch(
                          setAlert({
                            header: 'Maximum search date-range exceeded',
                            message: 'Search dates were updated automatically to allowed maximum',
                            variant: 'warning',
                          }),
                        );
                      }
                    }
                    dispatch(resetTableOptions());
                    submitAllOrdersForm();
                  }}
                />
              </div>
            </LocalizationProvider>
          )}
        />
        <Controller
          name="dateTo"
          control={control}
          defaultValue={now}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLng()}>
              <div data-testid="all-orders-table__filters-input-date-to">
                <DesktopDatePicker
                  {...field}
                  label={t('to')}
                  className={classes.datePickerWrapper}
                  minDate={dateFrom}
                  format="dd/MM/yyyy"
                  onChange={(event) => {
                    field.onChange(event);
                    if (event) {
                      const newDaysDiff = differenceInDays(event, dateFrom);
                      if (newDaysDiff > MAX_SEARCH_DAYS_RANGE) {
                        const newDateFrom = addDays(event, -MAX_SEARCH_DAYS_RANGE);
                        setValue('dateFrom', newDateFrom);
                        dispatch(
                          setAlert({
                            header: 'Maximum search date-range exceeded',
                            message: 'Search dates were updated automatically to allowed maximum',
                            variant: 'warning',
                          }),
                        );
                      }
                    }
                    dispatch(resetTableOptions());
                    submitAllOrdersForm();
                  }}
                />
              </div>
            </LocalizationProvider>
          )}
        />
        <Box sx={{ display: 'flex', justifyContent: 'end', flexGrow: 1 }}>
          {(anyFilterApplied || dateChanged) && (
            <Button
              size="large"
              sx={{ marginLeft: 1, marginRight: 2 }}
              variant="text"
              startIcon={<ClearIcon />}
              color="black"
              data-testid="all-orders-table__filters_button--clear-filters"
              onClick={() => {
                dispatch(resetTableOptions());
                reset(defaultOrdersFormValues);
                submitAllOrdersForm();
              }}
            >
              {t('Clear filters')}
            </Button>
          )}
          <Button
            onClick={() => {
              setShowExpandedFilters(!showExpandedFilters);
            }}
            sx={{ marginLeft: 1, marginRight: 2 }}
            variant="text"
            endIcon={showExpandedFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            color="black"
            data-testid="all-orders-table__filters_button--expand-filters"
          >
            {t('Filters')}
          </Button>
        </Box>
      </Box>
      <ExpandedTableFilters filters={filters} show={showExpandedFilters} />
      {showExpandedFilters ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            sx={{ marginRight: 3, marginBottom: 3 }}
            variant="contained"
            data-testid="all-orders-table__filters_button--apply"
            disabled={!formState.isValid}
            onClick={applyFilter}
          >
            {t('Apply')}
          </Button>
        </Box>
      ) : (
        <FiltersChipsOverview availableFilters={filters} />
      )}
    </Box>
  );
};

export default TableFilters;
