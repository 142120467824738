export const getBaseUrl = () => {
  const location = window.location.href;
  const apiBaseLength = location.indexOf(process.env.PUBLIC_URL);
  const subLocation = `${location.substring(0, apiBaseLength)}/`;
  const absoluteSubLocation = subLocation.toLowerCase().trim().startsWith('http');
  if (absoluteSubLocation) {
    return subLocation;
  }
  return window.location.origin;
};
