import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ typography, spacing, palette }) => {
  return {
    storeChangeWarningMessage: {
      marginLeft: spacing(1),
      color: 'rgba(205, 113, 27, 1)',
      backgroundColor: 'rgba(255, 237, 216, 1)',
      fontSize: typography.body2.fontSize,
      padding: `0px ${spacing(1)}`,
      borderRadius: '4px',
      border: '0px',
      '&> div': {
        padding: `${spacing(0.5)} 0px`,
      },
    },
    changeStoreText: {
      color: palette.black.main,
      margin: spacing(3),
    },
  };
});

export default buildClasses;
