import React from 'react';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { refundPayment } from 'stores/Payments';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { OnlinePaymentRefundAttemptStatus } from 'typings/Payments';

interface FormsData {
  textField: string;
}

interface RefundOrderDialogProps {
  showDialog: boolean;
  orderId: string;
  abort: () => void;
  onConfirm: () => void;
}

const RefundOrderDialog: React.FC<RefundOrderDialogProps> = ({ showDialog, orderId, abort, onConfirm }) => {
  const dispatch = useAppDispatch();
  const { handleSubmit } = useForm<FormsData>({ mode: 'onSubmit', shouldUnregister: true });
  const [t] = useTranslation('orders');
  const { spacing } = useTheme();
  const paymentRefundAttemptStatus = useAppSelector(({ payments }) => payments.paymentRefundAttemptStatus);
  const paymentRefundMessage =
    paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.Failed
      ? t('Failed refund attempt')
      : undefined;
  const paymentRefundInProgressMessage = t('Refund in progress');
  const formId = `refund-form-order-${orderId}`;
  const actionInProgress = paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.InProgress;
  const primaryButtonLabel = actionInProgress
    ? t('Processing...')
    : paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.Failed
      ? t('Retry refund')
      : t('OK');
  function onFormSubmit(data: FormsData) {
    dispatch(
      refundPayment({
        orderId,
        successCallback: () => {
          onConfirm();
        },
      }),
    );
  }

  return (
    <ModalContainer
      onClose={abort}
      title={t('Refund')}
      open={showDialog}
      primaryButton={{
        label: primaryButtonLabel,
        testId: 'text-field-modal__button--ok',
        type: 'submit',
        form: formId,
        disabled: actionInProgress,
      }}
      secondaryButton={{
        action: abort,
        testId: 'text-field-modal__button--cancel',
        label: t('common:CANCEL'),
      }}
      testId="text-field-modal"
    >
      <form name={formId} id={formId} onSubmit={handleSubmit(onFormSubmit)}>
        {paymentRefundMessage && (
          <Box sx={{ display: 'flex', px: `${spacing(3)}`, mb: 2 }}>
            <Alert severity="error" style={{ width: '100%' }}>
              <Typography variant="body1">{paymentRefundMessage}</Typography>
            </Alert>
          </Box>
        )}

        {actionInProgress && (
          <Box sx={{ display: 'flex', px: `${spacing(3)}`, mb: 2 }}>
            <Alert severity="info" style={{ width: '100%' }}>
              <Typography variant="body1">{paymentRefundInProgressMessage}</Typography>
            </Alert>
          </Box>
        )}
      </form>
    </ModalContainer>
  );
};

export default RefundOrderDialog;
