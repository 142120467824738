import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import {
  BestSellerProduct,
  Category,
  Group,
  Option,
  Product,
  SubOption,
  Topping,
  ToppingCategory,
} from 'typings/Products';
import { QueryResult } from 'utils/types';
import { Coupon } from 'typings/Coupons';
import { posBaseQuery } from '../base.queries';
import {
  fillCategoryImageUrlInProducts,
  removeEmptyCategories,
  removeEmptyOptions,
  removeProductsWithoutOptions,
  sortProductsAlphabetically,
  sortProductsBySortOrder,
  sortProductWithCategories,
} from '../Intake/IntakeStoreUtils';

type ProductRequest = {
  sortProductsByName: boolean;
  storeId?: number;
};

type ProductData = {
  products: Product[];
  categories: Category[];
  groups: Group[];
  options: Option[];
  subOptions: SubOption[];
  toppings: Topping[];
  toppingsCategories: ToppingCategory[];
  bestSellerProducts: BestSellerProduct[];
  coupons: Coupon[];
};

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getProductData: builder.query<ProductData, ProductRequest>({
      queryFn: async ({ sortProductsByName, storeId }, api, extraOptions, baseQuery) => {
        // this is just a simple way to ensure that when we change stores we refresh our data without having to manually invalidate the cache
        if (!storeId) {
          throw new Error('storeId is required');
        }
        const results = await Promise.all([
          baseQuery(posApiUrls.PRODUCTS_GET) as QueryResult<ProductData['products']>,
          baseQuery(posApiUrls.PRODUCTS_CATEGORIES_GET) as QueryResult<ProductData['categories']>,
          baseQuery(posApiUrls.PRODUCTS_GROUPS_GET) as QueryResult<ProductData['groups']>,
          baseQuery(posApiUrls.PRODUCTS_OPTIONS_GET) as QueryResult<ProductData['options']>,
          baseQuery(posApiUrls.PRODUCTS_SUBTYPES_GET) as QueryResult<ProductData['subOptions']>,
          baseQuery(posApiUrls.TOPPINGS_GET) as QueryResult<ProductData['toppings']>,
          baseQuery(posApiUrls.TOPPINGS_CATEGORIES) as QueryResult<ProductData['toppingsCategories']>,
          baseQuery(posApiUrls.BEST_SELLER_PRODUCTS_GET) as QueryResult<ProductData['bestSellerProducts']>,
          baseQuery(posApiUrls.COUPONS_GET) as QueryResult<ProductData['coupons']>,
        ]);

        const errorResult = results.find((r) => r.error);
        if (errorResult) {
          return { error: errorResult.error as FetchBaseQueryError };
        }

        const [
          productsResult,
          categoriesResult,
          groups,
          options,
          subOptions,
          toppings,
          toppingsCategories,
          bestSellerProducts,
          coupons,
        ] = results;

        const products = sortProductsByName
          ? sortProductsAlphabetically(productsResult.data ?? [])
          : sortProductsBySortOrder(productsResult.data ?? []);
        const productsWithCategoryImage = fillCategoryImageUrlInProducts(products, categoriesResult.data ?? []);
        const productsWithOptions = removeProductsWithoutOptions(productsWithCategoryImage);
        const sortedCategories = sortProductWithCategories(productsWithOptions, categoriesResult.data ?? []);
        const categories = removeEmptyCategories(sortedCategories);

        return {
          data: {
            products,
            categories,
            groups: groups.data ?? [],
            options: removeEmptyOptions(options.data ?? [], products),
            subOptions: subOptions.data ?? [],
            toppings: toppings.data ?? [],
            toppingsCategories: toppingsCategories.data ?? [],
            bestSellerProducts: (bestSellerProducts.data ?? []).map((bstPrd) => ({
              ...bstPrd,
              product: products.find((prd) => prd.id === bstPrd.product.id) ?? bstPrd.product,
            })),
            coupons: coupons.data ?? [],
          },
        };
      },
      providesTags: ['ProductData'],
    }),
  }),
  tagTypes: ['ProductData'],
});
export const { useGetProductDataQuery } = productsApi;
