import React from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { TableBar } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AvailableIntakeContents } from 'containers/Intake/IntakeConsts';
import { setActiveIntakeTab } from 'stores/Intake';
import { setIsTableChangeInProgress } from 'stores/DineIn/dineIn.slice';
import { DineInOrder } from 'stores/DineIn/dineInTypes';

interface DineInReceiptHeaderProps {
  dineInOrder?: DineInOrder;
}

const DineInReceiptHeader: React.FC<DineInReceiptHeaderProps> = ({ dineInOrder }) => {
  const { selectedDineInOrder } = useAppSelector((state) => state.dineIn);
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();

  function getTableSectionContent(): JSX.Element {
    let tableIdentifier = '?';

    if (dineInOrder) {
      tableIdentifier = dineInOrder.tableIdentifier;
    }

    return (
      <Typography
        variant="body1"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '130px',
          color: palette.black.main,
        }}
      >
        {tableIdentifier}
      </Typography>
    );
  }

  function getTableTooltip(): string {
    if (dineInOrder) {
      return `${t('Table')}: ${dineInOrder.tableIdentifier}`;
    }

    return t('No table selected');
  }

  const tableSectionContent = getTableSectionContent();
  const tableButtonTooltip = getTableTooltip();

  function onTableEditClick(): void {
    dispatch(setActiveIntakeTab(AvailableIntakeContents.Tables));
    dispatch(setIsTableChangeInProgress(true));
  }

  function isTableChangePossible(): boolean {
    if (!selectedDineInOrder) return false;
    return selectedDineInOrder.items.length > 0;
  }

  return (
    <Box sx={{ display: 'inline-flex', textAlign: 'center', width: '100%' }}>
      <Tooltip
        title={
          <Box component="span" sx={{ fontSize: '14px' }}>
            {tableButtonTooltip}
          </Box>
        }
        placement="top"
      >
        <Box
          sx={{
            borderTop: `1px solid ${palette.secondary.dark}`,
            width: '50%',
            wordBreak: 'break-all',
            display: 'flex',
            padding: spacing(1),
          }}
          data-testid="receipt-dine-in__table-button"
        >
          <TableBar sx={{ color: 'black', justifyContent: 'start', marginRight: spacing(1) }} />
          {tableSectionContent}
        </Box>
      </Tooltip>
      <Box
        sx={{
          borderTop: `1px solid ${palette.secondary.dark}`,
          width: '50%',
          wordBreak: 'break-all',
          display: 'flex',
          justifyContent: 'end',
          padding: spacing(1),
        }}
        data-testid="receipt-dine-in__table-edit-button"
      >
        {isTableChangePossible() && (
          <IconButton sx={{ color: palette.black.main, padding: 0 }} onClick={onTableEditClick}>
            <EditIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default DineInReceiptHeader;
