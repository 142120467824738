import { createApi } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import { BestSellerCoupon, Coupon, MealCoupon } from 'typings/Coupons';
import { BasketItem } from 'typings/Basket';
import { posBaseQuery } from '../base.queries';

type BestSellerCouponsRequest = {
  storeId: number;
  basketItems: BasketItem[];
  activeDeliveryType: string;
  basketCoupons: Coupon[] | MealCoupon[];
};

type BestSellerCoupons = BestSellerCoupon[];

export const couponsApi = createApi({
  reducerPath: 'couponsApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getBestSellerCoupons: builder.query<BestSellerCoupons, BestSellerCouponsRequest>({
      query: (request) => ({
        url: posApiUrls.BEST_SELLER_COUPONS_GET,
        method: 'PUT',
        body: {
          storeId: request.storeId,
          basketItems: request.basketItems.map((item) => {
            return {
              id: item.id,
              productSelection: {
                productId: item.itemId,
                productOptionId: item.optionId,
              },
              quantity: item.quantity,
              sliceCustomizations: item.sliceCustomizations,
            };
          }),
          totalDiscounts: [],
          pickupType: request.activeDeliveryType,
          deliveryCharge: null,
          selectedCoupons: request.basketCoupons,
        },
      }),
      providesTags: ['BestSellerCoupons'],
    }),
  }),
  tagTypes: ['BestSellerCoupons'],
});
export const { useGetBestSellerCouponsQuery } = couponsApi;
