import React, { memo, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import { setCustomizeProduct } from 'stores/Products';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ProductsView from 'containers/Intake/Products/ProductsView/ProductsView';
import { getAreProductsLoaded, getProductToCustomize } from 'stores/Products/products.selector';
import ProductCustomization from './ProductCustomization/ProductCustomization';

interface ProductContainerProps {
  onHideReceipt?: () => void;
  onShowReceipt?: () => void;
}

const ProductsContainer: React.FC<ProductContainerProps> = memo(({ onHideReceipt, onShowReceipt }) => {
  const dispatch = useAppDispatch();
  const productToCustomize = useAppSelector(getProductToCustomize);
  const areProductsLoaded = useAppSelector(getAreProductsLoaded);

  useEffect(() => {
    if (productToCustomize) {
      if (onHideReceipt) onHideReceipt();
      if (onShowReceipt) onShowReceipt();
    }
  }, [onHideReceipt, onShowReceipt, productToCustomize]);

  const handleCancelClick = useCallback(() => {
    dispatch(setCustomizeProduct());
  }, [dispatch]);

  if (!areProductsLoaded) return <PartialLoader />;

  return (
    <Box sx={{ overflowY: 'auto', height: '100%' }}>
      {productToCustomize ? (
        <ProductCustomization productToCustomize={productToCustomize} onCancelClick={handleCancelClick} />
      ) : (
        <ProductsView />
      )}
    </Box>
  );
});

ProductsContainer.displayName = 'ProductsContainer';

export default ProductsContainer;
