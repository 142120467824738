import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { AppRoute } from 'App/Routes/routes-list';
import { getAvailableSwitchStores, getSwitchedStore } from 'stores/Store/store.selectors';
import { useSwitchStoreMutation } from 'stores/Store/store.api';

type SwitchedStoreGuardProps = {
  children: React.ReactNode;
  route: AppRoute;
};

const SwitchedStoreGuard: React.FC<SwitchedStoreGuardProps> = ({ children, route }) => {
  const { forbiddenOnSwitchedStore } = route;
  const availableSwitchStores = useAppSelector(getAvailableSwitchStores);
  const [switchStore] = useSwitchStoreMutation({
    fixedCacheKey: 'shared-store-switch-status',
  });

  const switchedStore = useAppSelector(getSwitchedStore);
  const defaultStore = useMemo(() => {
    return availableSwitchStores?.find((el) => el.isDefaultStore);
  }, [availableSwitchStores]);

  useEffect(() => {
    if (forbiddenOnSwitchedStore && defaultStore && defaultStore !== switchedStore) {
      switchStore(defaultStore.id?.toString());
    }
  }, [defaultStore, forbiddenOnSwitchedStore, switchStore, switchedStore]);

  return children as React.ReactElement;
};
export default SwitchedStoreGuard;
