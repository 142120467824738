import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { clockOutCashier } from 'stores/Cashier';
import { PinPad } from 'components/Auth/PinPad';

const CashFlowAuth: React.FC = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation('authentication');
  const [pin, setPin] = useState<string>('');
  const [pinErrorMessage, setPinErrorMessage] = useState<string | undefined>(undefined);
  const { employeeCashBalanceInfo } = useAppSelector(({ cashier }) => cashier);

  function clearPin(): void {
    setPin('');
    setPinErrorMessage(undefined);
  }
  function updatePin(pinValue: string): void {
    setPinErrorMessage(undefined);
    setPin(pinValue);
  }
  function validatePin(): boolean {
    const isPinMissing = !pin;
    setPinErrorMessage(isPinMissing ? t('PIN is required') : undefined);
    return !isPinMissing;
  }

  function clockOut(): void {
    if (validatePin()) {
      dispatch(clockOutCashier({ secret: pin, employeeId: employeeCashBalanceInfo?.employeeId }));
    }
  }

  return (
    <Box>
      <Typography color="black.main" variant="h3">
        {t('Confirm with your PIN code')}
      </Typography>
      <PinPad
        pinCode={pin}
        clearPin={clearPin}
        submit={clockOut}
        showExtraConfirm={false}
        onChange={updatePin}
        errorMessage={pinErrorMessage}
      />
      <Button sx={{ my: 2 }} variant="contained" fullWidth onClick={clockOut}>
        {t('Confirm & Continue')}
      </Button>
    </Box>
  );
};
export default CashFlowAuth;
