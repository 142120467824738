import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import { getSelectedStore } from '../Store/store.selectors';

const addClientToStoreGroup = createAsyncThunk<void, undefined, { state: RootState }>(
  '[NOTIFY]/addClientToStoreGroup',
  async (_arg, { getState }) => {
    const state = getState();
    const selectedStore = getSelectedStore(getState());
    const currentStoreId = selectedStore?.id;

    if (state.notifications.notificationsConnectionId && currentStoreId) {
      await posApi.put(
        posApiUrls.NOTIFICATIONS_ADD_TO_STORE(state.notifications.notificationsConnectionId, currentStoreId),
      );
    }
  },
);

export { addClientToStoreGroup };
