import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';

const selectSelf = (state: RootState) => state;

const selectProducts = createSelector(selectSelf, (state) => state.products);

export const getActiveOptionId = createSelector(selectProducts, (products) => products.activeOptionId);

export const getActiveSubOptionId = createSelector(selectProducts, (products) => products.activeSubOptionId);

export const getOptions = createSelector(selectProducts, (products) => products.options);

export const getSubOptions = createSelector(selectProducts, (products) => products.subOptions);

export const getProducts = createSelector(selectProducts, (products) => products.products);

export const getCategories = createSelector(selectProducts, (products) => products.categories);

export const getActiveCategoryCode = createSelector(selectProducts, (products) => products.activeCategoryCode);

export const getActiveCategory = createSelector(
  [getCategories, getActiveCategoryCode],
  (categories, activeCategoryCode) => categories.find((el) => el.code === activeCategoryCode),
);

export const getGroups = createSelector(selectProducts, (products) => products.groups);

export const getAreProductsLoaded = createSelector(selectProducts, (products) => products.productDataLoaded);

export const getProductToCustomize = createSelector(selectProducts, (products) => products.productToCustomize);

export const getBestSellerProducts = createSelector(selectProducts, (products) => products.bestSellerProducts);

export const getToppings = createSelector(selectProducts, (products) => products.toppings);

export const getToppingCategories = createSelector(selectProducts, (products) => products.toppingCategories);

export const getCoupons = createSelector(selectProducts, (products) => products.coupons);
