import React, { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import bestSeller from 'assets/images/bestSeller.png';
import { Category } from 'typings/Products';
import { MediaComponent } from 'stores/Cms/cms.interface';
import buildClasses from './ProductCategories.css';

interface CategoryButtonProps {
  category: Category;
  cmsImage: MediaComponent | undefined;
  isActive: boolean;
  onClick: (categoryCode: string) => void;
}

const CategoryButton: React.FC<CategoryButtonProps> = memo(({ category, cmsImage, isActive, onClick }) => {
  const { classes, cx } = buildClasses();

  return (
    <Box className={classes.categoryButtonContainer} key={`category_${category.code}`}>
      <Button
        color="primary"
        onClick={(): void => onClick(category.code)}
        className={cx(classes.categoryButton, { [classes.categoryButtonInactive]: !isActive })}
        variant="contained"
        data-testid={`product-categories__button--${category.name}`}
      >
        {category.code === 'BSL' ? (
          <img className={classes.categoryImg} src={cmsImage ? cmsImage.url : bestSeller} alt={category.code} />
        ) : category.imageUrl ? (
          <img className={classes.categoryImg} src={category.imageUrl} alt={category.code} />
        ) : (
          category.code
        )}
      </Button>
      <Typography
        variant="body2"
        className={cx(classes.categoryButtonLabel, {
          [classes.activeLabel]: isActive,
        })}
      >
        {category.name}
      </Typography>
    </Box>
  );
});

export default CategoryButton;
