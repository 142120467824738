import React, { useCallback } from 'react';
import { Alert, Box, Button, ButtonBase, TextField, Typography, useTheme } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { ReturnKeyboard } from 'assets/icons';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useTranslation } from 'react-i18next';
import buildClasses from './PinPad.css';
import { PinNumber } from './PinNumber';

interface PinPadProps {
  submit: () => void;
  clearPin: () => void;
  onChange: (value: string) => void;
  showExtraConfirm: boolean;
  errorMessage?: string;
  pinCode: string;
  inputType?: 'password' | 'text';
}

export const PinPad: React.FC<PinPadProps> = ({
  pinCode,
  onChange,
  showExtraConfirm,
  errorMessage,
  submit,
  clearPin,
  inputType = 'password',
}) => {
  const { classes, cx } = buildClasses();
  const [t] = useTranslation('authentication');
  const pinCodeLength = pinCode.length;
  const { spacing, palette, typography } = useTheme();

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  const onNumberClick = useCallback(
    (value: string) => {
      onChange(pinCode.concat(value));
    },
    [onChange, pinCode],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 50,
          margin: 1,
          width: '100%',
        }}
      >
        <input
          type={inputType}
          autoComplete="off"
          autoFocus
          value={pinCode}
          inputMode="none"
          className={classes.hiddenInput}
          onChange={(event) => {
            if (event.nativeEvent instanceof InputEvent) {
              if (event.nativeEvent.data && Number.isNaN(Number(event.nativeEvent.data))) {
                // not backspace (null) & not a number - don't update
                return;
              }
            }

            onChange(event.currentTarget.value);
          }}
          onKeyDown={onKeyDown}
          onBlur={({ target }) => target.focus()}
          data-testid="pincode_login__input-pin"
        />
        {errorMessage ? (
          <Typography variant="body1" gutterBottom data-testid="pinpad-alert" sx={{ width: '100%' }}>
            <Alert severity="error" icon={<ErrorOutlineOutlined fontSize="inherit" />}>
              {`${t(errorMessage)}`}
            </Alert>
          </Typography>
        ) : inputType === 'text' ? (
          <TextField
            value={pinCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.value);
            }}
            sx={{
              '& input': {
                color: palette.black.main,
                fontSize: typography.h6.fontSize,
                textAlign: `center`,
              },
              borderRadius: spacing(1),
              width: `50%`,
            }}
          />
        ) : (
          <>
            {pinCode.split('').map((_, index) => {
              if (index > 7) return null; // max 8 dots from design
              return (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  sx={{
                    mx: 2,
                    height: 16,
                    width: 15,
                    background: palette.primary.main,
                    borderRadius: 8,
                  }}
                />
              );
            })}
          </>
        )}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <PinNumber key={`numpad_${num}`} num={num} onClick={onNumberClick} />
        ))}
        {showExtraConfirm ? (
          <ButtonBase
            className={cx(classes.numpadButton, classes.numpadClearButton)}
            onClick={() => {
              pinCodeLength > 0 && onChange(pinCode.slice(0, pinCode.length - 1));
            }}
            data-testid="pincode_login__button--clear"
          >
            <BackspaceIcon color="disabled" />
          </ButtonBase>
        ) : (
          <Button
            className={cx(classes.numpadButton, classes.numpadClearButton)}
            onClick={clearPin}
            disabled={pinCodeLength === 0}
            sx={{
              ':disabled': {
                color: 'grey.200',
              },
            }}
            data-testid="pincode_login__button--clear"
            startIcon={<CloseIcon color={pinCodeLength === 0 ? 'disabled' : 'error'} />}
          >
            {t('CLEAR')}
          </Button>
        )}
        <PinNumber num={0} onClick={onNumberClick} />
        {showExtraConfirm && (
          <ButtonBase
            className={cx(classes.numpadButton, classes.numpadClearButton)}
            onClick={submit}
            data-testid="pincode_login__button--confirm"
          >
            <ReturnKeyboard />
          </ButtonBase>
        )}
      </Box>
    </>
  );
};
