import React, { memo } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, useTheme } from '@mui/material';
import { ConnectivityInfo } from 'components/Connectivity/ConnectivityInfo';
import { HybridModePanel } from 'App/HybridMode/HybridModePanel';
import { getEtaAdjustmentConfiguration, getInstanceType } from 'stores/Config/config.selector';
import buildClasses from './ApplicationBar.css';
import { EtaConfirmationNotifier } from './components/EtaConfirmationNotifier';
import { EtaInfo } from './components/EtaInfo';
import { CashierInfo } from './components/CashierInfo';
import { MenuActions } from './components/MenuActions';

type ApplicationBarProps = {
  onMenuOpenClick: () => void;
  isMenuOpen: boolean;
  showConnectivityInfo?: boolean;
};

export const ApplicationBar = memo(
  ({ onMenuOpenClick, isMenuOpen, showConnectivityInfo }: ApplicationBarProps): JSX.Element => {
    const { classes, cx } = buildClasses();
    const { palette, transitions, sizing } = useTheme();

    const etaAdjustmentConfiguration = useAppSelector(getEtaAdjustmentConfiguration);
    const instanceType = useAppSelector(getInstanceType);

    const { isHybridModeActive } = useAppSelector((store) => store.hybridMode);

    const showHybridModePanel = instanceType === 'InStore' && isHybridModeActive;

    return (
      <AppBar
        position="fixed"
        id="application_main_bar"
        sx={{
          background: palette.appBar ? palette.appBar.main : palette.primary.main,
          height: sizing.appBarHeight,
          transition: transitions.create(['margin', 'width'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
          }),
        }}
        className={cx(classes.appBar, {
          [classes.appBarShift]: isMenuOpen,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <MenuActions onMenuOpenClick={onMenuOpenClick} />
          {showConnectivityInfo && !showHybridModePanel && <ConnectivityInfo />}
          {showHybridModePanel && <HybridModePanel />}
          <Box
            sx={{
              display: 'flex',
              flexShrink: 0,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EtaConfirmationNotifier />
            {instanceType === 'Central' && etaAdjustmentConfiguration?.showEta && <EtaInfo />}
            <CashierInfo />
          </Box>
        </Toolbar>
      </AppBar>
    );
  },
);

ApplicationBar.displayName = 'ApplicationBar';
