import CmsHandler from 'App/CmsHandler/CmsHandler';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { getOnsiteMachine } from 'stores/Auth';
import { reconnectToNotifications } from 'stores/Notifications';
import { addClientToStoreGroup } from 'stores/Notifications/notifications.thunk-actions';
import { LocalStorageAuthConsts } from 'typings/Cashier';
import { saveLastRefreshTimestamp } from 'utils/applicationContainer/refreshAppUtils';
import { useGetConfigQuery } from 'stores/Config/config.api';
import { postPageRedirectStatusSelector } from 'stores/Auth/auth.selectors';
import { useLazyGetLoggedInCashierQuery } from 'stores/Cashier/cashier.api';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { useWindowResizeEvents } from './useWindowResizeEvents';
import { useHybridCommunication } from './useHybridCommunication';
import { useAppInsights } from './useAppInsights';
import { useLanguageChange } from './useLanguageChange';

interface AppInitializationWrapperProps {
  children: ReactNode;
  fallback: ReactElement;
}

const AppInitializationWrapper: React.FC<AppInitializationWrapperProps> = ({ children, fallback }) => {
  const postPageRedirectStatus = useAppSelector(postPageRedirectStatusSelector);
  const selectedStore = useAppSelector(getSelectedStore);
  const { notificationsConnectionId } = useAppSelector((state) => state.notifications);
  const { loggedCashier } = useAppSelector((state) => state.cashier);
  const dispatch = useAppDispatch();

  const { isSuccess: configLoaded, data: configData } = useGetConfigQuery(undefined, {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const { useNotifications, instanceType, cmsUiSettings } = (configLoaded && configData) || {};

  const [triggerGetLoggedInCashier] = useLazyGetLoggedInCashierQuery();

  // setup global listeners
  useAppInsights();
  useWindowResizeEvents();
  useHybridCommunication({ configLoaded });
  useLanguageChange();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('system') && searchParams.get('redirect-url')) {
      localStorage.setItem(LocalStorageAuthConsts.AuthQuery, window.location.search);
    }
  }, []);

  useEffect(() => {
    if (selectedStore?.id && configLoaded) {
      triggerGetLoggedInCashier();
    }
  }, [configLoaded, dispatch, selectedStore?.id, triggerGetLoggedInCashier]);

  useEffect(() => {
    saveLastRefreshTimestamp(new Date().getTime());
  }, [dispatch]);

  useEffect(() => {
    if (configLoaded && instanceType) {
      dispatch(getOnsiteMachine());
    }
  }, [postPageRedirectStatus, instanceType, dispatch, configLoaded]);

  useEffect(() => {
    if (configLoaded && useNotifications && selectedStore && loggedCashier) {
      dispatch(reconnectToNotifications());
    }
  }, [configLoaded, dispatch, useNotifications, loggedCashier, selectedStore]);

  useEffect(() => {
    if (notificationsConnectionId) {
      dispatch(addClientToStoreGroup());
    }
  }, [dispatch, notificationsConnectionId]);

  if (configLoaded)
    return (
      <>
        {children}
        {cmsUiSettings?.useCms && <CmsHandler />}
      </>
    );
  return fallback;
};

export default AppInitializationWrapper;
