import React from 'react';
import { Button, Grid2 as Grid, GridSize, Typography, useTheme } from '@mui/material';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { lowerCaseFirstLetter } from 'utils/orders/ordersUtils';
import { AddressControlType, AddressInputConfigurationElement, AddressSectionType } from 'stores/Config';
import { useAppSelector } from 'hooks/useRedux';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { isNotNullish } from 'utils/types';
import { FormTextField } from 'components/FormFields';
import CustomSelect from './CustomSelect/CustomSelect';
import FormPart from '../FormPart/FormPart';
import SuggestionBoxWithPopup from './SuggestionBoxWithPopup';

export interface DeliveryAddressErrors {
  guiLabel: FieldError;
  firstName: FieldError;
  lastName: FieldError;
  street: FieldError;
  streetNumber: FieldError;
  streetNumberAddition: FieldError;
  buildingName: FieldError;
  entranceNumber: FieldError;
  apartmentNumber: FieldError;
  streetType: FieldError;
  zipcode: FieldError;
  city: FieldError;
  district: FieldError;
  addressType: FieldError;
  addressRemark: FieldError;
  suggestionBox: FieldError;
  region: FieldError;
}

interface DeliveryAddressFormProps {
  usePartialAddressSuggestions: boolean | undefined;
  errors: DeliveryAddressErrors;
  inputConfig?: AddressInputConfigurationElement[];
  onInputMethodChanged?: () => void;
  onChangeFn: (addressSectionType: AddressSectionType) => void;
}

type GridSizeType = GridSize | 'auto';

function matchColXs(divCss: string): GridSizeType {
  if (!divCss) {
    return 'auto';
  }

  const substring = divCss.match(/col-xs-[0-9]{1,2}/);
  const result = substring ? substring[0].match(/[0-9]{1,2}/) : null;

  if (result && Number(result[0]) <= 12) {
    return Number(result[0]) as GridSizeType;
  }

  return 'auto';
}

function matchColMd(divCss: string): GridSizeType {
  if (!divCss) {
    return 'auto';
  }

  const substring = divCss.match(/col-md-[0-9]{1,2}/);
  const result = substring ? substring[0].match(/[0-9]{1,2}/) : null;

  if (result && Number(result[0]) <= 12) {
    return Number(result[0]) as GridSizeType;
  }

  return 'auto';
}

function mapInputsConfig(inputsConfig: AddressInputConfigurationElement[]): AddressInputConfigurationElement[][] {
  const resultArray = [];
  for (let i = 0; i < 14; i += 1) {
    const filerArray = inputsConfig.filter((element) => element.line === i);
    resultArray.push(filerArray);
  }

  return resultArray
    .filter((el) => el.length > 0)
    .map((line) =>
      line.sort(function sort(a, b) {
        return a.pos - b.pos;
      }),
    );
}

const DeliveryAddressForm: React.FC<DeliveryAddressFormProps> = ({
  usePartialAddressSuggestions,
  errors,
  inputConfig,
  onInputMethodChanged,
  onChangeFn,
}) => {
  const [t] = useTranslation('intake');
  const { palette, spacing } = useTheme();
  const { register } = useFormContext<CheckoutDetailsForm>();

  const { customerAddresses } = useAppSelector(({ customer }) => customer);

  const watchedValues = useWatch();

  const isAllRequiredFieldsFilled = inputConfig
    ?.filter((input) => !input.isOptional)
    .every((input) => {
      const value = watchedValues[lowerCaseFirstLetter(input.sectionType) as AddressSectionType];
      return value && value.trim().length > 0;
    });

  function generateAddressFields(mappedAddressInputs: AddressInputConfigurationElement[][]): JSX.Element[] {
    function inputTypeSwitch(
      inputData: AddressInputConfigurationElement,
      inputName: AddressSectionType,
    ): JSX.Element | undefined {
      const camelCaseInputName = lowerCaseFirstLetter(inputName) as AddressSectionType;

      if (camelCaseInputName === AddressSectionType.AddressRemark) {
        return (
          <FormTextField
            fullWidth
            label={inputData.defaultText}
            variant="outlined"
            defaultValue=""
            inputProps={{
              'data-testid': `finalize-address-form__input--${camelCaseInputName}`,
            }}
            style={{
              display: isAllRequiredFieldsFilled ? 'block' : 'none',
            }}
            name={camelCaseInputName}
            rules={{
              required: {
                value: !inputData.isOptional,
                message: t('Field required'),
              },
              pattern: {
                value: inputData.format ? RegExp(inputData.format) : /.*/,
                message: t('Incorrect value'),
              },
            }}
            onChange={() => {
              onChangeFn(camelCaseInputName);
            }}
          />
        );
      }

      switch (inputData.controlType) {
        case AddressControlType.ComboBox:
          return (
            <CustomSelect
              formRef={register}
              inputName={camelCaseInputName}
              label={inputData.defaultText}
              options={inputData.controlValues as { [key: string]: string }}
              testId={`finalize-address-form__select--${camelCaseInputName}`}
              isRequired={!inputData.isOptional}
              errorMessage={t('Field required')}
              onChangeFn={() => onChangeFn(camelCaseInputName)}
            />
          );
        case AddressControlType.TextBox:
          if (camelCaseInputName === AddressSectionType.SuggestionBox) {
            return (
              <SuggestionBoxWithPopup
                inputData={inputData}
                camelCaseInputName={camelCaseInputName}
                errors={errors}
                onChangeFn={onChangeFn}
              />
            );
          }

          return (
            <FormTextField
              fullWidth
              label={inputData.defaultText}
              variant="outlined"
              defaultValue=""
              inputProps={{
                'data-testid': `finalize-address-form__input--${camelCaseInputName}`,
              }}
              name={camelCaseInputName}
              rules={{
                required: {
                  value: !inputData.isOptional,
                  message: t('Field required'),
                },
                pattern: {
                  value: inputData.format ? RegExp(inputData.format) : /.*/,
                  message: t('Incorrect value'),
                },
              }}
              onChange={() => {
                onChangeFn(camelCaseInputName);
              }}
            />
          );
        default:
          return undefined;
      }
    }

    return mappedAddressInputs
      .map((line) => {
        return line
          .map((inputData) => {
            const inputName = inputData.sectionType;
            if (inputData.controlType.toString() === '3') return null; // skip GuiLabel on POS2
            if (
              lowerCaseFirstLetter(inputName) === AddressSectionType.SuggestionBox &&
              !usePartialAddressSuggestions
            )
              return null; // skip if address suggestion setting not turned on

            return (
              <Grid
                key={inputName}
                size={{
                  xs: matchColXs(inputData.divCssClass),
                  sm: matchColMd(inputData.divCssClass),
                }}
              >
                {inputTypeSwitch(inputData, inputName)}
              </Grid>
            );
          })
          .filter(isNotNullish);
      })
      .flat();
  }

  return (
    <FormPart>
      <Typography variant="h6" gutterBottom sx={{ color: palette.black.main, marginBottom: spacing(3) }}>
        {t('Delivery Address')}
      </Typography>
      <Grid container spacing={3}>
        {inputConfig?.length && generateAddressFields(mapInputsConfig(inputConfig))}
      </Grid>
      {onInputMethodChanged && (
        <Button
          data-testid="intake-finalize_button--open-address-book"
          sx={{ marginTop: spacing(1), color: palette.primary.dark }}
          onClick={onInputMethodChanged}
        >
          {`${t('Choose an address')} (${customerAddresses.length}) `}
        </Button>
      )}
    </FormPart>
  );
};

export default DeliveryAddressForm;
