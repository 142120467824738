import React, { Suspense, lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { getShowFirstTimeLaunch } from 'stores/Config/config.selector';
import { useAppSelector } from 'hooks/useRedux';
import MainContainer from 'App/MainContainer/MainContainer';
import LoginWrapper from 'containers/Authorize/LoginWrapper';
import AppConfigComponent from 'containers/Authorize/AppConfigComponent';
import { RoutePath } from './routes-paths';

const LoginFailed = lazy(() => import('components/Auth/LoginFailed'));
const PostLogin = lazy(() => import('components/Auth/PostLogin'));
const FirstTimeLaunch = lazy(() => import('containers/FirstTimeLaunch/FirstTimeLaunch'));
const CustomerDisplay = lazy(() => import('containers/CustomerDisplay/CustomerDisplay'));

const DefaultRedirect = () => {
  const showFirstTimeLaunch = useAppSelector(getShowFirstTimeLaunch);
  return showFirstTimeLaunch ? <Navigate to="./first-time-launch" /> : <Navigate to={RoutePath.AppConfig} />;
};

const RoutesComponent = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="/" element={<DefaultRedirect />} />
        <Route path="/login-redirect" element={<PostLogin />} />
        <Route path="/login-failed" element={<LoginFailed />} />
        <Route path={RoutePath.AppConfig} element={<AppConfigComponent />} />
        <Route path={RoutePath.CashierLogin} element={<LoginWrapper />} />
        <Route path="/first-time-launch" element={<FirstTimeLaunch />} />
        <Route path="/customer-display" element={<CustomerDisplay />} />
        <Route path="*" element={<MainContainer />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
