import React, { memo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { useTranslation } from 'react-i18next';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import { getConnectivityStatus } from 'stores/Config/config.selector';
import { useAppSelector } from 'hooks/useRedux';

export const ConnectivityInfo: React.FC = memo(() => {
  const [t] = useTranslation('common');
  const { spacing } = useTheme();

  const connectivityStatus = useAppSelector(getConnectivityStatus);

  return connectivityStatus !== ConnectivityStatusEnum.Connected ? (
    <Box
      data-testid="disconnected__container-info"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <CloudOffIcon sx={{ fontSize: spacing(3) }} />
      <div>
        <Typography component="div" variant="h6" sx={{ marginLeft: spacing(0.5) }}>
          <Box>{t('Disconnected')}</Box>
        </Typography>
      </div>
    </Box>
  ) : null;
});

ConnectivityInfo.displayName = 'ConnectivityInfo';
