/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getActivePayment, getFinalizationTimeDataSelector } from 'stores/OrderPayment/orderPayment.selector';
import { PaymentMethodCode } from 'typings/Payments';
import { AnimatePresence } from 'framer-motion';
import { getEftPaymentStatus, startEftPayment } from 'stores/EftPayment/eftPayment.actions';
import { getEFTPaymentStatus } from 'stores/EftPayment/eftPayment.selector';
import EftPending from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftPending';
import { clearEftPaymentData } from 'stores/EftPayment/eftPayment.slice';
import EftOperationButtons from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftOperationButtons';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import EftIdle from 'components/Shared/OrderPayment/PaymentMethods/EftCardPayment/EftIdle';
import PaymentSummary from 'components/Shared/OrderPayment/Shared/PaymentSummary';
import CashAmountEdition from 'components/Shared/OrderPayment/Shared/CashAmountEdition';
import TipEdition from 'components/Shared/OrderPayment/Shared/TipEdition';
import ChangeValue from 'components/Shared/OrderPayment/Shared/ChangeValue';
import { getPaymentFormatter, getStringToNumberFormatter } from 'stores/Payments/payment.selector';
import { clearActivePayment, setActivePaymentStep } from 'stores/OrderPayment/orderPayment.slice';
import ConfirmStepButtons from 'components/Shared/OrderPayment/Shared/ConfirmStepButtons';
import { useFormContext } from 'react-hook-form';
import { OrderPaymentForm } from 'components/Shared/OrderPayment/OrderPayment';
import uuidGenerator from 'utils/GuidGenerator';
import ProvideAmountStepButtons from 'components/Shared/OrderPayment/Shared/ProvideAmountStepButtons';
import AdjustTipButtons from 'components/Shared/OrderPayment/Shared/AdjustTipButtons';
import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';
import ManualCardNumber from 'components/Shared/GiftCardActivation/ManualCardNumber';
import { GiftCardActivationForm } from 'components/Shared/GiftCardActivation/GiftCardActivation';
import GiftCardErrorResult from 'components/Shared/GiftCardQrCodePayment/GiftCardErrorResult';
import GiftCardSuccessResult from 'components/Shared/GiftCardQrCodePayment/GiftCardSuccessResult';
import { getEftPaymentSettings } from 'stores/Config/config.selector';

const GiftCardQrPayment: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const eftPaymentSettings = useAppSelector(getEftPaymentSettings);
  const { orderId, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const { eftPaymentId } = useAppSelector(({ eftPayment }) => eftPayment);
  const pollingInterval = useRef<any>(null);
  const activePayment = useAppSelector(getActivePayment);
  const paymentStatus = useAppSelector(getEFTPaymentStatus);
  const appInsights = useAppInsightsContext();
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter);
  const { watch } = useFormContext<OrderPaymentForm>();
  const cashInputValue = watch('cashInput');
  const cashInputNumberValue = formatStringToNumber(cashInputValue);
  const isGiftCardQrPayment =
    activePayment?.paymentMethod?.code === PaymentMethodCode.GiftcardQr &&
    activePayment.paymentMethod.supportsEftPayment;
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const { getValues, reset } = useFormContext<GiftCardActivationForm>();
  const getFinalizationTimeData = useAppSelector(getFinalizationTimeDataSelector);

  function startPaymentStatusPolling(identifier: string): void {
    const pollingIntervalInSeconds = eftPaymentSettings?.statusPollingIntervalInSeconds ?? 1;
    if (!pollingInterval.current) {
      pollingInterval.current = setInterval(() => {
        dispatch(
          getEftPaymentStatus({
            paymentId: identifier,
          }),
        );
      }, pollingIntervalInSeconds * 1000);
      appInsights.trackEvent({
        name: 'Eft payment pulling started',
        properties: { identifier, orderId },
      });
    }
  }

  function stopPaymentStatusPolling(): void {
    clearInterval(pollingInterval?.current);
    if (pollingInterval) {
      pollingInterval.current = null;
      appInsights.trackEvent({
        name: 'Eft payment pulling stopped',
        properties: { orderId },
      });
    }
  }

  useEffect(() => {
    if (eftPaymentId === undefined) {
      stopPaymentStatusPolling();
    }
    if (eftPaymentId) {
      startPaymentStatusPolling(eftPaymentId);
    }
  }, [eftPaymentId]);

  useEffect(() => {
    return () => {
      stopPaymentStatusPolling();
      dispatch(clearEftPaymentData());
      reset();
    };
  }, []);

  if (!activePayment) return null;

  const activeCardStep = activePayment.paymentStep;

  return (
    <PaymentFrame>
      <>
        {activeCardStep === 'provideQrCode' && <ManualCardNumber />}
        {activeCardStep === 'provideAmount' && <CashAmountEdition />}
        {activeCardStep === 'confirm' && <ChangeValue />}
        {activeCardStep === 'provideTip' && <TipEdition />}

        {activeCardStep === 'finalizePayment' && (
          <>
            {paymentStatus === 'ERROR' && <GiftCardErrorResult />}
            {paymentStatus === 'IDLE' && <EftIdle />}
            {getFinalizationTimeData().skipFinalizationScreen ? (
              (paymentStatus === 'PENDING' || paymentStatus === 'SUCCESS') && <EftPending />
            ) : (
              <>
                {paymentStatus === 'SUCCESS' && <GiftCardSuccessResult />}
                {paymentStatus === 'PENDING' && <EftPending />}
              </>
            )}
          </>
        )}
      </>
      <>
        {activeCardStep === 'provideQrCode' ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '90%',
                width: '100%',
                position: 'absolute',
              }}
            >
              <Typography variant="h5" color="black.main">
                {t('Scan the QR code to pay:')}
              </Typography>
              <Typography
                variant="h2"
                color="black.main"
                sx={{ p: 2, background: palette.grey[50], borderRadius: spacing(1) }}
              >
                {formatToDisplay(cashInputNumberValue)}
              </Typography>
              <Typography variant="h6" color="black.main" sx={{ fontSize: '16px', fontWeight: '400' }}>
                {t('Or enter the gift card number manually.')}
              </Typography>
            </Box>
          </Box>
        ) : (
          <PaymentSummary />
        )}
        <AnimatePresence initial={false}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {activeCardStep === 'provideAmount' && <ProvideAmountStepButtons />}
            {activeCardStep === 'provideTip' && <AdjustTipButtons />}
            {(activeCardStep === 'confirm' || activeCardStep === 'provideQrCode') && (
              <ConfirmStepButtons
                onGoBack={() => {
                  dispatch(clearActivePayment());
                }}
                onNextStep={() => {
                  const toPayAmount =
                    cashInputNumberValue < totalLeftAmount ? cashInputNumberValue : totalLeftAmount;
                  const isPartialyPayment = cashInputNumberValue < totalLeftAmount;

                  if (isPartialyPayment && activeCardStep === 'confirm') {
                    dispatch(setActivePaymentStep('provideQrCode'));
                  } else {
                    dispatch(setActivePaymentStep('finalizePayment'));
                    dispatch(
                      startEftPayment({
                        orderAmount: toPayAmount,
                        paymentId: uuidGenerator(),
                        orderId: orderId as string,
                        tipAmount: activePayment.tipValue,
                        giftCardNumber: isGiftCardQrPayment ? getValues().cardNumberInput : undefined,
                      }),
                    );
                  }
                }}
              />
            )}
            {activeCardStep === 'finalizePayment' && <EftOperationButtons />}
          </Box>
        </AnimatePresence>
      </>
    </PaymentFrame>
  );
};
export default GiftCardQrPayment;
