import React from 'react';
import CardActionArea from '@mui/material/CardActionArea';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { Product } from 'typings/Products';
import buildClasses from './ProductSimplifiedCard.css';

type ProductSimplifiedCardProps = {
  product: Product;
  selected?: boolean;
  onProductSelected: (product: Product) => void;
};

const ProductSimplifiedCard: React.FC<ProductSimplifiedCardProps> = ({ product, onProductSelected, selected }) => {
  const { classes, cx } = buildClasses();
  const { spacing, sizing, breakpoints } = useTheme();
  return (
    <Box
      sx={{
        height: sizing.productCard.height,
        margin: spacing(0.5),
        position: 'relative',
        width: `calc(${100 / sizing.productCard.lineCount.md}% - ${spacing(1)})`,
        [breakpoints.down('md')]: {
          width: `calc(${100 / sizing.productCard.lineCount.xs}% - ${spacing(1)})`,
        },
        [breakpoints.up('xl')]: {
          width: `calc(${100 / sizing.productCard.lineCount.xl}% - ${spacing(1)})`,
        },
      }}
    >
      <Card
        className={cx(classes.productCardContent, {
          [classes.active]: selected === true,
        })}
      >
        <CardActionArea
          sx={{ padding: spacing(0.5), textAlign: 'center', height: '100%' }}
          onClick={(): void => onProductSelected(product)}
          data-testid={`product-card__button--${product.title}`}
        >
          <Typography variant="subtitle2">{product.name}</Typography>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default ProductSimplifiedCard;
