import React, { memo, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Option } from 'typings/Products';
import { ProductOption } from './ProductOption';

interface ProductOptionsProps {
  options: Option[];
  activeOptionId: number | null;
  activeCategoryCode: string;
  onOptionChange: (optionId: number) => void;
}

const ProductOptions: React.FC<ProductOptionsProps> = memo(
  ({ options, activeOptionId, activeCategoryCode, onOptionChange }) => {
    const { sizing, spacing, palette, radius } = useTheme();

    useEffect(() => {
      if (
        (!activeOptionId && options.length > 0) ||
        (options.find((el) => el.id === activeOptionId) === undefined && options.length > 0)
      ) {
        onOptionChange(options[0].id);
      }
    }, [options]);

    if (!options || options.length === 0) return null;

    return (
      <Box
        sx={{
          backgroundColor: `${palette.secondary.light}`,
          display: 'flex',
          flexDirection: 'row',
          width: sizing.full,
          borderRadius: radius.sm,
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          padding: spacing(1),
        }}
        data-testid="product-selection__container--options"
      >
        {options &&
          options.map((option) => (
            <ProductOption
              key={`option_${option.id}`}
              option={option}
              isActive={activeOptionId === option.id}
              activeCategoryCode={activeCategoryCode}
              onClick={onOptionChange}
            />
          ))}
      </Box>
    );
  },
);

ProductOptions.displayName = 'ProductOptions';

export default ProductOptions;
