import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Validators from 'utils/validation/ValidationConstants';
import InputUnit, { InputValidation, ValidationInput } from 'components/Shared/Inputs/InputUnit';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { formatToEdit, numberFromMoney } from 'utils/payment/PaymentUtils';
import { useAppSelector } from 'hooks/useRedux';
import { getMaxDeliveryChargeOverride, getPaymentConfig } from 'stores/Config/config.selector';
import buildClasses from './DeliveryCharge.css';

export interface DeliveryChargeProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (inputValue: string) => void;
  initialValue: number | undefined;
}

const DeliveryChargeModal: React.FC<DeliveryChargeProps> = ({ onClose, open, onSuccess, initialValue }) => {
  const { classes } = buildClasses();
  const initVal = initialValue !== undefined ? initialValue : 0;
  const [t] = useTranslation('intake');

  const paymentSettings = useAppSelector(getPaymentConfig);
  const maxDeliveryChargeOverride = useAppSelector(getMaxDeliveryChargeOverride);

  const handleClose = (): void => {
    if (initialValue === undefined) {
      onSuccess('0'); // after first modal opening set 0 to improve UX.
    }
    onClose();
  };

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<InputValidation>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const onSubmit = function onSubmit(data: InputValidation): void {
    onSuccess(data.inputValue);
    onClose();
  };

  const inputValidator: ValidationInput = {
    errors,
    formRef: register,
    required: true,
    validators: {
      isMoney: (val: string): boolean | string =>
        Validators.Money.Validator(paymentSettings).test(val) || Validators.Money.Message,
      isBelowLimit: (val: string): boolean | string =>
        Validators.IsBelowOrderTotal.Validator(
          numberFromMoney(val, paymentSettings),
          Number(maxDeliveryChargeOverride),
        ) || `Value must be below ${maxDeliveryChargeOverride}`,
    },
  };

  return (
    <ModalContainer
      onClose={handleClose}
      title={t('Delivery charge')}
      open={open}
      primaryButton={{
        type: 'submit',
        form: 'settlePaymentForm',
        testId: 'delivery-charge-modal__button--set-delivery-charge',
        label: t('Set delivery charge'),
      }}
      testId="delivery-charge-modal"
    >
      <form
        className={classes.dialogForm}
        autoComplete="off"
        name="settlePaymentForm"
        onSubmit={handleSubmit(onSubmit)}
        id="settlePaymentForm"
      >
        <div>
          <InputUnit
            autofocus
            inputValidation={inputValidator}
            defaultValue={formatToEdit(initVal, paymentSettings)}
            testId="delivery-charge-modal__input--delivery-charge"
            label={t('Delivery charge value')}
          />
        </div>
      </form>
    </ModalContainer>
  );
};

export default DeliveryChargeModal;
