import { RootState } from 'stores';
import { createSelector } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { configAPI, initialConfigState } from './config.api';
import { ConfigResponse, FeatureFlags } from './configTypes';

const selectConfigQuery = configAPI.endpoints.getConfig.select();

const selectSelf = (state: RootState) => state;

const selectLocalConfig = createSelector(selectSelf, (state) => state.config);

const getConfigState = createSelector(selectConfigQuery, (apiConfig) => apiConfig?.data);

const getAllConfig = createSelector([selectLocalConfig, getConfigState], (localConfig, apiConfig) => ({
  local: localConfig,
  api: apiConfig,
}));

// memoize the different feature flags separately to avoid unnecessary re-renders when the base config object changes
const selectApiFeatureFlags = createSelector(getConfigState, (apiConfig) => apiConfig?.featureFlags);
const selectLocalFeatureFlags = createSelector(selectLocalConfig, (localConfig) => localConfig.featureFlags);
export const getFeatureFlags = createSelector(
  [selectApiFeatureFlags, selectLocalFeatureFlags],
  (apiFeatureFlags, localFeatureFlags) => {
    if (!apiFeatureFlags) {
      throw new Error('Feature flags are not available');
    }
    return merge(apiFeatureFlags, localFeatureFlags) as FeatureFlags;
  },
);

export const getInstanceType = createSelector(
  getAllConfig,
  ({ api, local }) => local.instanceType ?? api?.instanceType,
);

export const getThemeKey = createSelector(
  getAllConfig,
  ({ api, local }) => local.themeKey ?? api?.themeKey ?? initialConfigState.themeKey,
);

export const getShowFirstTimeLaunch = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.showFirstTimeLaunch ?? initialConfigState.showFirstTimeLaunch,
);

export const getMachineActivityUpdate = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.machineActivityUpdate ?? initialConfigState.machineActivityUpdate,
);

export const getAppVersion = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.appVersion ?? initialConfigState.appVersion,
);

export const getFiscalizationConfiguration = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.fiscalizationConfiguration ?? initialConfigState.fiscalizationConfiguration,
);

export const getDefaultStoreOnSiteMachineType = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.defaultStoreOnSiteMachineType ?? initialConfigState.defaultStoreOnSiteMachineType,
);

export const getIdentityServer = createSelector(getConfigState, (apiConfig) => apiConfig?.identityServer);

export const getIsDesktop = createSelector(getConfigState, (apiConfig) => apiConfig?.instanceType !== 'Central');

export const getOsmKebabMenuVisibilitySettings = createSelector(
  getConfigState,
  (apiConfig) =>
    apiConfig?.osmKebabMenuVisibilitySettings ?? ({} as Partial<ConfigResponse['osmKebabMenuVisibilitySettings']>),
);

export const getUseDeliveryAddressCheck = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.useDeliveryAddressCheck,
);

export const getAddressInput = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.addressInput ?? { configurationElements: [] },
);

export const getUsePagination = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.usePagination ?? initialConfigState.usePagination,
);

export const getPaymentMethodsConfiguration = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.paymentMethodsConfiguration ?? {},
);

export const getPayOnAccountCode = createSelector(
  getPaymentMethodsConfiguration,
  (paymentMethodsConfiguration) => paymentMethodsConfiguration.payOnAccountMethodCode,
);

export const getShowTippingModule = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.payment?.v2?.enableTipping ?? initialConfigState.payment.v2.enableTipping,
);

export const getEftPaymentSettings = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.eftPaymentSettings ?? initialConfigState.eftPaymentSettings,
);

export const getIntakeSettings = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.intakeSettings ?? initialConfigState.intakeSettings,
);

export const getSortProductsByName = createSelector(
  getIntakeSettings,
  (intakeSettings) => intakeSettings.sortProductsByName,
);

export const getPizzaCategoryCode = createSelector(
  getConfigState,
  (apiConfig) =>
    apiConfig?.intakeSettings?.pizzaCategoryCode ?? initialConfigState.intakeSettings.pizzaCategoryCode,
);

export const getOtherGroupCode = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.intakeSettings?.otherGroupCode ?? initialConfigState.intakeSettings.otherGroupCode,
);

export const getToppingsCustomizationConfiguration = createSelector(
  getConfigState,
  (apiConfig) =>
    apiConfig?.toppingsCustomizationConfiguration ?? initialConfigState.toppingsCustomizationConfiguration,
);

export const getMaxNumberOfDuplicateToppings = createSelector(
  getConfigState,
  (apiConfig) =>
    apiConfig?.toppingsCustomizationConfiguration?.maximumNumberOfDuplicatesToppings ??
    initialConfigState.toppingsCustomizationConfiguration.maximumNumberOfDuplicatesToppings,
);

export const getPaymentConfig = createSelector(getConfigState, (apiConfig) => {
  if (!apiConfig?.payment) {
    throw new Error('Payment settings are not available');
  }
  return apiConfig.payment;
});

export const getPaymentCulture = createSelector(getPaymentConfig, (paymentConfig) => paymentConfig.culture);

export const getQuickPaymentValuesConfig = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.quickPaymentValues,
);

export const getMaxUserIdleTime = createSelector(
  getAllConfig,
  ({ api, local }) => local?.maxUserIdleTime ?? api?.maxUserIdleTime ?? initialConfigState.maxUserIdleTime,
);

export const getMaxPriceOverride = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.maxPriceOverride ?? initialConfigState.maxPriceOverride,
);

export const getMaxCustomerCreditAssignment = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.maxCustomerCreditAssignment ?? initialConfigState.maxCustomerCreditAssignment,
);

export const getMaxDeliveryChargeOverride = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.maxDeliveryChargeOverride ?? initialConfigState.maxDeliveryChargeOverride,
);

export const getZoomSettings = createSelector(getConfigState, (apiConfig) => apiConfig?.zoomSettings);

export const getEtaAdjustmentConfiguration = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.etaAdjustmentConfiguration,
);

export const getEtaConfirmationNotifierEnabled = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.etaConfirmationNotifierEnabled,
);

export const getUseZipCodeAddressSuggestions = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.useZipCodeAddressSuggestions,
);

export const getUsePartialAddressSuggestions = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.usePartialAddressSuggestions,
);

export const getEnableManualCashDrawerOperation = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.enableManualCashDrawerOperation,
);

export const getDefaultIanaTimeZone = createSelector(
  getConfigState,
  (apiConfig) => apiConfig?.defaultIanaTimeZone,
);

export const getUseNotifications = createSelector(getConfigState, (apiConfig) => apiConfig?.useNotifications);

export const getConnectivityStatus = createSelector(selectLocalConfig, (state) => state.connectivityStatus);

export const getLoggingCorrelationId = createSelector(selectLocalConfig, (state) => state.loggingCorrelationId);

export const getFiscalizationEnabled = createSelector(
  [getFeatureFlags, getFiscalizationConfiguration],
  (featureFlags, fiscalizationConfiguration) =>
    featureFlags.OfflineModule_Fiscalization &&
    fiscalizationConfiguration.isActive &&
    fiscalizationConfiguration.fiscalProvider === 'FDM',
);
