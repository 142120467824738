import React, { memo } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AddButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const AddButton: React.FC<AddButtonProps> = memo(
  ({ onClick, disabled }) => {
    const [t] = useTranslation('intake');

    return (
      <Button
        data-testid="product-customization__button--confirm-customization"
        onClick={onClick}
        variant="contained"
        disabled={disabled}
      >
        {t('Add')}
      </Button>
    );
  },
  (prevProps, nextProps) => {
    // Only rerender if disabled or onClick changes; Also prevents flickering.
    return prevProps.disabled === nextProps.disabled && prevProps.onClick === nextProps.onClick;
  },
);

AddButton.displayName = 'AddButton';
export default AddButton;
