import React from 'react';
import { useAppSelector } from 'hooks/useRedux';
import ExternalComponentContainer from 'components/Shared/Containers/ExternalComponentContainer';
import { bubbleIframeMouseMove } from 'utils/applicationContainer/iframeUtils';
import { getSelectedStore } from 'stores/Store/store.selectors';

const Dispatching = (): JSX.Element => {
  const selectedStore = useAppSelector(getSelectedStore);

  return (
    <ExternalComponentContainer>
      <iframe
        key={selectedStore?.id}
        title="dispatching"
        style={{ position: 'relative', height: '100%', width: '100%' }}
        id="micro-frontend-container"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        src="/Online/Dispatching"
        onLoad={(event) => {
          bubbleIframeMouseMove(event.currentTarget);
        }}
      />
    </ExternalComponentContainer>
  );
};

export default Dispatching;
