import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { DineInOrderStatus } from 'containers/Intake/IntakeConsts';

interface DineInOrdersFilterProps {
  selectedOrderStatus: DineInOrderStatus;
  onSelectedOrderStatusChanged: (value: DineInOrderStatus) => void;
}

const filterOptions = [DineInOrderStatus.Opened, DineInOrderStatus.Finished, DineInOrderStatus.Cancelled];

const DineInOrdersFilter: React.FC<DineInOrdersFilterProps> = ({
  selectedOrderStatus,
  onSelectedOrderStatusChanged,
}) => {
  const { palette, spacing, sizing } = useTheme();
  const [t] = useTranslation('intake');

  const handleOrderStatusChange = (event: SelectChangeEvent) => {
    onSelectedOrderStatusChanged(event.target.value as DineInOrderStatus);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body2" sx={{ color: palette.black.main, padding: spacing(2) }}>
        {t('Filter by')}
      </Typography>
      <Select value={selectedOrderStatus} onChange={handleOrderStatusChange} sx={{ width: sizing.selectWidth }}>
        {filterOptions.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {t(opt)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default DineInOrdersFilter;
