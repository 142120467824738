import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import UseCustomerCreditModal from 'containers/Intake/Customer/CustomerCredit/UseCustomerCreditModal';
import { clearCustomerCreditDetails, setUseCustomerCredits } from 'stores/Customer/customer.slice';
import InvalidBasketModal from 'containers/Intake/Receipt/InvalidBasketModal/InvalidBasketModal';
import { restartOrder } from 'stores/combined.actions';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import GeneralPopup from 'components/Shared/Modal/GeneralPopup';
import { setManualPriceOverride } from 'stores/Intake';
import { clearCouponError } from 'stores/Coupons/coupons.slice';
import { useTranslation } from 'react-i18next';
import { useKeyScanner } from 'App/KeyboardScanner/useKeyScanner';
import { getValidationResult } from 'stores/Basket/basket.selector';
import { IntakeForm } from './IntakeForm';

const IntakeWindow = memo(() => {
  useKeyScanner();

  const [t] = useTranslation('intake');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const validationResult = useAppSelector(getValidationResult);

  const { couponCustomizationError } = useAppSelector((state) => state.coupons);
  const { customerCreditDetails } = useAppSelector((state) => state.customer);

  const abortCustomerCreditModal = useCallback(() => {
    dispatch(setUseCustomerCredits(undefined));
    dispatch(clearCustomerCreditDetails());
  }, [dispatch]);

  const confirmCustomerCreditModal = useCallback(() => {
    dispatch(setManualPriceOverride(undefined));
    dispatch(setUseCustomerCredits(customerCreditDetails?.total));
    dispatch(clearCustomerCreditDetails());
    dispatch(recalculateBasket({}));
  }, [customerCreditDetails?.total, dispatch]);

  const showCustomerCreditModal = !!customerCreditDetails;

  const cancelInvalidBasketModal = useCallback(() => {
    dispatch(restartOrder());
    navigate('/all-orders');
  }, [dispatch, navigate]);

  const confirmInvalidBasketModal = useCallback(() => {
    dispatch(
      recalculateBasket({
        requestedBasket: validationResult?.newRequestedBasket ?? [],
        coupons: validationResult?.newRequestedCoupons ?? [],
      }),
    );
  }, [dispatch, validationResult?.newRequestedBasket, validationResult?.newRequestedCoupons]);

  const showInvalidBasketModal = !!validationResult;

  const clearCouponCustomizationError = useCallback(() => {
    dispatch(clearCouponError());
  }, [dispatch]);

  const showCouponCustomizationError = !!couponCustomizationError;

  return (
    <>
      <IntakeForm />
      {showCustomerCreditModal && (
        <UseCustomerCreditModal
          abort={abortCustomerCreditModal}
          onConfirm={confirmCustomerCreditModal}
          showDialog={showCustomerCreditModal}
          customerCreditDetails={customerCreditDetails}
        />
      )}
      {showInvalidBasketModal && (
        <InvalidBasketModal
          open={showInvalidBasketModal}
          invalidCoupons={validationResult?.invalidBasketCoupons}
          invalidProducts={validationResult?.invalidBasketItems}
          allProductsInvalid={
            (validationResult?.newRequestedBasket && validationResult.newRequestedBasket.length === 0) ?? false
          }
          onCancel={cancelInvalidBasketModal}
          onConfirm={confirmInvalidBasketModal}
        />
      )}
      {showCouponCustomizationError && (
        <GeneralPopup
          showDialog={showCouponCustomizationError}
          headerKey={t('Coupon cannot be added to basket')}
          text={t(couponCustomizationError ?? '')}
          onSubmit={clearCouponCustomizationError}
          onClose={clearCouponCustomizationError}
        />
      )}
    </>
  );
});

IntakeWindow.displayName = 'IntakeWindow';

export default IntakeWindow;
