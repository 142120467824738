import React, { useCallback, useRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Menu, MenuItem, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { CustomerBlock, ReceiptRepeat, ReceiptReturn } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { initOrderEdition } from 'stores/Intake';
import { useNavigate } from 'react-router';
import AssignCustomerCreditDialog from 'components/Orders/AssignCustomerCreditDialog/AssignCustomerCreditDialog';
import { OrdersModalType, printOrder } from 'stores/AllOrders';
import { checkOrderGiftCards } from 'stores/GiftCardActivation/giftCardActivation.actions';
import { getFeatureFlags, getOsmKebabMenuVisibilitySettings } from 'stores/Config/config.selector';
import { RoutePath } from 'App/Routes/routes-paths';

interface OrderDetailsMenuProps {
  onAddToBlacklistClick: () => void;
  onAddCommentClick: () => void;
  onDownloadReceiptClick: () => void;
  onEmailOrderClick: () => void;
}

export const OrderDetailsMenu: React.FC<OrderDetailsMenuProps> = ({
  onAddToBlacklistClick,
  onAddCommentClick,
  onDownloadReceiptClick,
  onEmailOrderClick,
}) => {
  const [t] = useTranslation('orders');
  const [open, setOpen] = useState(false);
  const [visibleModal, setVisibleModal] = useState<OrdersModalType | undefined>();
  const kebabMenu = useRef<SVGSVGElement>(null);
  const { palette, spacing } = useTheme();
  const { selectedOrderDetails } = useAppSelector((state) => state.allOrders);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const featureFlags = useAppSelector(getFeatureFlags);
  const osmKebabMenuVisibilitySettings = useAppSelector(getOsmKebabMenuVisibilitySettings);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAddComment = useCallback(() => {
    if (onAddCommentClick) onAddCommentClick();
    handleClose();
  }, [onAddCommentClick, handleClose]);

  const handleRepeatOrder = useCallback(() => {
    if (selectedOrderDetails) dispatch(initOrderEdition({ order: selectedOrderDetails, orderEditMode: 'repeat' }));
    navigate(RoutePath.Intake);
    handleClose();
  }, [dispatch, selectedOrderDetails, navigate, handleClose]);

  const handleAddToBlacklist = useCallback(() => {
    if (onAddToBlacklistClick) onAddToBlacklistClick();
    handleClose();
  }, [onAddToBlacklistClick, handleClose]);

  const handleAssignCredit = useCallback(() => {
    setVisibleModal('assign-credit');
    handleClose();
  }, [handleClose]);

  const handleDownloadReceipt = useCallback(() => {
    onDownloadReceiptClick();
    handleClose();
  }, [onDownloadReceiptClick, handleClose]);

  const handleOrderPrint = useCallback(() => {
    if (selectedOrderDetails?.id) dispatch(printOrder({ orderId: selectedOrderDetails.id }));
    handleClose();
  }, [dispatch, selectedOrderDetails?.id, handleClose]);

  const handleEmailOrder = useCallback(() => {
    if (onEmailOrderClick) onEmailOrderClick();
    handleClose();
  }, [onEmailOrderClick, handleClose]);

  const handleActivateGiftCard = useCallback(() => {
    selectedOrderDetails?.id &&
      dispatch(
        checkOrderGiftCards({
          orderId: selectedOrderDetails.id,
          isLocalOrder: selectedOrderDetails.isLocalOrder,
          details: {
            ticketNumber: selectedOrderDetails.ticketNumber,
            orderReference: selectedOrderDetails.orderReference,
            tableName: selectedOrderDetails.tableIdentifier,
          },
        }),
      );
  }, [
    dispatch,
    selectedOrderDetails?.id,
    selectedOrderDetails?.isLocalOrder,
    selectedOrderDetails?.ticketNumber,
    selectedOrderDetails?.orderReference,
    selectedOrderDetails?.tableIdentifier,
  ]);

  return (
    <>
      {featureFlags && !featureFlags.OfflineModule_HideOsmKebabMenu && (
        <Box
          ref={kebabMenu}
          onClick={() => setOpen(true)}
          sx={{
            height: spacing(5),
            width: spacing(5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MoreVertIcon sx={{ fill: palette.grey[800] }} />
        </Box>
      )}

      {kebabMenu.current && (
        <Menu
          anchorEl={kebabMenu.current}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            li: {
              p: 0.5,
            },
            button: {
              '&:hover': {
                background: 'none',
              },
            },
          }}
        >
          {osmKebabMenuVisibilitySettings?.printReceipt && (
            <MenuItem onClick={handleOrderPrint}>
              <Button
                color="black"
                startIcon={<ReceiptOutlinedIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--printReceipt"
              >
                {t('printReceipt')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.emailInvoice && (
            <MenuItem onClick={handleEmailOrder}>
              <Button
                color="black"
                startIcon={<MailOutlinedIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--mailOrder"
              >
                {t('mailOrder')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.faxOrder && (
            <MenuItem onClick={handleClose}>
              <Button
                color="black"
                startIcon={<FaxOutlinedIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--faxOrder"
              >
                {t('faxOrder')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.vatReturnInvoice && (
            <MenuItem onClick={handleClose}>
              <Button
                color="black"
                startIcon={<ReceiptReturn />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--returnVat"
              >
                {t('returnVat')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.downloadOrder && (
            <MenuItem onClick={handleDownloadReceipt}>
              <Button
                color="black"
                startIcon={<DownloadIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--downloadOrder"
              >
                {t('downloadOrder')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.repeatOrder && (
            <MenuItem onClick={handleRepeatOrder}>
              <Button
                color="black"
                startIcon={<ReceiptRepeat />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--repeatOrder"
              >
                {t('repeatOrder')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.addCustomerToBlackList && (
            <MenuItem onClick={handleAddToBlacklist}>
              <Button
                color="black"
                startIcon={<CustomerBlock />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--blockOrder"
              >
                {t('blockOrder')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.addCustomerCredit && (
            <MenuItem onClick={handleAssignCredit}>
              <Button
                color="black"
                startIcon={<StarOutlineIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--assignCredit"
              >
                {t('assignCredit')}
              </Button>
            </MenuItem>
          )}
          {osmKebabMenuVisibilitySettings?.addComment && (
            <MenuItem onClick={handleAddComment}>
              <Button
                color="black"
                startIcon={<AddCommentOutlinedIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--addComment"
              >
                {t('addComment')}
              </Button>
            </MenuItem>
          )}
          {selectedOrderDetails?.hasAnyGiftCardsToActivate === true && (
            <MenuItem onClick={handleActivateGiftCard}>
              <Button
                color="black"
                startIcon={<CardGiftcardIcon />}
                disableRipple
                data-testid="all-orders__order_details_kebabMenu--activateGiftCards"
              >
                {t('activateGiftCard')}
              </Button>
            </MenuItem>
          )}
        </Menu>
      )}
      <AssignCustomerCreditDialog
        showDialog={visibleModal === 'assign-credit'}
        orderId={selectedOrderDetails?.id as string}
        abort={() => setVisibleModal(undefined)}
        onConfirm={() => setVisibleModal(undefined)}
      />
    </>
  );
};
