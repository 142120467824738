import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, palette }) => {
  return {
    productsGroupContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      borderBottom: `1px solid ${palette.secondary.dark}`,
      marginBottom: '20px',
    },
    productsGroup: {
      margin: `${spacing(1)} 0 ${spacing(1)} 0`,
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      position: 'relative',
      gap: spacing(1),
    },
    groupLabel: {
      margin: '0',
    },
    swiper: {
      display: 'flex !important',
      flexDirection: 'column',
      flex: '1',
      height: '100%',
      width: '100%',
    },
    swiperSlide: {
      height: 'fit-content !important',
      display: 'flex !important',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
    swiperWrapper: {
      flex: 1,
      position: 'relative',
      width: ' 100%',
      zIndex: 1,
      display: 'flex !important',
      transitionProperty: 'transform',
      transitionTimingFunction: 'var(--swiper-wrapper-transition-timing-function,initial)',
      boxSizing: 'content-box',
    },
    scrollableWrapper: {
      height: 'fit-content',
      display: 'flex !important',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
    chipWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'auto',
      gap: spacing(1),
    },
  };
});

export default buildClasses;
