import { useUpdateMachineLastActivityQuery } from 'stores/Auth/auth.api';
import { useAppSelector } from 'hooks/useRedux';
import { getInstanceType, getMachineActivityUpdate } from 'stores/Config/config.selector';
import { getLoggedCashier } from 'stores/Cashier/cashier.selector';

export const useHeartbeat = () => {
  const loggedCashier = useAppSelector(getLoggedCashier);
  const instanceType = useAppSelector(getInstanceType);
  const machineActivityUpdate = useAppSelector(getMachineActivityUpdate);

  const interval = machineActivityUpdate?.updateIntervalInSecods ?? 0;
  const heartbeatTimerEnabled =
    !!machineActivityUpdate?.enabled && !!interval && !!loggedCashier && instanceType === 'Central';

  useUpdateMachineLastActivityQuery(undefined, {
    pollingInterval: interval * 1000,
    refetchOnReconnect: true,
    skip: !heartbeatTimerEnabled,
  });
};
