import React from 'react';
import { Box } from '@mui/material';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import { useGetRewardsQuery } from 'stores/Loyalty/loyalty.api';
import { useAppSelector } from 'hooks/useRedux';
import { getActiveDeliveryType, getSelectedOrderCustomerId } from 'stores/Intake/intake.selector';
import { getSelectedStoreId } from 'stores/Store/store.selectors';
import { getFeatureFlags } from 'stores/Config/config.selector';
import RewardsView from './RewardsView';

const RewardsContainer: React.FC = () => {
  const activeDeliveryType = useAppSelector(getActiveDeliveryType);
  const featureFlags = useAppSelector(getFeatureFlags);
  const storeId = useAppSelector(getSelectedStoreId) ?? 0;
  const customerId = useAppSelector(getSelectedOrderCustomerId) ?? 0;

  const { isSuccess, data, isFetching } = useGetRewardsQuery(
    {
      pickupType: activeDeliveryType,
      storeId,
      customerId,
    },
    { skip: !featureFlags.OfflineModule_ShowRewardsOnPos },
  );

  const pending = isFetching || !isSuccess;

  return (
    <Box sx={{ overflowY: 'auto', height: '100%' }}>
      {pending ? <PartialLoader /> : <RewardsView rewardTabs={data ?? []} />}
    </Box>
  );
};

export default RewardsContainer;
