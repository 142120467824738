import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useAppSelector } from 'hooks/useRedux';

interface OutsideStoreDeliveryAreaDialogProps {
  open: boolean;
  abort: () => void;
  onConfirm: () => void;
}

const OutsideStoreDeliveryAreaDialog: React.FC<OutsideStoreDeliveryAreaDialogProps> = ({
  open,
  abort,
  onConfirm,
}) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');
  const { deliveryAreaDetails } = useAppSelector((state) => state.intake);

  const addressOutsideStoreDeliveryAreaText = ((): string => {
    const suffix = t('Would you like to proceed with the order anyway?');
    if (deliveryAreaDetails && deliveryAreaDetails.storesDeliveringForRequiredAddress?.length > 0) {
      return `${t(
        'The address falls outside your delivery area, but belongs to store(s)',
      )} : ${deliveryAreaDetails.storesDeliveringForRequiredAddress.join(', ')}. ${suffix}`;
    }
    return `${t('The address falls outside your delivery area.')} ${suffix}`;
  })();

  return (
    <ModalContainer
      maxWidth="sm"
      onClose={abort}
      title={t('Address Outside Delivery Zone')}
      open={open}
      primaryButton={{
        action: onConfirm,
        label: t('common:Yes'),
        testId: 'text-field-modal__button--ok',
      }}
      secondaryButton={{
        action: abort,
        testId: 'text-field-modal__button--cancel',
        label: t('common:No'),
      }}
      testId="text-field-modal"
    >
      <Box sx={{ display: 'flex', px: `${spacing(3)}`, mb: 2 }}>
        <Typography variant="body1" gutterBottom data-testid="outside-store-delivery-area--alert">
          {addressOutsideStoreDeliveryAreaText}
        </Typography>
      </Box>
    </ModalContainer>
  );
};

export default OutsideStoreDeliveryAreaDialog;
