import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getLoggedCashier } from 'stores/Cashier/cashier.selector';
import { getInstanceType } from 'stores/Config/config.selector';
import { useEndBreakMutation, useGetClockedInUsersQuery } from 'stores/Cashier/cashier.api';
import { startBreak } from 'stores/Cashier';
import { getIsCloudPosAvailable } from 'stores/HybridMode/hybridMode.selector';
import CashierBasicInfo from './components/CashierBasicInfo';
import CashierBreakDialog from './components/CashierBreakDialog';

export const CashierInfo = memo(() => {
  const [endBreak] = useEndBreakMutation();

  const {
    isSuccess,
    data,
    isFetching: isFetchingClockedInUsers,
  } = useGetClockedInUsersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const dispatch = useAppDispatch();
  const loggedCashier = useAppSelector(getLoggedCashier);
  const instanceType = useAppSelector(getInstanceType);
  const isCloudPosAvailable = useAppSelector(getIsCloudPosAvailable);

  const [isClockedIn, setIsClockedIn] = useState<boolean>(false);
  const [isOnBreak, setIsOnBreak] = useState<boolean>(false);
  const [isShiftBreakDialogOpened, setIsShiftBreakDialogOpened] = useState<boolean>(false);

  const openShiftBreakDialog = useCallback(() => setIsShiftBreakDialogOpened(true), []);
  const closeShiftBreakDialog = useCallback(() => setIsShiftBreakDialogOpened(false), []);

  const showSwitchButton = instanceType === 'InStore' && (isCloudPosAvailable ?? false);
  const clockedInEmployees = useMemo(() => (isSuccess ? data : []), [data, isSuccess]);

  const onStartBreakClick = useCallback(() => {
    setIsShiftBreakDialogOpened(false);
    dispatch(startBreak());
  }, [dispatch]);

  const onEndBreakClick = useCallback(() => {
    setIsShiftBreakDialogOpened(false);
    endBreak();
  }, [endBreak]);

  const onConfirmClick = useCallback(() => {
    isOnBreak ? onEndBreakClick() : onStartBreakClick();
  }, [isOnBreak, onEndBreakClick, onStartBreakClick]);

  useEffect(() => {
    if (isFetchingClockedInUsers) {
      return;
    }
    const clockedInEmployee = clockedInEmployees.find((employee) => employee.userId === loggedCashier?.id);

    setIsClockedIn(clockedInEmployee !== undefined);

    const isEmployeeOnBreak = clockedInEmployee?.breakDurationInMinutes !== undefined;
    setIsOnBreak(isEmployeeOnBreak);

    setIsShiftBreakDialogOpened(isEmployeeOnBreak);
  }, [clockedInEmployees, isFetchingClockedInUsers, loggedCashier]);

  return loggedCashier ? (
    <>
      <CashierBasicInfo
        name={`${loggedCashier.firstName} ${loggedCashier.lastName}`}
        role={loggedCashier.roleName}
        showSwitchToOnlineButton={showSwitchButton}
        isClockedIn={isClockedIn}
        isOnBreak={isOnBreak}
        onShiftBreakChangeClick={openShiftBreakDialog}
      />
      {isShiftBreakDialogOpened && (
        <CashierBreakDialog
          isOpened={isShiftBreakDialogOpened}
          isOnBreak={isOnBreak}
          onCancelClick={closeShiftBreakDialog}
          onConfirmClick={onConfirmClick}
        />
      )}
    </>
  ) : null;
});

CashierInfo.displayName = 'CashierInfo';
