import { OrderFilters } from 'containers/AllOrders/AllOrdersTypes';
import { AddressBookEntry, OrderDeliveryAddress, ProfileAddress } from 'stores/Intake';
import { ActiveDiscount, BasketItem, CouponInformation, DiscountType } from 'typings/Basket';
import { Category, Option, Product, SliceCustomization } from 'typings/Products';
import {
  GetOrdersListRequest,
  OrdersTableOptions,
  OSMDeliveryAddress,
  OSMDiscount,
  OSMOrderDayHistory,
  OSMOrderHistory,
  OSMOrderItem,
} from 'stores/AllOrders/allOrders.types';
import { format } from 'date-fns';
import { CouponValidity } from 'containers/Intake/IntakeConsts';
import { Coupon, Meal, MealCoupon, MealSettings } from 'typings/Coupons';
import uuidGenerator from 'utils/GuidGenerator';
import posApi, { posApiUrls } from 'API/PosApi';
import { mapToManyMeals } from 'stores/Intake/IntakeStoreUtils';
import { generateToppingConfiguration } from 'utils/intake/productCustomizationUtils';
import { formatDeliveryOrCollectionTime } from '../localisation/dateTimeUtils';

export function mapOSMDeliveryAddressToSelectedAddress(
  osmAddress: OSMDeliveryAddress,
  fullAddress: string,
): AddressBookEntry {
  return {
    id: 0,
    fullAddress,
    isSelected: true,
    ...osmAddress,
  } as AddressBookEntry;
}

export function mapOSMDeliveryAddress(osmAddress: OSMDeliveryAddress): ProfileAddress {
  return {
    city: osmAddress.locality,
    zipcode: osmAddress.postalCode,
    street: osmAddress.route,
    streetNumber: osmAddress.streetNumber,
    district: osmAddress.neighborhood,
    addressRemark: osmAddress.remarks,
    region: osmAddress.country,
    ...osmAddress,
  } as ProfileAddress;
}

export function mapToOSMDeliveryAddress(orderAddress: OrderDeliveryAddress): OSMDeliveryAddress {
  return {
    route: orderAddress.street,
    locality: orderAddress.city,
    postalCode: orderAddress.zipcode,
    neighborhood: orderAddress.district,
    remarks: orderAddress.addressRemark,
    country: orderAddress.region,
    ...orderAddress,
  } as OSMDeliveryAddress;
}

export function mapOSMOrderItem(
  osmItem: OSMOrderItem,
  allProducts: Product[],
  allOptions: Option[],
): BasketItem | undefined {
  const baseProduct = allProducts.find((el) => el.id === osmItem.productSelection.productId);
  const baseOption = allOptions.find((el) => el.id === osmItem.productSelection.productOptionId);

  return {
    id: osmItem.id,
    basketItemGuid: uuidGenerator(),
    itemId: osmItem.productSelection.productId,
    itemName: baseProduct?.name ?? osmItem.description,
    optionId: osmItem.productSelection.productOptionId,
    optionName: baseOption?.name ?? osmItem.type,
    quantity: osmItem.quantity,
    receiptId: osmItem.id,
    sliceCustomizations: osmItem.sliceCustomizations.map((sc) => {
      const sliceBaseProduct = allProducts.find((el) => el.id === sc.sliceProductId);
      return {
        ...sc,
        toppingsConfiguration: generateToppingConfiguration(
          sliceBaseProduct,
          sc.addedToppings,
          sc.removedToppings,
        ),
        sliceProductName: sliceBaseProduct?.name ?? sc.sliceProductName,
      } as SliceCustomization;
    }),
    remark: osmItem.remark,
    doNotGroup: true,
    isAutomaticItem: osmItem.isBoundProduct,
  } as BasketItem;
}

export async function mapOSMDiscountToBasketCoupon(
  osmDiscount: OSMDiscount,
  allBasketItems: BasketItem[],
  allCoupons: Coupon[],
  allOptions: Option[],
  allProducts: Product[],
  allCategories: Category[],
): Promise<Coupon | MealCoupon | undefined> {
  const baseCoupon = allCoupons.find((el) => el.couponCode === osmDiscount.code);

  const linkedBaskedItemIds = osmDiscount.isSetDiscount
    ? osmDiscount.matchingItems.map((mi) => mi.orderItemId)
    : [];

  let meals: Meal[] = [];

  if (linkedBaskedItemIds?.length > 0) {
    const mealSettings = await (
      await posApi.get<MealSettings[]>(posApiUrls.DISCOUNT_MEAL_SETTINGS(osmDiscount.code))
    ).data;
    meals = mapToManyMeals(mealSettings, allCategories, allProducts, allOptions).map((meal, index) => {
      const selectedProduct = allBasketItems.find((el) => el.id === linkedBaskedItemIds[index]);
      return {
        ...meal,
        selectedProduct: selectedProduct
          ? { ...selectedProduct, baseProduct: allProducts.find((el) => el.id === selectedProduct.itemId) }
          : undefined,
      } as Meal;
    });
  }

  return {
    ...baseCoupon,
    description: baseCoupon?.description ?? osmDiscount.description,
    singleUseCouponCode: osmDiscount.singleUseCouponCode,
    useMealConfigurator: osmDiscount.isSetDiscount,
    couponId: osmDiscount.couponId,
    linkedBaskedItemIds,
    title: baseCoupon?.description ?? osmDiscount.description,
    complete: true,
    meals,
  } as MealCoupon;
}

export function mapOSMDiscountToActiveDiscount(osmDiscount: OSMDiscount): ActiveDiscount {
  // below should be extended on backend, but covers needs for now
  const isSet = osmDiscount.matchingItems.length > 0 && osmDiscount.type !== DiscountType.Percentage;

  return {
    description: osmDiscount.description,
    isAutomatic: false, // to get from backend
    isSetDiscount: isSet, // to get from backend
    matchingReceiptItemIds: osmDiscount.matchingItems.map((mi) => mi.orderItemId),
    type: osmDiscount.type,
    couponId: osmDiscount.couponId,
    value: osmDiscount.value,
  } as ActiveDiscount;
}

export function mapOSMDiscountToCouponInformation(osmDiscount: OSMDiscount): CouponInformation {
  return {
    couponId: osmDiscount.couponId,
    status: CouponValidity.Active,
  } as CouponInformation;
}

export function lowerCaseFirstLetter(sentence: string): string {
  return `${sentence?.charAt(0).toLowerCase()}${sentence?.slice(1)}`;
}

export function formatTimeToOSMDto(time?: string, asapTranslation?: string): string | undefined {
  const isAsap =
    !time ||
    time.toUpperCase() === 'ASAP' ||
    (asapTranslation && time.toUpperCase() === asapTranslation?.toUpperCase());

  return formatDeliveryOrCollectionTime(!!isAsap, time || '');
}

export function mapFiltersToOrdersRequest(
  options: OrdersTableOptions,
  filters?: OrderFilters,
): GetOrdersListRequest {
  return {
    Search: filters?.searchGeneric,
    DateFrom: filters?.dateFrom && format(filters.dateFrom, 'yyyy-MM-dd'),
    DateTo: filters?.dateTo && format(filters.dateTo, 'yyyy-MM-dd'),
    PaymentMethodCode: filters?.paymentMethod,
    PickupType: filters?.orderType,
    StoreIds: filters?.storeName,
    TicketNumber: filters?.ticketNumber,
    ChannelId: filters?.channel,
    OrderStatus: filters?.orderStatus,
    sortBy: options.sortBy.length > 0 ? `${options.sortBy}.${options.sortingDirection}` : '',
    pageSize: options.pageSize,
    startingRowIndex: options.pageSize * options.currentPageIndex,
  };
}
export function mapOSMHistoryToDayHistory(historyEntries: OSMOrderHistory[]): OSMOrderDayHistory[] {
  const historyDays: OSMOrderDayHistory[] = [];
  if (!historyEntries || historyEntries.length === 0) return [];
  historyEntries
    .sort((a, b) => {
      return Date.parse(b.timestamp) - Date.parse(a.timestamp);
    })
    .map((history) => {
      return {
        ...history,
        date: format(Date.parse(history.timestamp as string), 'yyyy-MM-dd'),
        time: format(Date.parse(history.timestamp as string), 'hh:mm:ss'),
      };
    })
    .forEach((hd) => {
      const historyDay = historyDays.findIndex((el) => el.day === hd.date);
      if (historyDay === -1) {
        historyDays.push({ day: hd.date, history: [hd] });
      } else {
        historyDays[historyDay].history.unshift(hd);
      }
    });
  return historyDays;
}
