import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { floorsSelectors } from 'stores/Floors/floors.slice';
import { setActiveIntakeTab } from 'stores/Intake';
import { FloorEntity, TableElement } from 'typings/Tables';
import { ReadOnlyCanvas } from 'components/Intake/Floors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getLastSelectedFloorId, storeLastSelectedFloorId } from 'utils/floorsPlanner';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getDineInOrder, openNewTable, swapDineInTables } from 'stores/DineIn/dineIn-thunk.actions';
import { setIsTableChangeInProgress } from 'stores/DineIn/dineIn.slice';
import { useTranslation } from 'react-i18next';
import buildClasses from './Floors.css';
import { AvailableIntakeContents } from '../IntakeConsts';
import NewDineInOrder from '../NewDineInOrder/NewDineInOrder';
import TableChangeConfirmationModal from './TableChangeConfirmation';

interface FloorsProps {
  setEditMode(): void;
}

const Floors = ({ setEditMode }: FloorsProps) => {
  const floorEntities = useAppSelector((state) => floorsSelectors.selectAll(state.floors));
  const { dineInOrders, selectedDineInOrder, isTableChangeInProgress } = useAppSelector((state) => state.dineIn);
  const [selectedFloor, setSelectedFloor] = useState<FloorEntity>();
  const [openOrdersTableNames, setOpenOrdersTableNames] = useState<string[]>([]);
  const [canvasContainerHeight, setCanvasContainerHeight] = useState<number>();
  const [canvasContainerWidth, setCanvasContainerWidth] = useState<number>();
  const [isTableChangeConfirmationOpened, setIsTableChangeConfirmationOpened] = useState<boolean>(false);
  const [targetTableId, setTargetTableId] = useState<string>();
  const [targetTableName, setTargetTableName] = useState<string>();
  const [t] = useTranslation('intake');

  const dispatch = useAppDispatch();

  const container = document.getElementById('intake-window') as HTMLElement;
  const { classes, cx } = buildClasses();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const resizeHandler = useCallback((ref: HTMLDivElement) => {
    // small delay required to finish receipt toggle animation
    setTimeout(() => {
      wrapperRef.current = ref;
      setCanvasContainerWidth(ref?.clientWidth);
      setCanvasContainerHeight(ref?.clientHeight);
    }, 100);
  }, []);

  useEffect(() => {
    if (floorEntities && !selectedFloor) {
      const lastSelectedFloorId = getLastSelectedFloorId();
      const lastSelectedFloor = floorEntities.find((f) => f.id === lastSelectedFloorId);
      setSelectedFloor(lastSelectedFloor ?? floorEntities[0]);
    }
  }, [floorEntities]);

  useEffect(() => {
    if (dineInOrders) {
      setOpenOrdersTableNames(dineInOrders.pageItems.map((item) => item.tableIdentifier));
    }
  }, [dineInOrders]);

  if (!container || !floorEntities || !selectedFloor) {
    return null;
  }

  function onTableChangeConfirmed(): void {
    if (!targetTableId && targetTableName && selectedDineInOrder) {
      changeTable(selectedDineInOrder.id, undefined, targetTableName);
    } else if (selectedDineInOrder && targetTableId) {
      changeTable(selectedDineInOrder.id, targetTableId);
    } else {
      dispatch(setIsTableChangeInProgress(false));
    }
  }

  function onTableChangeConfirmationClosed(): void {
    setIsTableChangeConfirmationOpened(false);
  }

  function onNewTableCreated(tableName: string): void {
    if (selectedDineInOrder) {
      changeTable(selectedDineInOrder.id, undefined, tableName);
    }
  }

  function changeTable(
    originTabId: string,
    targetTabId: string | undefined,
    targetTableName: string | undefined = undefined,
  ): void {
    if (selectedDineInOrder) {
      dispatch(swapDineInTables({ originTabId, targetTabId, targetTableName }));
      dispatch(setIsTableChangeInProgress(false));
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
  }

  function openTableSwapConfirmation(tableId: string | undefined, tableName: string): void {
    setTargetTableId(tableId);
    setTargetTableName(tableName);
    setIsTableChangeConfirmationOpened(true);
  }

  function onBackClicked(): void {
    dispatch(setIsTableChangeInProgress(false));
  }

  async function selectTable(table?: TableElement) {
    if (!table || selectedDineInOrder?.tableIdentifier === table.name) return;

    if (selectedDineInOrder && isTableChangeInProgress && dineInOrders) {
      const dineInOrder = dineInOrders.pageItems.find((item) => item.tableIdentifier === table.name);
      openTableSwapConfirmation(dineInOrder?.id, table.name);
    } else if (!openOrdersTableNames.includes(table.name)) {
      dispatch(openNewTable(table.name));
    } else if (dineInOrders) {
      const dineInOrder = dineInOrders.pageItems.find((order) => order.tableIdentifier === table.name);
      if (dineInOrder) {
        await dispatch(getDineInOrder(dineInOrder.id));
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.goBackContainer}>
          {isTableChangeInProgress && (
            <Button onClick={onBackClicked} color="inherit">
              <ArrowBackIcon className={classes.arrowIcon} />
              {t('GO BACK')}
            </Button>
          )}
        </div>
        <div className={classes.newDineInButtonContainer}>
          <Typography variant="body2" className={classes.selectTableLabel}>
            {t('Select table from a floor map below or')}
          </Typography>
          <NewDineInOrder onNewTableCreated={onNewTableCreated} />
        </div>
      </div>
      <div className={classes.floorSelectorContainer}>
        <div className={classes.tabsContainer}>
          {floorEntities?.map((floor) => (
            <div
              className={cx(classes.tab, {
                [classes.tabActive]: floor.id === selectedFloor.id,
              })}
              onClick={() => {
                setSelectedFloor(floor);
                storeLastSelectedFloorId(floor.id);
              }}
              key={floor.id}
            >
              <Typography
                variant="subtitle1"
                className={cx({
                  [classes.activeFloorText]: floor.id === selectedFloor.id,
                })}
              >
                {floor.name}
              </Typography>
            </div>
          ))}
        </div>
        <div id="canvas-wrapper" className={classes.canvasWrapper} ref={resizeHandler}>
          <ReadOnlyCanvas
            onSelect={selectTable}
            floor={selectedFloor}
            wrapperHeight={canvasContainerHeight}
            wrapperWidth={canvasContainerWidth}
            openOrders={openOrdersTableNames}
            selectedTableName={selectedDineInOrder?.tableIdentifier}
          />
        </div>
        {!isTableChangeInProgress && (
          <IconButton onClick={setEditMode} classes={{ root: classes.editButton }}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      <TableChangeConfirmationModal
        open={isTableChangeConfirmationOpened}
        onClose={onTableChangeConfirmationClosed}
        onSuccess={onTableChangeConfirmed}
        currentTableName={selectedDineInOrder?.tableIdentifier}
        targetTableName={targetTableName}
      />
    </div>
  );
};

export default Floors;
