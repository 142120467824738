import React, { memo } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { getInstanceType } from 'stores/Config/config.selector';
import { usePhoneCallLogic } from 'hooks/usePhoneCallLogic';
import { getPhoneNumberCalling } from 'stores/HybridMode/hybridMode.selector';
import ClearBasketOnPhonecallDialog from './ClearBasketOnPhonecallDialog';

export const PhoneCallHandler = memo(() => {
  const instanceType = useAppSelector(getInstanceType);
  const { showAcceptPhonecallDialog, acceptPhoneCall, ignorePhoneCall } = usePhoneCallLogic();
  const showDialog = instanceType === 'Central' && showAcceptPhonecallDialog;
  const phoneNumberCalling = useAppSelector(getPhoneNumberCalling);

  return showDialog ? (
    <ClearBasketOnPhonecallDialog
      open={showAcceptPhonecallDialog}
      phoneNumber={phoneNumberCalling}
      onCallAccepted={acceptPhoneCall}
      onCallIgnored={ignorePhoneCall}
    />
  ) : null;
});

PhoneCallHandler.displayName = 'PhoneCallHandler';
