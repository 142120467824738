import React, { ReactNode, memo } from 'react';
import { Button, useTheme } from '@mui/material';

interface OutlinedButtonProps {
  children: string;
  icon?: ReactNode;
  onClickFn(): void;
  testId: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

const OutlinedButton: React.FC<OutlinedButtonProps> = memo(
  ({ children, icon, onClickFn, testId, fullWidth = false, disabled = false }) => {
    const { palette, spacing } = useTheme();

    return (
      <Button
        data-testid={testId}
        startIcon={icon}
        onClick={onClickFn}
        variant="outlined"
        sx={{
          height: 'fit-content',
          width: fullWidth ? '100%' : 'auto',
          padding: `${spacing(1)} ${spacing(1.5)}`,
          color: palette.black.main,
          borderColor: palette.customGray.dark,
        }}
        disabled={disabled}
      >
        {children}
      </Button>
    );
  },
);

OutlinedButton.displayName = 'OutlinedButton';
export default OutlinedButton;
