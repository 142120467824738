import React from 'react';
import { Box, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';

interface PayOnAccountMethodProps {
  chargedCompanyName: string;
}

const PayOnAccountMethod: React.FC<PayOnAccountMethodProps> = ({ chargedCompanyName }) => {
  const [t] = useTranslation('intake');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <BusinessIcon sx={{ fontSize: '10rem' }} />
      <Typography align="center" variant="h6" gutterBottom>
        {chargedCompanyName}
      </Typography>
      <Typography align="center" variant="h6" gutterBottom>
        {t('The order will be fulfilled with pay on account')}
      </Typography>
    </Box>
  );
};

export default PayOnAccountMethod;
