import React, { useMemo, useState } from 'react';
import { Avatar, Box, Chip, Grid2 as Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ToppingCard from 'components/Intake/Product/ToppingCard/ToppingCard';
import { ToppingGroup, ToppingsCounter, ToppingSelection, ToppingSelectionSimplified } from 'typings/Products';
import { skipFromToppingWeightCalculationCategories } from 'containers/Intake/Products/ProductCustomization/CustomizeProduct/CustomizeProduct';
import { useAppSelector } from 'hooks/useRedux';
import { getMaxNumberOfDuplicateToppings } from 'stores/Config/config.selector';

interface ToppingsWrapperProps {
  onToppingsChange: (appliedToppings: ToppingSelectionSimplified[]) => void;
  hideTitle?: boolean;
  toppingsCounter?: ToppingsCounter;
  availableGroupedToppings: ToppingGroup[];
  selectedToppings: ToppingSelectionSimplified[];
}

const ToppingsWrapper: React.FC<ToppingsWrapperProps> = ({
  onToppingsChange,
  hideTitle,
  toppingsCounter,
  availableGroupedToppings,
  selectedToppings,
}) => {
  const [t] = useTranslation('intake');
  const { palette, typography } = useTheme();
  const maxToppingQuantity = useAppSelector(getMaxNumberOfDuplicateToppings);
  const [selectedCategoryCode, setSelectedCategoryCode] = useState<string | undefined>();

  const numberOfToppingsLeft = useMemo(() => {
    return recalculateNumberOfToppingsLeft();
  }, [toppingsCounter]);

  const toppingsToShow = useMemo(() => {
    return availableGroupedToppings.filter((group) =>
      selectedCategoryCode ? group.code === selectedCategoryCode : true,
    );
  }, [selectedCategoryCode, availableGroupedToppings]);

  const onlyRemovalPossible = numberOfToppingsLeft < 1;

  function recalculateNumberOfToppingsLeft(): number {
    if (toppingsCounter?.enabled && toppingsCounter.maxNumberOfToppings > 0) {
      const selectedToppingsWeightedCount = selectedToppings
        .filter(
          (el) =>
            !skipFromToppingWeightCalculationCategories.some(
              (ctg) => ctg === el.toppingCategoryCode.toUpperCase(),
            ),
        )
        .filter((t) => t.quantity && t.quantity > 0)
        .reduce((accumulator, object) => {
          return accumulator + (object.quantity ?? 0) * object.calculationWeight;
        }, 0);

      return toppingsCounter.maxNumberOfToppings < selectedToppingsWeightedCount
        ? 0
        : toppingsCounter.maxNumberOfToppings - selectedToppingsWeightedCount;
    }
    return 0;
  }

  function handleToppingClicked(clickedTopping: ToppingSelection): void {
    const toppingIndex = selectedToppings.findIndex((el) => el.id === clickedTopping.id);

    if (toppingIndex !== -1) {
      const newToppings = selectedToppings.map((tp, index) => {
        if (index !== toppingIndex) return tp;

        const newQuantity =
          tp.quantity === maxToppingQuantity
            ? 0
            : tp.calculationWeight > numberOfToppingsLeft
              ? 0
              : tp.quantity + 1;
        return { ...tp, quantity: newQuantity };
      });
      onToppingsChange(newToppings);
      return;
    }

    const addedTopping = {
      name: clickedTopping.name,
      calculationWeight: clickedTopping.calculationWeight,
      id: clickedTopping.id,
      quantity: 1,
      toppingCategoryCode: clickedTopping.toppingCategoryCode,
    };

    onToppingsChange([...selectedToppings, addedTopping]);
  }

  return (
    <>
      <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', my: 1 }}>
        {!hideTitle && (
          <Typography sx={{ marginLeft: '8px' }} variant="subtitle1">
            {t('Toppings')}
          </Typography>
        )}
        {toppingsCounter?.enabled && (
          <>
            {onlyRemovalPossible && (
              <Typography
                sx={{ marginRight: '15px', display: 'inline-flex', alignItems: 'center' }}
                variant="subtitle1"
              >
                {t('You can add no more toppings')}
              </Typography>
            )}
            {!onlyRemovalPossible && (
              <Typography
                sx={{ marginRight: '15px', display: 'inline-flex', alignItems: 'center' }}
                variant="subtitle1"
              >
                {t('You can add')}
                <Avatar sx={{ width: 27, height: 27, marginLeft: '5px', marginRight: '5px' }}>
                  {numberOfToppingsLeft}
                </Avatar>
                {numberOfToppingsLeft > 1 ? t('more toppings') : t('more topping')}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', overflowX: 'auto' }}>
        {availableGroupedToppings.map((toppingGroup) => {
          const isSelected = selectedCategoryCode === toppingGroup.code;
          return (
            <Chip
              label={t(toppingGroup.name)}
              variant="outlined"
              key={toppingGroup.code}
              sx={{
                color: palette.black.main,
                fill: palette.black.main,
                mx: 0.5,
                my: 1,
                backgroundColor: isSelected ? palette.grey[200] : 'transparent',
                border: `1px solid ${isSelected ? palette.grey[500] : palette.grey[200]}`,
                ...typography.body1,
              }}
              onClick={() => {
                if (isSelected) {
                  setSelectedCategoryCode(undefined);
                } else {
                  setSelectedCategoryCode(toppingGroup.code);
                }
              }}
            />
          );
        })}
      </Box>
      {toppingsToShow.map((toppingGroup) => (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}
          key={`toppings-group-${toppingGroup.code}`}
          data-testid="product-customization__container--topping-group"
        >
          <Typography variant="h5" color="black.main" sx={{ my: 1 }}>
            {t(toppingGroup.name)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            data-testid="product-customization__container--topping-group-groups"
          >
            <Grid container spacing={1} columns={{ xs: 4, md: 5, lg: 6 }} sx={{ width: '100%' }}>
              {toppingGroup.toppings.map((top) => {
                return (
                  <Grid size={{ xs: 1 }} key={top.id}>
                    <ToppingCard
                      onToppingChange={(toppingClicked): void => {
                        handleToppingClicked(toppingClicked);
                      }}
                      topping={top}
                      toppingQuantity={selectedToppings.find((el) => el.id === top.id)?.quantity ?? 0}
                      disableAdding={
                        toppingsCounter?.enabled &&
                        (onlyRemovalPossible || top.calculationWeight > numberOfToppingsLeft)
                      }
                      maxQuantity={maxToppingQuantity}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ToppingsWrapper;
