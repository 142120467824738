import React, { useCallback, useState } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { AvailableColumns, NotSortableColumns, submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { OrderColumn, resetSorting, setSorting } from 'stores/AllOrders';
import { AllOrdersTableHeadCell } from './AllOrdersTableHeadCell';

const AllOrdersTableHead: React.FC = () => {
  const [hoveredColumn, setHoveredColumn] = useState<string | undefined>();
  const { tableOptions } = useAppSelector((store) => store.allOrders);
  const dispatch = useAppDispatch();
  const { sortBy, sortingDirection } = tableOptions;

  const setSortValue = useCallback(
    (column: OrderColumn) => {
      if (NotSortableColumns.some((el) => el === column.key)) return;

      if (sortBy === column.key) {
        if (sortingDirection === 'asc') {
          dispatch(
            setSorting({
              sortBy: column.key,
              sortingDirection: 'desc',
            }),
          );
          submitAllOrdersForm();
          return;
        }
        if (sortingDirection === 'desc') {
          dispatch(resetSorting());
          submitAllOrdersForm();
          return;
        }
      }

      dispatch(
        setSorting({
          sortBy: column.key,
          sortingDirection: 'asc',
        }),
      );
      submitAllOrdersForm();
    },
    [dispatch, sortBy, sortingDirection],
  );

  return (
    <TableHead sx={{ backgroundColor: 'white' }}>
      <TableRow>
        {AvailableColumns.sort((a, b) => a.columnSortNumber - b.columnSortNumber).map((ac) => (
          <AllOrdersTableHeadCell
            key={ac.key}
            column={ac}
            sortBy={sortBy}
            sortingDirection={sortingDirection}
            setHoveredColumn={setHoveredColumn}
            hoveredColumn={hoveredColumn}
            setSortValue={setSortValue}
          />
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AllOrdersTableHead;
