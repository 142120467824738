import { createApi } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import { StoreConfiguration } from 'typings/Store';
import { getAuthorizationHeader } from 'utils/auth';
import { posBaseQuery } from '../base.queries';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getInitialStores: builder.query<StoreConfiguration[], void>({
      query: () => ({
        url: posApiUrls.INITIAL_SETUP_STORES,
        headers: { Authorization: getAuthorizationHeader() },
      }),
    }),
    updateMachineLastActivity: builder.query<void, void>({
      query: () => ({
        url: posApiUrls.ACCOUNT_MACHINE_UPDATE_LAST_ACTIVITY,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetInitialStoresQuery, useUpdateMachineLastActivityQuery } = authApi;
