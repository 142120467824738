import { Button } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getIsAnySetOnReceipt, getVirtualReceipt } from 'stores/Basket/basket.selector';
import { getIsIterationPending } from 'stores/DineIn/dineIn.selector';

type SendButtonProps = {
  onClick: () => void;
};
export const SendButton: React.FC<SendButtonProps> = memo(({ onClick }) => {
  const [t] = useTranslation('intake');

  const isIterationPending = useAppSelector(getIsIterationPending);
  const virtualReceipt = useAppSelector(getVirtualReceipt);
  const anySetOnReceipt = useAppSelector(getIsAnySetOnReceipt);

  const sendIteration = useCallback(() => {
    if (isIterationPending) {
      onClick();
    }
  }, [isIterationPending, onClick]);

  return (
    <Button
      color="primary"
      size="large"
      variant="contained"
      fullWidth
      onClick={sendIteration}
      disabled={virtualReceipt.receiptProducts.length < 1 && !anySetOnReceipt}
      data-testid="receipt-footer__button--dine-in-send"
    >
      {t('SEND')}
    </Button>
  );
});

SendButton.displayName = 'SendButton';
