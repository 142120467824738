import React, { useMemo } from 'react';
import ProductCard from 'components/Intake/Product/Card/ProductCard';
import { sortProductsAlphabetically, sortProductsBySortOrder } from 'stores/Intake/IntakeStoreUtils';
import { useAppSelector } from 'hooks/useRedux';
import { Group, Option, Product } from 'typings/Products';
import { Box } from '@mui/system';
import { getIntakeSettings, getOtherGroupCode, getPizzaCategoryCode } from 'stores/Config/config.selector';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { getActiveCategoryCode } from 'stores/Products/products.selector';
import buildClasses from './ProductsCatalog.css';

type ProductCatalogProps = {
  products: Product[];
  groups: Group[];
  onProductClick: (product: Product) => void;
  selectedOption: Option | undefined;
};

const ScrollableCatalog: React.FC<ProductCatalogProps> = ({
  products,
  groups,
  onProductClick,
  selectedOption,
}) => {
  const { classes } = buildClasses();

  const activeCategoryCode = useAppSelector(getActiveCategoryCode);
  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);
  const otherGroupCode = useAppSelector(getOtherGroupCode);
  const selectedStore = useAppSelector(getSelectedStore);
  const intakeSettings = useAppSelector(getIntakeSettings);

  const sortedProducts = useMemo(
    () =>
      intakeSettings?.sortProductsByName
        ? sortProductsAlphabetically(products)
        : sortProductsBySortOrder(products),
    [intakeSettings?.sortProductsByName, products],
  );

  return (
    <Box sx={{ flex: 1, overflowY: 'auto' }}>
      <div className={classes.scrollableWrapper}>
        {activeCategoryCode === pizzaCategoryCode || groups.length <= 1
          ? products.map((product) => (
              <ProductCard
                product={product}
                selectedOption={selectedOption}
                onProductSelected={onProductClick}
                hideOptionName
                key={`PRODUCT_${product.id}`}
                displayImage={selectedStore?.displayProductImagesDuringIntake}
              />
            ))
          : groups.map((group) => (
              <div className={classes.productsGroupContainer} key={`GROUP_${group.code}`}>
                <h3 className={classes.groupLabel}>{group.name}</h3>
                <div className={classes.productsGroup}>
                  {sortedProducts.length &&
                    sortedProducts.map(
                      (product) =>
                        (product.groupCode === group.code ||
                          (!product.groupCode && group.code === otherGroupCode)) && (
                          <ProductCard
                            key={`PRODUCT_${product.id}`}
                            product={product}
                            onProductSelected={onProductClick}
                            displayImage={selectedStore?.displayProductImagesDuringIntake}
                          />
                        ),
                    )}
                </div>
              </div>
            ))}
      </div>
    </Box>
  );
};

export default ScrollableCatalog;
