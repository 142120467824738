import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import { MachineType } from 'typings/Auth';
import { QueryResult } from 'utils/types';
import {
  AppVersionInformation,
  ConfigQueryResult,
  ConfigResponse,
  FiscalSystemStatus,
  SyncHistoryRecord,
  SyncStatus,
  UpgradeStatus,
} from './configTypes';

import { posBaseQuery } from '../base.queries';

export const initialConfigState = {
  featureFlags: {},
  themeKey: 'S4D',
  showFirstTimeLaunch: false,
  machineActivityUpdate: undefined,
  maxUserIdleTime: '00:01:00',
  eftPaymentSettings: {
    statusPollingIntervalInSeconds: 1,
    manualFinishEnabledDelayInSeconds: 5,
    additionalMethodsWhenEftIsForced: [],
    hideManualFinishButton: false,
  },
  toppingsCustomizationConfiguration: {
    maximumExtraToppings: 0,
    maximumTotalToppingsForCyo: 0,
    maximumNumberOfDuplicatesToppings: 0,
  },
  defaultStoreOnSiteMachineType: MachineType.Registry,
  maxPriceOverride: 1000,
  maxDeliveryChargeOverride: 1000,
  maxCustomerCreditAssignment: 1000,
  payment: {
    v2: {
      enableTipping: true,
    },
  },
  intakeSettings: {
    pizzaCategoryCode: 'PIZ',
    otherGroupCode: 'Oth',
    sortProductsByName: false,
    hideCustomerSearch: false,
  },
  usePagination: {
    productsPage: true,
    couponsPage: true,
  },
  appVersion: {
    baseVersion: '',
    gitSha: undefined,
  },
  fiscalizationConfiguration: {
    isActive: false,
    fiscalProvider: undefined,
    awaitingFiscalizationNotificationIntervalInSeconds: 60,
  },
};

export function mapToAppVersionObject(response: string): AppVersionInformation {
  const [baseVersion, gitSha] = response.split('+');
  return gitSha ? { baseVersion, gitSha } : { baseVersion };
}

export const configAPI = createApi({
  reducerPath: 'configAPI',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigQueryResult, void>({
      queryFn: async (_arg, api, extraOptions, baseQuery) => {
        // Should these be a single backend endpoint?
        const results = await Promise.all([
          baseQuery(posApiUrls.CONFIGURATION_FEATURE_FLAGS) as QueryResult<ConfigQueryResult['featureFlags']>,
          baseQuery(posApiUrls.POS_UI_CONFIG) as QueryResult<ConfigResponse>,
          baseQuery({
            url: posApiUrls.INITIAL_SETUP_SHOW_FIRST_TIME_LAUNCH,
            responseHandler: async (response) => (await response.text()) === 'true',
          }) as QueryResult<ConfigQueryResult['showFirstTimeLaunch']>,
          baseQuery({
            url: posApiUrls.DEV_APP_VERSION,
            responseHandler: (response) => response.text(),
          }) as QueryResult<ConfigQueryResult['appVersion']>,
        ]);

        const errorResult = results.find((r) => r.error);
        if (errorResult) {
          return { error: errorResult.error as FetchBaseQueryError };
        }

        const [featureFlags, baseConfig, showFirstTimeLaunch, appVersion] = results;

        const baseConfigData = baseConfig.data;
        const sortedAddressInput =
          baseConfigData?.addressInput?.configurationElements &&
          [...baseConfigData.addressInput.configurationElements].sort((a, b) => a.line - b.line);

        return {
          data: {
            ...initialConfigState,
            ...baseConfigData,
            addressInput: { configurationElements: sortedAddressInput },
            featureFlags: featureFlags.data,
            showFirstTimeLaunch: showFirstTimeLaunch.data,
            appVersion: mapToAppVersionObject(`${appVersion.data}`),
          } as ConfigQueryResult,
        };
      },
      providesTags: ['Config'],
    }),
    getVersionUpgradeStatus: builder.query<UpgradeStatus, void>({
      query: () => posApiUrls.VERSION_UPGRADE_STATUS,
    }),
    getSyncStatus: builder.query<SyncStatus, void>({
      query: () => posApiUrls.SYNCHRONIZATION_STATUS,
    }),
    getSyncHistory: builder.query<SyncHistoryRecord[], void>({
      query: () => ({
        url: posApiUrls.SYNCHRONIZATION_HISTORY,
        params: {
          PageNumber: 1,
          PageSize: 5,
        },
      }),
      providesTags: ['SyncHistory'],
    }),
    getFdmDeviceStatus: builder.query<FiscalSystemStatus, void>({
      query: () => posApiUrls.FDM_DEVICE_STATUS,
    }),
    runSynchronization: builder.mutation<void, void>({
      query: () => ({
        url: posApiUrls.SYNCHRONIZATION_RUN,
        method: 'POST',
      }),
      invalidatesTags: ['SyncHistory'],
    }),
  }),
  tagTypes: ['Config', 'SyncHistory'],
});

export const {
  useGetVersionUpgradeStatusQuery,
  useGetSyncStatusQuery,
  useGetSyncHistoryQuery,
  useRunSynchronizationMutation,
  useGetConfigQuery,
  useGetFdmDeviceStatusQuery,
} = configAPI;
