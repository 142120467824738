import { ButtonBase } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { SubOption } from 'typings/Products';
import buildClasses from './ProductSubOptions.css';

type ProductSubOptionProps = {
  subOption: SubOption;
  isActive: boolean;
  onClick: (subOptionId: number) => void;
};

export const ProductSubOption: React.FC<ProductSubOptionProps> = memo(({ subOption, isActive, onClick }) => {
  const { classes, cx } = buildClasses();
  const click = useCallback((): void => {
    onClick(subOption.id);
  }, [onClick, subOption.id]);
  return (
    <ButtonBase
      className={cx(classes.buttonBox, {
        [classes.active]: isActive,
      })}
      onClick={click}
      data-testid={`product-sub-options__button--${subOption.description}`}
    >
      {subOption.description.toUpperCase()}
    </ButtonBase>
  );
});

ProductSubOption.displayName = 'ProductSubOption';
