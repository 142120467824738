import React from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ToppingGroup, ToppingSelectionSimplified } from 'typings/Products';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import { getToppingCategories } from 'stores/Products/products.selector';

interface SingleSelectToppingsProps {
  selectedToppings: ToppingSelectionSimplified[];
  singleSelectionToppingGroups: ToppingGroup[];
  onToppingSelected: (newSelectedToppings: ToppingSelectionSimplified[]) => void;
}

const SingleSelectToppingWrapper: React.FC<SingleSelectToppingsProps> = ({
  singleSelectionToppingGroups,
  onToppingSelected,
  selectedToppings,
}) => {
  const [t] = useTranslation('intake');

  const toppingCategories = useAppSelector(getToppingCategories);

  function selectSingleUseTopping(selectedTopping: ToppingSelectionSimplified): void {
    const newToppings = selectedToppings.map((el) => {
      if (el.toppingCategoryCode === selectedTopping.toppingCategoryCode) return selectedTopping;
      return el;
    });

    onToppingSelected(newToppings.includes(selectedTopping) ? newToppings : [...newToppings, selectedTopping]);
  }
  function removeToppingOfCategory(categoryCode: string): void {
    const newToppings = selectedToppings.filter((el) => el.toppingCategoryCode !== categoryCode);

    onToppingSelected(newToppings);
  }

  return (
    <>
      {singleSelectionToppingGroups.map((stg) => {
        const value = selectedToppings.find((el) => el.toppingCategoryCode === stg.code)?.id ?? -1;

        return (
          <FormControl variant="outlined" fullWidth sx={{ pb: 2 }} key={`single-select--_${stg.code}`}>
            <InputLabel>{toppingCategories.find((el) => el.code === stg.code)?.name}</InputLabel>
            <Select
              label={toppingCategories.find((el) => el.code === stg.code)?.name}
              value={value}
              onChange={(event: SelectChangeEvent<number>): void => {
                if ((event.target.value as number) < 0) {
                  removeToppingOfCategory(stg.code);
                }
                const baseTopping = stg.toppings.find((el) => el.id === event.target.value);
                baseTopping &&
                  selectSingleUseTopping({
                    id: baseTopping.id,
                    calculationWeight: baseTopping.calculationWeight,
                    name: baseTopping.name,
                    quantity: 1,
                    toppingCategoryCode: stg.code,
                  });
              }}
              data-testid={`product-customization__select-${stg.code}`}
            >
              {value < 0 && (
                <MenuItem
                  value={-1}
                  key="product-customization__select--select-not-selected"
                  data-testid="product-customization__select--select-not-selected"
                >
                  {t('Not selected')}
                </MenuItem>
              )}
              {stg.toppings.map((topping) => (
                <MenuItem
                  value={topping.id}
                  key={topping.id}
                  data-testid={`product-customization__select--select-id-${topping.id}`}
                >
                  {topping.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
    </>
  );
};

export default SingleSelectToppingWrapper;
