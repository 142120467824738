import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Routes from 'App/Routes/routes-list';
import Typography from '@mui/material/Typography';
import { Alert, CircularProgress } from '@mui/material';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { SwitchStore } from 'typings/Store';
import { restartOrder } from 'stores/combined.actions';
import { useLocation } from 'react-router';
import { getAvailableSwitchStores, getSelectedStore, getSwitchedStore } from 'stores/Store/store.selectors';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { CashierPermission } from 'typings/Cashier';
import { useSwitchStoreMutation } from 'stores/Store/store.api';
import { getBasketHasItems } from 'stores/Basket/basket.selector';
import { isEmpty } from 'lodash';
import buildClasses from './StorePanel.css';
import { StoreSelector } from './StoreSelector';

export const StorePanel = memo(() => {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [t] = useTranslation('common');

  const selectedStore = useAppSelector(getSelectedStore);
  const availableSwitchStores = useAppSelector(getAvailableSwitchStores);
  const switchedStore = useAppSelector(getSwitchedStore);
  const basketHasItems = useAppSelector(getBasketHasItems);
  const canUserAccess = useAppSelector(getPermissionChecker);
  const isProcessing = !selectedStore || isEmpty(availableSwitchStores);
  const [storeToSwitchId, setStoreToSwitchId] = useState<string | undefined>(undefined);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [switchStore] = useSwitchStoreMutation({
    fixedCacheKey: 'shared-store-switch-status',
  });
  const activeRoute = Routes.find((el) => el.path === location.pathname);
  const cashierCanChangeStore = canUserAccess(CashierPermission.ChooseStore);

  const storeChangedWarning = !!switchedStore && !switchedStore.isDefaultStore;

  const handleStoreChange = useCallback((storeId: string): void => {
    setStoreToSwitchId(storeId);
  }, []);

  useEffect(() => {
    const showModal = basketHasItems && !!storeToSwitchId;
    setShowWarningModal(showModal);
    if (!showModal && storeToSwitchId) {
      switchStore(storeToSwitchId);
      setStoreToSwitchId(undefined);
    }
  }, [basketHasItems, storeToSwitchId, switchStore]);

  const sortedAvailableStores = useMemo(
    () => [switchedStore, ...availableSwitchStores.filter((el) => el.id !== switchedStore?.id)] as SwitchStore[],
    [availableSwitchStores, switchedStore],
  );

  const switchingAllowedOnCurrentRoute = !activeRoute?.forbiddenOnSwitchedStore;

  const storeSwitchingAvailable =
    sortedAvailableStores?.length > 1 && selectedStore && switchingAllowedOnCurrentRoute && cashierCanChangeStore;

  const onClose = useCallback((): void => {
    setStoreToSwitchId(undefined);
  }, []);

  const onSuccess = useCallback((): void => {
    dispatch(restartOrder());
    switchStore(storeToSwitchId as string);
    onClose();
  }, [dispatch, onClose, storeToSwitchId, switchStore]);

  return isProcessing ? (
    <CircularProgress size={30} />
  ) : storeSwitchingAvailable ? (
    <>
      <StoreSelector
        handleStoreChange={handleStoreChange}
        availableStores={sortedAvailableStores}
        switchedStore={switchedStore}
        selectedStore={selectedStore}
      />
      {storeChangedWarning && (
        <Alert
          className={classes.storeChangeWarningMessage}
          severity="warning"
          icon={false}
          data-testid="switch-store-external-store-warning"
        >
          {t('External store')}
        </Alert>
      )}
      {showWarningModal && (
        <ModalContainer
          maxWidth="sm"
          onClose={onClose}
          open={showWarningModal}
          title={t('Change store')}
          primaryButton={{
            label: t('Change store'),
            testId: 'switch-store-modal-confirm',
            action: onSuccess,
          }}
          secondaryButton={{
            label: t('Cancel'),
            testId: 'switch-store-modal-cancel',
            action: onClose,
          }}
          testId="switch-store-modal"
        >
          <Typography variant="body1" textAlign="center" className={classes.changeStoreText}>
            {t(
              'All items in the basket will be removed when the store is changed. Do you want to change the store anyway?',
            )}
          </Typography>
        </ModalContainer>
      )}
    </>
  ) : (
    <Typography variant="h6" noWrap data-testid="store-panel-selected-store">
      {selectedStore?.name}
    </Typography>
  );
});

StorePanel.displayName = 'StorePanel';
