import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OnlinePaymentRefundAttemptStatus, PaymentsState, SettlePaymentState } from 'typings/Payments';
import { postPlaceUnpaidTakeAwayOrder } from 'stores/Intake';
import { postFinalizeDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import {
  getPaymentMethods,
  getTerminalStatus,
  refundPayment,
  sendCtmpCommand,
  sendOpenCashDrawerCommand,
} from './payments.thunk-actions';

const initialState: PaymentsState = {
  availablePaymentMethods: [],
  terminalLastStatus: undefined,
  paymentRefundAttemptStatus: undefined,
};

const paymentsSlice = createSlice({
  name: '[PAYMENT]',
  initialState,
  reducers: {
    clearCtmpStatus: (state) => ({
      ...state,
      ctmpCommandStatus: undefined,
    }),
    setSettlePayment: (state, action: PayloadAction<SettlePaymentState>) => ({
      ...state,
      settlePayment: action.payload,
    }),
    clearSettlePayment: (state) => ({
      ...state,
      settlePayment: undefined,
    }),
    clearTerminalLastStatus: (state) => ({
      ...state,
      terminalLastStatus: undefined,
    }),
    resetPaymentRefundAttemptStatus: (state) => ({
      ...state,
      paymentRefundAttemptStatus: initialState.paymentRefundAttemptStatus,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(sendCtmpCommand.pending, (state, action) => ({
      ...state,
      ctmpCommandStatus: 'PENDING',
    }));
    builder.addCase(sendCtmpCommand.fulfilled, (state, action) => ({
      ...state,
      ctmpCommandStatus: action.payload ? 'SUCCESS' : 'FAILED',
    }));
    builder.addCase(sendCtmpCommand.rejected, (state) => ({
      ...state,
      ctmpCommandStatus: 'FAILED',
    }));
    builder.addCase(sendOpenCashDrawerCommand.fulfilled, (state, action) => ({
      ...state,
      cashDrawerOpened: action.payload,
    }));
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => ({
      ...state,
      availablePaymentMethods: action.payload,
    }));
    builder.addCase(getTerminalStatus.fulfilled, (state, action) => ({
      ...state,
      terminalLastStatus: action.payload,
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.pending, (state) => ({
      ...state,
      unpaidOrderPlacementInProgress: true,
    }));
    builder.addCase(postPlaceUnpaidTakeAwayOrder.fulfilled, (state, action) => {
      const newSettlePaymentState = state.settlePayment
        ? ({
            ...state.settlePayment,
            unpaidOrderPlaced: true,
          } as SettlePaymentState)
        : undefined;

      return {
        ...state,
        settlePayment: newSettlePaymentState,
        unpaidOrderPlacementInProgress: false,
      };
    });
    builder.addCase(postPlaceUnpaidTakeAwayOrder.rejected, (state) => ({
      ...state,
      unpaidOrderPlacementInProgress: false,
    }));
    builder.addCase(postFinalizeDineInOrder.fulfilled, (state, action) => {
      const newSettlePaymentState = state.settlePayment
        ? ({
            ...state.settlePayment,
            unpaidOrderPlaced: true,
          } as SettlePaymentState)
        : undefined;

      return {
        ...state,
        settlePayment: newSettlePaymentState,
      };
    });
    builder.addCase(refundPayment.pending, (state) => ({
      ...state,
      paymentRefundAttemptStatus: OnlinePaymentRefundAttemptStatus.InProgress,
    }));
    builder.addCase(refundPayment.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          paymentRefundAttemptStatus: action.payload.succeded
            ? OnlinePaymentRefundAttemptStatus.Succeded
            : OnlinePaymentRefundAttemptStatus.Failed,
        };
      }

      return {
        ...state,
      };
    });
    builder.addCase(refundPayment.rejected, (state) => ({
      ...state,
      paymentRefundAttemptStatus: OnlinePaymentRefundAttemptStatus.Failed,
    }));
  },
});

const { reducer } = paymentsSlice;

export const {
  clearCtmpStatus,
  setSettlePayment,
  clearSettlePayment,
  clearTerminalLastStatus,
  resetPaymentRefundAttemptStatus,
} = paymentsSlice.actions;

export default reducer;
