import { Box, Chip, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { ReceiptProduct } from 'typings/Basket';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSetStepSelection } from 'typings/Products';
import { CommentOutlined } from '@mui/icons-material';
import buildClass from './ReceiptProductSet.Details.css';

interface ReceiptProductSetDetailsProps {
  receiptProductSet: ReceiptProduct;
  remark?: string;
}

const ReceiptProductSetDetails: React.FC<ReceiptProductSetDetailsProps> = ({ receiptProductSet, remark }) => {
  const { classes } = buildClass();
  const { palette, spacing, radius } = useTheme();
  const [t] = useTranslation('intake');
  const stepDispayValueOffset = receiptProductSet.selectedSetSteps?.find((s) => s.order === 0) ? 1 : 0;

  function renderStepDetails(step: ProductSetStepSelection): JSX.Element {
    const toppingNames = step.selectedToppings.map((t) => `${t.title} x${t.quantity}`).join(', ');

    return (
      <Box
        key={`product-set-${receiptProductSet.id}-step-${step.order + stepDispayValueOffset}`}
        data-testid="receipt-item--item-details"
        sx={{
          borderBottom: '1px solid #c8c8c8',
          padding: spacing(1),
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography variant="body2">{step.title}</Typography>
          <Typography variant="body2" sx={{ color: palette.black.main }}>
            {toppingNames}
          </Typography>
        </div>
        <Chip label={`${t('step')} ${step.order + stepDispayValueOffset}`} size="small" />
      </Box>
    );
  }

  function renderSteps(): JSX.Element {
    const renderedSteps = receiptProductSet.selectedSetSteps?.map((step) => {
      return renderStepDetails(step);
    });

    return <Paper>{renderedSteps?.map((renderedStep) => renderedStep)}</Paper>;
  }

  return (
    <>
      {renderSteps()}
      {remark && (
        <Paper>
          <Tooltip title={<span className={classes.remarkTooltip}>{remark}</span>} placement="top">
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '100px',
                wordBreak: 'break-word',
                padding: spacing(1),
                display: 'flex',
                border: `1px solid ${palette.blue[300]}`,
                borderRadius: radius.sm,
                marginTop: spacing(1),
              }}
            >
              <CommentOutlined sx={{ marginRight: spacing(1), color: palette.blue[300] }} />
              <Typography variant="body2">{remark}</Typography>
            </Box>
          </Tooltip>
        </Paper>
      )}
    </>
  );
};

export default ReceiptProductSetDetails;
