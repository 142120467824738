import React, { useEffect, useRef, useState } from 'react';
import { Layer, Line, Stage } from 'react-konva';
import { FloorEntity, GuideLine, TableElement } from 'typings/Tables';
import { Layer as LayerRef } from 'konva/lib/Layer';
import { Stage as StageRef } from 'konva/lib/Stage';
import { KonvaEventObject } from 'konva/lib/Node';
import { getBackgroundUrl, getGuides, getLineGuideStops, getObjectSnappingEdges } from 'utils/floorsPlanner';
import EditableTableObject from './EditableTableObject';
import buildClasses from './CustomizableCanvas.css';

interface CustomizableCanvasProps {
  floor: FloorEntity;
  onChangeSize(size: TableElement['size'], placement: TableElement['placement']): void;
  onChangePosition(size: TableElement['size'], placement: TableElement['placement']): void;
  setDefaultCanvasSize(size: FloorEntity['defaultCanvasSize']): void;
  selectedObjectId?: TableElement['id'];
  setSelectedObject(object?: TableElement): void;
  wrapperHeight?: number;
  wrapperWidth?: number;
}

const CustomizableCanvas = ({
  floor,
  onChangeSize,
  onChangePosition,
  setDefaultCanvasSize,
  selectedObjectId,
  setSelectedObject,
  wrapperHeight,
  wrapperWidth,
}: CustomizableCanvasProps) => {
  const [guideLines, setGuideLines] = useState<JSX.Element[]>([]);

  const [ratioX, setRatioX] = useState(1);
  const [ratioY, setRatioY] = useState(1);

  const layerRef = useRef<LayerRef>(null);
  const stageRef = useRef<StageRef>(null);
  const { classes } = buildClasses();
  const [containerSize, setContainerSize] = useState<{ width: number; height: number }>();

  useEffect(() => {
    const container = document.getElementById('canvas-wrapper') as HTMLElement;
    setContainerSize({ width: container.clientWidth, height: container.clientHeight });
  }, []);

  useEffect(() => {
    if (floor.defaultCanvasSize) {
      if (wrapperWidth) {
        setRatioX(wrapperWidth / floor.defaultCanvasSize.width);
      }
      if (wrapperHeight) {
        setRatioY(wrapperHeight / floor.defaultCanvasSize.height);
      }
    }
  }, [wrapperWidth, wrapperHeight]);

  useEffect(() => {
    if (!containerSize) return;
    setDefaultCanvasSize(containerSize);
  }, [containerSize]);

  if (!floor || !containerSize) {
    return null;
  }

  function drawGuides(guides: GuideLine[]) {
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        const horizontalLine = (
          <Line
            points={[-6000, 0, 6000, 0]}
            stroke="rgb(0, 161, 255)"
            strokeWidth={1}
            name="guid-line"
            dash={[4, 6]}
            x={0}
            y={lg.lineGuide}
            key={guideLines.length}
          />
        );
        setGuideLines((prev) => [...prev, horizontalLine]);
      } else if (lg.orientation === 'V') {
        const verticalLine = (
          <Line
            points={[0, -6000, 0, 6000]}
            stroke="rgb(0, 161, 255)"
            strokeWidth={1}
            name="guid-line"
            dash={[4, 6]}
            x={lg.lineGuide}
            y={0}
            key={guideLines.length}
          />
        );
        setGuideLines((prev) => [...prev, verticalLine]);
      }
    });
  }

  const checkDeselect = (e: KonvaEventObject<MouseEvent>) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedObject();
    }
  };

  function onDragStart(e: KonvaEventObject<DragEvent>, table: TableElement) {
    if (!layerRef.current || !stageRef.current) return;
    // clear all previous lines on the screen
    layerRef.current.find('.guid-line').forEach((l) => l.destroy());
    setSelectedObject(table);

    // find possible snapping lines
    const lineGuideStops = getLineGuideStops(e.target, stageRef.current);
    // find snapping points of current object
    const itemBounds = getObjectSnappingEdges(e.target);

    // now find where can we snap current object
    const guides = getGuides(lineGuideStops, itemBounds);

    // do nothing of no snapping
    if (!guides.length) {
      return;
    }

    drawGuides(guides);

    const absPos = e.target.absolutePosition();
    // now force object position
    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
            default:
              break;
          }
          break;
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
            default:
              break;
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    });
    e.target.absolutePosition(absPos);
  }

  function onDragEnd(size: TableElement['size'], placement: TableElement['placement']) {
    // clear all previous lines on the screen
    onChangePosition(size, placement);
    setGuideLines([]);
  }

  return (
    <Stage
      className={classes.stage}
      // stage width has value of container height multiplied by 1.5 to maintain 3/2 ratio
      width={wrapperWidth}
      height={wrapperHeight}
      style={{
        backgroundImage: `url(${getBackgroundUrl(floor)})`,
        backgroundSize: '100% 100%',
      }}
      ref={stageRef}
      onMouseDown={checkDeselect}
      onTap={checkDeselect}
    >
      <Layer ref={layerRef}>
        {floor.tables.map((table) => (
          <EditableTableObject
            placement={table.placement}
            size={table.size}
            name={table.name}
            onDragFn={(e) => onDragStart(e, table)}
            onDragEndFn={onDragEnd}
            onSelectFn={() => setSelectedObject(table)}
            isSelected={selectedObjectId === table.id}
            onChangeFn={onChangeSize}
            key={table.id}
            type={table.type}
            ratioX={ratioX}
            ratioY={ratioY}
          />
        ))}
        {guideLines}
      </Layer>
    </Stage>
  );
};

export default CustomizableCanvas;
