import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

const selectSelf = (state: RootState) => state;

const selectDineIn = createSelector(selectSelf, (state) => state.dineIn);

export const getIsIterationPending = createSelector(selectDineIn, (dineIn) => dineIn.isIterationPending);

export const getSelectedDineInOrder = createSelector(selectDineIn, (dineIn) => dineIn.selectedDineInOrder);

export const getIsDineInOrder = createSelector(
  getSelectedDineInOrder,
  (selectedDineInOrder) => !!selectedDineInOrder,
);
