import React, { memo } from 'react';
import { Typography } from '@mui/material';
import buildClasses from './ProductCustomization.css';
import AddButton from './AddButton';

interface SummaryBarProps {
  customizationProductPrice: number | undefined;
  formatToDisplay: (price: number) => string;
  onAddClick: () => void;
}

const SummaryBar: React.FC<SummaryBarProps> = memo(
  ({ customizationProductPrice, formatToDisplay, onAddClick }) => {
    const { classes } = buildClasses();
    const disabled = customizationProductPrice === undefined;

    return (
      <div className={classes.summaryBar}>
        {customizationProductPrice && (
          <Typography
            className={classes.priceField}
            variant="h5"
            align="center"
            data-testid="product-customization__label--calculated-price"
          >
            {formatToDisplay(customizationProductPrice)}
          </Typography>
        )}
        <AddButton onClick={onAddClick} disabled={disabled} />
      </div>
    );
  },
);

SummaryBar.displayName = 'SummaryBar';

export default SummaryBar;
