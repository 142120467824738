import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FloorEntity } from 'typings/Tables';
import buildClasses from './FloorsEditTabs.css';

interface FloorsEditTabsProps {
  onSelect(id: FloorEntity['id']): void;
  onAddFloor(): void;
  floorEntities: FloorEntity[];
  selectedFloorId: FloorEntity['id'];
  showAddButton: boolean;
  selectedFloorName: string;
}

const FloorsEditTabs = ({
  onSelect,
  onAddFloor,
  floorEntities,
  selectedFloorId,
  showAddButton,
  selectedFloorName,
}: FloorsEditTabsProps) => {
  const { classes, cx } = buildClasses();
  const { spacing, palette } = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      {floorEntities?.map((floor) => (
        <div
          className={cx(classes.tab, { [classes.tabActive]: floor.id === selectedFloorId })}
          onClick={() => onSelect(floor.id)}
          key={floor.id}
        >
          <Typography
            variant="subtitle1"
            className={cx({ [classes.activeFloorText]: floor.id === selectedFloorId })}
          >
            {floor.id === selectedFloorId ? selectedFloorName : floor.name}
          </Typography>
        </div>
      ))}
      {showAddButton && (
        <IconButton
          sx={{
            marginBottom: spacing(1),
            borderRadius: spacing(0.5),
            padding: spacing(1.5),
            backgroundColor: palette.customGray.main,
            color: palette.black.main,
          }}
          onClick={onAddFloor}
        >
          <AddIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default FloorsEditTabs;
