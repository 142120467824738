import React, { useCallback } from 'react';
import { Box, ButtonBase, Popover, Typography, useTheme } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import { useAppSelector } from 'hooks/useRedux';
import { authSelector } from 'stores/Auth/auth.selectors';
import { getAppVersion, getFiscalizationConfiguration } from 'stores/Config/config.selector';
import { getSelectedStore } from 'stores/Store/store.selectors';

const AppVersion: React.FC = () => {
  const { palette, spacing } = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const selectedStore = useAppSelector(getSelectedStore);
  const { onsiteMachine } = useAppSelector(authSelector);
  const terminalNumber = onsiteMachine?.touchpointId?.slice(-12);

  const fiscalizationConfiguration = useAppSelector(getFiscalizationConfiguration);
  const appVersion = useAppSelector(getAppVersion);
  const hasVersion = !!appVersion?.baseVersion;

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((): void => {
    setAnchorEl(undefined);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {hasVersion && (
        <>
          <ButtonBase sx={{ alignItems: 'center', display: 'flex', padding: spacing(1) }} onClick={handleClick}>
            <CodeIcon />
            <Typography color="textPrimary" variant="subtitle2">
              {appVersion.baseVersion}
            </Typography>
          </ButtonBase>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ padding: spacing(3), background: palette.primary.main }}>
              <Typography color="textSecondary" variant="subtitle2">
                {'App version: '}
                <b>{appVersion.baseVersion}</b>
              </Typography>
              {appVersion.gitSha && (
                <Typography color="textSecondary" variant="subtitle2">
                  {'sha: '}
                  <b>{appVersion.gitSha}</b>
                </Typography>
              )}
            </Box>
          </Popover>
        </>
      )}
      {fiscalizationConfiguration?.fiscalProvider === 'FDM' && fiscalizationConfiguration?.isActive && (
        <>
          <Typography color="textPrimary" variant="subtitle2">
            {'POS: '}
            {selectedStore?.posSerialNumber}
          </Typography>
          {terminalNumber && (
            <Typography color="textPrimary" variant="subtitle2">
              {'Terminal: '}
              {terminalNumber}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AppVersion;
