import { useCallback, useEffect } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { automaticCashierLogOut } from 'stores/Cashier';
import posApi, { endpointIsCashierAuthRelevant } from 'API/PosApi';
import { useAppDispatch } from 'hooks/useRedux';
import { posQueryOptions } from 'stores/base.queries';
import { appInsights } from './AppInitializationWrapper/useAppInsights';

export const useGlobalInterceptors = () => {
  const dispatch = useAppDispatch();

  const handleCashier401 = useCallback(
    (requestUrl?: string) => {
      if (!requestUrl) {
        return;
      }
      const isCashierRelevantEndpoint = endpointIsCashierAuthRelevant(requestUrl);
      if (!isCashierRelevantEndpoint) {
        return;
      }
      dispatch(automaticCashierLogOut());
      appInsights?.trackEvent({
        name: `[AutoSignout][InactivityGuard] User signed out due to 401 returned from api`,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    const interceptor = posApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === HttpStatusCode.Unauthorized) {
          handleCashier401(error.config?.url);
        }
        return error;
      },
    );

    posQueryOptions.errorHandler = (error, request) => {
      if (error.status === HttpStatusCode.Unauthorized) {
        handleCashier401(request?.url);
      }
    };

    return () => {
      posApi.interceptors.response.eject(interceptor);
    };
  }, [dispatch, handleCashier401]);
};
