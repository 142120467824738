import React from 'react';
import { ToggleButton, useTheme } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { setIsEatIn } from 'stores/Intake';
import { getIsEatInAvailable, getIsTakeAwayAvailable } from 'stores/Intake/intake.selector';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { PickUpIcon, TakeAwayIcon } from 'assets/icons';
import buildClasses from './TakeAwayTypeSwitch.css';

enum TakeAwayTypeEnum {
  TakeAway = 'Take away',
  InStore = 'In store',
}

const EatInSwitch: React.FC = () => {
  const { classes } = buildClasses();
  const { palette } = useTheme();
  const [t] = useTranslation('intake');
  const { isEatIn } = useAppSelector(({ intake }) => intake);
  const isTakeAwayAvailable = useAppSelector(getIsTakeAwayAvailable);
  const isEatInAwayAvailable = useAppSelector(getIsEatInAvailable);

  const dispatch = useAppDispatch();

  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, isEatIn: boolean) => {
    dispatch(setIsEatIn(isEatIn));
    dispatch(recalculateBasket({}));
  };

  return (
    <ToggleButtonGroup value={isEatIn} exclusive sx={{ width: '100%' }} onChange={handleAlignment}>
      {isTakeAwayAvailable && (
        <ToggleButton
          value={false}
          sx={{
            borderRadius: 0,
            width: '100%',
            '& $icon': {
              fill: palette.customGray.dark,
            },
          }}
          classes={{ selected: classes.activeToggleButton }}
          data-testid={`take-away-type__button--${TakeAwayTypeEnum.TakeAway}`}
        >
          <TakeAwayIcon className={classes.icon} />
          {t(TakeAwayTypeEnum.TakeAway)}
        </ToggleButton>
      )}
      {isEatInAwayAvailable && (
        <ToggleButton
          value
          sx={{
            borderRadius: 0,
            width: '100%',
            '& $icon': {
              fill: palette.customGray.dark,
            },
          }}
          classes={{ selected: classes.activeToggleButton }}
          data-testid={`take-away-type__button--${TakeAwayTypeEnum.InStore}`}
        >
          <PickUpIcon className={classes.icon} />
          {t(TakeAwayTypeEnum.InStore)}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

export default EatInSwitch;
