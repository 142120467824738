import React, { memo, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { useTranslation } from 'react-i18next';
import { getOrderPlacementStatus } from 'stores/Intake/intake.selector';
import { getOpenOrderPaymentInProgress } from 'stores/OrderPayment/orderPayment.selector';

export const OperationInProgress = memo(() => {
  const [t] = useTranslation('common');

  const orderPlacementStatus = useAppSelector(getOrderPlacementStatus);
  const openOrderPaymentInProgress = useAppSelector(getOpenOrderPaymentInProgress);
  const blockingOperationInProgress = openOrderPaymentInProgress || orderPlacementStatus === 'pending';

  const operationText = ((): string => {
    if (orderPlacementStatus === 'pending') return 'Placing order';
    if (openOrderPaymentInProgress) return 'Starting order payment';
    return 'Operation in progress';
  })();

  const onClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  return blockingOperationInProgress ? (
    <Box
      onClick={onClick}
      sx={{
        position: 'fixed',
        background: 'rgba(255,255,255,0.4)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <JumpingDotsLoader />
      <Typography variant="h5" sx={{ ml: 1 }}>
        {t(operationText)}
        {'...'}
      </Typography>
    </Box>
  ) : null;
});

OperationInProgress.displayName = 'OperationInProgress';
