import { Button, Tooltip, useTheme } from '@mui/material';
import { TwoWheelerOutlined } from '@mui/icons-material';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getActiveDeliveryType } from 'stores/Intake/intake.selector';
import { getBasketDataDeliveryCharge } from 'stores/Basket/basket.selector';
import { setManualDeliveryCharge } from 'stores/Basket/basket.slice';
import { numberFromMoney } from 'utils/payment/PaymentUtils';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { getPaymentConfig } from 'stores/Config/config.selector';
import DeliveryChargeModal from '../../DeliveryCharge/DeliveryCharge';
import { PickUpTypesValues } from '../../../IntakeConsts';

export const ChangeDeliveryChargeButton = memo(() => {
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();
  const { spacing } = useTheme();
  const activeDeliveryType = useAppSelector(getActiveDeliveryType);
  const deliveryChargeDetails = useAppSelector(getBasketDataDeliveryCharge);
  const paymentSettings = useAppSelector(getPaymentConfig);
  const [deliveryPriceModalOpen, setDeliveryPriceModalOpen] = useState(false);
  const openDeliveryPriceModal = useCallback(() => setDeliveryPriceModalOpen(true), []);
  const closeDeliveryPriceModal = useCallback(() => setDeliveryPriceModalOpen(false), []);

  const saveManualDeliveryCharge = useCallback(
    (value: string): void => {
      value && dispatch(setManualDeliveryCharge(numberFromMoney(value, paymentSettings)));
      dispatch(recalculateBasket({}));
    },
    [dispatch, paymentSettings],
  );

  return (
    <>
      <Tooltip enterDelay={500} title={t('Delivery price override').toString()} arrow>
        <span>
          <Button
            variant="iconButton"
            disabled={activeDeliveryType !== PickUpTypesValues.delivery}
            onClick={openDeliveryPriceModal}
            aria-label="deliveryCharge"
            data-testid="receipt-footer__button--change-delivery-charge"
          >
            <TwoWheelerOutlined sx={{ fontSize: spacing(4) }} />
          </Button>
        </span>
      </Tooltip>
      {deliveryPriceModalOpen && (
        <DeliveryChargeModal
          initialValue={deliveryChargeDetails?.grossPrice}
          open={deliveryPriceModalOpen}
          onClose={closeDeliveryPriceModal}
          onSuccess={saveManualDeliveryCharge}
        />
      )}
    </>
  );
});

ChangeDeliveryChargeButton.displayName = 'ChangeDeliveryChargeButton';
