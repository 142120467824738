import { Box, useTheme } from '@mui/material';
import React from 'react';
import buildClasses from './SettingGroup.css';

interface GroupProps {
  children: React.ReactNode;
  label: string;
}

const SettingGroup: React.FC<GroupProps> = ({ label, children }) => {
  const { classes } = buildClasses();
  const { spacing } = useTheme();

  return (
    <Box sx={{ marginBottom: spacing(4) }}>
      <h2 className={classes.label}>{label}</h2>
      {children}
    </Box>
  );
};

export default SettingGroup;
