import React, { useEffect, useMemo, useState } from 'react';
import { filterOptionsByActiveSubOption } from 'stores/Intake/IntakeStoreUtils';
import {
  mapSliceCustomizationsToXTastyModel,
  mapToSimplifiedToppingSelection,
  removeSubOptionWithoutOptions,
} from 'utils/intake/xTastyUtils';
import ProductSubOptions from 'components/Intake/Product/SubOptions/ProductSubOptions';
import ProductOptions from 'components/Intake/Product/Options/ProductOptions';
import ToppingsWrapper from 'components/Intake/XTasty/Toppings/ToppingsWrapper';
import {
  getAvailableProductOptions,
  getProductCustomization,
  prepareToppingsCounter,
} from 'utils/intake/productCustomizationUtils';
import { useAppSelector } from 'hooks/useRedux';
import { Box, Fade, useTheme } from '@mui/material';
import ProductOptionSelect from 'components/Intake/Product/ProductOptionSelect/ProductOptionSelect';
import { Customization, CustomizationProduct, ToppingGroup, ToppingSelectionSimplified } from 'typings/Products';
import { sortBy } from 'lodash';
import SingleSelectToppingWrapper from 'components/Intake/XTasty/Toppings/SingleSelectToppingWrapper';
import { getFeatureFlags, getToppingsCustomizationConfiguration } from 'stores/Config/config.selector';
import { getToppingCategories, getToppings } from 'stores/Products/products.selector';

interface CustomizeProductProps {
  customizationProduct: CustomizationProduct;
  triggerAddItem?: boolean;
  quantity: number;
  onItemRecalculate: (activeOptionId: number, productCustomization?: Customization) => void;
  onAddItem: (activeOptionId: number, productCustomization?: Customization) => void;
}

export const singleSelectCategories = ['RND', 'SAU']; // MOVE TO BACKEND
export const skipFromToppingWeightCalculationCategories = ['RND', 'SAU']; // MOVE TO BACKEND

const CustomizeProduct: React.FC<CustomizeProductProps> = ({
  customizationProduct,
  quantity,
  onItemRecalculate,
  onAddItem,
  triggerAddItem,
}) => {
  const { spacing } = useTheme();
  const [activeOptionId, setActiveOptionId] = useState<number>(customizationProduct.originalOptionId);
  const [activeSubOptionId, setActiveSubOptionId] = useState<number | null>(null); // only for pizzas
  const [selectedToppings, setSelectedToppings] = useState<ToppingSelectionSimplified[]>([]);
  const { activeDeliveryType } = useAppSelector((store) => store.intake);
  const { products, options, subOptions, activeCategoryCode } = useAppSelector((store) => store.products);
  const toppings = useAppSelector(getToppings);
  const toppingCategories = useAppSelector(getToppingCategories);

  const availableGroupedToppings = useMemo(() => {
    const availableToppings = toppings.filter(
      (tp) => !customizationProduct.baseProduct.forbiddenToppings.some((ft) => ft.toppingId === tp.id),
    );
    const groupedToppings = availableToppings.reduce<ToppingGroup[]>((acc, current) => {
      const categoryIndex = acc.findIndex((el) => el.code === current.toppingCategoryCode);
      if (categoryIndex > -1) acc[categoryIndex].toppings.push(current);
      else {
        const baseToppingCategory = toppingCategories.find((el) => el.code === current.toppingCategoryCode);
        acc.push({
          name: baseToppingCategory?.name ?? current.toppingCategoryCode,
          code: current.toppingCategoryCode,
          sortOrder: baseToppingCategory?.sortOrder ?? -1,
          toppings: [current],
          isSingleSelectGroup: singleSelectCategories.some(
            (singleSelectGroupCode) => singleSelectGroupCode === current.toppingCategoryCode.toUpperCase(),
          ),
        });
      }
      return acc;
    }, []);
    return getSortedToppingGroups(groupedToppings);
  }, [customizationProduct.baseProduct]);

  function getSortedToppingGroups(toppingGroups: ToppingGroup[]): ToppingGroup[] {
    const sortedToppingGroups: ToppingGroup[] = [];

    toppingGroups.forEach((toppingGroup) => {
      sortedToppingGroups.push({ ...toppingGroup, toppings: sortBy(toppingGroup.toppings, 'sortOrder') });
    });

    return sortBy(sortedToppingGroups, 'sortOrder');
  }

  const featureFlags = useAppSelector(getFeatureFlags);
  const toppingsCustomizationConfiguration = useAppSelector(getToppingsCustomizationConfiguration);

  const availableProductOptions = getAvailableProductOptions(customizationProduct, options);
  const availableProductSubOptions = removeSubOptionWithoutOptions(availableProductOptions, subOptions);

  function addItem(): void {
    onAddItem(activeOptionId, getProductCustomization(customizationProduct.baseProduct, selectedToppings));
  }
  function recalculateItem(): void {
    onItemRecalculate(activeOptionId, getProductCustomization(customizationProduct.baseProduct, selectedToppings));
  }

  useEffect(() => {
    const defaultSelectedToppings = mapToSimplifiedToppingSelection(
      availableGroupedToppings.flatMap((x) => {
        return x.toppings;
      }),
      customizationProduct.baseProduct.defaultToppings,
    );

    const predefinedModel =
      customizationProduct.sliceCustomizations &&
      mapSliceCustomizationsToXTastyModel(customizationProduct.sliceCustomizations, products, toppings);

    if (predefinedModel && predefinedModel[0]) {
      setSelectedToppings(predefinedModel?.at(0)?.selectedToppings ?? []);
    } else {
      setSelectedToppings(defaultSelectedToppings);
    }

    if (customizationProduct.originalOptionId) {
      const originalOption = availableProductOptions.find((el) => el.id === customizationProduct.originalOptionId);
      if (originalOption) {
        setActiveSubOptionId(originalOption.optionSubTypeId);
        setActiveOptionId(originalOption.id);
      }
    }
  }, [customizationProduct]);

  useEffect(() => {
    recalculateItem();
  }, [quantity, activeOptionId, activeDeliveryType]);

  useEffect(() => {
    if (triggerAddItem) {
      addItem();
    }
  }, [triggerAddItem]);

  const toppingsCounter = useMemo(() => {
    if (featureFlags?.OfflineModule_RespectMaxToppingsSettings && toppingsCustomizationConfiguration) {
      return prepareToppingsCounter(
        customizationProduct.baseProduct,
        selectedToppings,
        toppings,
        toppingsCustomizationConfiguration,
      );
    }
    return undefined;
  }, [selectedToppings, featureFlags?.OfflineModule_RespectMaxToppingsSettings]);

  function handleToppingClicked(selToppings: ToppingSelectionSimplified[]): void {
    onItemRecalculate(activeOptionId, getProductCustomization(customizationProduct.baseProduct, selToppings));
    setSelectedToppings(selToppings);
  }

  const renderContent = availableGroupedToppings.length > 0 && !!toppingsCounter && !!handleToppingClicked;

  if (!renderContent) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      {availableProductOptions.length > 1 && !customizationProduct.baseProduct.canChangeToppings && (
        <ProductOptionSelect
          customizationProduct={customizationProduct}
          preselectedProductId={customizationProduct.baseProduct.id}
          preselectedOptionId={customizationProduct.originalOptionId}
          onChange={setActiveOptionId}
        />
      )}
      {customizationProduct.baseProduct.canChangeToppings && (
        <>
          <Box sx={{ paddingBottom: spacing(4) }}>
            {subOptions && availableProductOptions && (
              <>
                <ProductSubOptions
                  subOptions={availableProductSubOptions}
                  activeSubOptionId={activeSubOptionId}
                  onSubOptionChange={setActiveSubOptionId}
                />

                <ProductOptions
                  options={filterOptionsByActiveSubOption(availableProductOptions, activeSubOptionId)}
                  activeOptionId={activeOptionId}
                  onOptionChange={setActiveOptionId}
                  activeCategoryCode={activeCategoryCode}
                />
              </>
            )}
          </Box>
          <Box sx={{ paddingBottom: spacing(1) }}>
            <SingleSelectToppingWrapper
              selectedToppings={selectedToppings}
              singleSelectionToppingGroups={availableGroupedToppings.filter((el) => el.isSingleSelectGroup)}
              onToppingSelected={(selToppings: ToppingSelectionSimplified[]): void => {
                onItemRecalculate(
                  activeOptionId,
                  getProductCustomization(customizationProduct.baseProduct, selToppings),
                );
                setSelectedToppings(selToppings);
              }}
            />
          </Box>
          <Fade in unmountOnExit timeout={{ enter: 200 }}>
            <Box sx={{ padding: `0px 0px ${spacing(4)}`, marginBottom: spacing(0.5) }}>
              <ToppingsWrapper
                onToppingsChange={handleToppingClicked}
                availableGroupedToppings={availableGroupedToppings.filter((el) => !el.isSingleSelectGroup)}
                selectedToppings={selectedToppings}
                toppingsCounter={toppingsCounter}
              />
            </Box>
          </Fade>
        </>
      )}
    </Box>
  );
};
export default CustomizeProduct;
