import React from 'react';
import { Box, Collapse, IconButton, OutlinedInput, TextField, Typography, useTheme } from '@mui/material';
import { DuplicateObjectIcon, RotateLeftIcon, RotateRightIcon } from 'assets/icons';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';

interface MenuObjectSectionProps {
  show: boolean;
  onRotate(direction: 'left' | 'right'): void;
  copyObject(): void;
  deleteObject(): void;
  tableNumber: string;
  setTableNumber(val: string): void;
  rotation: number;
  setRotation(val: number): void;
  error?: 'REQUIRED' | 'DUPLICATE';
}

const MenuObjectSection = ({
  show,
  onRotate,
  copyObject,
  deleteObject,
  tableNumber,
  setTableNumber,
  rotation,
  setRotation,
  error,
}: MenuObjectSectionProps) => {
  const { t } = useTranslation('intake');
  const { palette, spacing } = useTheme();
  const helperText = error && error === 'DUPLICATE' ? t('Duplicated table number') : t('Table number is required');

  function manualRotationOverride(val: string) {
    if (val.match(/[0-9]+\u00B0/)) {
      const rotationVal = Number(val.substring(0, val.length - 1));
      const rotation = Number.isNaN(rotationVal) ? 0 : Math.round(rotationVal);
      setRotation(rotation);
    } else if (val.match(/\u00B0/)) {
      setRotation(0);
    }
  }
  const buttonStyle = {
    borderRadius: spacing(0.5),
    padding: spacing(1),
    backgroundColor: palette.customGray.light,
    marginRight: spacing(1),
    color: palette.black.main,
  };
  const inputLabelStyle = { color: palette.black.main };

  return (
    <Collapse in={show}>
      <Box sx={{ padding: spacing(3), borderTop: '1px solid #ccc' }}>
        <Box
          sx={{
            display: 'flex',
            paddingBottom: spacing(2),
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" color="black" sx={{ color: palette.black.main }}>
            {t('Properties')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => onRotate('left')} sx={buttonStyle}>
              <RotateLeftIcon />
            </IconButton>
            <IconButton onClick={() => onRotate('right')} sx={buttonStyle}>
              <RotateRightIcon />
            </IconButton>
            <IconButton onClick={copyObject} sx={buttonStyle}>
              <DuplicateObjectIcon />
            </IconButton>
            <IconButton
              onClick={deleteObject}
              sx={{
                borderRadius: spacing(0.5),
                padding: spacing(1),
                backgroundColor: '#f4f4f4',
                color: palette.warning.main,
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ paddingRight: spacing(0.5) }}>
            <Typography variant="body2" sx={{ inputLabelStyle }}>
              {t('Table number')}
            </Typography>
            <TextField
              value={tableNumber}
              onChange={(e) => setTableNumber(e.target.value)}
              fullWidth
              error={!!error}
              helperText={helperText}
            />
          </Box>
          <Box sx={{ paddingLeft: spacing(0.5) }}>
            <Typography variant="body2" sx={{ inputLabelStyle }}>
              {t('Rotate')}
            </Typography>
            <OutlinedInput
              value={`${rotation}\u00B0`}
              onChange={(e) => manualRotationOverride(e.target.value)}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
    </Collapse>
  );
};

export default MenuObjectSection;
