import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useFormContext } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';

import PaymentFrame from 'components/Shared/OrderPayment/Shared/PaymentFrame';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { GiftCardActivationForm } from 'components/Shared/GiftCardActivation/GiftCardActivation';
import ManualCardNumber from 'components/Shared/GiftCardActivation/ManualCardNumber';
import { getCurrentGiftCardActivation } from 'stores/GiftCardActivation/giftCardActivation.selector';
import { useTheme } from '@mui/system';
import { activateGiftCard } from 'stores/GiftCardActivation/giftCardActivation.actions';
import { clearGiftCardActivation } from 'stores/GiftCardActivation/giftCardActivation.slice';
import MultipleCardsIndicator from 'components/Shared/GiftCardActivation/MultipleCardsIndicator';

export type GiftCardActivationStep = 'scanOrManual' | 'enterCardNumber' | 'success' | 'failure';

const EnterCardDetails: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  const { getValues, reset } = useFormContext<GiftCardActivationForm>();

  const currentGiftCard = useAppSelector(getCurrentGiftCardActivation);

  function confirmGiftCardNumber(): void {
    const { cardNumberInput } = getValues();
    dispatch(
      activateGiftCard({
        cardNumber: cardNumberInput,
        giftCardActivationId: currentGiftCard?.id as string,
        onSuccess: () => {
          reset({ cardNumberInput: '' });
        },
      }),
    );
  }
  function cancel(): void {
    dispatch(clearGiftCardActivation());
  }

  if (!currentGiftCard) return null;

  return (
    <PaymentFrame>
      <ManualCardNumber />
      <AnimatePresence initial={false}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <MultipleCardsIndicator />
            <Typography variant="h5" color="black.main">
              {t('Scan the QR code to activate and load:')}
            </Typography>
            <Typography
              variant="h2"
              color="black.main"
              sx={{ p: 2, background: palette.grey[50], borderRadius: spacing(1) }}
            >
              {formatToDisplay(currentGiftCard.amount)}
            </Typography>
            <Typography variant="h6" color="black.main">
              {t('Or enter the gift card number manually.')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Button
              data-testid="gift-card-activation--cancel"
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              onClick={cancel}
            >
              {t('Cancel')}
            </Button>
            <Button
              data-testid="gift-card-activation--confirm"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={confirmGiftCardNumber}
            >
              {t('Confirm')}
            </Button>
          </Box>
        </Box>
      </AnimatePresence>
    </PaymentFrame>
  );
};
export default EnterCardDetails;
