import React, { useState } from 'react';
import { Box, Button, MenuItem, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import InputUnit, { ValidationInput } from 'components/Shared/Inputs/InputUnit';

type ItemType = 'radio' | 'select' | 'inputUnit' | 'button' | 'custom';

export interface SelectOption {
  value: string;
  description: string;
}
interface ItemProps {
  children?: React.ReactNode;
  label: string;
  type: ItemType;
  defaultValue?: string | number;
  changeHandler?: (value: unknown) => void;
  onButtonClick?: () => void;
  buttonLabel?: string;
  selectOptions?: SelectOption[];
  validation?: ValidationInput;
  testId: string;
  name?: string;
}

const SettingItem: React.FC<ItemProps> = ({
  label,
  type,
  children,
  defaultValue,
  changeHandler,
  onButtonClick,
  buttonLabel,
  selectOptions,
  validation,
  testId,
  name,
}) => {
  const { spacing, palette } = useTheme();
  const [value, setValue] = useState<string | number>(defaultValue ?? '');
  const [t] = useTranslation('settings');

  function selectChangeHandler(event: SelectChangeEvent): void {
    setValue(event.target.value);
    changeHandler && changeHandler(event.target.value);
  }

  function determineChildren(): JSX.Element | React.ReactNode {
    switch (type) {
      case 'select': {
        return (
          <Select
            value={value.toString()}
            onChange={selectChangeHandler}
            variant="standard"
            fullWidth
            name={name}
            data-testid={testId}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
          >
            {selectOptions &&
              selectOptions.map((option) => (
                <MenuItem value={option.value} key={option.value} data-testid={`${testId}-option-${option.value}`}>
                  {t(option.description)}
                </MenuItem>
              ))}
          </Select>
        );
      }
      case 'inputUnit': {
        return <InputUnit inputValidation={validation} defaultValue={defaultValue} testId={testId} />;
      }
      case 'button': {
        return (
          <Button data-testid={testId} variant="contained" onClick={() => onButtonClick && onButtonClick()}>
            {buttonLabel}
          </Button>
        );
      }
      default: {
        return <div data-testid={testId}>{children}</div>;
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        justifyItems: 'end',
        marginBottom: spacing(1),
        borderBottom: '1px solid #cbcbcb',
        padding: spacing(1),
        fontSize: spacing(2),
        '&:hover': {
          backgroundColor: palette.customGray.light,
        },
        alignItems: 'center',
      }}
    >
      <Box sx={{ justifySelf: 'start' }}>{label}</Box>
      {determineChildren()}
    </Box>
  );
};

export default SettingItem;
