import React, { memo, useCallback, useState } from 'react';
import { ArrowBack, EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OutlinedButton } from 'components/Shared/Buttons';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { restartOrder } from 'stores/combined.actions';
import { getIsBasketEdited, getIsEditMode } from 'stores/Intake/intake.selector';
import { Box, Typography, useTheme } from '@mui/material';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { BasketRecalculated, HybridModeMessages } from 'typings/HybridMode';
import { useFormContext } from 'react-hook-form';
import {
  CheckoutDetailsForm,
  emptyDetailsForm,
} from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';

interface ReceiptControlsProps {
  editOrder: () => void;
  canEditOrder: boolean;
}

export const ReceiptControls: React.FC<ReceiptControlsProps> = memo(({ editOrder, canEditOrder }) => {
  const { spacing } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation('intake');
  const isEditMode = useAppSelector(getIsEditMode);
  const isBasketEdited = useAppSelector(getIsBasketEdited);

  const { reset } = useFormContext<CheckoutDetailsForm>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const closeConfirmModal = useCallback(() => setShowConfirmModal(false), []);
  const openConfirmModal = useCallback(() => setShowConfirmModal(true), []);

  const restartOrderHandler = useCallback((): void => {
    dispatch(restartOrder());
    reset(emptyDetailsForm);

    const hybridModeCommunicator = getHybridModeCommunicator();
    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.BasketRecalculated, {} as BasketRecalculated);

    isEditMode && navigate('/all-orders');
  }, [dispatch, isEditMode, navigate, reset]);

  const handleRestartClicked = useCallback((): void => {
    if (isEditMode && isBasketEdited) {
      openConfirmModal();
      return;
    }
    restartOrderHandler();
  }, [isBasketEdited, isEditMode, openConfirmModal, restartOrderHandler]);

  return (
    <>
      <Box sx={{ width: '100%', padding: spacing(2) }}>
        {canEditOrder && (
          <OutlinedButton
            icon={<ArrowBack />}
            onClickFn={handleRestartClicked}
            testId="receipt__button--restart-order"
            fullWidth
          >
            {t(isEditMode ? 'Cancel Order Edition' : 'Restart Order')}
          </OutlinedButton>
        )}
        {!canEditOrder && (
          <OutlinedButton
            icon={<EditOutlined />}
            onClickFn={editOrder}
            testId="receipt__button--edit-order"
            fullWidth
          >
            {t('Edit order')}
          </OutlinedButton>
        )}
      </Box>
      {showConfirmModal && (
        <ModalContainer
          maxWidth="sm"
          onClose={closeConfirmModal}
          open={showConfirmModal}
          title={t('Edit order')}
          primaryButton={{
            label: t('Cancel'),
            testId: 'restart-order-modal-confirm',
            action: restartOrderHandler,
          }}
          secondaryButton={{
            label: t('Continue editing'),
            testId: 'restart-order-modal-cancel',
            action: closeConfirmModal,
          }}
          testId="restart-order-modal"
        >
          <Typography variant="body1" textAlign="center" sx={{ p: 5 }}>
            {t('The edit of the order has not been saved.')}
          </Typography>
        </ModalContainer>
      )}
    </>
  );
});

ReceiptControls.displayName = 'ReceiptControls';
