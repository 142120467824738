import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';

interface DownloadReceiptDialogProps {
  showDialog: boolean;
  orderId: string;
  onClose: () => void;
}

const DownloadReceiptDialog: React.FC<DownloadReceiptDialogProps> = ({ orderId, onClose, showDialog }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [t] = useTranslation('orders');

  useEffect(() => {
    showDialog === true && handleDownloadReceipt();
  }, [showDialog]);

  function handleDownloadReceipt() {
    if (orderId === undefined) return;

    const url = `/Online/Print/DownloadCustomerOrderReceipt?publicId=${orderId}`;
    setShowLoader(true);
    setShowError(false);

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf', // Set the content type based on your file type
      },
    })
      .then((response) => {
        if (!response.ok) {
          setShowError(true);
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `Order - ${orderId}.pdf`;
        a.style.display = 'none';
        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
        setShowLoader(false);
        onClose();
      })
      .catch((error) => {
        setShowError(true);
      });
  }

  return showDialog ? (
    <ModalContainer
      primaryButtonOverride={!showError ? <div /> : undefined}
      onClose={onClose}
      title={t('Dowload order receipt')}
      open
      testId="text-field-modal"
      hideCloseIcon
      primaryButton={{
        label: t('Cancel'),
        testId: 'all-orders-modal-download-receipt__button--ok',
        type: 'submit',
        action: onClose,
      }}
    >
      <Box
        sx={{
          width: '100%',
          minHeight: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {showLoader && !showError && (
          <>
            <JumpingDotsLoader />
            <Typography sx={{ ml: 1 }} variant="h5">
              {t('Order receipt is downloading...')}
            </Typography>
          </>
        )}
        {showError && (
          <>
            <WarningAmberIcon color="error" />
            <Typography sx={{ ml: 1 }} variant="h5">
              {t('Problem with receipt download!')}
            </Typography>
          </>
        )}
      </Box>
    </ModalContainer>
  ) : null;
};

export default DownloadReceiptDialog;
