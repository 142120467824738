import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import {
  EftPaymentTerminalStatus,
  GetPaymentMethodsRequest,
  PaymentMethod,
  RefundPaymentAttemptResult,
} from 'typings/Payments';
import { isValidSuccessfulAxiosResponse } from 'typings/type-guards';
import { isEmpty, sortBy } from 'lodash';
import { SaveCashDrawerTransactionContext } from 'typings/Cashier';
import { getPaymentConfig } from '../Config/config.selector';
import { getSelectedStore } from '../Store/store.selectors';

export const sendCtmpCommand = createAsyncThunk<boolean, undefined, { state: RootState }>(
  '[PAYMENTS]/sendCtmpCommand',
  async (_, { getState }) => {
    const selectedStore = getSelectedStore(getState());
    const payment = getPaymentConfig(getState());

    const useV2Payment = payment?.v2.useInStores?.some((el) => el === selectedStore?.id);
    const response = await posApi.post(
      useV2Payment ? posApiUrls.EFT_PAYMENT_V2_CTMP : posApiUrls.EFT_PAYMENT_TERMINAL_CTMP,
    );
    if (isValidSuccessfulAxiosResponse(response)) {
      return true;
    }

    return false;
  },
);

export const getTerminalStatus = createAsyncThunk<
  EftPaymentTerminalStatus | undefined,
  undefined,
  { state: RootState }
>('[INTAKE]/getTerminalStatus', async (_, { getState }) => {
  const selectedStore = getSelectedStore(getState());

  const payment = getPaymentConfig(getState());

  const useV2Payment = payment?.v2.useInStores?.some((el) => el === selectedStore?.id);

  const result = await posApi.get<EftPaymentTerminalStatus>(
    useV2Payment ? posApiUrls.EFT_PAYMENT_V2_TERMINAL_STATUS : posApiUrls.EFT_PAYMENT_TERMINAL_STATUS,
  );
  if (!isValidSuccessfulAxiosResponse(result) || isEmpty(result.data)) {
    throw Error('Error on getting device status');
  }

  return result.data;
});

export const sendOpenCashDrawerCommand = createAsyncThunk<boolean, undefined, { state: RootState }>(
  '[PAYMENTS]/sendOpenCashDrawerCommand',
  async (_, { getState }) => {
    const selectedStore = getSelectedStore(getState());

    if (!selectedStore) {
      return false;
    }

    const response = await posApi.post(posApiUrls.CASH_DRAWER_OPEN);

    if (response.status === 200 || response.status === 204) {
      return true;
    }

    return false;
  },
);

export const saveCashDrawerTransaction = createAsyncThunk<
  void,
  {
    isCashIn: boolean;
    amount: number;
    reason: string;
    approvalSecret: string | undefined;
    successCallback: () => void;
  },
  { state: RootState }
>(
  '[PAYMENTS]/saveCashDrawerTransaction',
  async ({ isCashIn, amount, reason, approvalSecret, successCallback }) => {
    const request = {
      isCashIn,
      amount,
      reason,
      approvalSecret,
    } as SaveCashDrawerTransactionContext;

    const result = await posApi.post(posApiUrls.CASH_DRAWER_TRANSACTION, request);

    if (!isValidSuccessfulAxiosResponse(result)) {
      throw Error('Error on saving cash drawer transaction');
    }

    successCallback();
  },
);

export const getPaymentMethods = createAsyncThunk(
  '[PAYMENTS]/getPaymentMethods',
  async (params: GetPaymentMethodsRequest | undefined) => {
    const result = await posApi.get<PaymentMethod[]>(posApiUrls.PAYMENT_METHODS, { params });
    return sortBy(result.data, ['sortOrder', 'description']);
  },
);

export const refundPayment = createAsyncThunk<
  RefundPaymentAttemptResult,
  {
    orderId: string;
    successCallback: () => void;
  },
  { state: RootState }
>('[PAYMENTS]/refundPayment', async ({ orderId, successCallback }) => {
  if (!orderId) throw new Error('Cannot refund payment without order in context');

  const response = await posApi.put<RefundPaymentAttemptResult>(posApiUrls.PAYMENT_REFUND(orderId));

  if (response.status !== 200 || isEmpty(response.data)) {
    throw Error('Payment cannot be refunded, there is an api error');
  }

  successCallback();
  return response.data;
});
