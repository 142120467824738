import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { changeDeliveryType, setFinalizeTrigger, setTableTag } from 'stores/Intake';
import TableTagNumpadModal from 'components/Intake/Finalize/TakeAwayTypeSelector/TableTagNumpadModal';
import buildClasses from './Receipt.css';
import { ReceiptFooter } from './Footer/ReceiptFooter';
import { AvailableIntakeContents, IntakeStatuses, PickUpTypesValues } from '../IntakeConsts';
import { ReceiptPersistentAlert } from './ReceiptPersistentAlert/ReceiptPersistentAlert';
import { ReceiptBody } from './ReceiptBody';

interface ReceiptProps {
  intakeStatus: IntakeStatuses;
  canChangePaymentMethod: boolean;
  onFinishOrderButtonClick: () => void;
  canEdit?: boolean;
  onSearchCustomer?: (query: string) => void;
  onIntakeWithoutProfile?: () => void;
  onHideReceipt?: () => void;
}

export const Receipt: React.FC<ReceiptProps> = memo(
  ({
    intakeStatus,
    onFinishOrderButtonClick,
    canEdit,
    canChangePaymentMethod,
    onSearchCustomer,
    onIntakeWithoutProfile,
    onHideReceipt,
  }) => {
    const { classes } = buildClasses();
    const { activeDeliveryType, activeIntakeTab, onSiteSettings } = useAppSelector(({ intake }) => intake);
    const dispatch = useAppDispatch();
    const [isTableTagModalVisible, setIsTableTagModalVisible] = useState(false);

    useEffect(() => {
      dispatch(changeDeliveryType());
    }, []);

    const finalizeOrderHandler = useCallback((): void => {
      dispatch(setFinalizeTrigger(true));
    }, [dispatch]);

    const hideTableTagModal = useCallback(() => {
      setIsTableTagModalVisible(false);
    }, []);

    const showTableTagModal = useCallback(() => {
      setIsTableTagModalVisible(true);
    }, []);

    const onCtaClick =
      intakeStatus === IntakeStatuses.finalizeOrder
        ? finalizeOrderHandler
        : canChangePaymentMethod &&
            (activeDeliveryType === PickUpTypesValues.takeAway ||
              activeDeliveryType === PickUpTypesValues.dineIn) &&
            onSiteSettings?.isEatInPromptEnabledForToGo &&
            activeIntakeTab !== AvailableIntakeContents.Customer
          ? showTableTagModal
          : onFinishOrderButtonClick;

    const onTableTagSubmit = useCallback(
      (val: string): void => {
        dispatch(setTableTag(val));
        hideTableTagModal();
        onFinishOrderButtonClick();
      },
      [dispatch, hideTableTagModal, onFinishOrderButtonClick],
    );

    return (
      <>
        {isTableTagModalVisible && (
          <TableTagNumpadModal onClose={hideTableTagModal} onSubmit={onTableTagSubmit} isMandatory={false} />
        )}
        <div className={classes.receiptContainer}>
          <ReceiptPersistentAlert />
          <ReceiptBody
            intakeStatus={intakeStatus}
            canEdit={canEdit}
            onHideReceipt={onHideReceipt}
            onSearchCustomer={onSearchCustomer}
            onIntakeWithoutProfile={onIntakeWithoutProfile}
          />
          <ReceiptFooter
            intakeStatus={intakeStatus}
            canChangePaymentMethod={canChangePaymentMethod}
            canChangeDeliveryType={intakeStatus === IntakeStatuses.productsSection}
            onFinishOrderButtonClick={onCtaClick}
          />
        </div>
      </>
    );
  },
);

Receipt.displayName = 'Receipt';
