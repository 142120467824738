import React, { memo, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActiveProductCategory } from 'stores/Products';
import { Category } from 'typings/Products';
import { getBestSeller } from 'stores/Cms/cms.selector';
import { getActiveCategoryCode, getCategories } from 'stores/Products/products.selector';
import buildClasses from './ProductCategories.css';
import CategoryButton from './CategoryButton';

const ProductCategories: React.FC = memo(() => {
  const { classes } = buildClasses();

  const dispatch = useAppDispatch();
  const activeCategoryCode = useAppSelector(getActiveCategoryCode);
  const categories = useAppSelector(getCategories);
  const cmsImage = useAppSelector(getBestSeller);

  useEffect(() => {
    if (categories.length > 0) {
      if (!activeCategoryCode || !categories.find((cat) => cat.code === activeCategoryCode)) {
        dispatch(setActiveProductCategory(categories[0].code));
      }
    }
  });

  const handleCategoryClick = useCallback(
    (categoryCode: string) => {
      dispatch(setActiveProductCategory(categoryCode));
    },
    [dispatch],
  );

  return (
    <Box className={classes.container} data-testid="product-selection__container--categories">
      {categories.map((category: Category) => (
        <CategoryButton
          key={category.code}
          category={category}
          cmsImage={cmsImage}
          isActive={category.code === activeCategoryCode}
          onClick={handleCategoryClick}
        />
      ))}
    </Box>
  );
});

ProductCategories.displayName = 'ProductCategories';

export default ProductCategories;
