import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/Shared/Inputs/SearchInput';
import { Grid2 as Grid, IconButton, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CouponCard from 'components/Intake/Coupon/CouponCard/CouponCard';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import { processAddingCoupon, replaceIncompatibleCoupons } from 'stores/Basket/basket.thunk-actions';
import { filterCouponsBySearchString } from 'stores/Intake/IntakeStoreUtils';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Coupon } from 'typings/Coupons';
import PagedCoupons from 'containers/Intake/Coupons/PagedCoupons';
import { AvailableIntakeContents } from 'containers/Intake/IntakeConsts';
import InapplicableCouponModal from 'containers/Intake/Coupons/InapplicableCouponModal/InapplicableCouponModal';
import { getCouponsForCurrentBasket } from 'stores/Coupons';
import { ErrorBoundary, type ErrorBoundaryPropsWithRender } from 'react-error-boundary';
import { getUsePagination } from 'stores/Config/config.selector';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { getBasketCoupons, getBasketItems } from 'stores/Basket/basket.selector';
import { getCoupons } from 'stores/Products/products.selector';
import ErrorCoupon from './ErrorCoupon/ErrorCoupon';
import buildClasses from './Coupons.css';

const CouponCatalog: React.FC = memo(() => {
  const { classes } = buildClasses();
  const [searchString, setSearchString] = useState('');
  const [inapplicableCoupon, setInapplicableCoupon] = useState<Coupon>();
  const [t] = useTranslation('intake');

  const showManualCode = false; // dummy flag that will be deleted when we switch this functionality on

  const { activeDeliveryType, activeIntakeTab } = useAppSelector(({ intake }) => intake);
  const selectedStore = useAppSelector(getSelectedStore);
  const { couponsForCurrentBasket, pendingGetCouponsRequest: pendingCouponsRequest } = useAppSelector(
    (state) => state.coupons,
  );
  const basketItems = useAppSelector(getBasketItems);
  const basketCoupons = useAppSelector(getBasketCoupons);
  const usePagination = useAppSelector(getUsePagination);
  const coupons = useAppSelector(getCoupons);

  const dispatch = useAppDispatch();

  const incompatibleCoupons = useMemo(() => {
    return coupons.filter((cmd: Coupon) =>
      inapplicableCoupon?.notApplicableWithCouponCodes?.includes(cmd.couponCode),
    );
  }, [inapplicableCoupon]);

  useEffect(() => {
    activeIntakeTab === AvailableIntakeContents.Coupons && dispatch(getCouponsForCurrentBasket());
  }, [basketItems, basketCoupons, activeDeliveryType, selectedStore, activeIntakeTab]);

  function handleCouponCardClicked(coupon2add: Coupon): void {
    const incompatibleCouponCodes = coupon2add.notApplicableWithCouponCodes;
    if (incompatibleCouponCodes?.length > 0) {
      setInapplicableCoupon(coupon2add);
      return;
    }
    dispatch(processAddingCoupon(coupon2add));
  }

  const filteredCoupons = filterCouponsBySearchString(couponsForCurrentBasket, searchString);

  const applicableCoupons = couponsForCurrentBasket.slice(0, 0);

  const fallbackRender: ErrorBoundaryPropsWithRender['fallbackRender'] = useCallback(({ resetErrorBoundary }) => {
    return (
      <ErrorCoupon
        title={t('error with coupon')}
        description={t('sorry coupon invalid')}
        onClose={() => {
          setInapplicableCoupon(undefined);
          resetErrorBoundary();
        }}
      />
    );
  }, []);

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <div className={classes.wrapper}>
        {pendingCouponsRequest && (
          <div className={classes.loader}>
            <PartialLoader />
          </div>
        )}

        <div className={classes.header}>
          <SearchInput
            value={searchString}
            fullWidth
            placeholder={t('Search for coupons')}
            changeFunc={setSearchString}
          />
          {showManualCode && (
            <div className={classes.manualCode}>
              <TextField
                name="couponCode"
                fullWidth
                label={t('Manual coupon code')}
                variant="outlined"
                inputProps={{ 'data-testid': 'coupon-catalog__input--search-coupon' }}
              />
              <IconButton>
                <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
            </div>
          )}
        </div>
        {applicableCoupons.length > 0 && (
          <div className={classes.applicableCoupons}>
            <Typography variant="h5">{t('We found available coupons for you!')}</Typography>
            <Grid container spacing={2} className={classes.applicableCouponsGrid}>
              {applicableCoupons.slice(0, 4).map((coupon) => (
                <Grid size={{ sm: 12, md: 3 }} key={coupon.couponCode}>
                  <CouponCard coupon={coupon} />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {usePagination?.couponsPage ? (
          <PagedCoupons coupons={filteredCoupons} onCouponClick={handleCouponCardClicked} />
        ) : (
          <Grid container spacing={2}>
            {filteredCoupons.map((coupon) => (
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={coupon.couponCode}>
                <CouponCard coupon={coupon} onCouponSelected={handleCouponCardClicked} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <InapplicableCouponModal
        open={incompatibleCoupons.length > 0}
        incompatibleCoupons={incompatibleCoupons}
        onClose={() => {
          setInapplicableCoupon(undefined);
        }}
        onSuccess={() => {
          dispatch(
            replaceIncompatibleCoupons({
              couponCodes: incompatibleCoupons.map((ic) => ic.couponCode),
              coupon: couponsForCurrentBasket.find(
                (el) => el.couponCode === inapplicableCoupon?.couponCode,
              ) as Coupon,
            }),
          );
          setInapplicableCoupon(undefined);
        }}
      />
    </ErrorBoundary>
  );
});
CouponCatalog.displayName = 'CouponCatalog';

export default CouponCatalog;
