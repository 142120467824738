import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { PaymentConfiguration } from 'stores/Config';
import {
  formatDateTimeToDisplay,
  formatDateToDisplay,
  formatTimeToDisplay,
} from 'utils/localisation/dateTimeUtils';
import { numberFromMoney } from 'utils/payment/PaymentUtils';
import { getSelectedStore } from 'stores/Store/store.selectors';
import {
  getDefaultIanaTimeZone,
  getPaymentConfig,
  getQuickPaymentValuesConfig,
} from 'stores/Config/config.selector';
import { DefaultQuickCash } from 'components/Shared/OrderPayment/OrderPayment.consts';

const selectSelf = (state: RootState) => state;

const selectPayments = createSelector(selectSelf, (state) => state.payments);

export const getAvailablePaymentMethods = createSelector(
  selectPayments,
  (payments) => payments.availablePaymentMethods,
);

export const getPaymentFormatter = createSelector(
  getPaymentConfig,
  (paymentConfig) =>
    (value: number, useDecimalPlaces = true, showCurrencySign = true) => {
      let valueToBeUsed = value;
      if (!paymentConfig) throw new Error('Payment settings are not available');
      if (useDecimalPlaces === false) {
        valueToBeUsed = Number(value.toFixed(0));
      }

      if (showCurrencySign) {
        return formatValueToCurrencyMoney(valueToBeUsed, paymentConfig);
      }

      return formatValueToMoney(valueToBeUsed, paymentConfig);
    },
);

export const getStringToPaymentFormatter = createSelector(getPaymentConfig, (paymentConfig) => (value: string) => {
  if (!paymentConfig) throw new Error('Payment settings are not available');
  return formatStringToPayment(value, paymentConfig);
});

export const getStringToNumberFormatter = createSelector(getPaymentConfig, (paymentConfig) => (value: string) => {
  if (!paymentConfig) throw new Error('Payment settings are not available');
  return numberFromMoney(value, paymentConfig);
});

export const getDateFormatter = createSelector(
  [
    getPaymentConfig,
    getDefaultIanaTimeZone,
    getSelectedStore,

    (state: RootState, useStoreTimeZone = false) => useStoreTimeZone,
  ],
  (payment, defaultIanaTimeZone, selectedStore, useStoreTimeZone) => (value: Date) => {
    if (!value) throw new Error(`Date is incorrect ${value}`);
    if (!payment) throw new Error('Payment settings are not available');
    return formatDateToDisplay(
      value,
      payment.culture,
      useStoreTimeZone ? selectedStore?.ianaTimeZone : defaultIanaTimeZone,
    );
  },
);

export const getDateTimeFormatter = createSelector(
  [
    getPaymentConfig,
    getDefaultIanaTimeZone,
    getSelectedStore,

    (state: RootState, useStoreTimeZone = false) => useStoreTimeZone,
  ],
  (payment, defaultIanaTimeZone, selectedStore, useStoreTimeZone) => (value: Date) => {
    if (!value) throw new Error(`Date is incorrect ${value}`);
    if (!payment) throw new Error('Payment settings are not available');
    return formatDateTimeToDisplay(
      value,
      payment.culture,
      useStoreTimeZone ? selectedStore?.ianaTimeZone : defaultIanaTimeZone,
    );
  },
);

export const getTimeFormatter = createSelector(
  [
    getPaymentConfig,
    getDefaultIanaTimeZone,
    getSelectedStore,

    (state: RootState, useStoreTimeZone = false) => useStoreTimeZone,
  ],
  (payment, defaultIanaTimeZone, selectedStore, useStoreTimeZone) => (value: Date) => {
    if (!value) throw new Error(`Date is incorrect ${value}`);
    if (!payment) throw new Error('Payment settings are not available');
    return formatTimeToDisplay(
      value,
      payment.culture,
      useStoreTimeZone ? selectedStore?.ianaTimeZone : defaultIanaTimeZone,
    );
  },
);

function formatValueToCurrencyMoney(value: number, paymentSettings: PaymentConfiguration): string {
  const val = value !== undefined ? value : 0;
  if (paymentSettings.culture && paymentSettings.currencyISO) {
    return val.toLocaleString(paymentSettings.culture, {
      style: 'currency',
      currency: paymentSettings.currencyISO,
      minimumFractionDigits: paymentSettings.decimalPlacesForDisplay,
    });
  }
  return '';
}

function formatValueToMoney(value: number, paymentSettings: PaymentConfiguration): string {
  const val = value !== undefined ? value : 0;
  if (paymentSettings.culture && paymentSettings.currencyISO) {
    return val.toLocaleString(paymentSettings.culture, {
      minimumFractionDigits: paymentSettings.decimalPlacesForDisplay,
    });
  }
  return '';
}

function formatStringToPayment(stringValue: string, paymentSettings: PaymentConfiguration): string {
  if (stringValue.length === 1) {
    const decimalZeros = '0'.repeat(paymentSettings.decimalPlacesForEdition - 1);
    return `0${paymentSettings.decimalSeparator}${decimalZeros}${stringValue}`;
  }

  if (stringValue.indexOf(paymentSettings.decimalSeparator) !== -1) {
    // value already contains separator (0,037 or 0,307 3,007 etc)
    // do only shift left
    const val = stringValue.replace(paymentSettings.decimalSeparator, '');
    // now val = 0037 0307 3007
    // now should be :
    // 0,37 3,07 30,07
    const separatorPosition = val.length - paymentSettings.decimalPlacesForEdition;
    const beforeDecimalSeparator = val.slice(0, separatorPosition);
    const beforeSeparator = Number.parseInt(beforeDecimalSeparator, 10).toString();
    const afterDecimalSeparator = val.slice(separatorPosition);
    const valueWithSeparator = `${beforeSeparator}${paymentSettings.decimalSeparator}${afterDecimalSeparator}`;
    return valueWithSeparator;
  }
  return stringValue;
}

export const getDefaultPaymentQuickValues = createSelector(getQuickPaymentValuesConfig, (quickValues) => () => {
  return (
    quickValues?.paymentMethodQuickValues?.find((item) => item.code === 'Default')?.values ?? DefaultQuickCash
  );
});
