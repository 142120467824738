import React, { useEffect, useState } from 'react';
import { Box, Grid2 as Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  formatDenominationToDisplay,
  formatToEdit,
  isNumber,
  makePositiveValue,
  numberFromMoney,
} from 'utils/payment/PaymentUtils';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import InputUnit, { ValidationInput } from 'components/Shared/Inputs/InputUnit';
import BanknoteMiniature from 'components/Shared/Payment/BanknoteMiniature';
import { getDefaultPaymentQuickValues, getPaymentFormatter } from 'stores/Payments/payment.selector';
import CalculateChange from 'services/PaymentService';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentConfig } from 'stores/Config/config.selector';

interface PaymentDenominationsProps {
  orderTotal: number;
  inputValidator: ValidationInput;
  triggerValidation: (name?: string | string[]) => Promise<boolean>;
  onChangeAmountUpdated: (value: number | undefined) => void;
}

const PaymentDenominations: React.FC<PaymentDenominationsProps> = ({
  orderTotal,
  inputValidator,
  triggerValidation,
  onChangeAmountUpdated,
}) => {
  const { palette, spacing } = useTheme();

  const [selectedDenominations, setSelectedDenominations] = useState<number[]>([]);
  const [denominationsSum, setDenominationsSum] = useState<number>(orderTotal);
  const [calculatedChange, setCalculatedChange] = useState<number | null>(null);
  const [t] = useTranslation('intake');

  const paymentSettings = useAppSelector(getPaymentConfig);
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const defaultPaymentQuickValues = useAppSelector(getDefaultPaymentQuickValues);
  const denominations = defaultPaymentQuickValues();

  useEffect(() => {
    const currentVal = (document.getElementById('paymentDenominations_inputUnit') as HTMLInputElement).value;
    if (isNumber(currentVal, paymentSettings)) {
      CalculateChange(numberFromMoney(currentVal, paymentSettings), orderTotal).then((change) => {
        const changeValue = change as number;
        if (changeValue !== undefined) {
          setCalculatedChange(changeValue);
          onChangeAmountUpdated(changeValue);
        }
      });
    } else {
      setCalculatedChange(null);
      onChangeAmountUpdated(undefined);
    }
  }, [orderTotal, denominationsSum, selectedDenominations]);

  function recalculateAmount(): void {
    const newSum = selectedDenominations.reduce((a, b) => a + b, 0);
    setDenominationsSum(newSum);
    (document.getElementById('paymentDenominations_inputUnit') as HTMLInputElement).value = formatToEdit(
      newSum,
      paymentSettings,
    );
    triggerValidation(undefined);
  }

  function setNewSelectedDenominations(newValues: number[]): void {
    setSelectedDenominations([...newValues]);
    recalculateAmount();
  }

  function handleDenominationClick(value: number): void {
    selectedDenominations.push(value);
    setNewSelectedDenominations(selectedDenominations);
  }

  function handleDeleteBillClick(value: number): void {
    const index = selectedDenominations.indexOf(value);
    if (index > -1) {
      selectedDenominations.splice(index, 1);
      setNewSelectedDenominations(selectedDenominations);
    }
  }

  function handleInputChange(): void {
    setSelectedDenominations([]);
  }

  function renderDenominations(): JSX.Element[] {
    return denominations.map((denomination) => {
      return (
        <Grid size={{ xs: 12, sm: 3 }} key={denomination}>
          <RoundedButton
            width="80%"
            height="50px"
            secondary
            value={denomination}
            onClick={(event: React.MouseEvent, value?: string | number): void => {
              handleDenominationClick(Number(value));
            }}
            testId={`settle-payment__button--denomination-value-${denomination}`}
          >
            {formatDenominationToDisplay(denomination, paymentSettings)}
          </RoundedButton>
        </Grid>
      );
    });
  }

  function renderBillsPart(): JSX.Element {
    return (
      <>
        <Box sx={{ minWidth: '200px' }}>{t('Bills given', { sum: formatToDisplay(denominationsSum) })}</Box>
        <div>
          {selectedDenominations.map((denomination) => (
            <BanknoteMiniature
              onClick={(e: React.MouseEvent, v: number): void => handleDeleteBillClick(v)}
              value={denomination}
              key={`${denomination}`}
            />
          ))}
        </div>
      </>
    );
  }

  const orderTotalMessage = t('orderTotalMessage', {
    total: orderTotal && formatToDisplay(orderTotal),
  });

  return (
    <Box sx={{ background: `${palette.secondary.light}`, width: '40vw' }}>
      <div>
        <Typography variant="subtitle1" gutterBottom data-testid="settle-payment__label--order-total">
          {orderTotalMessage}
        </Typography>
        <InputUnit
          inputValidation={inputValidator}
          onChange={(): void => handleInputChange()}
          fullWidth
          id="paymentDenominations_inputUnit"
          defaultValue={formatToEdit(denominationsSum, paymentSettings)}
          testId="settle-payment__input--amount-given-by-customer"
        />
        <Box sx={{ minHeight: '1.25rem', marginTop: spacing(2), display: 'flex', alignItems: 'center' }}>
          {selectedDenominations.length > 0 && renderBillsPart()}
        </Box>
      </div>
      <Grid container spacing={2} sx={{ marginTop: spacing(0.5) }}>
        {renderDenominations()}
      </Grid>
      {calculatedChange !== null && calculatedChange > 0 && (
        <Typography
          sx={{ marginTop: spacing(3) }}
          variant="h4"
          gutterBottom
          align="center"
          data-testid="settle-payment__label--change"
        >
          {t('Change')}
          <Box
            component="span"
            sx={{ color: `${palette.primary.main}` }}
            data-testid="settle-payment__label--change-value"
          >
            {formatToDisplay(makePositiveValue(calculatedChange))}
          </Box>
        </Typography>
      )}
    </Box>
  );
};

export default PaymentDenominations;
