import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AppMenu } from 'App/AppMenu/AppMenu';
import { ApplicationBar } from 'App/ApplicationBar/ApplicationBar';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Messages from 'typings/Messaging';
import { updateConnectivityStatus } from 'stores/Config';
import { PaymentSuccessfulResult } from 'typings/Payments';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { clearSettlePayment, getPaymentMethods } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { clearEditRequestStatus, initOrderEdition } from 'stores/Intake';
import { initEmbeddedModeOrderEdition } from 'stores/EmbeddedMode/embeddedMode.thunk-actions';
import { clearEmbeddedModeOrderEdit } from 'stores/EmbeddedMode';
import { getShowGiftCardModal } from 'stores/GiftCardActivation/giftCardActivation.selector';
import GiftCardActivation from 'components/Shared/GiftCardActivation/GiftCardActivation';
import { getInstanceType } from 'stores/Config/config.selector';
import { useGetSelectedStoreQuery, useGetSwitchStoresQuery } from 'stores/Store/store.api';
import { useGetConfigQuery } from 'stores/Config/config.api';
import { PhoneCallHandler } from 'components/Intake/Phonecall/PhoneCallHandler';
import { ClockInConfirmationModal } from 'components/Shared/Modal/ClockInConfirmationModal';
import { getEditRequestStatus } from 'stores/Intake/intake.selector';
import ProcessingComponent from 'components/Shared/Loaders/ProcessingComponent';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { RoutePath } from 'App/Routes/routes-paths';
import { getLoggedCashier } from 'stores/Cashier/cashier.selector';
import { InactivityGuard } from './components/InactivityGuard';
import { OperationInProgress } from './components/OperationInProgress';
import { useHeartbeat } from './useHeartbeat';
import { CashierRoutes } from './components/CashierRoutes';
import { PaymentModal } from './components/PaymentModal';
import { useFiscalizationEvents } from './useFiscalizationEvents';

const MainContainer: React.FC = () => {
  useFiscalizationEvents();
  useHeartbeat();
  const [open, setOpen] = React.useState(false);
  const [showConnectivityInfo, setShowConnectivityInfo] = useState<boolean>(false);
  const [callbackEvent, setCallbackEvent] = React.useState<MessageEvent | undefined>(undefined);

  const editRequestStatus = useAppSelector(getEditRequestStatus);
  const instanceType = useAppSelector(getInstanceType);

  const { embeddedModeOrderEdition } = useAppSelector((store) => store.embeddedMode);
  const { selectedOrderDetails } = useAppSelector(({ allOrders }) => allOrders);
  const showGiftCardActivation = useAppSelector(getShowGiftCardModal);

  const selectedStore = useAppSelector(getSelectedStore);
  const loggedCashier = useAppSelector(getLoggedCashier);

  const { onsiteMachine, onsiteMachineLoading } = useAppSelector((appState) => appState.authorization);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useGetSwitchStoresQuery(undefined, {
    refetchOnReconnect: true,
    skip: !instanceType,
  });

  const { isSuccess: storeLoaded, isFetching: storeFetching } = useGetSelectedStoreQuery(undefined, {
    refetchOnMountOrArgChange: true, // this is needed to make sure that the store slice is filled in after logout/login
    refetchOnReconnect: true,
  });

  // since we're using a slice for the store for ease of querying, we need to check if the store is ready as well
  const storeInfoReady = storeLoaded && !storeFetching && !!selectedStore;

  const { isSuccess: configLoaded } = useGetConfigQuery();

  const handleDrawerOpen = useCallback((): void => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback((): void => {
    setOpen(false);
  }, []);

  const closeSettlePaymentForm = useCallback((): void => {
    dispatch(clearSettlePayment());
  }, [dispatch]);

  const paymentSuccessfulHandler = useCallback(
    (result: PaymentSuccessfulResult): void => {
      if (callbackEvent?.source) {
        const totalPriceFromMessage = Number.parseFloat(
          callbackEvent?.data?.payload?.totalPrice?.replace(',', '.'),
        );
        callbackEvent.source.postMessage({
          type: Messages.Events.PaymentSucceeded,
          data: {
            orderId: callbackEvent?.data?.payload?.orderId,
            value: totalPriceFromMessage,
            method: result.paymentMethodCode,
            eftPaymentOperationId: result.eftPaymentOperation?.finishedManually
              ? undefined
              : result.eftPaymentOperation?.eftPaymentId,
            eftDeviceId: result.eftPaymentOperation?.deviceId,
          },
        });
      }
      closeSettlePaymentForm();
      setCallbackEvent(undefined);
    },
    [
      callbackEvent?.data?.payload?.orderId,
      callbackEvent?.data?.payload?.totalPrice,
      callbackEvent?.source,
      closeSettlePaymentForm,
    ],
  );

  const paymentModalClosedHandler = useCallback((): void => {
    if (callbackEvent?.source) {
      callbackEvent.source.postMessage({
        type: Messages.Events.PaymentModalClosed,
      });
    }
    closeSettlePaymentForm();
    setCallbackEvent(undefined);
  }, [callbackEvent?.source, closeSettlePaymentForm]);

  const handleStartPaymentCommand = useCallback((messageEvent: MessageEvent<any>): void => {
    setCallbackEvent(messageEvent);
  }, []);

  const handleChangeViewCommand = useCallback((messageEvent: MessageEvent<any>): void => {
    if (messageEvent.data.payload.viewType === 'intake') {
      window.location.hash = '#intake';
    }
  }, []);

  const handleEditOrderCommand = useCallback(
    (messageEvent: MessageEvent<any>): void => {
      dispatch(
        initEmbeddedModeOrderEdition({
          orderPublicId: messageEvent.data.payload.orderPublicId as string,
          isOrderPaid: messageEvent.data.payload.isOrderPaid as boolean,
        }),
      );
      navigate(RoutePath.Intake);
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    if (embeddedModeOrderEdition?.isDataReady && selectedOrderDetails) {
      dispatch(
        initOrderEdition({
          order: selectedOrderDetails,
          orderEditMode: embeddedModeOrderEdition.isOrderPaid ? 'editPaidOrder' : 'editFullOrder',
        }),
      );
    }
  }, [dispatch, embeddedModeOrderEdition, selectedOrderDetails]);

  useEffect(() => {
    if (editRequestStatus === 'failure' || editRequestStatus === 'success') {
      dispatch(clearEditRequestStatus());

      if (embeddedModeOrderEdition) {
        dispatch(clearEmbeddedModeOrderEdit());

        // Sometimes the changes are not yet there when user is redirected back to dispatcing screen.
        // Adding a 1 second delay should resolve most of such cases.
        setTimeout(() => {
          navigate('/dispatching');
        }, 1000);
      } else {
        navigate('/all-orders');
      }
    }
  }, [dispatch, editRequestStatus, embeddedModeOrderEdition, navigate]);

  useEffect(() => {
    if (callbackEvent?.data?.payload?.totalPrice) {
      dispatch(
        getPaymentMethods({
          pickupType: callbackEvent.data.payload.isDelivery
            ? PickUpTypesValues.delivery
            : PickUpTypesValues.pickUp,
        }),
      );
      const totalPriceFromMessage = Number.parseFloat(callbackEvent.data.payload.totalPrice.replace(',', '.'));
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId: callbackEvent.data.payload.publicId,
          totalToPay: totalPriceFromMessage,
          successCallback: paymentSuccessfulHandler,
          closeCallback: paymentModalClosedHandler,
          deliveryType: callbackEvent.data.payload.isDelivery
            ? PickUpTypesValues.delivery
            : PickUpTypesValues.pickUp,
          isLocalOrder: true,
        }),
      );
    }
  }, [callbackEvent, dispatch, paymentModalClosedHandler, paymentSuccessfulHandler]);

  useEffect(() => {
    const paymentEventsHandling = (event: WindowEventMap['message']) => {
      if (event.data.type === Messages.Commands.StartPayment) {
        handleStartPaymentCommand(event);
      } else if (event.data.type === Messages.Commands.ChangeView) {
        handleChangeViewCommand(event);
      } else if (event.data.type === Messages.Commands.EditOrder) {
        handleEditOrderCommand(event);
      }
    };
    window.addEventListener('message', paymentEventsHandling);
    return () => {
      window.removeEventListener('message', paymentEventsHandling);
    };
  }, [handleChangeViewCommand, handleEditOrderCommand, handleStartPaymentCommand]);

  useEffect(() => {
    if (instanceType) {
      if (instanceType === 'Central') {
        setShowConnectivityInfo(false);
        dispatch(updateConnectivityStatus(ConnectivityStatusEnum.Connected));
      } else {
        setShowConnectivityInfo(true);
        dispatch(updateConnectivityStatus(ConnectivityStatusEnum.Disconnected));
      }
    }
  }, [dispatch, instanceType, onsiteMachine, onsiteMachineLoading]);

  if (!configLoaded) {
    return <Navigate to={RoutePath.AppConfig} />;
  }

  if (!loggedCashier) {
    return <Navigate to={RoutePath.CashierLogin} />;
  }

  if (!storeInfoReady) {
    return <ProcessingComponent />;
  }

  return (
    <>
      <OperationInProgress />
      <ApplicationBar
        isMenuOpen={open}
        onMenuOpenClick={handleDrawerOpen}
        showConnectivityInfo={showConnectivityInfo}
      />
      <AppMenu open={open} onClose={handleDrawerClose} />
      <CashierRoutes />
      <PaymentModal />
      {showGiftCardActivation && <GiftCardActivation />}
      <PhoneCallHandler />
      <InactivityGuard />
      <ClockInConfirmationModal />
    </>
  );
};

export default MainContainer;
