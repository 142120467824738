import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/system';
import { Button, Popover, Typography } from '@mui/material';
import StoreOutlineIcon from '@mui/icons-material/StoreOutlined';
import DeliveryDiningOutlineIcon from '@mui/icons-material/DeliveryDiningOutlined';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { CashierPermission } from 'typings/Cashier';
import StrikeThroughContainer from 'components/Shared/Containers/StrikeThroughContainer';
import { getEtaAdjustmentConfiguration } from 'stores/Config/config.selector';
import { useGetEtaConfigurationQuery, useUpdateStoreEtaConfigurationMutation } from 'stores/Store/store.api';
import { getSelectedStoreId } from 'stores/Store/store.selectors';
import EtaAdjustmentChoices from './EtaAdjustmentChoices';

const pollingIntervalInSec = 60;

const getSortedEtaAdjustments = (values: number[], currentValue: number | undefined) => {
  const result = [...values];
  if (currentValue && !result.some((x) => x === currentValue)) {
    result.push(currentValue);
  }
  return result.sort((a, b) => a - b);
};

export const EtaInfo = memo((): JSX.Element => {
  const { spacing, palette } = useTheme();
  const [t] = useTranslation('common');
  const etaAdjustmentConfiguration = useAppSelector(getEtaAdjustmentConfiguration);
  const storeId = useAppSelector(getSelectedStoreId);
  const canUserAccess = useAppSelector(getPermissionChecker);

  const [pickupEta, setPickupEta] = useState<number | undefined>();
  const [deliveryEta, setDeliveryEta] = useState<number | undefined>();
  const [etaInfoAnchorEl, setEtaInfoAnchorEl] = useState<null | HTMLElement>(null);
  const [pickupAdjustments, setPickupAdjustments] = useState<number[]>([]);
  const [deliveryAdjustments, setDeliveryAdjustments] = useState<number[]>([]);

  const cashierCanChangeEta = canUserAccess(CashierPermission.EditEta);
  const open = Boolean(etaInfoAnchorEl);

  const { data: etaConfiguration } = useGetEtaConfigurationQuery(storeId ?? 0, {
    pollingInterval: pollingIntervalInSec * 1000,
    skipPollingIfUnfocused: true,
    refetchOnReconnect: true,
    skip: !storeId,
  });

  const [updateStoreEtaConfiguration] = useUpdateStoreEtaConfigurationMutation();

  const deliveryOrdersAllowed = etaConfiguration?.orderingState?.deliveryOrdersAllowed ?? false;
  const pickupOrdersAllowed = etaConfiguration?.orderingState?.pickupOrdersAllowed ?? false;
  const currentPickupEta = pickupOrdersAllowed ? etaConfiguration?.pickUpEtaMinutes : undefined;
  const currentDeliveryEta = deliveryOrdersAllowed ? etaConfiguration?.deliveryEtaMinutes : undefined;

  useEffect(() => {
    setPickupAdjustments(
      getSortedEtaAdjustments(etaAdjustmentConfiguration?.pickupEtaAdjustments ?? [], currentPickupEta),
    );
    setDeliveryAdjustments(
      getSortedEtaAdjustments(etaAdjustmentConfiguration?.deliveryEtaAdjustments ?? [], currentDeliveryEta),
    );
    setEtaInfoAnchorEl(null);
  }, [
    currentDeliveryEta,
    currentPickupEta,
    etaAdjustmentConfiguration?.deliveryEtaAdjustments,
    etaAdjustmentConfiguration?.pickupEtaAdjustments,
  ]);

  const onEtaInfoClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (cashierCanChangeEta) {
        setEtaInfoAnchorEl(event.currentTarget);
      }
    },
    [cashierCanChangeEta],
  );

  const onSave = useCallback(() => {
    if (storeId) {
      updateStoreEtaConfiguration({
        storeId,
        config: {
          pickUpEtaMinutes: pickupOrdersAllowed ? pickupEta : currentPickupEta,
          deliveryEtaMinutes: deliveryOrdersAllowed ? deliveryEta : currentDeliveryEta,
        },
      });
    }
  }, [
    currentDeliveryEta,
    currentPickupEta,
    deliveryEta,
    deliveryOrdersAllowed,
    pickupEta,
    pickupOrdersAllowed,
    storeId,
    updateStoreEtaConfiguration,
  ]);

  const onClose = useCallback(() => {
    setPickupEta(pickupOrdersAllowed ? currentPickupEta : undefined);
    setDeliveryEta(deliveryOrdersAllowed ? currentDeliveryEta : undefined);
    setEtaInfoAnchorEl(null);
  }, [currentDeliveryEta, currentPickupEta, deliveryOrdersAllowed, pickupOrdersAllowed]);

  return (
    <Box sx={{ borderRight: `1px solid ${palette.white}`, mr: 2, pr: 1 }}>
      <Box
        data-testid="eta-configuration-container"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: palette.white,
          py: cashierCanChangeEta ? 0.5 : 0,
          px: cashierCanChangeEta ? 1 : 0,
          background: cashierCanChangeEta ? 'rgba(255, 255, 255, 0.15)' : 'initial',
          borderRadius: spacing(0.5),
        }}
        onClick={onEtaInfoClick}
      >
        <Box
          sx={{
            pr: 1,
            borderRight: `1px solid ${
              cashierCanChangeEta ? (palette.appBar ? palette.appBar.main : palette.primary.main) : palette.white
            }`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {pickupOrdersAllowed ? (
            <>
              <StoreOutlineIcon />
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
              >
                {etaConfiguration?.pickUpEtaMinutes ?? 0} {t('Minutes')}
              </Typography>
            </>
          ) : (
            <>
              <StrikeThroughContainer>
                <StoreOutlineIcon />
              </StrikeThroughContainer>
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
                color={palette.warning.main}
              >
                {t('Off')}
              </Typography>
            </>
          )}
        </Box>
        <Box sx={{ pl: 1, display: 'flex', alignItems: 'center' }}>
          {deliveryOrdersAllowed ? (
            <>
              <DeliveryDiningOutlineIcon />
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
              >
                {etaConfiguration?.deliveryEtaMinutes ?? 0} {t('Minutes')}
              </Typography>
            </>
          ) : (
            <>
              <StrikeThroughContainer>
                <DeliveryDiningOutlineIcon />
              </StrikeThroughContainer>
              <Typography
                variant="body2"
                sx={{
                  py: 1.5,
                  px: 1,
                  textAlign: 'center',
                }}
                color={palette.warning.main}
              >
                {t('Off')}
              </Typography>
            </>
          )}
        </Box>
        {cashierCanChangeEta && !open && <ArrowDropDown sx={{ cursor: 'pointer' }} />}
        {cashierCanChangeEta && open && <ArrowDropUp sx={{ cursor: 'pointer' }} />}
      </Box>
      {cashierCanChangeEta && etaConfiguration && (
        <Popover
          open={open}
          onClose={onClose}
          anchorEl={etaInfoAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ mt: 1 }}
        >
          <Box
            sx={{
              px: 3,
              py: 3,
            }}
          >
            <EtaAdjustmentChoices
              title={t('Pick up ETA')}
              label={pickupOrdersAllowed ? null : t('Closed for pickup')}
              adjustments={pickupAdjustments}
              disabled={pickupOrdersAllowed !== true}
              selectedAdjustmentValue={pickupEta}
              onAdjustmentClick={setPickupEta}
            />
            <Box sx={{ mt: 3 }} />
            <EtaAdjustmentChoices
              title={t('Delivery ETA')}
              label={deliveryOrdersAllowed ? null : t('Closed for delivery')}
              adjustments={deliveryAdjustments}
              disabled={deliveryOrdersAllowed !== true}
              selectedAdjustmentValue={deliveryEta}
              onAdjustmentClick={setDeliveryEta}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-end',
                mt: 3,
              }}
            >
              <Button
                onClick={onClose}
                variant="contained"
                color="secondary"
                sx={{ py: 2, px: 3, mr: 1, color: palette.customGray.dark }}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={onSave}
                disabled={!deliveryOrdersAllowed && !pickupOrdersAllowed}
                color={!deliveryOrdersAllowed && !pickupOrdersAllowed ? 'secondary' : undefined}
              >
                {t('Save')}
              </Button>
            </Box>
          </Box>
        </Popover>
      )}
    </Box>
  );
});

EtaInfo.displayName = 'EtaInfo';
