import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';

export const FormTextField = (props: TextFieldProps & UseControllerProps<any>) => {
  const {
    id,
    fullWidth,
    label,
    variant,
    inputProps,
    style,
    multiline,
    rows,
    sx,
    placeholder,
    autoFocus,
    onClick,
    onBlur,
    onChange,
    ...controllerProps
  } = props;
  return (
    <Controller
      {...controllerProps}
      render={(renderProps) => {
        const {
          field: { ref: formRef, onBlur: formBlur, onChange: formChange, ...fieldProps },
          fieldState: { error },
        } = renderProps;
        return (
          <TextField
            inputRef={formRef}
            onClick={onClick}
            onChange={(e) => {
              formChange && formChange(e);
              onChange && onChange(e);
            }}
            onBlur={(e) => {
              formBlur && formBlur();
              onBlur && onBlur(e);
            }}
            error={!!error}
            helperText={error ? error.message : null}
            id={id}
            fullWidth={fullWidth}
            label={label}
            variant={variant}
            inputProps={inputProps}
            style={style}
            multiline={multiline}
            rows={rows}
            sx={sx}
            placeholder={placeholder}
            autoFocus={autoFocus}
            {...fieldProps}
          />
        );
      }}
    />
  );
};
