import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Coupon } from 'typings/Coupons';
import { BasketItem } from 'typings/Basket';

export interface InvalidBasketModalProps {
  open: boolean;
  invalidCoupons?: Coupon[];
  invalidProducts?: BasketItem[];
  onCancel: () => void;
  onConfirm: (inputValue?: string) => void;
  allProductsInvalid: boolean;
}

const InvalidBasketModal: React.FC<InvalidBasketModalProps> = ({
  onCancel,
  open,
  onConfirm,
  invalidCoupons,
  invalidProducts,
  allProductsInvalid,
}) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');

  return (
    <ModalContainer
      onClose={onCancel}
      open={open}
      title={t('Invalid items found')}
      primaryButton={{
        label: allProductsInvalid ? t('OK') : t('common:Proceed'),
        testId: 'invalid-basket-proceed',
        action: allProductsInvalid ? onCancel : onConfirm,
      }}
      secondaryButton={
        allProductsInvalid
          ? undefined
          : {
              label: t('Cancel'),
              testId: 'invalid-basket-cancel',
              action: onCancel,
            }
      }
      testId="invalid-basket-modal"
    >
      <Typography variant="body1" textAlign="center" sx={{ padding: `${spacing(3)}` }}>
        {t('Basket cannot be recalculated due some invalid items :')}
        <br />
        <b>
          {invalidProducts?.map((ip) => (
            <>
              {ip.itemName}
              <br />
            </>
          ))}
          {invalidCoupons?.map((ic) => (
            <>
              {ic.description}
              <br />
            </>
          ))}
        </b>
        {!allProductsInvalid && t('Would you like to continue without listed items?')}
      </Typography>
    </ModalContainer>
  );
};

export default InvalidBasketModal;
