import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'hooks/useRedux';
import InputUnit, { ValidationInput } from 'components/Shared/Inputs/InputUnit';
import { getPaymentConfig } from 'stores/Config/config.selector';
import Validators from 'utils/validation/ValidationConstants';
import { getStringToNumberFormatter } from 'stores/Payments/payment.selector';

interface Transaction {
  inputValue: number;
  reason: string;
}

interface CashDrawerTransactionDialogProps {
  open: boolean;
  abort: () => void;
  onConfirm: (isCashIn: boolean, amount: number, reason: string) => void;
}

const formId = 'add-transaction';

const CashDrawerTransactionDialog: React.FC<CashDrawerTransactionDialogProps> = ({ open, abort, onConfirm }) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');

  const paymentSettings = useAppSelector(getPaymentConfig);
  const formatStringToNumber = useAppSelector(getStringToNumberFormatter);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<Transaction>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const [isCashIn, setIsCashIn] = useState(true);

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCashIn((event.target as HTMLInputElement).value === 'true');
  };

  function onSubmit(data: Transaction): void {
    onConfirm(isCashIn, data.inputValue, data.reason);
  }

  const inputAmountValidator: ValidationInput = {
    errors,
    formRef: register,
    required: true,
    validators: {
      isMoney: (val: string): boolean | string =>
        Validators.Money.Validator(paymentSettings).test(val) || Validators.Money.Message,
      isAboveValue: (val: string): boolean | string =>
        Validators.IsAboveGivenValue.Validator(formatStringToNumber(val), 0) || t('Value must be greater than 0'),
    },
  };

  const { ref, ...rest } = register('reason', {
    required: t('Reason is required'),
    maxLength: { value: 250, message: `${t('Value is too long')} (${t('intake:max.')} ${250})` },
  });

  return (
    <ModalContainer
      onClose={abort}
      title={t('Cash-In and Cash-Out')}
      open={open}
      primaryButton={{
        type: 'submit',
        form: formId,
        label: t('Confirm'),
        testId: 'cash-drawer-transaction-modal__button--ok',
      }}
      secondaryButton={{
        action: abort,
        testId: 'cash-drawer-transaction-modal__button--cancel',
        label: t('Cancel'),
      }}
      testId="cash-drawer-transaction-modal"
    >
      <form name={formId} id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ px: `${spacing(3)}`, mb: 2 }}>
          <Typography variant="body1" gutterBottom>
            {t('Transaction details:')}
          </Typography>

          <RadioGroup
            data-testid="cash-drawer-transaction-modal__input--is-cash-in"
            row
            name="isCashIn"
            value={isCashIn.toString()}
            onChange={onTypeChange}
          >
            <FormControlLabel value="true" control={<Radio />} label={t('Cash in')} />
            <FormControlLabel value="false" control={<Radio />} label={t('Cash out')} />
          </RadioGroup>

          <Box sx={{ mt: 2 }}>
            <InputUnit
              inputValidation={inputAmountValidator}
              testId="cash-drawer-transaction-modal__input--amount"
              placeholder={t('Enter amount *')}
              name="amount"
            />
          </Box>

          <TextField
            inputRef={ref}
            {...rest}
            required
            fullWidth
            error={!!errors.reason}
            helperText={`${errors.reason?.message || ''}`}
            placeholder={t('Transaction reason *')}
            type="text"
            variant="outlined"
            inputProps={{
              maxLength: 250,
            }}
            sx={{ mt: 2 }}
          />
        </Box>
      </form>
    </ModalContainer>
  );
};

export default CashDrawerTransactionDialog;
