import { Button, Tooltip } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OpenDrawer } from 'assets/icons';
import { saveCashDrawerTransaction, sendOpenCashDrawerCommand } from 'stores/Payments';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getFeatureFlags, getEnableManualCashDrawerOperation  } from 'stores/Config/config.selector';
import { CashierPermission } from 'typings/Cashier';
import { validateCashDrawerPermission } from 'stores/Cashier';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import CashDrawerTransactionDialog from 'components/Intake/Finalize/CashDrawerTransactionDialog/CashDrawerTransactionDialog';
import CashDrawerAuth from '../../CashDrawerAuth/CashDrawerAuth';

export const OpenCashDrawerButton = memo(() => {
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();
  const featureFlags = useAppSelector(getFeatureFlags);
  const canUserAccess = useAppSelector(getPermissionChecker);
  const enableManualCashDrawerOperation = useAppSelector(getEnableManualCashDrawerOperation);

  const [isCashDrawerTransactionDialog, setCashDrawerTransactionDialog] = useState(false);
  const [isCashDrawerAuthDialog, setCashDrawerAuthDialog] = useState(false);
  const [cashDrawerAuthSecret, setCashDrawerAuthSecret] = useState<string>();

  const cashierCanAddTransactions = canUserAccess(CashierPermission.AddCashDrawerTransaction);

  const openCashDrawerTransactionDialog = useCallback(() => setCashDrawerTransactionDialog(true), []);
  const closeCashDrawerTransactionDialog = useCallback(() => setCashDrawerTransactionDialog(false), []);

  const openCashDrawerAuthDialog = useCallback(() => setCashDrawerAuthDialog(true), []);
  const closeCashDrawerAuthDialog = useCallback(() => setCashDrawerAuthDialog(false), []);

  const validateCashDrawerAccess = useCallback(
    (pin: string): void => {
      setCashDrawerAuthSecret(pin);
      dispatch(
        validateCashDrawerPermission({
          secret: pin,
          successCallback: () => {
            closeCashDrawerAuthDialog();
            openCashDrawerTransactionDialog();
          },
        }),
      );
    },
    [dispatch, closeCashDrawerAuthDialog, openCashDrawerTransactionDialog],
  );

  const handleOpenCashDrawerDialog = useCallback(() => {
    if (cashierCanAddTransactions) {
      openCashDrawerTransactionDialog();
    } else {
      openCashDrawerAuthDialog();
    }
  }, [cashierCanAddTransactions, openCashDrawerAuthDialog, openCashDrawerTransactionDialog]);

  const openCashDrawer = useCallback(() => {
    if (featureFlags && featureFlags.OfflineModule_UseCashDrawer) {
      dispatch(sendOpenCashDrawerCommand());
    }
  }, [featureFlags, sendOpenCashDrawerCommand]);

  const handleCashDrawerOpen = useCallback(() => {
    if (enableManualCashDrawerOperation) {
      handleOpenCashDrawerDialog();
    } else {
      openCashDrawer();
    }
  }, [enableManualCashDrawerOperation, handleOpenCashDrawerDialog, openCashDrawer]);

  const addCashDrawerTransaction = useCallback(
    (isCashIn: boolean, amount: number, reason: string): void => {
      dispatch(
        saveCashDrawerTransaction({
          isCashIn,
          amount,
          reason,
          approvalSecret: cashDrawerAuthSecret,
          successCallback: () => {
            setCashDrawerTransactionDialog(false);
            setCashDrawerAuthSecret(undefined);
            openCashDrawer();
          },
        }),
      );
    },
    [dispatch, cashDrawerAuthSecret, openCashDrawer],
  );

  return (
    <>
      <Tooltip enterDelay={500} title={t('Open cash drawer').toString()} arrow>
        <span>
          <Button
            variant="iconButton"
            aria-label="cashDrawerOpen"
            data-testid="receipt-footer__button--cash-drawer-open"
            onClick={handleCashDrawerOpen}
          >
            <OpenDrawer />
          </Button>
        </span>
      </Tooltip>
      {isCashDrawerTransactionDialog && (
        <CashDrawerTransactionDialog
          open={isCashDrawerTransactionDialog}
          abort={closeCashDrawerTransactionDialog}
          onConfirm={addCashDrawerTransaction}
        />
      )}
      {isCashDrawerAuthDialog && (
        <CashDrawerAuth open abort={closeCashDrawerAuthDialog} onConfirm={validateCashDrawerAccess} />
      )}
    </>
  );
});

OpenCashDrawerButton.displayName = 'OpenCashDrawerButton';
