/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from 'react';
import { useSwiper } from 'swiper/react';
import { Button, ButtonBase, Typography, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/system';

export interface ProductPagination {
  pageItems: any[][];
}

const SwiperPagination: React.FC<ProductPagination> = ({ pageItems }) => {
  const swiper = useSwiper();
  const { palette, spacing } = useTheme();
  if (pageItems.length < 2) return null;

  const remainingPagesToEnd = pageItems.length - swiper.realIndex;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <Button
        variant="outlined"
        sx={{
          minWidth: spacing(5),
          width: spacing(5),
          height: spacing(5),
        }}
        disabled={swiper.realIndex === 0}
        color="black"
        onClick={() => {
          swiper.slidePrev();
        }}
      >
        <ChevronLeftIcon />
      </Button>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {pageItems.length < 11 ? (
          pageItems.map((page, index) => {
            return (
              <ButtonBase
                // eslint-disable-next-line react/no-array-index-key
                key={index + 1}
                sx={{
                  px: 1.5,
                  py: 0.75,
                  borderRadius: 0.5,
                  color: 'black',
                  background: swiper.realIndex === index ? palette.customGray.main : 'transparent',
                  mx: 0.5,
                }}
                onClick={() => {
                  swiper.slideTo(index);
                }}
              >
                <Typography variant="body2">{index + 1}</Typography>
              </ButtonBase>
            );
          })
        ) : (
          <>
            {pageItems
              .slice(
                remainingPagesToEnd > 9 ? swiper.realIndex : pageItems.length - 10,
                (remainingPagesToEnd > 9 ? swiper.realIndex : pageItems.length - 10) + 5,
              )
              .map((page, index) => {
                const itemIndex = (remainingPagesToEnd > 9 ? swiper.realIndex : pageItems.length - 10) + index;
                return (
                  <ButtonBase
                    key={itemIndex + 1}
                    sx={{
                      px: 1.5,
                      py: 0.75,
                      borderRadius: 0.5,
                      color: 'black',
                      background: swiper.realIndex === itemIndex ? palette.customGray.main : 'transparent',
                      mx: 0.5,
                    }}
                    onClick={() => {
                      swiper.slideTo(itemIndex);
                    }}
                  >
                    <Typography variant="body2">{itemIndex + 1}</Typography>
                  </ButtonBase>
                );
              })}
            {remainingPagesToEnd > 10 && (
              <Typography variant="body2" sx={{ px: 1 }}>
                {'...'}
              </Typography>
            )}
            {pageItems.slice(pageItems.length - 5).map((page, index) => {
              const currentIndex = pageItems.length - 5 + index;
              return (
                <ButtonBase
                  key={currentIndex + 1}
                  sx={{
                    px: 1.5,
                    py: 0.75,
                    borderRadius: 0.5,
                    color: 'black',
                    background: swiper.realIndex === currentIndex ? palette.customGray.main : 'transparent',
                    mx: 0.5,
                  }}
                  onClick={() => {
                    swiper.slideTo(currentIndex);
                  }}
                >
                  <Typography variant="body2">{currentIndex + 1}</Typography>
                </ButtonBase>
              );
            })}
          </>
        )}
      </Box>
      <Button
        variant="outlined"
        sx={{
          minWidth: spacing(5),
          width: spacing(5),
          height: spacing(5),
        }}
        color="black"
        disabled={swiper.realIndex === pageItems.length - 1}
        onClick={() => {
          swiper.slideNext();
        }}
      >
        <ChevronRightIcon />
      </Button>
    </Box>
  );
};
export default SwiperPagination;
