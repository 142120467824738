import { TextField, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import QuantityButtonGroup from 'components/Shared/Buttons/QuantityButtonGroup';

import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSliceCustomizationsWithDisplayModel,
  removeSubOptionWithoutOptions,
} from 'utils/intake/xTastyUtils';
import { getAvailableProductOptions, isItemRemovedFromBasket } from 'utils/intake/productCustomizationUtils';
import { OutlinedButton } from 'components/Shared/Buttons';
import { mapCustomizationsToStepSelections } from 'utils/intake/productSetUtils';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Customization, CustomizationProduct, ProductCustomizationState } from 'typings/Products';
import { clearCustomizationProductPrice, getCustomizedItemPrice } from 'stores/Products';
import { addToBasket, replaceInBasket } from 'stores/Basket/basket.thunk-actions';
import { getPizzaCategoryCode } from 'stores/Config/config.selector';
import CustomizePizza from './CustomizeProduct/CustomizeProduct';
import XTastyContainer from './XTasty/XTasty';
import buildClasses from './ProductCustomization.css';
import ProductSet from './ProductSet/ProductSet';
import SummaryBar from './SummaryBar';

interface ProductCustomizationProps {
  productToCustomize: CustomizationProduct;
  onCancelClick: () => void;
  onAddItem?: (productToAdd: CustomizationProduct) => void;
}

const ProductCustomization: React.FC<ProductCustomizationProps> = ({
  onCancelClick,
  onAddItem,
  productToCustomize,
}) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const [quantity, setQuantity] = useState(1);
  const [minLimit, maxLimit] = [1, 999];
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);

  const [productEditionState, setProductEditionState] = useState<ProductCustomizationState>();
  const [productRemark, setProductRemark] = useState<string>();
  const [triggerAddItem, setTriggerAddItem] = useState<boolean>();
  const { basketItems } = useAppSelector((store) => store.basket);
  const { options, subOptions, categories, customizationProductPrice } = useAppSelector((state) => state.products);

  const dispatch = useAppDispatch();

  const availableProductOptions = getAvailableProductOptions(productToCustomize, options);
  const availableProductSubOptions = removeSubOptionWithoutOptions(availableProductOptions, subOptions);
  const pizzaCategory = categories.find((category) => category.code === pizzaCategoryCode);
  const pizzas = pizzaCategory && pizzaCategory.products;

  const arrowBack = useMemo(() => <ArrowBack />, []);

  useEffect(() => {
    setQuantity(productToCustomize.quantity);
    setProductRemark(productToCustomize.remark);
    if (productToCustomize.baseProduct.isXTasty) {
      setProductEditionState(ProductCustomizationState.XTasty);
    } else if (productToCustomize.baseProduct.isCYO) {
      setProductEditionState(ProductCustomizationState.CustomizeProduct);
    } else if (productToCustomize.baseProduct.hasToppingsSelectionSteps) {
      setProductEditionState(ProductCustomizationState.ProductSet);
    } else {
      setProductEditionState(ProductCustomizationState.CustomizeProduct);
    }
  }, [productToCustomize]);

  useEffect(() => {
    isItemRemovedFromBasket(basketItems, productToCustomize) && onCancelClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketItems]);

  function recalculateItemPrice(productCustomizations: Customization[], productOptionId: number): void {
    dispatch(
      getCustomizedItemPrice({
        productCustomizations,
        defaultToppings: productToCustomize.baseProduct.defaultToppings,
        quantity,
        productOptionId,
        productId: productToCustomize.baseProduct.id,
      }),
    );
  }

  function addProductToBasket(pizzaSlices: Customization[], activeOptionId: number): void {
    const stepsSelections = pizzaSlices.flatMap((ps) => ps.stepSelections ?? []);
    const productRemarkEmpty = !productRemark;

    if (productToCustomize.basketItemIndex !== undefined && productToCustomize.basketItemGuid !== undefined) {
      const activeOption = options.find((opt) => opt.id === activeOptionId);
      dispatch(
        replaceInBasket({
          id: productToCustomize.basketItemIndex,
          receiptId: productToCustomize.basketItemIndex,
          basketItemGuid: productToCustomize.basketItemGuid,
          itemId: productToCustomize.baseProduct.id,
          itemName: productToCustomize.baseProduct.title,
          optionName: activeOption?.name ?? '',
          optionId: activeOptionId,
          quantity,
          sliceCustomizations: createSliceCustomizationsWithDisplayModel(
            pizzaSlices,
            productToCustomize.baseProduct.hasToppingsSelectionSteps
              ? productToCustomize.baseProduct.defaultToppings
              : [],
          ),
          remark: productRemark,
          selectedSetSteps: stepsSelections,
          doNotGroup: !productRemarkEmpty,
        }),
      );
    } else {
      dispatch(
        addToBasket({
          itemId: productToCustomize.baseProduct.id,
          itemName: productToCustomize.baseProduct.title,
          optionId: activeOptionId,
          quantity,
          sliceCustomizations: createSliceCustomizationsWithDisplayModel(
            pizzaSlices,
            productToCustomize.baseProduct.hasToppingsSelectionSteps
              ? productToCustomize.baseProduct.defaultToppings
              : [],
          ),
          remark: productRemark,
          selectedSetSteps: stepsSelections,
        }),
      );
    }
    dispatch(clearCustomizationProductPrice());
    onCancelClick();
  }

  function handleOnAddItem(productCustomization: Customization[], activeOptionId: number): void {
    if (onAddItem) {
      // perform custom steps but not add to basket
      const customizedProductToAdd: CustomizationProduct = {
        ...productToCustomize,
        originalOptionId: activeOptionId,
        optionName: options.find((opt) => opt.id === activeOptionId)?.name,
        sliceCustomizations: createSliceCustomizationsWithDisplayModel(productCustomization),
        stepSelections: mapCustomizationsToStepSelections(productCustomization),
      };
      onAddItem(customizedProductToAdd);
    } else {
      addProductToBasket(productCustomization, activeOptionId);
    }
  }

  function handleOnItemRecalculate(productCustomization: Customization[], activeOptionId: number): void {
    recalculateItemPrice(productCustomization, activeOptionId);
  }

  const addClicked = useCallback(() => {
    setTriggerAddItem(true);
  }, []);

  const plusClicked = useCallback(() => {
    if (quantity < maxLimit) setQuantity(quantity + 1);
  }, [quantity, maxLimit]);

  const minusClicked = useCallback(() => {
    if (quantity > minLimit) setQuantity(quantity - 1);
  }, [quantity, minLimit]);

  const handleCancelClick = useCallback(() => {
    dispatch(clearCustomizationProductPrice());
    onCancelClick();
  }, [dispatch, onCancelClick]);

  return (
    <div className={classes.root}>
      <div className={classes.navigation}>
        <OutlinedButton
          testId="product-customization__button--cancel-product-customization"
          icon={arrowBack}
          onClickFn={handleCancelClick}
        >
          {t('Cancel')}
        </OutlinedButton>
        <Typography classes={{ root: classes.textBlack }} variant="h5">
          {productToCustomize.baseProduct.title}
        </Typography>
        {productToCustomize.restrictions?.hideQuantity !== true && (
          <QuantityButtonGroup
            showLabel
            quantity={quantity}
            onMinus={minusClicked}
            onPlus={plusClicked}
            testIdPrefix="product-customization"
          />
        )}
      </div>
      <div className={classes.content}>
        {productToCustomize && (
          <>
            {productEditionState === ProductCustomizationState.XTasty && pizzas && (
              <XTastyContainer
                quantity={quantity}
                onItemRecalculate={handleOnItemRecalculate}
                onAddItem={handleOnAddItem}
                customization={productToCustomize}
                pizzas={pizzas}
                options={availableProductOptions}
                subOptions={availableProductSubOptions}
                triggerAddItem={triggerAddItem}
              />
            )}
            {(productEditionState === ProductCustomizationState.CreateYourOwn ||
              productEditionState === ProductCustomizationState.CustomizeProduct) && (
              <CustomizePizza
                quantity={quantity}
                onItemRecalculate={(optionId, customization): void => {
                  handleOnItemRecalculate(customization ? [customization] : [], optionId);
                }}
                onAddItem={(optionId, customization): void => {
                  handleOnAddItem(customization ? [customization] : [], optionId);
                }}
                customizationProduct={productToCustomize}
                triggerAddItem={triggerAddItem}
              />
            )}
            {productEditionState === ProductCustomizationState.ProductSet && (
              <ProductSet
                quantity={quantity}
                customizationProduct={productToCustomize}
                options={availableProductOptions}
                triggerAddItem={triggerAddItem}
                onItemRecalculate={(optionId, customization): void => {
                  handleOnItemRecalculate([customization], optionId);
                }}
                onAddItem={(optionId, customization): void => {
                  handleOnAddItem([customization], optionId);
                }}
                onProductSetNotComplete={(): void => {
                  dispatch(clearCustomizationProductPrice());
                }}
              />
            )}
          </>
        )}

        <TextField
          value={productRemark}
          onChange={(event): void => {
            setProductRemark(event.target.value);
          }}
          className={classes.textField}
          multiline
          placeholder={t('Add product remark')}
          name="textField"
          variant="outlined"
          fullWidth
          rows="4"
          inputProps={{ 'data-testid': 'product-customization__input--remark' }}
        />
      </div>
      <SummaryBar
        customizationProductPrice={customizationProductPrice}
        formatToDisplay={formatToDisplay}
        onAddClick={addClicked}
      />
    </div>
  );
};
export default ProductCustomization;
