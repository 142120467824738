import { createSlice } from '@reduxjs/toolkit';
import { StoresState } from 'typings/Store';
import { storeApi } from './store.api';

export const initialState: StoresState = {
  selectedStore: null,
};

const storeSlice = createSlice({
  name: '[STORE]',
  initialState,
  reducers: {
    resetStoreState() {
      storeApi.util.invalidateTags(['SwitchStores', 'SelectedStore']);
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(storeApi.endpoints.switchStore.matchFulfilled, (state, action) => ({
      ...state,
      selectedStore: action.payload,
    }));
    builder.addMatcher(storeApi.endpoints.getSelectedStore.matchFulfilled, (state, action) => ({
      ...state,
      selectedStore: action.payload,
    }));
  },
});

const { reducer } = storeSlice;

export const { resetStoreState } = storeSlice.actions;

export default reducer;
