import { Button, Tooltip, useTheme } from '@mui/material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import React, { memo, useCallback, useState } from 'react';
import { setAlert } from 'stores/Alerts';
import { setOrderRemarks } from 'stores/Intake';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import TextFieldDialog from 'components/Shared/TextFieldDialog/TextFieldDialog';
import { getRemarks } from 'stores/Intake/intake.selector';

export const RemarksButton = memo(() => {
  const [t] = useTranslation('intake');
  const { spacing, palette } = useTheme();
  const dispatch = useAppDispatch();
  const remarks = useAppSelector(getRemarks);

  const [isRemarkOpen, setRemarkOpen] = useState(false);
  const openRemarks = useCallback(() => setRemarkOpen(true), []);
  const closeRemarks = useCallback(() => setRemarkOpen(false), []);

  const saveRemarks = useCallback(
    (value: string): void => {
      closeRemarks();
      dispatch(
        setAlert({
          header: 'Remarks saved',
          variant: 'success',
          position: {
            horizontal: 'right',
            vertical: 'bottom',
          },
        }),
      );
      dispatch(setOrderRemarks(value));
    },
    [closeRemarks, dispatch],
  );
  return (
    <>
      <Tooltip enterDelay={500} title={t('Order remark').toString()} arrow>
        <span>
          <Button
            variant="iconButton"
            onClick={openRemarks}
            data-testid="receipt-footer__button--add-remark"
            sx={{ backgroundColor: palette.grey[200] }}
          >
            <AddCommentOutlinedIcon sx={{ fontSize: spacing(4) }} />
          </Button>
        </span>
      </Tooltip>
      {isRemarkOpen && (
        <TextFieldDialog
          showDialog={isRemarkOpen}
          headerKey="Remarks"
          formId="remarks"
          i18NameSpace="intake"
          onClose={closeRemarks}
          onSubmit={saveRemarks}
          defaultValue={remarks}
          maxLength={500}
          placeholder="Remarks"
        />
      )}
    </>
  );
});

RemarksButton.displayName = 'RemarksButton';
