import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing, sizing }) => {
  return {
    subNavbar: { background: palette.appBar?.subMain ?? palette.primary.dark, boxShadow: 'none' },
    subNavbarContent: {
      height: sizing.subNavBarsHeight,
    },
    indicator: {
      height: spacing(0.5),
      background: palette.appBar?.subMainText ?? palette.primary.contrastText,
    },
    tabButton: {
      minWidth: '160px',
      color: palette.appBar?.subMainText ?? palette.primary.contrastText,
    },
    tabButtonSelected: {
      fontWeight: 700,
      color: `${palette.appBar?.subMainText ?? palette.primary.contrastText} !important`,
    },
    tabButtonActive: {
      backgroundColor: `${palette.appBar?.active ?? palette.primary.contrastText} !important`,
    },
  };
});
export default buildClasses;
