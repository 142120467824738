import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import PluginComponent from 'components/Plugins/PluginComponent';
import ScreenComponent from 'components/Plugins/ScreenComponent';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Backdrop, Box, useTheme } from '@mui/material';
import { resetCashierState } from 'stores/Cashier';
import { clearOrderEdition } from 'stores/combined.actions';
import { getSwitchedStore } from 'stores/Store/store.selectors';
import { getLogo } from 'stores/Cms/cms.selector';
import { validateUser } from 'stores/AllOrders';
import { getIsDesktop } from 'stores/Config/config.selector';
import { useGetConfigQuery } from 'stores/Config/config.api';
import routes, { AppRoute } from '../Routes/routes-list';
import AppMenuItem from './AppMenuItem/AppMenuItem';

interface AppMenuProps {
  open: boolean;
  onClose: () => void;
}

export const AppMenu: React.FC<AppMenuProps> = memo(({ open, onClose }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const cmsImage = useAppSelector(getLogo);
  const { brandLogo, sizing } = useTheme();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const switchedStore = useAppSelector(getSwitchedStore);
  const storeChanged = (switchedStore && !switchedStore.isDefaultStore) ?? false;

  const { isSuccess: configLoaded, data: configData } = useGetConfigQuery();
  const { featureFlags, hiddenRoutes } = (configLoaded && configData) || {};

  const isDesktop = useAppSelector(getIsDesktop);

  function getCurrentRouteId(): number {
    const foundRoutes = routes.filter((route) => route.path === location.pathname);
    return foundRoutes.length > 0 ? foundRoutes[0].routeId : 0;
  }

  const [activeRouteId, setActiveRouteId] = useState(getCurrentRouteId());

  const showLocalOrders = featureFlags?.OfflineModule_DisableLocalOrdersInCloud === false || isDesktop;
  const showCoreModules = featureFlags?.OfflineModule_SingleNavigation && !isDesktop;
  const visibleRoutes = useMemo(() => {
    return routes
      .filter((route) => !route.isExperimental || showCoreModules)
      .filter((route) => (storeChanged ? !route.forbiddenOnSwitchedStore : true))
      .filter((route) => !isDesktop || route.path !== '/all-orders')
      .filter((route) => showLocalOrders || route.path !== '/local-orders')
      .filter((route) => (hiddenRoutes ? !hiddenRoutes.some((hr) => hr === route.path) : true));
  }, [hiddenRoutes, isDesktop, showCoreModules, showLocalOrders, storeChanged]);

  const onMenuItemClicked = useCallback(
    (route: AppRoute) => {
      const activateRoute = (routeId: number, moduleIdentifier: string): void => {
        document.cookie = `ActiveModule=${moduleIdentifier};path=/`;
        setActiveRouteId(routeId);
        dispatch(resetCashierState());
        dispatch(clearOrderEdition());
      };
      activateRoute(route.routeId, route.moduleIdentifier ?? 'offline-pos');
      onClose();
      if (route?.requireClockIn) {
        dispatch(validateUser({}));
      }
    },
    [dispatch, onClose],
  );

  // make sure drawer always closes when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedOnDrawerElement = !!drawerRef.current?.contains(event.target as Node);
      const clickedAButton = (event.target as HTMLElement).closest('button');
      if (!clickedOnDrawerElement && !clickedAButton) {
        onClose();
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{ alignItems: 'left', width: sizing.sideMenuWidth, flexShrink: 0 }}
        ref={drawerRef}
      >
        <Box
          component="img"
          sx={{ mx: 'auto', my: 1, maxHeight: 80, maxWidth: sizing.sideMenuWidth - 40 }}
          src={cmsImage ? cmsImage.url : (brandLogo as string)}
        />
        <List sx={{ alignItems: 'center', flex: 1 }}>
          {visibleRoutes.map((route) => (
            <React.Fragment key={route.routeId}>
              <AppMenuItem
                route={route}
                isActive={route.routeId === activeRouteId}
                menuItemClicked={onMenuItemClicked}
                testId={`navigation-drawer__anchor--go-to-${route.navbarName}`}
              />
              <Divider />
            </React.Fragment>
          ))}
        </List>
        <ScreenComponent />
        <PluginComponent />
      </Drawer>
      {open && <Backdrop open onClick={onClose} sx={{ zIndex: 1101 }} />}
    </>
  );
});

AppMenu.displayName = 'AppMenu';
