import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { AppBar, CircularProgress, Dialog, DialogTitle, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { Box } from '@mui/system';
import { getEFTStatus } from 'stores/Eft/eft.selector';
import { automaticCashierLogOut } from 'stores/Cashier/cashier.thunk-actions';
import { getEFTPaymentStatus } from 'stores/EftPayment/eftPayment.selector';
import { appInsights } from 'App/AppInitializationWrapper/useAppInsights';
import { getMaxUserIdleTime } from 'stores/Config/config.selector';
import timeSpanToSeconds from 'utils/localisation/dateTimeUtils';
import { getLocalStorageItem, LocalStorageItems } from 'utils/localStorageUtils';
import { getLoggedCashier } from 'stores/Cashier/cashier.selector';

const remainingSecondsThreshold = 30;

export const InactivityGuard = memo(() => {
  const dispatch = useAppDispatch();
  const { sizingBig } = useTheme();
  const [t] = useTranslation('common');

  const maxUserIdleTime = useAppSelector(getMaxUserIdleTime);
  const idleTimeSeconds = useMemo(
    () =>
      timeSpanToSeconds(
        getLocalStorageItem<string>(LocalStorageItems.idleTimeSpan) ?? maxUserIdleTime ?? '00:01:00',
      ),
    [maxUserIdleTime],
  );

  const [remainingSeconds, setRemainingSeconds] = useState<number>(idleTimeSeconds);
  const endTimeRef = useRef(Date.now());
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const eftStatus = useAppSelector(getEFTStatus);
  const eftStatusV2 = useAppSelector(getEFTPaymentStatus);
  const loggedCashier = useAppSelector(getLoggedCashier);
  const eftPending = eftStatus === 'PENDING' || eftStatusV2 !== 'IDLE';
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOnIdle = useCallback((): void => {
    dispatch(automaticCashierLogOut());
    appInsights.trackEvent({
      name: `[AutoSignout][InactivityGuard] User ${loggedCashier?.id} signed out due to inactivity`,
    });
  }, [dispatch, loggedCashier?.id]);

  const disableCountdownAndClosePrompt = useCallback((): void => {
    setDialogOpen(false);
    setRemainingSeconds(remainingSecondsThreshold);
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  // start a realtime timer to update the remaining time approximately every second and opens the dialog
  const handleOnPrompt = useCallback(
    (event?: Event, idleTimer?: IIdleTimer): void => {
      const remainingMs = idleTimer?.getRemainingTime() ?? remainingSecondsThreshold * 1000;
      endTimeRef.current = Date.now() + remainingMs;
      setRemainingSeconds(Math.round(remainingMs / 1000));
      setDialogOpen(true);

      timeoutRef.current && clearTimeout(timeoutRef.current);

      const tick = () => {
        if (eftPending) {
          idleTimer?.reset();
          disableCountdownAndClosePrompt();
          return;
        }
        const now = Date.now();
        const remaining = Math.max(0, Math.ceil((endTimeRef.current - now) / 1000));
        setRemainingSeconds(remaining);

        if (remaining > 0) {
          // Calculate drift and adjust the next timeout
          const drift = now - (endTimeRef.current - remaining * 1000);
          const nextTick = 1000 - (drift % 1000);
          timeoutRef.current = setTimeout(tick, nextTick);
        }
      };

      tick();
    },
    [disableCountdownAndClosePrompt, eftPending],
  );

  // only used when prompt is opened to automatically dismiss the dialog when the user interacts with the page
  const onAction = useCallback(
    (event?: Event, idleTimer?: IIdleTimer) => {
      if (idleTimer?.isPrompted()) {
        idleTimer?.reset();
        disableCountdownAndClosePrompt();
      }
    },
    [disableCountdownAndClosePrompt],
  );

  const { reset } = useIdleTimer({
    timeout: 1000 * idleTimeSeconds,
    events: ['mousemove', 'keydown', 'touchstart'],
    element: document,
    disabled: eftPending,
    onPrompt: handleOnPrompt,
    onIdle: handleOnIdle,
    promptBeforeIdle: remainingSecondsThreshold * 1000,
    eventsThrottle: 1000,
    onAction,
  });

  const onClose = useCallback(() => {
    reset();
    disableCountdownAndClosePrompt();
  }, [reset, disableCountdownAndClosePrompt]);

  const progressVal = Math.floor(remainingSeconds * (100 / remainingSecondsThreshold));

  return dialogOpen ? (
    <Dialog maxWidth="lg" onClose={onClose} open={dialogOpen} data-testid="idle-time-popup">
      <AppBar position="relative">
        <DialogTitle id="simple-dialog-title">{t('Idle timer')}</DialogTitle>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: `${sizingBig(2)} ${sizingBig(4)}`,
        }}
      >
        <Typography variant="h4">{t('You will be logged out')}</Typography>
        <Box
          sx={{
            position: 'relative',
            height: sizingBig(10),
            width: sizingBig(10),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 4,
          }}
        >
          <Typography variant="h3">{dialogOpen ? remainingSeconds : ''}</Typography>
          <CircularProgress
            variant="determinate"
            defaultValue={0}
            value={progressVal}
            sx={{ position: 'absolute' }}
            color="primary"
            size={250}
          />
        </Box>
        <Typography variant="h4">{t('Touch screen to stop timer')}</Typography>
      </Box>
    </Dialog>
  ) : null;
});
