import React, { useCallback } from 'react';
import { useTheme } from '@mui/material';
import { useWindowSize } from 'usehooks-ts';
import { useAppSelector } from 'hooks/useRedux';
import { getUsePagination } from 'stores/Config/config.selector';

export const useCalculatePageCount = (itemRef: React.RefObject<HTMLElement>, totalItems: number) => {
  const { sizing, breakpoints } = useTheme();
  const { width } = useWindowSize();
  const paginationConfig = useAppSelector(getUsePagination);

  const getProductRowAmount = useCallback(
    (windowWidth: number | undefined): number => {
      if (windowWidth && windowWidth < breakpoints.values.md) return sizing.productCard.lineCount.xs;
      if (windowWidth && windowWidth > breakpoints.values.xl) return sizing.productCard.lineCount.xl;
      return sizing.productCard.lineCount.md;
    },
    [
      breakpoints.values.md,
      breakpoints.values.xl,
      sizing.productCard.lineCount.md,
      sizing.productCard.lineCount.xl,
      sizing.productCard.lineCount.xs,
    ],
  );

  return useCallback((): { itemsPerPage: number; pagesCount: number } => {
    const gridHeight = itemRef.current?.clientHeight ?? 0;
    const productCartHeight = sizing.productCard.height;
    const itemsPerColumn = paginationConfig?.productsPage
      ? Math.floor((gridHeight - sizing.spacingBase * 5) / (productCartHeight + sizing.spacingBase * 1))
      : sizing.bestSellerBoard.numberOfLines;

    const itemsPerRow = getProductRowAmount(width);
    if (itemsPerColumn === 0) {
      return { pagesCount: Math.ceil(totalItems / itemsPerRow), itemsPerPage: itemsPerRow };
    }

    const itemsPerPage = itemsPerColumn * itemsPerRow;
    const pagesCount = Math.ceil(totalItems / itemsPerPage);
    return { pagesCount, itemsPerPage };
  }, [
    itemRef,
    sizing.productCard.height,
    sizing.spacingBase,
    sizing.bestSellerBoard.numberOfLines,
    paginationConfig?.productsPage,
    getProductRowAmount,
    width,
    totalItems,
  ]);
};
