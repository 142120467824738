import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { resetDineInFilters, restartDineInOrders, setDineInFilters } from 'stores/DineIn/dineIn.slice';
import { getPaymentMethods } from 'stores/Payments';
import buildClasses from './Overview.css';
import DineInOrdersTable from './Table/Table';
import DineInOrdersFilter from './Filters/Filter';
import { AvailableIntakeContents, DineInOrderStatus, PickUpTypesValues } from '../IntakeConsts';
import NewDineInOrder from '../NewDineInOrder/NewDineInOrder';

interface DineInOrdersOverviewProps {
  onOrderClick?: () => void;
}

const DineInOrdersOverview: React.FC<DineInOrdersOverviewProps> = ({ onOrderClick }) => {
  const { classes } = buildClasses();

  const [pageIndex, setPageIndex] = useState(0);

  const { dineInFilters } = useAppSelector((appState) => appState.dineIn);
  const { activeIntakeTab } = useAppSelector((state) => state.intake);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeIntakeTab === AvailableIntakeContents.DineInOpenOrders)
      dispatch(
        getPaymentMethods({
          pickupType: PickUpTypesValues.takeAway,
        }),
      );
    return () => {
      dispatch(resetDineInFilters());
      dispatch(restartDineInOrders());
    };
  }, []);

  const handleSelectedOrderStatus = useCallback(
    (value: DineInOrderStatus): void => {
      dispatch(setDineInFilters({ ...dineInFilters, rowIndex: 0, orderStatus: value }));
      setPageIndex(0);
    },
    [dineInFilters, dispatch],
  );

  return (
    <div className={classes.dineInWrapper}>
      <div className={classes.filterWrapper}>
        <DineInOrdersFilter
          selectedOrderStatus={dineInFilters.orderStatus}
          onSelectedOrderStatusChanged={handleSelectedOrderStatus}
        />
        <NewDineInOrder />
      </div>
      <div className={classes.ordersContainer}>
        <div className={classes.overviewTable}>
          <DineInOrdersTable pageIndex={pageIndex} setPageIndex={setPageIndex} onOrderClick={onOrderClick} />
        </div>
      </div>
    </div>
  );
};

export default DineInOrdersOverview;
