import React, { memo, useCallback, useEffect, useMemo } from 'react';
import ReceiptToggleGroup from 'components/Intake/Receipt/ToggleGroup/ReceiptToggleGroup';
import ReceiptTotal from 'components/Intake/Receipt/Total/ReceiptTotal';
import PaymentMethodGroup from 'components/Intake/Receipt/ToggleGroup/PaymentMethodsGroup';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setPendingIteration } from 'stores/DineIn/dineIn.slice';
import { getCanEditBasket } from 'stores/Intake/intake.selector';
import { getSelectedDineInOrder } from 'stores/DineIn/dineIn.selector';
import { submitPendingIteration } from 'stores/DineIn/dineIn-thunk.actions';
import { getSelectedStore } from 'stores/Store/store.selectors';
import buildClasses from './ReceiptFooter.css';
import { RemarksButton } from './components/RemarksButton';
import { PriceOverrideButton } from './components/PriceOverrideButton';
import { OpenCashDrawerButton } from './components/OpenCashDrawerButton';
import { ChangeDeliveryChargeButton } from './components/ChangeDeliveryChargeButton';
import { FinishButton } from './components/FinishButton';
import { SendButton } from './components/SendButton';
import { IntakeStatuses } from '../../IntakeConsts';

interface ReceiptItemProps {
  onFinishOrderButtonClick: () => void;
  canChangeDeliveryType: boolean;
  canChangePaymentMethod: boolean;
  intakeStatus: IntakeStatuses;
}

const distinct = (value: number, index: number, self: number[]): boolean => self.indexOf(value) === index;

export const ReceiptFooter: React.FC<ReceiptItemProps> = memo(
  ({ onFinishOrderButtonClick, canChangeDeliveryType, canChangePaymentMethod, intakeStatus }) => {
    const { classes } = buildClasses();
    const dispatch = useAppDispatch();
    const canEditBasket = useAppSelector(getCanEditBasket);
    const selectedDineInOrder = useAppSelector(getSelectedDineInOrder);
    const selectedStore = useAppSelector(getSelectedStore);
    const { basketItems, basketCoupons } = useAppSelector((store) => store.basket);

    const onDineInItemsSend = useCallback((): void => {
      if (selectedStore) {
        dispatch(submitPendingIteration());
      }
    }, [dispatch, selectedStore]);

    const isDineInOrder = !!selectedDineInOrder;

    const isDineInSendButtonVisible = useMemo(() => {
      const dineInItems = selectedDineInOrder?.items ?? [];
      const dineInCoupons = selectedDineInOrder?.coupons ?? [];
      const distinctBasketItemsIds = basketItems.map((itm) => itm.splittedFromId ?? itm.id).filter(distinct);
      return (
        isDineInOrder &&
        (distinctBasketItemsIds.length > dineInItems.filter((itm) => !itm.isCancelled).length ||
          basketCoupons.length > dineInCoupons.length)
      );
    }, [
      basketCoupons.length,
      basketItems,
      isDineInOrder,
      selectedDineInOrder?.coupons,
      selectedDineInOrder?.items,
    ]);

    const isFinishButtonVisible =
      !isDineInSendButtonVisible &&
      (intakeStatus === IntakeStatuses.productsSection || intakeStatus === IntakeStatuses.finalizeOrder);

    useEffect(() => {
      if (isDineInSendButtonVisible) {
        dispatch(setPendingIteration());
      }
    }, [dispatch, isDineInSendButtonVisible]);

    return (
      <div className={classes.container}>
        {canChangePaymentMethod && canEditBasket && <PaymentMethodGroup placeRemarkOnTop />}
        {canChangeDeliveryType && canEditBasket && (
          <ReceiptToggleGroup isInDineInMode={selectedDineInOrder !== undefined} />
        )}
        <ReceiptTotal />
        {canEditBasket && (
          <div className={classes.receiptFooterActionsContainer}>
            <ChangeDeliveryChargeButton />
            <OpenCashDrawerButton />
            <PriceOverrideButton />
            <RemarksButton />
            {isFinishButtonVisible && <FinishButton onClick={onFinishOrderButtonClick} />}
            {isDineInSendButtonVisible && <SendButton onClick={onDineInItemsSend} />}
          </div>
        )}
      </div>
    );
  },
);

ReceiptFooter.displayName = 'ReceiptFooter';
