import React, { useCallback, useEffect } from 'react';
import CustomerDataForm from 'components/Intake/Finalize/CustomerDataForm/CustomerDataForm';
import DeliveryTimeForm from 'components/Intake/Finalize/DeliveryTimeForm/DeliveryTimeForm';
import { CheckoutDetailsForm, emptyAddress } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import DeliveryAddressForm, {
  DeliveryAddressErrors,
} from 'components/Intake/Finalize/DeliveryAddressForm/DeliveryAddressForm';
import { AvailableIntakeContents, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useFormContext } from 'react-hook-form';
import {
  getAddressSuggestions,
  getDeliveryAreaDetails,
  getLocalizationDetails,
  setManualDeliveryAddress,
  clearAddressSuggestions,
} from 'stores/Intake';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import {
  checkCurrentCustomerCanPayOnAccount,
  clearSelectedCustomerAddress,
  getCustomerAddressesByPhone,
  selectCustomerAddress,
} from 'stores/Customer';
import { STREET_SUGGESTION_THRESHOLD, ADDRESS_CHANGE_DEBOUNCE_DELAY } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AddressSectionType } from 'stores/Config';
import _ from 'lodash';
import { mapFormDataToDeliveryAddress } from 'stores/Intake/IntakeStoreUtils';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { isAddressValid } from 'utils/intake/IntakeUtils';
import FinalizeAlerts from 'components/Intake/Finalize/FinalizeAlerts/FinalizeAlerts';
import {
  getAddressInput,
  getConnectivityStatus,
  getUseDeliveryAddressCheck,
  getUseZipCodeAddressSuggestions,
  getUsePartialAddressSuggestions,
} from 'stores/Config/config.selector';
import CustomerAddressBook from '../AddressBook/CustomerAddressBook';
import buildClasses from './OrderRequiredInfoForm.css';

interface OrderRequiredInfoProps {
  onFormSubmit: (data: CheckoutDetailsForm) => void;
}

const OrderRequiredInfoForm: React.FC<OrderRequiredInfoProps> = ({ onFormSubmit }) => {
  const {
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    formState: { dirtyFields, errors },
  } = useFormContext<CheckoutDetailsForm>();
  const { classes } = buildClasses();

  const {
    activeDeliveryType,
    localizationDetails,
    suggestionDetails,
    selectedOrderCustomer,
    activeIntakeTab,
    selectedOrderDeliveryTime,
    onSiteSettings,
  } = useAppSelector(({ intake }) => intake);

  const connectivityStatus = useAppSelector(getConnectivityStatus);
  const addressInput = useAppSelector(getAddressInput);
  const useZipCodeAddressSuggestions = useAppSelector(getUseZipCodeAddressSuggestions);
  const usePartialAddressSuggestions = useAppSelector(getUsePartialAddressSuggestions);

  const useDeliveryAddressCheck = useAppSelector(getUseDeliveryAddressCheck);

  const { customerAddresses, addressesLoading } = useAppSelector(({ customer }) => customer);
  const onAddressChangeDebounce = useCallback(_.debounce(onAddressChange, ADDRESS_CHANGE_DEBOUNCE_DELAY), [
    activeIntakeTab,
  ]);
  const dispatch = useAppDispatch();

  const addressBookMethod = customerAddresses.some((el) => el.isSelected);

  useEffect(() => {
    clearErrors();
  }, [activeDeliveryType]);

  useEffect(() => {
    if (!localizationDetails) return;
    const values = getValues();

    const newValues = {
      ...values,
      region: localizationDetails.region ?? values.region,
      city: localizationDetails.city ?? values.city,
      street: localizationDetails.street ?? values.street,
    };
    reset(newValues, { keepDirty: false });
    dispatch(setManualDeliveryAddress(mapFormDataToDeliveryAddress(newValues)));

    if (isAddressValid(newValues, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(newValues));
      recalculate();
    }
  }, [localizationDetails]);

  useEffect(() => {
    if (!suggestionDetails) return;
    const values = getValues();

    const newValues = {
      ...values,
      region: suggestionDetails.region,
      city: suggestionDetails.city,
      zipcode: suggestionDetails.zipCode,
      street: suggestionDetails.street,
      streetNumber: suggestionDetails.streetNumber,
      buildingName: suggestionDetails.buildingName,
    };
    reset(newValues, { keepDirty: true });
    dispatch(setManualDeliveryAddress(mapFormDataToDeliveryAddress(newValues)));

    if (isAddressValid(newValues, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(newValues));
      recalculate();
    }
  }, [suggestionDetails]);

  useEffect(() => {
    const deliveryData = getValues();
    if (selectedOrderCustomer) {
      dispatch(checkCurrentCustomerCanPayOnAccount());
    }
    if (isAddressValid(deliveryData, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(deliveryData));
    }
  }, []);

  function recalculate() {
    if (onSiteSettings?.isToGoModeEnabled === true && activeIntakeTab !== AvailableIntakeContents.Customer) return;
    dispatch(recalculateBasket({}));
  }

  function isAddressSearchingDisabled(): boolean {
    return (
      activeDeliveryType !== PickUpTypesValues.delivery || connectivityStatus !== ConnectivityStatusEnum.Connected
    );
  }
  function onPhoneNumberChanged(phoneNumber: string | null) {
    if (isAddressSearchingDisabled() || selectedOrderCustomer?.profile?.id) {
      return;
    }

    if (phoneNumber) {
      dispatch(getCustomerAddressesByPhone(phoneNumber));
    }
  }

  function changeToManualAddressInput(): void {
    dispatch(clearSelectedCustomerAddress());
  }

  function changeToAddressBook(): void {
    dispatch(selectCustomerAddress(customerAddresses[0]));
    dispatch(setManualDeliveryAddress());
    const values = getValues();
    reset({
      ...values,
      ...emptyAddress,
    });
  }

  function onAddressChange(sectionType: AddressSectionType) {
    const deliveryData = getValues();

    if (isAddressValid(deliveryData, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(deliveryData));
      recalculate();
    }

    if (
      sectionType === AddressSectionType.SuggestionBox &&
      (!deliveryData?.suggestionBox || deliveryData?.suggestionBox?.length < STREET_SUGGESTION_THRESHOLD)
    ) {
      dispatch(clearAddressSuggestions());
      return;
    }

    const canApplySuggestion = !dirtyFields.city && !dirtyFields.street && !dirtyFields.region;

    if (usePartialAddressSuggestions) {
      switch (sectionType) {
        case AddressSectionType.SuggestionBox:
          if (deliveryData?.suggestionBox) {
            if (deliveryData.suggestionBox.length >= STREET_SUGGESTION_THRESHOLD) {
              dispatch(
                getAddressSuggestions({
                  partialAddress: deliveryData.suggestionBox,
                }),
              );
            }
          }
          break;
        default:
          break;
      }
    }

    if (useZipCodeAddressSuggestions && !usePartialAddressSuggestions) {
      switch (sectionType) {
        case AddressSectionType.Zipcode:
        case AddressSectionType.StreetNumber:
          if (deliveryData?.zipcode && deliveryData?.streetNumber && canApplySuggestion) {
            dispatch(
              getLocalizationDetails({
                zipCode: deliveryData.zipcode,
                streetNumber: deliveryData.streetNumber,
              }),
            );
          }
          break;
        default:
          break;
      }
    }
  }

  return (
    <form autoComplete="off" name="finalizeForm" onSubmit={handleSubmit(onFormSubmit)} id="orderRequiredInfoForm">
      <FinalizeAlerts />
      {(activeDeliveryType === PickUpTypesValues.pickUp || activeDeliveryType === PickUpTypesValues.delivery) && (
        <DeliveryTimeForm
          preselectedDateTime={selectedOrderDeliveryTime}
          isPickup={activeDeliveryType === PickUpTypesValues.pickUp}
        />
      )}
      <CustomerDataForm
        isPhoneRequired={activeDeliveryType !== PickUpTypesValues.takeAway}
        onPhoneNumberChanged={onPhoneNumberChanged}
      />
      {activeDeliveryType === PickUpTypesValues.delivery &&
        (addressBookMethod ? (
          <CustomerAddressBook
            addressBookEntries={customerAddresses}
            onAddressSelected={(address) => {
              dispatch(selectCustomerAddress(address));
            }}
            onInputMethodChanged={changeToManualAddressInput}
          />
        ) : (
          <>
            <Backdrop className={classes.backdrop} open={addressesLoading}>
              <CircularProgress />
            </Backdrop>
            <Box className={classes.deliveryAddressFormContainer}>
              <DeliveryAddressForm
                usePartialAddressSuggestions={usePartialAddressSuggestions}
                errors={errors as DeliveryAddressErrors}
                inputConfig={addressInput?.configurationElements}
                onInputMethodChanged={
                  customerAddresses?.length > 0
                    ? () => {
                        if (customerAddresses.length === 0) return;
                        changeToAddressBook();
                      }
                    : undefined
                }
                onChangeFn={(sectionType) => {
                  dispatch(setManualDeliveryAddress(mapFormDataToDeliveryAddress(getValues())));
                  onAddressChangeDebounce(sectionType);
                }}
              />
            </Box>
          </>
        ))}
    </form>
  );
};

export default OrderRequiredInfoForm;
