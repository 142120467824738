import React from 'react';
import { Box, InputAdornment, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Controller, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import NumericKeypad, { KeyboardKey } from 'components/Shared/NumericKeypad/NumericKeypad';
import { Keypad, PaymentModalAnimations } from 'components/Shared/OrderPayment/OrderPayment.consts';
import { GiftCardActivationForm } from 'components/Shared/GiftCardActivation/GiftCardActivation';

const ManualCardNumber: React.FC = () => {
  const [t] = useTranslation('common');
  const { palette, typography } = useTheme();
  const {
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<GiftCardActivationForm>();

  const cardNumber = watch('cardNumberInput');

  const cardNumberKeypad = Keypad.concat({
    displayName: `<-`,
    component: <BackspaceIcon />,
    numSx: { background: palette.grey[800], px: 2, ...typography.button, color: palette.white },
    action: () => {
      setValue('cardNumberInput', '');
    },
  } as KeyboardKey);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          mb: 2,
          gap: 2,
        }}
      >
        <Controller
          rules={{
            maxLength: {
              value: 20,
              message: t('Value is too long'),
            },
          }}
          defaultValue=""
          control={control}
          name="cardNumberInput"
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              onKeyDown={handleKeyDown}
              variant="outlined"
              label={t('Gift card number')}
              fullWidth
              error={errors.cardNumberInput && true}
              helperText={errors.cardNumberInput && errors.cardNumberInput.message}
              InputProps={{
                sx: { px: 2, py: 2, fontSize: 20, fontWeight: 500, color: palette.grey[600] },

                endAdornment: (
                  <InputAdornment position="end">
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        reset({ cardNumberInput: '' });
                      }}
                    />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: '20',
                  autoFocus: true,
                  style: {
                    padding: '0',
                    color: cardNumber !== '' ? 'black' : 'inherit',
                    WebkitTextFillColor: cardNumber !== '' ? 'black' : 'inherit',
                  },
                  'data-testid': `gift-card-activation-card-number-input`,
                },
              }}
            />
          )}
        />
      </Box>
      <AnimatePresence initial={false}>
        <motion.div
          key="cardNumberNumpad"
          variants={PaymentModalAnimations.variants}
          initial="slideDown"
          animate="onPlace"
          transition={PaymentModalAnimations.transition}
        >
          <NumericKeypad
            keys={cardNumberKeypad}
            onChange={(clickedValue) => {
              setValue('cardNumberInput', `${cardNumber || ''}${clickedValue}`);
            }}
          />
        </motion.div>
      </AnimatePresence>
    </>
  );
};
export default ManualCardNumber;
