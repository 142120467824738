import { Box, Button, CircularProgress } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateUser } from 'stores/AllOrders';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getIsAnySetOnReceipt, getRecalculateInProgress, getVirtualReceipt } from 'stores/Basket/basket.selector';
import { getEditRequestStatus, getIsEditMode } from 'stores/Intake/intake.selector';
import OutsideStoreDeliveryAreaDialog from 'components/Intake/Finalize/OutsideStoreDeliveryAreaDialog/OutsideStoreDeliveryAreaDialog';
import { getFeatureFlags, getUseDeliveryAddressCheck } from 'stores/Config/config.selector';

type FinishButtonProps = {
  onClick: () => void;
};

export const FinishButton: React.FC<FinishButtonProps> = memo(({ onClick }) => {
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();

  const editRequestStatus = useAppSelector(getEditRequestStatus);
  const editMode = useAppSelector(getIsEditMode);
  const recalculateInProgress = useAppSelector(getRecalculateInProgress);
  const virtualReceipt = useAppSelector(getVirtualReceipt);
  const anySetOnReceipt = useAppSelector(getIsAnySetOnReceipt);
  const { deliveryAreaDetails } = useAppSelector((state) => state.intake);
  const featureFlags = useAppSelector(getFeatureFlags);
  const useDeliveryAddressCheck = useAppSelector(getUseDeliveryAddressCheck);

  const [isOutsideStoreDeliveryAreaDialog, setOutsideStoreDeliveryAreaDialog] = useState(false);

  const openDeliveryAreaModal = useCallback(() => setOutsideStoreDeliveryAreaDialog(true), []);
  const closeDeliveryAreaModal = useCallback(() => setOutsideStoreDeliveryAreaDialog(false), []);

  const finishButtonEventHandler = useCallback((): void => {
    const shouldDisplayOutsideStoreDeliveryAreaWarning =
      !featureFlags.OfflineModule_DisableModalForOutsideStoreDeliveryArea &&
      !isOutsideStoreDeliveryAreaDialog &&
      useDeliveryAddressCheck &&
      deliveryAreaDetails?.isAddressInCurrentStoreArea === false;

    if (shouldDisplayOutsideStoreDeliveryAreaWarning) {
      openDeliveryAreaModal();
      return;
    }

    if (isOutsideStoreDeliveryAreaDialog) {
      closeDeliveryAreaModal();
    }

    dispatch(
      validateUser({
        successCallback: () => {
          onClick();
        },
      }),
    );
  }, [
    closeDeliveryAreaModal,
    deliveryAreaDetails?.isAddressInCurrentStoreArea,
    dispatch,
    featureFlags.OfflineModule_DisableModalForOutsideStoreDeliveryArea,
    isOutsideStoreDeliveryAreaDialog,
    onClick,
    openDeliveryAreaModal,
    useDeliveryAddressCheck,
  ]);

  return (
    <>
      <Button
        color="primary"
        size="large"
        variant="contained"
        fullWidth
        onClick={finishButtonEventHandler}
        disabled={(virtualReceipt.receiptProducts.length < 1 && !anySetOnReceipt) || recalculateInProgress}
        data-testid="receipt-footer__button--go-to-finalize"
      >
        {editRequestStatus === 'pending' ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={20} color="secondary" sx={{ mr: 1 }} />
            {t('Saving...')}
          </Box>
        ) : (
          <>{t(editMode?.mode === 'full' ? 'FINISH EDIT' : 'FINISH')}</>
        )}
      </Button>
      {isOutsideStoreDeliveryAreaDialog && (
        <OutsideStoreDeliveryAreaDialog
          open={isOutsideStoreDeliveryAreaDialog}
          abort={closeDeliveryAreaModal}
          onConfirm={finishButtonEventHandler}
        />
      )}
    </>
  );
});

FinishButton.displayName = 'FinishButton';
