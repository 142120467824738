import React, { FC, useCallback, useEffect } from 'react';
import { CloudDoneOutlined } from '@mui/icons-material';
import { Alert, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useRedux';
import SyncHistoryTable from 'components/DataSync/SyncHistory/SyncHistoryTable';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DataSyncHistoryStatus, DataSyncStatus } from 'stores/Config';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { format } from 'date-fns';
import { Box } from '@mui/system';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import {
  useGetSyncHistoryQuery,
  useGetSyncStatusQuery,
  useRunSynchronizationMutation,
} from 'stores/Config/config.api';
import { getSelectedStore } from 'stores/Store/store.selectors';

const DataSync: FC = () => {
  const { palette } = useTheme();
  const [t] = useTranslation('dataSync');
  const selectedStore = useAppSelector(getSelectedStore);
  const {
    isSuccess: syncStatusLoaded,
    data: syncStatus,
    isLoading: syncStatusLoading,
  } = useGetSyncStatusQuery(undefined, {
    pollingInterval: 1000,
    skipPollingIfUnfocused: true,
  });
  const {
    isSuccess: syncHistoryLoaded,
    data: syncHistory,
    refetch: refetchSyncHistory,
  } = useGetSyncHistoryQuery();
  const [runSynchronization, { isLoading: synchronizationRunning }] = useRunSynchronizationMutation();

  useEffect(() => {
    if (syncStatusLoaded && syncHistoryLoaded) {
      refetchSyncHistory();
    }
  }, [refetchSyncHistory, syncHistoryLoaded, syncStatus?.status, syncStatusLoaded]);

  const inProgress =
    synchronizationRunning || syncStatusLoading || syncStatus?.status === DataSyncStatus.inProgress;

  const isHanging = syncStatus?.status === DataSyncStatus.inProgress && syncStatus.isHanging;
  const syncStatusColor = isHanging ? palette.warning.main : 'black.main';

  const getSyncStatusMessage = (): { isError: boolean; message: string } => {
    if (
      syncHistoryLoaded &&
      syncHistory &&
      syncHistory?.length > 0 &&
      syncHistory[0].status === DataSyncHistoryStatus.failed
    ) {
      return { isError: true, message: 'Last data download failed' };
    }

    if (!syncStatus) return { isError: true, message: 'Data not downloaded yet' };

    switch (syncStatus?.status) {
      case DataSyncStatus.dataNotDownloaded: {
        return { isError: true, message: 'Data not downloaded yet' };
      }
      case DataSyncStatus.updateRequired: {
        return { isError: true, message: 'Update is required!' };
      }
      case DataSyncStatus.inProgress: {
        return { isError: true, message: 'Data not downloaded yet' };
      }
      default: {
        return { isError: false, message: 'Up to date' };
      }
    }
  };

  const syncStatusMessage = getSyncStatusMessage();

  const runSynchronizationOnClick = useCallback(() => {
    runSynchronization();
  }, [runSynchronization]);

  return (
    <Box>
      <Typography variant="h3" color="black.main" sx={{ mb: 2 }}>
        {t('Data download')}
      </Typography>
      {inProgress ? (
        <Box sx={{ my: 4, display: 'flex' }}>
          <JumpingDotsLoader colorOverwrite={isHanging ? syncStatusColor : undefined} />
          <Typography sx={{ ml: 1, color: syncStatusColor }} variant="subtitle1">
            {t('Downloading data...')}
          </Typography>
        </Box>
      ) : (
        <Typography variant="h5" gutterBottom data-testid="pinpad-alert" sx={{ width: '100%', mb: 2 }}>
          <Alert
            severity={syncStatusMessage.isError ? 'error' : 'success'}
            icon={syncStatusMessage.isError ? <CancelOutlinedIcon /> : <CloudDoneOutlined fontSize="inherit" />}
          >
            {t(syncStatusMessage.message)}
          </Alert>
        </Typography>
      )}
      <Typography color="black.main" variant="body1" data-testid="status__label--storeid">
        {t('StoreID')} {selectedStore?.id}
      </Typography>
      <Typography color="black.main" variant="body1" data-testid="status__label--name">
        {t('StoreName')} {selectedStore?.name}
      </Typography>
      <Typography color="black.main" variant="body1" data-testid="status__label--name">
        {t('Last success')}
      </Typography>
      <Typography color="black.main" variant="body1" data-testid="status__label--name">
        {syncStatus && syncStatus.lastSuccessfulSyncTimestamp
          ? format(new Date(syncStatus.lastSuccessfulSyncTimestamp), 'dd/MM/yyyy HH:mm:ss')
          : '-'}
      </Typography>
      {syncStatus && syncHistory && (
        <>
          <Typography color="black.main" variant="subtitle1" sx={{ my: 2 }}>
            {t('History')}
          </Typography>
          <SyncHistoryTable historyData={syncHistory} />
        </>
      )}
      <Button
        onClick={runSynchronizationOnClick}
        variant="contained"
        fullWidth
        startIcon={<AutorenewIcon />}
        sx={{ mt: 2 }}
        disabled={inProgress && !isHanging}
      >
        {t('Refresh now')}
      </Button>
    </Box>
  );
};

export default DataSync;
