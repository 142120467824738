import {
  AutoAddedItem,
  BasketData,
  BasketItem,
  FiscalEvent,
  FiscalEventType,
  ItemPriceDetails,
} from 'typings/Basket';
import { isEqual } from 'lodash';

/*
  As part of BlackBox (FDM) integration we need to have proper handling of every modification that happens to basket which is causing price changes
 */
export const generateFiscalEvents = (
  currentBasketData: BasketData | null | undefined,
  previousBasketData: BasketData | null | undefined,
  currentBasketItems: BasketItem[],
  previousBasketItems: BasketItem[],
  currentAutoAddedItems: AutoAddedItem[],
  previousAutoAddedItems: AutoAddedItem[],
): FiscalEvent[] => {
  // temporarily here until the fixes are implemented on the dedicated ticket
  return analyzeRecalculatedBasket(currentBasketData, previousBasketData, currentBasketItems, previousBasketItems);
};

function analyzeRecalculatedBasket(
  currentBasketData: BasketData | null | undefined,
  previousBasketData: BasketData | null | undefined,
  currentBasketItems: BasketItem[],
  previousBasketItems: BasketItem[],
): FiscalEvent[] {
  const prevItemDetails = previousBasketData?.itemsDetails ?? [];
  const newItemsDetails = currentBasketData?.itemsDetails ?? [];
  const addedItems: ItemPriceDetails[] = [];
  const removedItems: ItemPriceDetails[] = [];
  const modifiedItems: ItemPriceDetails[] = [];
  const fiscalEventsFound: FiscalEvent[] = [];

  /* ----------- COUPON COMPARISON ------------- */
  const prevActiveDiscounts = previousBasketData?.activeDiscounts ?? [];
  const currentActiveDiscounts = currentBasketData?.activeDiscounts ?? [];

  currentActiveDiscounts.forEach((currentDiscount) => {
    const prevDiscount = prevActiveDiscounts.find((el) => el.couponId === currentDiscount.couponId);
    if (prevDiscount === undefined) {
      fiscalEventsFound.push({
        type: FiscalEventType.DiscountAdded,
        couponCode: currentDiscount.code,
        couponName: currentDiscount.description,
      });
    }
    if (
      prevDiscount &&
      (isEqual(currentDiscount.matchingReceiptItemIds, prevDiscount.matchingReceiptItemIds) === false ||
        didCouponQuantityChange(currentDiscount.matchingReceiptItemIds, prevItemDetails, newItemsDetails))
    ) {
      fiscalEventsFound.push({
        type: FiscalEventType.DiscountModified,
        couponCode: currentDiscount.code,
        couponName: currentDiscount.description,
      });
    }
  });
  prevActiveDiscounts.forEach((prevDiscount) => {
    if (currentActiveDiscounts.findIndex((el) => el.couponId === prevDiscount.couponId) < 0) {
      fiscalEventsFound.push({
        type: FiscalEventType.DiscountRemoved,
        couponCode: prevDiscount.code,
        couponName: prevDiscount.description,
      });
    }
  });

  /* ----------- PRODUCTS COMPARISON ------------- */
  newItemsDetails.forEach((newBasketItemDetails) => {
    const baseItem = currentBasketItems.find((el) => el.id === newBasketItemDetails.basketItemId);
    if (
      prevItemDetails.findIndex(
        (el) =>
          el.receiptItemId === newBasketItemDetails.basketItemId &&
          el.productId === newBasketItemDetails.productId,
      ) < 0
    ) {
      if (prevItemDetails.findIndex((el) => el.productId === newBasketItemDetails.productId) < 0) {
        addedItems.push(newBasketItemDetails);

        fiscalEventsFound.push({
          type: FiscalEventType.ProductAdded,
          productId: newBasketItemDetails.productId as number,
          optionId: baseItem?.optionId,
          newQuantity: newBasketItemDetails.quantity,
          previousPrice: 0,
          newPrice: newBasketItemDetails.total.grossValue,
          discountValue: newBasketItemDetails.discountValueIncVat,
        });
      }
    }
    const itemInPreviousBasketButWithLoverQuantity = prevItemDetails.find(
      (el) =>
        el.basketItemId === newBasketItemDetails.basketItemId &&
        el.quantity < newBasketItemDetails.quantity &&
        el.unitPrice.grossPrice === newBasketItemDetails.unitPrice.grossPrice,
    );
    if (itemInPreviousBasketButWithLoverQuantity) {
      addedItems.push(newBasketItemDetails);
      fiscalEventsFound.push({
        type: FiscalEventType.ProductAdded,
        productId: newBasketItemDetails.productId as number,
        optionId: baseItem?.optionId,
        newQuantity: newBasketItemDetails.quantity - itemInPreviousBasketButWithLoverQuantity.quantity,
        previousPrice: itemInPreviousBasketButWithLoverQuantity.total.grossValue,
        newPrice: newBasketItemDetails.total.grossValue,
        discountValue: newBasketItemDetails.discountValueIncVat,
      });
    }
  });

  prevItemDetails.forEach((prevBasketItemDetails) => {
    const baseItem = previousBasketItems.find((el) => el.id === prevBasketItemDetails.productId);
    if (newItemsDetails.findIndex((el) => el.productId === prevBasketItemDetails.productId) < 0) {
      removedItems.push(prevBasketItemDetails);
      fiscalEventsFound.push({
        type: FiscalEventType.ProductRemoved,
        productId: prevBasketItemDetails.productId as number,
        optionId: baseItem?.optionId,
        newQuantity: prevBasketItemDetails.quantity,
        previousPrice: prevBasketItemDetails.total.grossValue,
        newPrice: 0,
        discountValue: prevBasketItemDetails.discountValueIncVat,
        previousDiscountValue: prevBasketItemDetails.discountValueIncVat,
      });
      return;
    }

    const itemInNewBasketButWithLowerQuantity = newItemsDetails.find(
      (el) =>
        el.basketItemId === prevBasketItemDetails.basketItemId &&
        el.quantity < prevBasketItemDetails.quantity &&
        el.unitPrice.grossPrice === prevBasketItemDetails.unitPrice.grossPrice,
    );
    if (itemInNewBasketButWithLowerQuantity) {
      removedItems.push(prevBasketItemDetails);
      fiscalEventsFound.push({
        type: FiscalEventType.ProductRemoved,
        productId: prevBasketItemDetails.productId as number,
        optionId: baseItem?.optionId,
        newQuantity: prevBasketItemDetails.quantity - itemInNewBasketButWithLowerQuantity.quantity,
        previousPrice: prevBasketItemDetails.total.grossValue,
        newPrice: itemInNewBasketButWithLowerQuantity.total.grossValue,
        discountValue: itemInNewBasketButWithLowerQuantity.discountValueIncVat,
        previousDiscountValue: prevBasketItemDetails.discountValueIncVat,
      });
      return;
    }

    if (
      removedItems.includes(prevBasketItemDetails) ||
      addedItems.some((el) => el.basketItemId === prevBasketItemDetails.basketItemId)
    ) {
      return;
    }

    const itemInNewBasketButModified = newItemsDetails.find((el) =>
      areItemsTheSameButPriceChanged(el, prevBasketItemDetails),
    );

    if (itemInNewBasketButModified) {
      const modifiedBaseItem = currentBasketItems.find((el) => el.id === itemInNewBasketButModified.basketItemId);
      const baseItem = previousBasketItems.find((el) => el.id === prevBasketItemDetails.basketItemId);
      modifiedItems.push(prevBasketItemDetails);
      fiscalEventsFound.push({
        type: FiscalEventType.ProductModified,
        productId: prevBasketItemDetails.productId as number,
        optionId: modifiedBaseItem?.optionId,
        previousOptionId: baseItem?.optionId,
        newQuantity: itemInNewBasketButModified.quantity,
        previousQuantity: prevBasketItemDetails.quantity,
        previousPrice: prevBasketItemDetails.total.grossValue,
        newPrice: itemInNewBasketButModified.total.grossValue,
        discountValue: itemInNewBasketButModified.discountValueIncVat,
        previousDiscountValue: prevBasketItemDetails.discountValueIncVat,
      });
    }
  });

  /* ----------- DELIVERY CHARGE COMPARISON ------------- */
  const previousDeliveryCharge = previousBasketData?.deliveryCharge.grossPrice ?? 0;
  const currentDeliveryCharge = currentBasketData?.deliveryCharge.grossPrice ?? 0;

  if (previousDeliveryCharge !== currentDeliveryCharge) {
    fiscalEventsFound.push({
      type: FiscalEventType.DeliveryPriceChanged,
      previousPrice: previousBasketData?.deliveryCharge.grossPrice ?? 0,
      newPrice: currentBasketData?.deliveryCharge.grossPrice ?? 0,
    });
  }
  return fiscalEventsFound;
}

export function areItemsTheSameButPriceChanged(
  basketItem: ItemPriceDetails,
  basketItemToCompare: ItemPriceDetails,
): boolean {
  if (basketItem.productId !== basketItemToCompare.productId) return false;
  if (
    basketItem.quantity !== basketItemToCompare.quantity &&
    basketItem.unitPrice.grossPrice === basketItemToCompare.unitPrice.grossPrice
  )
    return false;

  if (basketItem.total.grossValue !== basketItemToCompare.total.grossValue) return true;

  return false;
}

export function didCouponQuantityChange(
  couponItems: number[],
  previousItemsDetails: ItemPriceDetails[],
  newItemsDetails: ItemPriceDetails[],
): boolean {
  const item = newItemsDetails
    .filter((x) => couponItems.includes(x.basketItemId))
    .find((el) => {
      const prevBasketItemDetails = previousItemsDetails.find((x) => x.basketItemId === el.basketItemId);

      return (
        prevBasketItemDetails &&
        el.basketItemId === prevBasketItemDetails.basketItemId &&
        el.quantity !== prevBasketItemDetails.quantity &&
        el.unitPrice.grossPrice === prevBasketItemDetails.unitPrice.grossPrice
      );
    });

  return item !== undefined;
}
