import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Validators from 'utils/validation/ValidationConstants';
import InputUnit, { InputValidation, ValidationInput } from 'components/Shared/Inputs/InputUnit';
import { formatToEdit, numberFromMoney } from 'utils/payment/PaymentUtils';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useAppSelector } from 'hooks/useRedux';
import { getMaxPriceOverride, getPaymentConfig } from 'stores/Config/config.selector';
import buildClasses from './ManualPriceOverride.css';

export interface ManualDiscountProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (inputValue?: string) => void;
}

const ManualPriceOverride: React.FC<ManualDiscountProps> = ({ onClose, open, onSuccess }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');

  const paymentSettings = useAppSelector(getPaymentConfig);
  const maxPriceOverride = useAppSelector(getMaxPriceOverride);

  const { manualPriceOverride } = useAppSelector(({ intake }) => intake);
  const { basketData } = useAppSelector((store) => store.basket);

  const IsPriceAlreadyOverridden = basketData?.summary.total === manualPriceOverride;

  const initVal = manualPriceOverride !== undefined ? manualPriceOverride : 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InputValidation>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  function onSubmit(data: InputValidation): void {
    onSuccess(data.inputValue);
    onClose();
  }

  function onReset(): void {
    onSuccess();
    onClose();
  }

  const inputValidator: ValidationInput = {
    errors,
    formRef: register,
    required: true,
    validators: {
      isMoney: (val: string): boolean | string =>
        Validators.Money.Validator(paymentSettings).test(val) || Validators.Money.Message,
      isBelowLimit: (val: string): boolean | string =>
        Validators.IsBelowOrderTotal.Validator(numberFromMoney(val, paymentSettings), Number(maxPriceOverride)) ||
        `Value must be below ${maxPriceOverride}`,
    },
  };

  return (
    <ModalContainer
      onClose={onClose}
      open={open}
      title={t('Change total price')}
      primaryButton={{
        type: 'submit',
        form: 'manualDiscountForm',
        label: t('Change price'),
        testId: 'manual-price-override-modal__button--set-delivery-charge',
      }}
      secondaryButton={
        IsPriceAlreadyOverridden
          ? {
              label: t('Reset price'),
              testId: 'manual-price-override-modal__button--reset-price',
              action: onReset,
            }
          : undefined
      }
      testId="manual-price-override-modal"
    >
      <form
        className={classes.dialogForm}
        autoComplete="off"
        name="manualDiscountForm"
        onSubmit={handleSubmit(onSubmit)}
        id="manualDiscountForm"
      >
        <div>
          <InputUnit
            inputValidation={inputValidator}
            defaultValue={formatToEdit(initVal, paymentSettings)}
            testId="manual-price-override-modal__input--delivery-charge"
            label={t('New order total value')}
            autofocus
          />
        </div>
      </form>
    </ModalContainer>
  );
};

export default ManualPriceOverride;
