import React, { memo } from 'react';
import { AppBar, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AvailableIntakeContents } from 'containers/Intake/IntakeConsts';
import buildClasses from './IntakeContentSwitch.css';

interface IntakeContentSwitchProps {
  handleChange: (event: React.SyntheticEvent, value: AvailableIntakeContents) => void;
  intakeContent: AvailableIntakeContents;
  availableContents: AvailableIntakeContents[];
}

const IntakeContentSwitch: React.FC<IntakeContentSwitchProps> = memo(
  ({ handleChange, intakeContent, availableContents }) => {
    const { classes } = buildClasses();
    const [t] = useTranslation('intake');

    return (
      <AppBar position="static" className={classes.subNavbar}>
        <Tabs
          className={classes.subNavbarContent}
          classes={{
            indicator: classes.indicator,
          }}
          value={intakeContent}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          color="secondary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          TabIndicatorProps={{
            className: classes.tabButtonActive,
          }}
        >
          {availableContents.map((availableContent) => (
            <Tab
              data-testid={`intake__select_main_content_button_${availableContent}`}
              classes={{ root: classes.tabButton, selected: classes.tabButtonSelected }}
              key={availableContent}
              label={t(availableContent)}
              value={availableContent}
            />
          ))}
        </Tabs>
      </AppBar>
    );
  },
);

IntakeContentSwitch.displayName = 'IntakeContentSwitch';

export default IntakeContentSwitch;
