import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppDispatch } from 'hooks/useRedux';
import { UpgradeStatus, triggerVersionUpgradeRetry } from 'stores/Config';
import StartupWrapper from 'App/Routes/DefaultCompontent';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { useGetConfigQuery, useGetVersionUpgradeStatusQuery } from 'stores/Config/config.api';
import { RoutePath } from 'App/Routes/routes-paths';

const AppConfigComponent: React.FC = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [configError, setConfigError] = useState<string | undefined>();

  const { isSuccess: configLoaded } = useGetConfigQuery();

  const { isSuccess: versionStatusLoaded, data: appConfigStatus } = useGetVersionUpgradeStatusQuery(undefined, {
    pollingInterval: 1000,
    skipPollingIfUnfocused: true,
  });

  useEffect(() => {
    if (!configLoaded || !versionStatusLoaded) {
      return;
    }
    if (appConfigStatus === UpgradeStatus.upToDate) {
      navigate(RoutePath.CashierLogin);
    } else if (appConfigStatus === UpgradeStatus.failure) {
      setConfigError(`${t('Upgrade failure')}\n${t('Contact administrator')}`);
    }
  }, [appConfigStatus, configLoaded, navigate, t, versionStatusLoaded]);

  const retryVersionUpgrade = useCallback((): void => {
    dispatch(triggerVersionUpgradeRetry());
    setConfigError(undefined);
  }, [dispatch]);

  return (
    <StartupWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ my: 4, display: 'flex' }}>
          {configError ? <ErrorIcon /> : <JumpingDotsLoader />}
          <Typography sx={{ ml: 1 }} variant="h5">
            {configError ?? t('Getting configuration...')}
          </Typography>
        </Box>
        {configError && (
          <Button variant="outlined" color="primary" onClick={retryVersionUpgrade}>
            {t('Retry')}
          </Button>
        )}
      </Box>
    </StartupWrapper>
  );
};
export default AppConfigComponent;
