import { Chip, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSetStepSelection } from 'typings/Products';
import { Box } from '@mui/system';

interface ProductSetDetailsProps {
  stepSelections: ProductSetStepSelection[];
}

const ProductSetDetails: React.FC<ProductSetDetailsProps> = ({ stepSelections }) => {
  const [t] = useTranslation('intake');
  const { palette, spacing } = useTheme();
  const stepDispayValueOffset = stepSelections?.find((s) => s.order === 0) ? 1 : 0;

  function renderStepDetails(step: ProductSetStepSelection): JSX.Element {
    const toppingNames = step.selectedToppings.map((t) => `${t.title} x${t.quantity}`).join(', ');

    return (
      <Box
        key={`product-set-step-${step.order + stepDispayValueOffset}`}
        sx={{
          borderBottom: `1px solid ${palette.secondary.dark}`,
          padding: spacing(1),
          display: 'flex',
          justifyContent: 'space-between',
        }}
        data-testid="basket-item__customization-product-set-wrapper"
      >
        <Box>
          <Typography variant="body2">{step.title}</Typography>
          <Typography
            sx={{
              color: palette.black.main,
            }}
            variant="body2"
            data-testid="basket-item__customization-product-set-step-toppings"
          >
            {toppingNames}
          </Typography>
        </Box>
        <Chip label={`${t('step')} ${step.order + stepDispayValueOffset}`} size="small" />
      </Box>
    );
  }

  function renderSteps(): JSX.Element {
    const renderedSteps = stepSelections?.map((step) => {
      return renderStepDetails(step);
    });

    return <Paper>{renderedSteps?.map((renderedStep) => renderedStep)}</Paper>;
  }

  return <>{renderSteps()}</>;
};

export default ProductSetDetails;
