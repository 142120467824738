import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CashierState,
  LoginStates,
  ResetCredentialResult,
  ResetCredentialStates,
  TimekeepingResult,
} from 'typings/Cashier';
import {
  clockOutCashier,
  getEmployeeCashBalance,
  logOutCashier,
  revalidatePin,
  validateCashDrawerPermission,
} from 'stores/Cashier/cashier.thunk-actions';
import { AxiosError } from 'axios';
import { cashierAPI } from './cashier.api';

const initialState: CashierState = {
  checkingCashierInProgress: false,
  loginState: LoginStates.enterPin,
  operationInProgress: undefined,
  clockInRequired: false,
};

const cashierSlice = createSlice({
  name: '[CASHIER]',
  initialState,
  reducers: {
    resetCashierState: (state) => {
      cashierAPI.util.invalidateTags(['ClockedInUsers']);
      return {
        ...initialState,
        loggedCashier: state.loggedCashier,
      };
    },
    setLoginState: (state, action: PayloadAction<LoginStates>) => ({
      ...state,
      loginState: action.payload,
    }),
    setClockInRequired: (state, action: PayloadAction<CashierState['clockInRequired']>) => ({
      ...state,
      clockInRequired: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(logOutCashier.fulfilled, (state) => {
      return {
        ...state,
        loggedCashier: undefined,
        operationInProgress: undefined,
        clockInRequired: initialState.clockInRequired,
      };
    });
    builder.addCase(logOutCashier.rejected, (state) => {
      return {
        ...state,
        loggedCashier: undefined,
        operationInProgress: undefined,
        clockInRequired: initialState.clockInRequired,
      };
    });
    builder.addCase(clockOutCashier.pending, (state) => {
      return { ...state, operationInProgress: 'clockOut' };
    });
    builder.addCase(clockOutCashier.fulfilled, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.clockOutSuccess,
        timekeepingOperationUser: {
          id: action.payload.userId,
          firstName: action.payload.givenName,
          lastName: action.payload.familyName,
          permissions: [],
        },
        operationInProgress: undefined,
      };
    });
    builder.addCase(clockOutCashier.rejected, (state, action) => {
      return {
        ...state,
        loginState: (action.payload as { employeeId: string | undefined }).employeeId
          ? LoginStates.cashflowConfirmationError
          : LoginStates.timekeepingError,
        operationInProgress: undefined,
        errorCode: ((action.payload as AxiosError).response?.data as TimekeepingResult)?.errorCode,
      };
    });
    builder.addCase(validateCashDrawerPermission.pending, (state) => {
      return { ...state, operationInProgress: 'revalidate' };
    });
    builder.addCase(validateCashDrawerPermission.fulfilled, (state, action) => {
      return {
        ...state,
        revalidationResult: undefined,
        operationInProgress: undefined,
      };
    });
    builder.addCase(validateCashDrawerPermission.rejected, (state, action) => {
      return {
        ...state,
        revalidationResult: {
          success: false,
          revalidateFor: '',
        },
        operationInProgress: undefined,
      };
    });
    builder.addCase(getEmployeeCashBalance.pending, (state, _) => {
      return {
        ...state,
        operationInProgress: 'clockOut',
      };
    });
    builder.addCase(getEmployeeCashBalance.rejected, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.timekeepingError,
        operationInProgress: undefined,
        errorCode: ((action.payload as AxiosError).response?.data as TimekeepingResult)?.errorCode,
      };
    });

    builder.addCase(getEmployeeCashBalance.fulfilled, (state, action) => {
      return {
        ...state,
        operationInProgress: undefined,
        loginState: action.payload ? LoginStates.cashFlow : state.loginState,
        employeeCashBalanceInfo: action.payload ? action.payload : undefined,
      };
    });

    builder.addCase(revalidatePin.fulfilled, (state, action) => {
      return {
        ...state,
        revalidationResult: action.payload,
        operationInProgress: undefined,
      };
    });
    builder.addCase(revalidatePin.pending, (state) => {
      return { ...state, operationInProgress: 'revalidate' };
    });
    builder.addCase(revalidatePin.rejected, (state, action) => {
      return {
        ...state,
        revalidationResult: {
          success: false,
          revalidateFor: action.payload as string,
        },
        operationInProgress: undefined,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.getLoggedInCashier.matchPending, (state) => {
      return {
        ...state,
        checkingCashierInProgress: true,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.getLoggedInCashier.matchRejected, (state) => {
      return {
        ...state,
        checkingCashierInProgress: false,
        loggedCashier: undefined,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.getLoggedInCashier.matchFulfilled, (state, action) => {
      return {
        ...state,
        checkingCashierInProgress: false,
        loggedCashier: action.payload,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.resetPincode.matchPending, (state) => {
      return { ...state, resetCredentialState: ResetCredentialStates.inProgress };
    });
    builder.addMatcher(cashierAPI.endpoints.resetPincode.matchFulfilled, (state) => {
      return {
        ...state,
        resetCredentialState: ResetCredentialStates.success,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.resetPincode.matchRejected, (state, action) => {
      const { errorCode } = action.payload?.data as ResetCredentialResult;
      const normalizedErrorCode = errorCode ? errorCode.charAt(0).toLowerCase() + errorCode.slice(1) : undefined;
      return {
        ...state,
        resetCredentialState:
          ResetCredentialStates[normalizedErrorCode as keyof typeof ResetCredentialStates] ||
          ResetCredentialStates.genericError,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.logInCashier.matchPending, (state) => {
      return { ...state, operationInProgress: 'unlock' };
    });
    builder.addMatcher(cashierAPI.endpoints.logInCashier.matchFulfilled, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.cashierLoginSuccess,
        loggedCashier: action.payload,
        operationInProgress: undefined,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.logInCashier.matchRejected, (state, action) => {
      const errorCode = action.payload?.data as string;

      return {
        ...state,
        operationInProgress: undefined,
        loginState: errorCode === 'ExpiredPin' ? LoginStates.resetPin : LoginStates.error,
        errorCode,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.clockInCashier.matchPending, (state) => {
      return { ...state, operationInProgress: 'clockIn' };
    });
    builder.addMatcher(cashierAPI.endpoints.clockInCashier.matchFulfilled, (state, action) => {
      return {
        ...state,
        loginState: LoginStates.clockInSuccess,
        timekeepingOperationUser: {
          id: action.payload.userId,
          firstName: action.payload.givenName,
          lastName: action.payload.familyName,
          permissions: [],
        },
        operationInProgress: undefined,
      };
    });
    builder.addMatcher(cashierAPI.endpoints.clockInCashier.matchRejected, (state, action) => {
      return {
        ...state,
        operationInProgress: undefined,
        loginState: LoginStates.timekeepingError,
        errorCode: (action.payload?.data as TimekeepingResult)?.errorCode,
      };
    });
  },
});

const { reducer } = cashierSlice;
export const { setLoginState, resetCashierState, setClockInRequired } = cashierSlice.actions;
export default reducer;
