import { useCallback, useEffect } from 'react';
import { clearPhoneNumberSearchQuery, setCustomerSearchQuery } from 'stores/Intake';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

export const useCustomerSearch = () => {
  const dispatch = useAppDispatch();
  const { phoneNumberSearchQuery } = useAppSelector((state) => state.intake);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const phoneNumber = searchParams.get('phone-number');
    if (phoneNumber) dispatch(setCustomerSearchQuery(phoneNumber));
  }, [dispatch]);

  useEffect(() => {
    if (phoneNumberSearchQuery) {
      dispatch(setCustomerSearchQuery(phoneNumberSearchQuery));
      dispatch(clearPhoneNumberSearchQuery());
    }
  }, [dispatch, phoneNumberSearchQuery]);

  const searchCustomer = useCallback(
    (query: string) => {
      dispatch(setCustomerSearchQuery(query));
    },
    [dispatch],
  );

  const clearCustomerSearch = useCallback(() => {
    dispatch(setCustomerSearchQuery(undefined));
  }, [dispatch]);

  return { searchCustomer, clearCustomerSearch };
};
