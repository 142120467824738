import { Box, Grid2 as Grid, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableProductOptions } from 'utils/intake/productCustomizationUtils';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { fetchProductOptionPrices } from 'stores/Products';
import { CustomizationProduct, Option, SubOption } from 'typings/Products';
import { CheckCircle } from '@mui/icons-material';
import SingleSelectCard from 'components/Shared/Cards/SingleSelectCard/SingleSelectCard';
import { getSelectedStore } from 'stores/Store/store.selectors';
import buildClasses from './ProductOptionSelect.css';
import OptionLabelContainer from '../OptionLabelContainer/OptionLabelContainer';

interface OptionGroup {
  title: string;
  name: string;
  price: number;
}

interface SubOptionPrice extends SubOption {
  price: number;
}

interface ProductOptionSelectProps {
  customizationProduct: CustomizationProduct;
  preselectedProductId: number;
  preselectedOptionId: number;
  onChange: (optionId: number) => void;
}

const ProductOptionSelect: React.FC<ProductOptionSelectProps> = ({
  customizationProduct,
  preselectedProductId,
  preselectedOptionId,
  onChange,
}) => {
  const { activeDeliveryType } = useAppSelector(({ intake }) => intake);
  const { options, subOptions, productOptionPrices } = useAppSelector((state) => state.products);
  const selectedStore = useAppSelector(getSelectedStore);
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  const dispatch = useAppDispatch();
  const [intakeTranslations] = useTranslation('intake');

  const [selectedOption, setSelectedOption] = useState<string>(
    getOptionName(customizationProduct.originalOptionId),
  );
  const [selectedSubOption, setSelectedSubOption] = useState<number>(
    getSubOptionId(customizationProduct.originalOptionId),
  );
  const isSuboptionSelectionVisible = checkSuboptionsAvailable(customizationProduct, options);

  const optionsToDisplay = getOptionsToDisplay(customizationProduct, options);
  let subOptionsToDisplay = getSubOptionsToDisplay(selectedOption, customizationProduct, options, subOptions);

  const { classes, cx } = buildClasses();

  const { palette, spacing } = useTheme();

  function checkSuboptionsAvailable(product: CustomizationProduct, allOptions: Option[]): boolean {
    const availableOptions = getAvailableProductOptions(product, allOptions);

    return availableOptions.find((option) => option.optionSubTypeId !== undefined) !== undefined;
  }

  function getOptionName(optionId: number): string {
    const item = options.find((option) => option.id === optionId);

    if (item) {
      return item.name;
    }

    return '';
  }

  function getSubOptionId(optionId: number): number {
    const item = options.find((option) => option.id === optionId);

    if (item && item.optionSubTypeId) {
      return item.optionSubTypeId;
    }

    return -1;
  }

  function getOptionsToDisplay(product: CustomizationProduct, allOptions: Option[]): OptionGroup[] {
    const availableOptions = getAvailableProductOptions(product, allOptions);
    const items: OptionGroup[] = [];

    availableOptions.forEach((option) => {
      if (!items.find((x) => x.name === option.name)) {
        items.push({
          name: option.name,
          title: option.title,
          price: isSuboptionSelectionVisible ? 0 : findOptionPrice(option.id),
        });
      }
    });

    return items;
  }

  function getSubOptionsToDisplay(
    optionName: string,
    product: CustomizationProduct,
    allOptions: Option[],
    allSubOptions: SubOption[],
  ): SubOptionPrice[] {
    const items: SubOptionPrice[] = [];
    const availableOptions = getAvailableProductOptions(product, allOptions);

    availableOptions
      .filter((option) => option.name === optionName)
      .forEach((option) => {
        const index = allSubOptions.findIndex((subOption) => subOption.id === option.optionSubTypeId);
        if (index !== -1) {
          items.push({ ...allSubOptions[index], price: findOptionPrice(option.id) });
        }
      });

    if (items.length === 0) {
      const optionId = findOptionId(optionName, -1);

      if (optionId) {
        items.push({ id: -1, description: optionName, price: findOptionPrice(optionId) });
      }
    }

    return items;
  }

  function findOptionPrice(optionId: number): number {
    if (productOptionPrices && productOptionPrices.length > 0) {
      const optionPrice = productOptionPrices.find((optionPrice) => optionPrice.optionId === optionId);

      if (optionPrice) {
        return optionPrice.price;
      }
    }

    return 0;
  }

  function findOptionId(optionName: string, subOptionId: number): number {
    const option = options.find(
      (option) => option.name === optionName && (subOptionId < 0 || option.optionSubTypeId === subOptionId),
    );

    if (option) {
      return option.id;
    }

    return -1;
  }

  function setSelectedOptionId(optionName: string, subOptionId: number) {
    const optionId = findOptionId(optionName, subOptionId);

    if (optionId > 0) {
      onChange(optionId);
    }
  }

  useEffect(() => {
    if (selectedStore) {
      dispatch(
        fetchProductOptionPrices({
          storeId: selectedStore.id,
          pickupType: activeDeliveryType,
          productId: preselectedProductId,
        }),
      );
    }
  }, [preselectedProductId]);

  useEffect(() => {
    setSelectedOption(getOptionName(preselectedOptionId));
    setSelectedSubOption(getSubOptionId(preselectedOptionId));
  }, [preselectedOptionId]);

  useEffect(() => {
    subOptionsToDisplay = getSubOptionsToDisplay(selectedOption, customizationProduct, options, subOptions);
    const selectedSubOptionVisible = subOptionsToDisplay.find((subOption) => subOption.id === selectedSubOption);
    if (!selectedSubOptionVisible) {
      setSelectedSubOption(subOptionsToDisplay.length > 0 ? subOptionsToDisplay[0].id : -1);
      setSelectedOptionId(selectedOption, subOptionsToDisplay.length > 0 ? subOptionsToDisplay[0].id : -1);
    } else {
      setSelectedOptionId(selectedOption, selectedSubOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    setSelectedOptionId(selectedOption, selectedSubOption);
  }, [selectedSubOption]);

  return (
    <>
      {optionsToDisplay.length > 0 && (
        <Box
          sx={{
            paddingBottom: spacing(2),
            marginBottom: spacing(2),
            borderBottom: `1px solid ${palette.grey[200]}`,
          }}
        >
          <OptionLabelContainer
            testId={`product-option__container--${optionsToDisplay[0]?.name}`}
            title={intakeTranslations('Available product options')}
            option={
              optionsToDisplay?.length === 1
                ? {
                    name: optionsToDisplay[0].name,
                    price: formatToDisplay(optionsToDisplay[0].price),
                    label: intakeTranslations('Product option'),
                  }
                : null
            }
          />
          {optionsToDisplay?.length > 1 && (
            <Grid container spacing={1} data-testid="product-selection__container--options">
              {optionsToDisplay.map(
                (option) =>
                  option.name && (
                    <Grid size={{ xs: 12, sm: 6, md: 3 }} key={`option_${option.name}`}>
                      <SingleSelectCard
                        active={selectedOption === option.name}
                        selected={selectedOption === option.name}
                        label={option.name}
                        price={!isSuboptionSelectionVisible ? formatToDisplay(option.price) : undefined}
                        testId={`product-options__button--${option.name}`}
                        onSelect={() => setSelectedOption(option.name)}
                      />
                    </Grid>
                  ),
              )}
            </Grid>
          )}
        </Box>
      )}
      {isSuboptionSelectionVisible && subOptionsToDisplay && subOptionsToDisplay.length > 0 && (
        <Box
          sx={{
            paddingBottom: spacing(2),
            marginBottom: spacing(2),
            borderBottom: `1px solid ${palette.grey[200]}`,
          }}
        >
          <OptionLabelContainer
            testId={`product-sub-option__container--${subOptionsToDisplay[0]?.description}`}
            title={intakeTranslations('Available product suboptions')}
            option={
              subOptionsToDisplay?.length === 1
                ? {
                    name: subOptionsToDisplay[0].description,
                    price: formatToDisplay(subOptionsToDisplay[0].price),
                    label: intakeTranslations('Product sub-option'),
                  }
                : null
            }
          />
          {subOptionsToDisplay?.length > 1 && (
            <Grid container spacing={1} data-testid="product-selection__container--sub-options">
              {subOptionsToDisplay.map(
                (subOption) =>
                  subOption.description && (
                    <Grid
                      size={{ xs: 2 }}
                      onClick={() => setSelectedSubOption(subOption.id)}
                      key={`subOption_${subOption.id}`}
                      data-testid={`product-sub-options__button--${subOption.description}`}
                    >
                      <div
                        className={cx(classes.gridItem, {
                          [classes.active]: selectedSubOption === subOption.id,
                        })}
                      >
                        <p>{subOption.description}</p>
                        <p>{formatToDisplay(subOption.price)}</p>
                        {selectedSubOption === subOption.id && (
                          <CheckCircle sx={{ position: 'absolute', top: spacing(0.5), right: spacing(0.5) }} />
                        )}
                      </div>
                    </Grid>
                  ),
              )}
            </Grid>
          )}
        </Box>
      )}
    </>
  );
};
export default ProductOptionSelect;
