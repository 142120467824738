import { Box, CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PinPad } from 'components/Auth/PinPad';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { useAppSelector } from 'hooks/useRedux';

interface CashDrawerAuthProps {
  open: boolean;
  abort: () => void;
  onConfirm: (pin: string) => void;
}

const CashDrawerAuth: React.FC<CashDrawerAuthProps> = ({ open, abort, onConfirm }) => {
  const { sizing, spacing } = useTheme();
  const [t] = useTranslation('authentication');
  const [pin, setPin] = useState<string>('');
  const [pinErrorMessage, setPinErrorMessage] = useState<string | undefined>(undefined);
  const incorrectPinMessageClearTimeout = 3000;
  const { revalidationResult, operationInProgress } = useAppSelector(({ cashier }) => cashier);

  useEffect(() => {
    if (revalidationResult && !revalidationResult.success) {
      setPinErrorMessage(t('Incorrect PIN'));
      setPin('');
      setTimeout(() => {
        setPinErrorMessage(undefined);
      }, incorrectPinMessageClearTimeout);
    }
  }, [revalidationResult]);

  function clearPin(): void {
    setPin('');
    setPinErrorMessage(undefined);
  }
  function updatePin(pinValue: string): void {
    setPinErrorMessage(undefined);
    setPin(pinValue);
  }
  function validatePin(): boolean {
    const isPinMissing = !pin;
    setPinErrorMessage(isPinMissing ? t('PIN is required') : undefined);
    return !isPinMissing;
  }

  function handleSubmit(): void {
    if (validatePin()) {
      onConfirm(pin);
    }
  }

  return (
    <ModalContainer
      maxWidth="md"
      onClose={abort}
      open={open}
      title={t('Verify your permission to open the cash drawer.')}
      testId="revalidation-modal"
      primaryButton={{
        action: () => {
          handleSubmit();
        },
        type: 'submit',
        label: t('Revalidate'),
        testId: 'revalidation-modal-submit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: sizing.pinPad.width,
          margin: `${spacing(2)} ${spacing(3)}`,
        }}
      >
        {operationInProgress ? (
          <div>
            <CircularProgress size={120} />
          </div>
        ) : (
          <PinPad
            pinCode={pin}
            clearPin={clearPin}
            submit={handleSubmit}
            showExtraConfirm={false}
            onChange={updatePin}
            errorMessage={pinErrorMessage}
          />
        )}
      </Box>
    </ModalContainer>
  );
};
export default CashDrawerAuth;
