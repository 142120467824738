import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'hooks/useRedux';
import { AppRoute } from 'App/Routes/routes-list';
import { CircularProgress } from '@mui/material';
import { getRevalidationResult } from 'stores/Cashier/cashier.selector';
import RevalidatePinModal from 'containers/Authorize/RevalidatePinPad';
import SwitchedStoreGuard from './SwitchedStoreGuard';

export interface ProtectedRouteProps {
  routeDetails: AppRoute;
  onRedirectToBase: () => void;
}

export const CashierRoute: React.FC<ProtectedRouteProps> = ({ routeDetails, onRedirectToBase }) => {
  const revalidationResult = useAppSelector(getRevalidationResult);

  const notValidatedYet =
    !revalidationResult || !revalidationResult.success || revalidationResult.revalidateFor !== routeDetails.path;

  const revalidationRequired = routeDetails.reValidationNeeded && notValidatedYet;

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route
          path={routeDetails.path}
          element={
            revalidationRequired ? (
              <RevalidatePinModal revalidateFor={routeDetails.path} onClose={onRedirectToBase} />
            ) : (
              <SwitchedStoreGuard route={routeDetails}>
                <routeDetails.component />
              </SwitchedStoreGuard>
            )
          }
        />
      </Routes>
    </Suspense>
  );
};
