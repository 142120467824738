import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  changeDeliveryType,
  payForOrderWithEft,
  putAcceptTakeAwayPayment,
  setActiveIntakeTab,
  setManualDeliveryAddress,
  setTableTag,
  submitOrder,
} from 'stores/Intake';
import OrderRequiredInfoForm from 'components/Intake/Finalize/OrderRequiredInfoForm/OrderRequiredInfoForm';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import IntakeContentSwitch from 'components/Intake/Finalize/IntakeContentSwitch/IntakeContentSwitch';
import VisibilityContainer from 'components/Shared/Containers/VisibilityContainer';
import CouponCatalog from 'containers/Intake/Coupons/Coupons';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import uuidGenerator from 'utils/GuidGenerator';
import TakeAwayTypeSelector from 'components/Intake/Finalize/TakeAwayTypeSelector/TakeAwayTypeSelector';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { postFinalizeDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import {
  EftPaymentOperation,
  PaymentMethodCode,
  PaymentSuccessfulResult,
  StartPaymentResult,
} from 'typings/Payments';
import { clearSettlePayment } from 'stores/Payments';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { restoreTransferredIntakeState } from 'stores/HybridMode/hybridMode.thunk-actions';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { removeCustomerFromOrder, restartOrder } from 'stores/combined.actions';
import { clearEftPaymentData } from 'stores/EftPayment/eftPayment.slice';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { getFeatureFlags } from 'stores/Config/config.selector';
import { clearOrderPaymentStatus } from 'stores/OrderPayment/orderPayment.slice';
import ProductsContainer from './Products/Products';
import { Receipt } from './Receipt/Receipt';
import buildClasses from './ToGoIntake.css';
import {
  AvailableIntakeContents,
  getAvailableToGoIntakeContents,
  IntakeStatuses,
  PickUpTypesValues,
} from './IntakeConsts';
import CustomerSearchResults from './Customer/CustomerSearchResults';
import { isBigScreenMediaQuery } from './Receipt/Receipt.css';
import DineInOrdersOverview from './DineInOrders/Overview';
import FloorsSection from './Floors/FloorsSection';
import MealConfigurator from './Products/MealConfigurator/MealConfigurator';
import RewardsContainer from './Loyalty/RewardsContainer';
import { useCustomerSearch } from './useCustomerSearch';

const ToGoIntake: React.FC = () => {
  const { searchCustomer, clearCustomerSearch } = useCustomerSearch();

  const { classes, cx } = buildClasses();
  const [t] = useTranslation('intake');
  const dispatch = useAppDispatch();
  const [receiptVisible, setReceiptVisible] = useState<boolean>(true);
  const isBigScreen = useMediaQuery(isBigScreenMediaQuery);
  const [orderId, setOrderId] = useState<string>(uuidGenerator());

  const {
    activeDeliveryType,
    selectedOrderCustomer,
    activeIntakeTab,
    manualPriceOverride,
    activePaymentMethod,
    orderPlacementStatus,
    customerSearchQuery,
  } = useAppSelector((state) => state.intake);
  const { selectedDineInOrder } = useAppSelector((state) => state.dineIn);
  const { productToCustomize } = useAppSelector((state) => state.products);
  const { couponToCustomize } = useAppSelector((store) => store.coupons);
  const { basketData } = useAppSelector((state) => state.basket);
  const { customerCanPayOnAccount, chargedCompany } = useAppSelector((store) => store.customer);
  const selectedStore = useAppSelector(getSelectedStore);
  const featureFlags = useAppSelector(getFeatureFlags);

  const { transferredIntake } = useAppSelector((store) => store.hybridMode);
  const { status: orderPaymentStatus } = useAppSelector((store) => store.orderPayment);

  const intakeStatus = IntakeStatuses.productsSection;
  const showCustomerSearch = customerSearchQuery !== undefined;

  useEffect(() => {
    if (transferredIntake && selectedStore) {
      dispatch(restoreTransferredIntakeState(transferredIntake));
    }
  }, [transferredIntake, selectedStore, dispatch]);

  const { getValues, setValue, trigger, handleSubmit } = useFormContext<CheckoutDetailsForm>();

  const isTakeAwayOrder = () => activeDeliveryType === PickUpTypesValues.takeAway;

  function getFormData(): CheckoutDetailsForm {
    let finalizationData = { ...getValues() };

    if (selectedOrderCustomer?.profile?.id) {
      finalizationData = { ...finalizationData, profile: { ...selectedOrderCustomer.profile } };
    }

    return finalizationData;
  }
  function clearEftDataFromForm(): void {
    setValue('EftPaymentOperation', undefined);
  }

  const closeSettlePaymentForm = (): void => {
    clearEftDataFromForm(); // to remove, should not exit any data in form that is not handled via checkoutForm context
    dispatch(clearSettlePayment()); // to remove with payment v2
    dispatch(clearEftPaymentData());
    setOrderId(uuidGenerator());
  };

  function handleFinishedOrder(): void {
    closeSettlePaymentForm();
    dispatch(setActiveIntakeTab(AvailableIntakeContents.Products)); // to remove, should be in combined "restart order action"
    dispatch(removeCustomerFromOrder()); // to remove with payment V2
    dispatch(setManualDeliveryAddress()); // to remove with payment V2
  }

  const openSettlePaymentForm = (): void => {
    // for payment V2 take-away/dine-in/eat-in we always place unpaid order.
    if (basketData?.summary) {
      dispatch(
        openOrderPaymentFromSettlePayment({
          orderId,
          closeCallback: closeSettlePaymentForm,
          successCallback: finalizePayment,
          startPaymentCallback: startPayment,
          totalToPay: basketData.summary.total,
          useTwoStepOrderPlacing: selectedDineInOrder === undefined,
          openTabId: selectedDineInOrder?.id,
          orderFinalizationData: getFormData(),
          preselectedPaymentMethod: activePaymentMethod,
          deliveryType: activeDeliveryType,
          isLocalOrder: true,
          cancelOnClose: true,
          customerData: {
            payOnAccountAccount: {
              customerCanPayOnAccount,
              chargedCompany,
            },
          },
        }),
      );
    }
  };

  function finalizePayment(result: PaymentSuccessfulResult): void {
    const usePaymentAcceptance =
      result.orderAlreadyPlaced || (isTakeAwayOrder() && !!result.eftPaymentOperation?.eftPaymentId);

    if (usePaymentAcceptance) {
      acceptPayment(result.orderId, result.paymentMethodCode, result.eftPaymentOperation);
    } else {
      paymentSuccessful(result.orderId, result.paymentMethodCode, result.eftPaymentOperation);
    }
  }

  function startPayment(result: StartPaymentResult): void {
    const usePaymentAcceptance = isTakeAwayOrder() && !!result.eftPaymentId;
    if (usePaymentAcceptance) {
      if (activeDeliveryType === PickUpTypesValues.dineIn && selectedDineInOrder !== undefined) {
        return;
      }

      if (result.orderAlreadyPlaced) {
        dispatch(payForOrderWithEft());
      } else {
        setValue('EftPaymentOperation', {
          eftPaymentId: result.eftPaymentId,
          finishedManually: false,
        });
        submitForm();
      }
    }
  }

  function acceptPayment(
    orderId: string,
    paymentMethod: PaymentMethodCode,
    eftPaymentOperation?: EftPaymentOperation,
  ): void {
    dispatch(
      putAcceptTakeAwayPayment({
        orderId,
        paymentMethodCode: paymentMethod,
        eftPaymentOperation,
      }),
    );
    handleFinishedOrder();
  }

  const onHideReceipt = useCallback(() => {
    if (!isBigScreen) setReceiptVisible(false);
  }, [isBigScreen]);

  const onShowReceipt = useCallback((): void => {
    setReceiptVisible(true);
  }, []);

  function submitForm(): void {
    handleSubmit((d) => onFormSubmit(d))();
  }

  async function finishButtonClickedHandler(): Promise<void> {
    const isCheckoutFormValid = await trigger();
    if (!isCheckoutFormValid && activeDeliveryType !== PickUpTypesValues.dineIn) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Customer));
      return;
    }
    if (activeDeliveryType === PickUpTypesValues.takeAway || activeDeliveryType === PickUpTypesValues.dineIn) {
      openSettlePaymentForm();
    } else {
      submitForm();
    }
  }

  function paymentSuccessful(
    orderId: string,
    paymentMethod: PaymentMethodCode,
    eftPaymentOperation?: EftPaymentOperation,
  ): void {
    const formValues = getValues();
    if (selectedDineInOrder) {
      dispatch(
        postFinalizeDineInOrder({
          tabId: selectedDineInOrder.id,
          newOrderId: orderId,
          config: { paymentMethodCode: paymentMethod, eftPaymentOperation, manualPriceOverride },
        }),
      );
    } else {
      onFormSubmit(formValues, true);
    }
    closeSettlePaymentForm();
  }

  function onFormSubmit(data: CheckoutDetailsForm, paymentSettled = false): void {
    let finalizationData = { ...data };

    if (selectedOrderCustomer?.profile?.id) {
      finalizationData = { ...finalizationData, profile: { ...selectedOrderCustomer.profile } };
    }

    switch (activeDeliveryType) {
      case PickUpTypesValues.takeAway:
        if (
          activeDeliveryType === PickUpTypesValues.takeAway &&
          finalizationData.EftPaymentOperation?.eftPaymentId !== undefined &&
          finalizationData.EftPaymentOperation?.finishedManually === false
        ) {
          dispatch(
            submitOrder({
              orderId,
              data: finalizationData,
              placeUnpaid: true,
            }),
          );
        } else if (paymentSettled) {
          dispatch(
            submitOrder({
              orderId,
              data: finalizationData,
            }),
          );
        } else {
          openSettlePaymentForm();
        }
        break;
      default:
        dispatch(
          submitOrder({
            orderId,
            data: finalizationData,
            isAsap: finalizationData.time === t('asap'),
          }),
        );
    }
  }

  function saveTableTag(value?: string): void {
    dispatch(setTableTag(value));
  }

  useEffect(() => {
    if (productToCustomize) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
  }, [dispatch, productToCustomize]);

  useEffect(() => {
    if (couponToCustomize) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Coupons));
    }
  }, [couponToCustomize]);

  useEffect(() => {
    if (selectedDineInOrder) {
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
      onShowReceipt();
    } else if (
      activeIntakeTab === AvailableIntakeContents.DineInOpenOrders ||
      activeIntakeTab === AvailableIntakeContents.Tables
    ) {
      onHideReceipt();
    }
  }, [selectedDineInOrder]);

  useEffect(() => {
    if (orderPlacementStatus === 'success') {
      handleFinishedOrder();
    }
  }, [orderPlacementStatus]);

  useEffect(() => {
    if (orderPaymentStatus === 'abandoned' || orderPaymentStatus === 'completed') {
      handleFinishedOrder();
      dispatch(restartOrder());
    }
    if (orderPaymentStatus === 'canceled') {
      closeSettlePaymentForm();
    }
    dispatch(clearOrderPaymentStatus());
  }, [dispatch, orderPaymentStatus]);

  const showIntakeContentSwitch = productToCustomize === undefined;

  const switchIntakeContent = useCallback(
    (_: React.SyntheticEvent, value: AvailableIntakeContents): void => {
      switch (value) {
        case AvailableIntakeContents.Tables:
        case AvailableIntakeContents.DineInOpenOrders:
          if (!selectedDineInOrder) {
            dispatch(changeDeliveryType(PickUpTypesValues.takeAway));
            onHideReceipt();
          } else onShowReceipt();
          break;
        default:
          onShowReceipt();
      }

      dispatch(setActiveIntakeTab(value));
    },
    [dispatch, onHideReceipt, selectedDineInOrder],
  );

  const availableIntakeContents = useMemo(
    () =>
      getAvailableToGoIntakeContents(
        featureFlags.OfflineModule_Restaurant,
        featureFlags.OfflineModule_UseFloorPlanConfigurator,
        featureFlags.OfflineModule_ShowRewardsOnPos,
      ),
    [featureFlags],
  );

  return (
    <div id="intake-window" className={classes.intakeWindow}>
      <div className={cx(classes.intakeContent, { [classes.intakeContentFullWidth]: !receiptVisible })}>
        {showCustomerSearch && (
          <CustomerSearchResults
            query={customerSearchQuery}
            onCancelCustomerSelection={clearCustomerSearch}
            onIntakeWithoutProfileClick={clearCustomerSearch}
            onCustomerSelected={clearCustomerSearch}
          />
        )}
        {couponToCustomize && !showCustomerSearch && <MealConfigurator />}
        {!couponToCustomize && !showCustomerSearch && (
          <>
            {showIntakeContentSwitch && (
              <IntakeContentSwitch
                handleChange={switchIntakeContent}
                intakeContent={activeIntakeTab}
                availableContents={availableIntakeContents}
              />
            )}
            {activeIntakeTab === AvailableIntakeContents.Products && (
              <Box className={classes.fullHeight}>
                <ProductsContainer onHideReceipt={onHideReceipt} onShowReceipt={onShowReceipt} />
              </Box>
            )}
            {activeIntakeTab === AvailableIntakeContents.Coupons && (
              <Box className={classes.fullHeight}>
                <CouponCatalog />
              </Box>
            )}
            {activeIntakeTab === AvailableIntakeContents.Rewards && (
              <Box className={classes.fullHeight}>
                <RewardsContainer />
              </Box>
            )}
            {activeIntakeTab === AvailableIntakeContents.DineInOpenOrders && (
              <Box>
                <DineInOrdersOverview onOrderClick={onShowReceipt} />
              </Box>
            )}
            {activeIntakeTab === AvailableIntakeContents.Tables && (
              <Box className={classes.fullHeight}>
                <FloorsSection receiptVisible={receiptVisible} setReceiptVisibility={setReceiptVisible} />
              </Box>
            )}

            <VisibilityContainer
              classes={classes.fullHeight}
              isVisible={activeIntakeTab === AvailableIntakeContents.Customer}
            >
              <Box className={classes.orderRequiredFormWrapper}>
                <OrderRequiredInfoForm onFormSubmit={onFormSubmit} />
                {selectedStore?.enableEatInOrders === true &&
                  activeDeliveryType === PickUpTypesValues.takeAway && (
                    <TakeAwayTypeSelector onTableTagChange={saveTableTag} />
                  )}
              </Box>
            </VisibilityContainer>
          </>
        )}
      </div>
      <Drawer className={classes.receiptDrawer} anchor="right" open={receiptVisible} variant="persistent">
        <Receipt
          intakeStatus={intakeStatus}
          onFinishOrderButtonClick={finishButtonClickedHandler}
          onSearchCustomer={searchCustomer}
          onIntakeWithoutProfile={clearCustomerSearch}
          canChangePaymentMethod
        />
      </Drawer>
    </div>
  );
};

export default ToGoIntake;
