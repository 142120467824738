import { RootState } from 'stores';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state;

const selectEft = createSelector(selectSelf, (state) => state.eft);

export const getEFTStatus = createSelector(selectEft, (eft) => {
  if (eft.success) return 'SUCCESS';
  if (eft.startTransactionInProgress || (eft.eftPaymentId && !eft.success && !eft.error)) return 'PENDING';
  return 'IDLE';
});
