import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import HybridModeState, { HybridModeActivityChangedEvent, TransferredIntake } from 'typings/HybridMode';

const initialState: HybridModeState = {
  isHybridModeActive: false,
  offlineModeEnabled: true,
};

const hybridModeSlice = createSlice({
  name: '[HYBRID-MODE]',
  initialState,
  reducers: {
    setIsHybridModeActive(state, action: PayloadAction<HybridModeActivityChangedEvent>) {
      return {
        ...state,
        isHybridModeActive: action.payload.isActive,
        offlineModeEnabled: action.payload.offlineModeEnabled,
      };
    },
    updateCloudPosAvailabilityStatus(state, action: PayloadAction<boolean>) {
      return { ...state, isCloudPosAvailable: action.payload };
    },
    updateCloudPosLastConnectionAttempt(state, action: PayloadAction<Date | undefined>) {
      return { ...state, lastCloudPosConnectionAttempt: action.payload };
    },
    setTransferredIntake(state, action: PayloadAction<TransferredIntake>) {
      return { ...state, transferredIntake: action.payload };
    },
    clearTransferredIntake(state) {
      return { ...state, transferredIntake: undefined };
    },
    setPhoneNumberCalling(state, action: PayloadAction<string>) {
      return { ...state, phoneNumberCalling: action.payload };
    },
    clearPhoneNumberCalling(state) {
      return { ...state, phoneNumberCalling: undefined };
    },
  },
});

const { reducer } = hybridModeSlice;

export const {
  setIsHybridModeActive,
  updateCloudPosAvailabilityStatus,
  updateCloudPosLastConnectionAttempt,
  setTransferredIntake,
  clearTransferredIntake,
  setPhoneNumberCalling,
  clearPhoneNumberCalling,
} = hybridModeSlice.actions;

export default reducer;
