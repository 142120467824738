import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from 'containers/AllOrders/AllOrderConsts';

interface OrderStatusLabelProps {
  status: string;
  isOrderRefunded: boolean;
}

const OrderStatusLabel: React.FC<OrderStatusLabelProps> = ({ status, isOrderRefunded }) => {
  const [t] = useTranslation('orders');
  const theme = useTheme();

  function getStatusBackgroundColor(status: string): string {
    switch (status.toUpperCase()) {
      case OrderStatus.ToPrepare.toUpperCase():
        return theme.palette.labels[50];
      case OrderStatus.InPreparation.toUpperCase():
        return theme.palette.labels[100];
      case OrderStatus.Canceled.toUpperCase():
        return theme.palette.labels[900];
      case OrderStatus.Completed.toUpperCase():
        return theme.palette.labels[800];
      case OrderStatus.OutForDelivery.toUpperCase():
        return theme.palette.labels[300];
      case OrderStatus.ReadyForDispatch.toUpperCase(): {
        return theme.palette.labels[500];
      }
      default:
        return theme.palette.grey[100];
    }
  }
  function getStatusTextColor(status: string): string {
    switch (status.toUpperCase()) {
      case OrderStatus.ToPrepare.toUpperCase():
        return theme.palette.yellow[900];
      case OrderStatus.InPreparation.toUpperCase():
        return theme.palette.yellow[900];
      case OrderStatus.Canceled.toUpperCase():
        return theme.palette.grey[800];
      case OrderStatus.Completed.toUpperCase():
        return theme.palette.green[900];
      case OrderStatus.OutForDelivery.toUpperCase():
        return theme.palette.red[900];
      case OrderStatus.ReadyForDispatch.toUpperCase():
        return theme.palette.blue[900];
      default:
        return theme.palette.grey[800];
    }
  }

  function getDisplayStatus() {
    if (status.toUpperCase() === OrderStatus.Canceled.toUpperCase() && isOrderRefunded) {
      return 'CanceledAndRefunded';
    }
    return status;
  }

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2, mt: 1 }}
      data-testid="all-orders__order_details_field-order-status"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 0.5,
          px: 1.5,
          backgroundColor: getStatusBackgroundColor(status),
          borderRadius: 0.5,
        }}
      >
        <Typography variant="subtitle2" sx={{ color: getStatusTextColor(status) }}>
          {t(getDisplayStatus())}
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderStatusLabel;
