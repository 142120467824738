import React, { useMemo } from 'react';
import { Box, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClockedInEmployee from 'components/Auth/ClockedInEmployee';
import { useGetClockedInUsersQuery } from 'stores/Cashier/cashier.api';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import buildClasses from './ClockedInEmployeesList.css';

const ClockedInEmployeesList: React.FC = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation('authentication');

  const { isSuccess, data, isFetching } = useGetClockedInUsersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const clockedInEmployees = useMemo(() => (isSuccess ? data : []), [data, isSuccess]);

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h3" color="black.main" sx={{ mb: 4 }}>
        {t('Clocked in employees')}
      </Typography>
      {isFetching ? (
        <PartialLoader />
      ) : clockedInEmployees.length < 1 ? (
        <Box className={classes.emptyListAlert}>
          <Typography variant="body1">{t('Oops, it looks like no one has checked in for work yet!')}</Typography>
        </Box>
      ) : (
        <List className={classes.employeeListContainer}>
          {clockedInEmployees.map((employeeInfo) => {
            if (!employeeInfo) return null;
            return <ClockedInEmployee employeeInfo={employeeInfo} key={employeeInfo.fullname} />;
          })}
        </List>
      )}
    </Box>
  );
};

export default ClockedInEmployeesList;
