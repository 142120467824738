import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingGroup from 'components/Settings/SettingGroup/SettingGroup';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import { useAppSelector } from 'hooks/useRedux';
import { getSupportedLanguages } from 'stores/Cms/cms.selector';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';

const Settings = (): JSX.Element => {
  const [t, i18n] = useTranslation('settings');

  function handleLanguageChange(value: unknown): void {
    const languageCode = value as string;
    i18n.changeLanguage(languageCode);
    const hybridModeCommunicator = getHybridModeCommunicator();
    hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.LanguageChanged, {
      languageCode,
    });
  }
  const languages = useAppSelector(getSupportedLanguages);

  return (
    <SettingGroup label={t('language')}>
      <SettingItem
        label={t('Choose language')}
        type="select"
        selectOptions={languages.map((lang) => {
          return { value: lang.code, description: lang.name };
        })}
        changeHandler={handleLanguageChange}
        defaultValue={i18n.language.slice(0, 2)}
        testId="language-settings__select--choose-language"
      />
    </SettingGroup>
  );
};

export default Settings;
