import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Chip, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { BasketItem, ReceiptDiscount, ReceiptProduct } from 'typings/Basket';
import ItemQuantity from 'components/Intake/Receipt/Item/ItemQuantity/ItemQuantity';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ReceiptProductToppings from 'components/Intake/Receipt/Item/ReceiptProduct/ReceiptProductToppings';
import { DeleteOutline } from '@mui/icons-material';

interface ReceiptSetDiscountDetailsProps {
  discount: ReceiptDiscount;
  discountProducts: ReceiptProduct[];
  customizeProductHandler: (product: BasketItem) => void;
  canRemoveItem: boolean;
  onRemoveFromBasket?: (id: number) => void;
  changeAutoAddedItemQuantity?: (itemId: number, quantity: number) => void;
}

const ReceiptSetDiscountDetails: React.FC<ReceiptSetDiscountDetailsProps> = ({
  discount,
  canRemoveItem,
  discountProducts,
  customizeProductHandler,
  onRemoveFromBasket,
  changeAutoAddedItemQuantity,
}) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');
  const { palette, radius } = useTheme();

  function itemIsXTasty(receiptProduct: BasketItem): boolean {
    return receiptProduct.sliceCustomizations ? receiptProduct.sliceCustomizations.length > 1 : false;
  }

  function itemHasCustomization(receiptProduct: BasketItem): boolean {
    return receiptProduct.sliceCustomizations
      ? receiptProduct.sliceCustomizations.length > 0 &&
          receiptProduct.sliceCustomizations.some(
            (el) =>
              (el.addedToppings && el.addedToppings.length > 0) ||
              (el.removedToppings && el.removedToppings.length > 0),
          )
      : false;
  }

  return (
    <Paper>
      {discountProducts.map((product) => (
        <Box
          sx={{
            borderBottom: `1px solid ${palette.secondary.dark}`,
            '&:last-child': {
              borderBottom: 'none',
            },
          }}
          key={`discount_item-${discount.code}-${product.basketItemGuid}`}
          data-testid="receipt-item--discount-details"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: spacing(1) }}>
            <Box sx={{ display: 'flex' }}>
              <ButtonBase
                onClick={() => {
                  customizeProductHandler(product);
                }}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}
              >
                <ItemQuantity quantity={product.quantity} />
                <Typography sx={{ marginLeft: spacing(2), color: palette.black.main }} variant="body2">
                  {`${product.optionName} - ${product.itemName}`}
                </Typography>
              </ButtonBase>
              {canRemoveItem && (
                <IconButton
                  onClick={(): void => {
                    onRemoveFromBasket && onRemoveFromBasket(product.id);
                  }}
                  sx={{ color: `${palette.error.main}` }}
                  data-testid="receipt-item-product__button--delete-product"
                >
                  <DeleteOutline />
                </IconButton>
              )}
            </Box>
            {(itemHasCustomization(product) || itemIsXTasty(product)) && (
              <Box sx={{ padding: `0 ${spacing(0.5)}`, marginBottom: spacing(0.5) }}>
                {product.sliceCustomizations?.map((slice, index) => {
                  return (
                    <Box
                      sx={{
                        border: `1px solid ${palette.customGray.main}`,
                        borderRadius: radius.sm,
                        padding: spacing(1),
                        marginTop: spacing(1),
                      }}
                    >
                      {itemIsXTasty(product) && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: spacing(0.5),
                          }}
                        >
                          <Typography variant="body2">{slice.sliceProductName}</Typography>
                          {itemIsXTasty(product) && (
                            <Chip label={`${t('part')} ${index + 1}`} size="small" sx={{ mr: 1.5 }} />
                          )}
                        </Box>
                      )}
                      <ReceiptProductToppings
                        addedToppings={slice.addedToppings}
                        removedToppings={slice.removedToppings}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          {product.boundedProducts?.map((bp) => (
            <Box sx={{ p: 1, background: palette.grey[50], marginLeft: spacing(2), color: palette.black.main }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
                <ItemQuantity quantity={bp.quantity} />
                <Typography sx={{ marginLeft: spacing(2), color: palette.black.main }} variant="body2">
                  {`${bp.optionName} - ${bp.itemName}`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    flex: 1,
                    justifyContent: 'flex-end',
                    mr: 3,
                  }}
                >
                  <IconButton
                    data-testid="receipt-item-bounded__button--add-quantity"
                    disabled={bp.quantity <= 0}
                    sx={{ p: 0 }}
                    onClick={() => {
                      changeAutoAddedItemQuantity && changeAutoAddedItemQuantity(bp.id, bp.quantity - 1);
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton
                    data-testid="receipt-item-bounded__button--subtract-quantity"
                    disabled={bp.quantity >= product.quantity}
                    onClick={() => {
                      changeAutoAddedItemQuantity && changeAutoAddedItemQuantity(bp.id, bp.quantity + 1);
                    }}
                    sx={{ p: 0 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Paper>
  );
};

export default ReceiptSetDiscountDetails;
