import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import {
  AcceptManualPaymentRequest,
  FinalizePaidOrderRequest,
  PaymentMethod,
  PaymentMethodCode,
  SettlePaymentState,
} from 'typings/Payments';

import { setSettlePayment } from 'stores/Payments';
import { AxiosResponse } from 'axios';
import { OrderHistory, finalizeOsmPickupV2, sendIntakeFinalizedEvent, submitOrder } from 'stores/Intake';
import {
  OrderPaymentDetails,
  OrderPaymentPart,
  clearOrderPaymentState,
  setPaymentParts,
  setSelectedPaymentMethodGroupName,
} from 'stores/OrderPayment/orderPayment.slice';
import uuidGenerator from 'utils/GuidGenerator';
import { postPlaceUnpaidDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import { restartOrder } from 'stores/combined.actions';
import { appInsights } from 'App/AppInitializationWrapper/useAppInsights';
import {
  OrderPaymentStatusData,
  OrderPaymentStatusResponse,
  SplitPaymentData,
} from 'typings/EftPayment/eftPayment.types';
import { isValidSuccessfulAxiosResponse } from 'typings/type-guards';
import { cancelOnPaymentAbandon } from 'stores/AllOrders/allOrders-actions';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { initEftFromIncompleted } from 'stores/EftPayment/eftPayment.slice';
import { Company } from 'typings/Customer';
import { checkOrderGiftCards } from 'stores/GiftCardActivation/giftCardActivation.actions';
import { OrderPaymentStatus } from 'typings/OrderPayment';
import { getPaymentConfig, getPaymentMethodsConfiguration } from '../Config/config.selector';
import { getSelectedStore } from '../Store/store.selectors';

export interface OpenOrderPaymentData extends SettlePaymentState {
  cancelOnClose?: boolean;
  isLocalOrder?: boolean;
  preselectedPaymentMethod?: PaymentMethodCode;
  availablePaymentMethods?: PaymentMethod[];
  customerData?: {
    customerId?: number;
    payOnAccountAccount?: {
      customerCanPayOnAccount?: boolean;
      chargedCompany?: Company;
    };
  };
}

export interface OrderPaymentOpeningData extends OpenOrderPaymentData {
  orderPayments: OrderPaymentPart[];
  isPaid: boolean;
  leftToPay: number;
  paidAmount: number;
  groupName?: string;
}

export const openOrderPayment = createAsyncThunk<
  OrderPaymentOpeningData,
  OpenOrderPaymentData,
  { state: RootState }
>('[OrderPayment]/openOrderPayment', async (settlePaymentData, { getState, dispatch }) => {
  const { payments } = getState();

  const paymentMethodsConfiguration = getPaymentMethodsConfiguration(getState());

  const { forcedPaymentMethodCode, customerData } = settlePaymentData;

  const payOnAccountCode = paymentMethodsConfiguration?.payOnAccountMethodCode;
  const payOnAccountAvailable =
    customerData?.payOnAccountAccount?.customerCanPayOnAccount === true &&
    !!customerData?.payOnAccountAccount?.chargedCompany;

  const allPaymentMethods = settlePaymentData.availablePaymentMethods ?? payments.availablePaymentMethods;

  const availablePaymentMethods = forcedPaymentMethodCode
    ? allPaymentMethods.filter((el) => el.code === forcedPaymentMethodCode)
    : allPaymentMethods.filter(
        (el) => el.code !== payOnAccountCode || (el.code === payOnAccountCode && payOnAccountAvailable),
      );

  availablePaymentMethods.filter((el) => el.code === payOnAccountCode && !payOnAccountAvailable);

  const preselectedPaymentMethod = availablePaymentMethods.find(
    (el) => el.code === settlePaymentData.preselectedPaymentMethod,
  );

  const orderPaymentStatusResponse = await posApi.get<OrderPaymentStatusResponse>(
    posApiUrls.PAYMENT_V2_PAYMENT_STATUS,
    {
      params: { orderId: settlePaymentData.orderId, isLocalOrder: settlePaymentData.isLocalOrder },
    },
  );

  if (!isValidSuccessfulAxiosResponse(orderPaymentStatusResponse)) {
    throw new Error('Could not get the order payment status details');
  }
  settlePaymentData.isLocalOrder && dispatch(getOrderTicketNumberForPayment(settlePaymentData.orderId));

  const paymentData = mapToOrderPaymentData(orderPaymentStatusResponse.data);
  const { isPaid, leftToPay, paidAmount } = paymentData;

  const orderPayments = mapToPayments(paymentData, availablePaymentMethods, preselectedPaymentMethod);

  const incompletedEftPayment = orderPayments.find(
    (el) => el.isActive && el.paymentMethod?.supportsEftPayment === true && el.paymentId,
  );
  if (incompletedEftPayment) {
    dispatch(initEftFromIncompleted(incompletedEftPayment.paymentId as string));
  }

  const groupName = preselectedPaymentMethod?.groupName;

  return {
    ...settlePaymentData,
    isPaid,
    leftToPay,
    paidAmount,
    availablePaymentMethods,
    orderPayments,
    groupName,
  };
});

export const exitOrderPayment = createAsyncThunk<void, void, { state: RootState }>(
  '[OrderPayment]/exitOrderPayment',
  (_, { dispatch }) => {
    dispatch(clearOrderPaymentState());
  },
);

export const manualExitOrderPayment = createAsyncThunk<OrderPaymentStatus, void, { state: RootState }>(
  '[OrderPayment]/manualExitOrderPayment',
  (_, { dispatch, getState }) => {
    const {
      orderPayment: { cancelOnClose, isLocalOrder, orderId, openTabId, details, payments },
    } = getState();

    const somePaymentsCompleted = payments.some((el) => el.isCompleted);

    if (cancelOnClose && isLocalOrder && orderId && !openTabId && !somePaymentsCompleted) {
      dispatch(
        cancelOnPaymentAbandon({
          orderId,
          canceledOrderIdentifier: details?.ticketNumber?.toString() ?? '',
        }),
      );
    }
    dispatch(clearOrderPaymentState());
    return somePaymentsCompleted ? 'abandoned' : 'canceled';
  },
);

export const openOrderPaymentFromSettlePayment = createAsyncThunk<
  void,
  OpenOrderPaymentData,
  { state: RootState }
>('[OrderPayment]/openOrderPaymentFromSettlePayment', async (settlePayment, { getState, dispatch }) => {
  const {
    intake: { manualPriceOverride },
  } = getState();
  const selectedStore = getSelectedStore(getState());
  const payment = getPaymentConfig(getState());
  const { orderId, orderFinalizationData, openTabId } = settlePayment;
  const storeIsUsingDirectEftIntegration = payment?.v2.useInStores?.some((el) => el === selectedStore?.id);

  appInsights.trackEvent({
    name: 'Opening payment modal with data',
    properties: {
      storeIsUsingDirectEftIntegration,
      selectedStore,
      orderId,
      orderFinalizationData,
      openTabId,
    },
  });
  if (storeIsUsingDirectEftIntegration) {
    if (openTabId) {
      const placeUnpaidDineIn = await dispatch(
        postPlaceUnpaidDineInOrder({
          tabId: openTabId as string,
          newOrderId: orderId,
          manualPriceOverride,
        }),
      );
      if (placeUnpaidDineIn.meta.requestStatus === 'fulfilled') {
        dispatch(restartOrder());
        // this "if" should be improved when V1 is removed
        dispatch(openOrderPayment(settlePayment)); // new payment state
        return;
      }
    }

    if (orderFinalizationData !== undefined) {
      const submittingOrderResult = await dispatch(
        submitOrder({
          orderId,
          data: orderFinalizationData,
          placeUnpaid: true,
        }),
      );

      if (submittingOrderResult.meta.requestStatus === 'fulfilled') {
        // this "if" should be improved when V1 is removed
        dispatch(openOrderPayment(settlePayment)); // new payment state
        return;
      }
    }
    dispatch(openOrderPayment(settlePayment)); // new payment state
    return;
  }
  dispatch(setSettlePayment(settlePayment)); // old payment state
});

export const calculateChange = createAsyncThunk<
  number,
  { paymentAmount: number; tipAmount: number },
  { state: RootState }
>('[OrderPayment]/calculateChange', async ({ paymentAmount, tipAmount }, { getState }) => {
  const state = getState();

  const req = {
    paymentAmount,
    tipAmount,
    totalPrice: state.orderPayment.totalLeftAmount,
  };
  const response: AxiosResponse<number> = await posApi.get(posApiUrls.PAYMENT_CHANGE, {
    params: req,
  });
  return response.data;
});

export const acceptPaymentForOrder = createAsyncThunk<void, { successCallback: () => void }, { state: RootState }>(
  '[OrderPayment]/acceptPaymentForOrder',
  async ({ successCallback }, { getState }) => {
    const {
      orderPayment: { isLocalOrder, orderId, payments, totalLeftAmount },
    } = getState();
    const activePayment = payments.find((el) => el.isActive);

    if (isLocalOrder === undefined || orderId === undefined || activePayment === undefined) {
      throw Error('Could not accept manual payment - missing data');
    }
    if (!activePayment.paymentMethod) {
      throw Error('Could not accept manual payment - missing payment method selected');
    }

    const toPayAmount =
      activePayment.customerPaidWith < totalLeftAmount ? activePayment.customerPaidWith : totalLeftAmount;

    const request: AcceptManualPaymentRequest = {
      orderAmount: toPayAmount,
      isLocalOrder,
      orderId,
      paymentId: uuidGenerator(),
      paymentMethodId: activePayment.paymentMethod.id,
      tipAmount: activePayment.tipValue,
    };
    appInsights.trackEvent({
      name: 'Accepting payment for order',
      properties: {
        ...request,
      },
    });

    const response: AxiosResponse<number> = await posApi.put(posApiUrls.PAYMENT_V2_ACCEPT_MANUAL_PAYMENT, request);
    if (!isValidSuccessfulAxiosResponse(response)) {
      throw Error('Could not accept payment for order');
    }

    successCallback();
  },
);

export const getOrderTicketNumberForPayment = createAsyncThunk<OrderPaymentDetails, string, { state: RootState }>(
  '[OrderPayment]/getOrderTicketNumberForPayment',
  async (orderId) => {
    // This will be much more improved with "payment session" concept, and necessary before opening modal V2
    const result = await posApi.get<OrderHistory>(posApiUrls.HISTORY_ORDER(orderId));
    return {
      ticketNumber: result.data.order.ticketNumber,
      tableName: result.data.order.tableIdentifier,
    };
  },
);

export const checkIfOrderIsFullyPaid = createAsyncThunk<OrderPaymentStatusData, void, { state: RootState }>(
  '[OrderPayment]/checkIfOrderIsFullyPaid',
  async (_, { dispatch, getState }) => {
    const { availablePaymentMethods, orderId, isLocalOrder } = getState().orderPayment;
    const orderPaymentStatusResponse = await posApi.get<OrderPaymentStatusResponse>(
      posApiUrls.PAYMENT_V2_PAYMENT_STATUS,
      {
        params: { orderId, isLocalOrder },
      },
    );

    if (!isValidSuccessfulAxiosResponse(orderPaymentStatusResponse)) {
      throw new Error(`Order payment status could not be get for orderId=${orderId}`);
    }

    const paymentData = mapToOrderPaymentData(orderPaymentStatusResponse.data);
    const { isPaid } = paymentData;
    if (isPaid) {
      dispatch(finalizeOrderAndExit());
      return paymentData;
    }

    const orderPayments = mapToPayments(paymentData, availablePaymentMethods);
    dispatch(setPaymentParts(orderPayments));

    const incompletedEftPayment = orderPayments.find(
      (el) => el.isActive && el.paymentMethod?.supportsEftPayment === true && el.paymentId,
    );
    if (incompletedEftPayment) {
      dispatch(initEftFromIncompleted(incompletedEftPayment.paymentId as string));
    }

    dispatch(setSelectedPaymentMethodGroupName(undefined));

    return paymentData;
  },
);
export const finalizeOrderAndExit = createAsyncThunk<string, void, { state: RootState }>(
  '[OrderPayment]/finalizeOrderAndCloseModal',
  async (_, { dispatch, getState }) => {
    const { orderId, deliveryType, isLocalOrder, details } = getState().orderPayment;
    const request: FinalizePaidOrderRequest = {
      isLocalOrder: isLocalOrder ?? false,
      orderId: orderId as string,
    };
    const response: AxiosResponse<number> = await posApi.put(posApiUrls.PAYMENT_V2_FINALIZE, request);

    if (!isValidSuccessfulAxiosResponse(response)) {
      throw Error('Could not finalize order payment');
    }

    switch (deliveryType) {
      case PickUpTypesValues.pickUp: {
        const result = await dispatch(finalizeOsmPickupV2({ finalizationOrderId: orderId as string }));
        if (result.meta.requestStatus === 'rejected') {
          throw new Error(`Error finalizing pickup via osm endpoint`);
        }
        break;
      }
      default:
    }
    await dispatch(
      checkOrderGiftCards({
        orderId: orderId as string,
        isLocalOrder,
        details: details ?? {
          ticketNumber: 0,
          orderReference: orderId,
          tableName: '',
        },
      }),
    );

    dispatch(exitOrderPayment());
    sendIntakeFinalizedEvent();
    return details?.ticketNumber.toString() ?? '';
  },
);

function mapToPayments(
  details: OrderPaymentStatusData,
  availablePaymentMethods: PaymentMethod[],
  defaultPaymentMethod?: PaymentMethod,
): OrderPaymentPart[] {
  const { leftToPay, payments: splitPayments, isPaid } = details;

  const defaultPaymentPart = {
    isActive: true,
    paymentMethod: defaultPaymentMethod,
    toPayAmount: leftToPay,
    paymentStep: 'provideAmount',
    customerPaidWith: 0,
    tipValue: 0,
    isCompleted: false,
  } as OrderPaymentPart;

  const payments = splitPayments.map((sp) => {
    const basePayment = availablePaymentMethods.find((pm) => pm.id === sp.paymentMethodId);
    return {
      customerPaidWith: sp.amount,
      isActive: !sp.isCompleted,
      paymentId: sp.paymentId,
      paymentStep: 'finalizePayment',
      tipValue: 0,
      toPayAmount: sp.amount,
      paymentMethod: basePayment as PaymentMethod,
      isCompleted: sp.isCompleted,
    } as OrderPaymentPart;
  });

  if (payments.length === 0) {
    return [defaultPaymentPart];
  }

  if (payments.every((el) => el.isCompleted) && !isPaid) {
    payments.push(defaultPaymentPart);
  }

  return payments;
}

function mapToOrderPaymentData(dto: OrderPaymentStatusResponse): OrderPaymentStatusData {
  const eftPayments: SplitPaymentData[] = dto.eftPayments.map((eft) => {
    return {
      amount: eft.amount,
      isCompleted: eft.isCompleted,
      paymentId: eft.id,
      paymentMethodId: eft.paymentMethodId,
    } as SplitPaymentData;
  });
  const manualPayments: SplitPaymentData[] = dto.manualPayments.map((mp) => {
    return {
      amount: mp.amount,
      isCompleted: true,
      paymentId: mp.id,
      paymentMethodId: mp.paymentMethodId,
    } as SplitPaymentData;
  });

  return {
    isPaid: dto.receivedFullAmount,
    leftToPay: dto.leftToPay,
    paidAmount: dto.paidAmount,
    payments: [...manualPayments, ...eftPayments],
  } as OrderPaymentStatusData;
}
