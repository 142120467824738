import { Box, TableCell, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useTranslation } from 'react-i18next';
import { OrderColumn } from 'stores/AllOrders';
import { NotSortableColumns } from '../../AllOrderConsts';

interface AllOrdersTableHeadCellProps {
  column: OrderColumn;
  sortBy: string;
  sortingDirection: 'asc' | 'desc';
  setHoveredColumn: (column: string | undefined) => void;
  hoveredColumn: string | undefined;
  setSortValue: (column: OrderColumn) => void;
}

export const AllOrdersTableHeadCell: React.FC<AllOrdersTableHeadCellProps> = ({
  column,
  sortBy,
  sortingDirection,
  setHoveredColumn,
  hoveredColumn,
  setSortValue,
}) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('orders');

  const onClick = useCallback(() => setSortValue(column), [setSortValue, column]);

  const mouseEnter = useCallback(() => {
    if (NotSortableColumns.some((el) => el === column.key)) return;
    setHoveredColumn(column.key);
  }, [column.key, setHoveredColumn]);

  const mouseLeave = useCallback(() => {
    if (NotSortableColumns.some((el) => el === column.key)) return;
    setHoveredColumn(undefined);
  }, [column.key, setHoveredColumn]);

  return (
    <TableCell
      align={column.align}
      key={column.name}
      sx={{
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {t(column.name)}
        <Box sx={{ height: spacing(3), width: spacing(3), ml: 0.5, color: palette.grey[500] }}>
          {sortBy === column.key ? (
            <>
              {sortingDirection === 'asc' && <ArrowDownwardIcon sx={{ height: spacing(3), width: spacing(3) }} />}
              {sortingDirection === 'desc' && <ArrowUpwardIcon sx={{ height: spacing(3), width: spacing(3) }} />}
            </>
          ) : (
            hoveredColumn === column.key && <ArrowDownwardIcon sx={{ height: spacing(3), width: spacing(3) }} />
          )}
        </Box>
      </Box>
    </TableCell>
  );
};
