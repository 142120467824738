import { createSelector } from '@reduxjs/toolkit';
import {
  AvailableIntakeContents,
  PickUpTypesValues,
  deliveryTypes,
  extendedDeliveryTypes,
} from 'containers/Intake/IntakeConsts';
import { RootState } from 'stores';
import { getFeatureFlags, getPayOnAccountCode } from 'stores/Config/config.selector';
import { PaymentMethod, PaymentMethodCode } from 'typings/Payments';
import { getChargedCompanyWhenPayingOnAccount, getCustomerCanPayOnAccount } from '../Customer/customer.selector';

// This does not belong here, but with the (architecturally wrong) dependency on the combined actions moving it to right place will cause a dependency loop
const selectPayments = ({ payments }: RootState) => payments;
const getAvailablePaymentMethods = createSelector(selectPayments, (payments) => payments.availablePaymentMethods);

const selectSelf = (state: RootState) => state;

const selectIntake = createSelector(selectSelf, (state) => state.intake);

export const getIsEditMode = createSelector(selectIntake, (intake) => intake.editMode);
export const getEditRequestStatus = createSelector(selectIntake, (intake) => intake.editRequestStatus);

export const getCanEditBasket = createSelector(selectIntake, (intake) =>
  intake.editMode ? intake.editMode.canEditBasket : true,
);

export const getRemarks = createSelector(selectIntake, (intake) => intake.remarks);

export const getIsBasketEdited = createSelector(selectIntake, (intake) => intake.editMode?.basketEdited ?? false);

export const getActivePaymentMethod = createSelector(selectIntake, (intake) => intake.activePaymentMethod);

export const canChangePayment = createSelector(selectIntake, (intake) =>
  intake.editMode ? intake.editMode.canChangePayment : true,
);

export const getAvailableIntakeContents = createSelector(
  [getFeatureFlags, getIsEditMode],
  (featureFlags, editMode) => {
    const availableIntakeContents: AvailableIntakeContents[] = [
      AvailableIntakeContents.Products,
      AvailableIntakeContents.Coupons,
    ];

    if (featureFlags.OfflineModule_ShowRewardsOnPos) {
      availableIntakeContents.push(AvailableIntakeContents.Rewards);
    }

    if (!editMode) {
      if (featureFlags.OfflineModule_Restaurant) {
        availableIntakeContents.push(AvailableIntakeContents.DineInOpenOrders);
      }

      if (featureFlags.OfflineModule_UseFloorPlanConfigurator) {
        availableIntakeContents.push(AvailableIntakeContents.Tables);
      }
    }

    return availableIntakeContents;
  },
);

export const getAvailableDeliveryTypes = createSelector(selectIntake, (intake) => {
  const result =
    intake.editMode?.mode === 'full'
      ? deliveryTypes.filter((el) => el.value !== PickUpTypesValues.takeAway)
      : deliveryTypes;

  const excludedDeliveryTypes = intake.onSiteSettings?.excludedDeliveryTypes || [];
  const includedDeliveryTypes = getExtendedDeliveryTypes(intake.editMode?.mode)
    .filter((el) => excludedDeliveryTypes.indexOf(el.key) === -1)
    .map((el) => el.deliveryType);

  return result.filter((el) => includedDeliveryTypes.indexOf(el.value) > -1);
});

export const getExtendedAvailableDeliveryTypes = createSelector(selectIntake, (intake) => {
  const deliveryTypes = getExtendedDeliveryTypes(intake.editMode?.mode);

  return deliveryTypes.filter(
    (el) => intake.onSiteSettings && intake.onSiteSettings.excludedDeliveryTypes.indexOf(el.key) === -1,
  );
});

export const getIsTakeAwayAvailable = createSelector(
  selectIntake,
  (intake) =>
    !intake.onSiteSettings ||
    intake.onSiteSettings.excludedDeliveryTypes.indexOf(PickUpTypesValues.takeAway) === -1,
);

export const getIsEatInAvailable = createSelector(
  selectIntake,
  (intake) =>
    !intake.onSiteSettings || intake.onSiteSettings.excludedDeliveryTypes.indexOf(PickUpTypesValues.eatIn) === -1,
);

const getExtendedDeliveryTypes = (mode: string | undefined) =>
  mode === 'full'
    ? extendedDeliveryTypes.filter((el) => el.deliveryType !== PickUpTypesValues.takeAway)
    : extendedDeliveryTypes;

export const getOnSiteSettings = createSelector(selectIntake, (intake) => intake.onSiteSettings);

export const getDefaultDeliveryType = createSelector(
  getOnSiteSettings,
  (onSiteSettings) => onSiteSettings?.defaultDeliveryType,
);

export const getActiveDeliveryType = createSelector(selectIntake, (intake) => intake.activeDeliveryType);

export const getPaymentMethodsToShow = createSelector(
  [
    getPayOnAccountCode,
    getAvailablePaymentMethods,
    getActiveDeliveryType,
    getCustomerCanPayOnAccount,
    getChargedCompanyWhenPayingOnAccount,
  ],
  (
    payOnAccountMethodCode,
    availablePaymentMethods,
    activeDeliveryType,
    customerCanPayOnAccount,
    chargedCompanyWhenPayingOnAccount,
  ) => {
    let showablePaymentMethods: PaymentMethod[] = availablePaymentMethods?.map((method) => ({ ...method })) ?? [];

    const payOnAccountPaymentMethod = showablePaymentMethods.find(
      (method) => method.code === payOnAccountMethodCode,
    );

    if (customerCanPayOnAccount && activeDeliveryType !== PickUpTypesValues.dineIn && payOnAccountPaymentMethod) {
      if (activeDeliveryType === PickUpTypesValues.pickUp) {
        showablePaymentMethods =
          showablePaymentMethods?.filter((method) => method.code === payOnAccountMethodCode) ?? [];
        const artificialPaymentMethod = {
          code: PaymentMethodCode.PayInStoreArtificial,
          description: 'Pay in store',
        } as PaymentMethod;
        showablePaymentMethods.push(artificialPaymentMethod);
      }

      payOnAccountPaymentMethod.methodRemark = chargedCompanyWhenPayingOnAccount?.name;
    } else {
      showablePaymentMethods =
        showablePaymentMethods?.filter((method) => method.code !== payOnAccountMethodCode) ?? [];
    }

    return showablePaymentMethods;
  },
);

export const isEftKindPaymentMethodSelected = createSelector(
  [getAvailablePaymentMethods, getActivePaymentMethod, getFeatureFlags],
  (availablePaymentMethods, activePaymentMethod, featureFlags) => {
    const eftEnabledPaymentMethods = availablePaymentMethods?.filter((pm) => pm.supportsEftPayment) ?? [];
    return (
      featureFlags.OfflineModule_EFT === true &&
      eftEnabledPaymentMethods.find((pm) => pm.code === activePaymentMethod) !== undefined
    );
  },
);

export const getOrderPlacementStatus = createSelector(selectIntake, (intake) => intake.orderPlacementStatus);

export const getSelectedOrderCustomer = createSelector(selectIntake, (intake) => intake.selectedOrderCustomer);

export const getSelectedOrderCustomerId = createSelector(
  getSelectedOrderCustomer,
  (customer) => customer?.profile?.id,
);

export const getManuallyFilledAddress = createSelector(selectIntake, (intake) => intake.manuallyFilledAddress);

export const getSelectedOrderDeliveryTime = createSelector(
  selectIntake,
  (intake) => intake.selectedOrderDeliveryTime,
);
