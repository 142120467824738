import React from 'react';
import { formatDenominationToDisplay } from 'utils/payment/PaymentUtils';
import { Box, useTheme } from '@mui/material';
import { getPaymentConfig } from 'stores/Config/config.selector';
import { useAppSelector } from 'hooks/useRedux';
import buildClasses from './BanknoteMiniature.css';

export interface BanknoteMiniatureProps {
  value: number;
  onClick: (event: React.MouseEvent, value: number) => void;
}

const BanknoteMiniature: React.FC<BanknoteMiniatureProps> = ({ value, onClick }) => {
  const { classes } = buildClasses();
  const { palette } = useTheme();
  const paymentSettings = useAppSelector(getPaymentConfig);

  return (
    <button
      type="button"
      className={classes.banknote}
      onClick={(event: React.MouseEvent): void => {
        onClick(event, value);
      }}
      data-testid={`settle-payment__button--banknote-${value}`}
    >
      {formatDenominationToDisplay(value, paymentSettings)}
      <Box
        sx={{
          transition: 'opacity 0.1s linear',
          opacity: '0',
          visibility: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontSize: '0.5rem',
          width: '0.8rem',
          height: '0.8rem',
          top: '-5px',
          right: '-5px',
          border: `1px solid ${palette.error.dark}`,
          borderRadius: '50%',
          position: 'absolute',
          backgroundColor: `${palette.error.main}`,
        }}
      >
        {'x'}
      </Box>
    </button>
  );
};

export default BanknoteMiniature;
