import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ palette, spacing }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      margin: `0 0 ${spacing(0.5)} 0`,
      width: '100%',
      overflowX: 'auto',
    },
    categoryButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: `0 ${spacing(2)} 0 ${spacing(2)}`,
    },
    categoryButton: {
      border: 'none',
      width: ' 50px',
      height: '50px',
      borderRadius: '32px',
      borderColor: 'transparent',
      minWidth: '50px',
    },
    categoryButtonInactive: {
      color: palette.white,
      backgroundColor: palette.grey[500],
      '&:hover': {
        backgroundColor: palette.grey[600],
      },
    },
    categoryImg: {
      maxHeight: '90%',
      filter: 'invert(40%) brightness(1.5)',
    },
    categoryButtonLabel: {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
      textTransform: 'lowercase',
      marginTop: '5px',
      color: palette.grey[800],
      textAlign: 'center',
      fontWeight: 'normal',
    },
    activeLabel: {
      fontWeight: 'bold',
      color: palette.products?.categories?.active?.label ?? palette.black.main,
    },
  };
});

export default useStyles;
