import React, { Fragment, useState } from 'react';
import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { SyncHistoryRecord } from 'stores/Config';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface SyncHistoryProps {
  historyData: SyncHistoryRecord[];
}

const SyncHistoryTable: React.FC<SyncHistoryProps> = ({ historyData }) => {
  const { palette, spacing } = useTheme();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [t] = useTranslation('dataSync');

  const selectRowHandler = function selectRowHandler(rowId: number, error?: string): void {
    if (error) {
      rowId !== selectedRow ? setSelectedRow(rowId) : setSelectedRow(null);
    }
  };

  const rowList = historyData?.map((row) => (
    <Fragment key={row.id}>
      <TableRow
        hover
        onClick={(): void => selectRowHandler(row.id, row.errorMessage)}
        data-testid={`status-history__row--id-${row.id}`}
      >
        <TableCell
          component="th"
          scope="row"
          data-testid={`status-history__row--id-${row.id}-status`}
          sx={{ color: row.isHanging ? palette.warning.main : row.errorMessage ? 'red.800' : 'black.main' }}
        >
          {row.isHanging ? t('Hanging') : t(row.status)}
        </TableCell>
        <TableCell align="right" data-testid={`status-history__row--id-${row.id}-start-time`}>
          <>
            <>{format(new Date(row.startTime), 'dd/MM/yyyy')}</>
            <br />
            <>{format(new Date(row.startTime), 'HH:mm')}</>
          </>
        </TableCell>
        <TableCell align="right" data-testid={`status-history__row--id-${row.id}-finish-time`}>
          {row.endTime ? (
            <>
              <>{format(new Date(row.endTime), 'dd/MM/yyyy')}</>
              <br />
              <>{format(new Date(row.endTime), 'HH:mm')}</>
            </>
          ) : (
            '-'
          )}
        </TableCell>
      </TableRow>
      {row.errorMessage && (
        <TableRow sx={{ padding: 0, margin: 0 }} data-testid={`status-history__row--id-${row.id}-expandable-row`}>
          <TableCell colSpan={4} sx={{ padding: '0 !important', margin: 0 }}>
            <Collapse in={selectedRow === row.id}>
              <Box sx={{ padding: spacing(1) }}>
                <Box sx={{ textAlign: 'center', fontWeight: 500 }}>
                  <strong data-testid={`status-history__row--id-${row.id}-expandable-row-message`}>
                    {row.errorMessage}
                  </strong>
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  ));

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none', border: `1px solid ${palette.grey[200]}` }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{t('Status')}</TableCell>
            <TableCell align="right">{t('Start time')}</TableCell>
            <TableCell align="right">{t('End time')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rowList}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SyncHistoryTable;
