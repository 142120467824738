import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderFilter } from 'stores/AllOrders';
import { lowerCaseFirstLetter } from 'utils/orders/ordersUtils';
import { OpenOrderStatuses } from '../../AllOrderConsts';
import buildClasses from './ExpandedTableFilters.css';

type SelectFilterProps = {
  filter: OrderFilter;
  show: boolean;
  currentStatusTab: string;
};

export const SelectFilter: React.FC<SelectFilterProps> = ({ filter, show, currentStatusTab }) => {
  const { t } = useTranslation('orders');
  const { classes } = buildClasses();
  const { control } = useFormContext();

  const renderValue = useCallback(
    (selected: (string | number)[]) =>
      filter.type.options
        ?.filter((el) => selected.includes(el.key))
        ?.map((x) => x.displayName)
        .join(', '),
    [filter.type.options],
  );

  const [defaultValue] = useState([]);

  const options = useMemo(
    () =>
      filter.type.options?.filter(
        (option) =>
          !(
            filter.name === 'orderStatus' &&
            currentStatusTab === 'open-orders' &&
            !OpenOrderStatuses.some((el) => el.toString() === option.key)
          ),
      ) ?? [],
    [currentStatusTab, filter.name, filter.type.options],
  );

  return (
    <Controller
      key={filter.name}
      control={control}
      name={lowerCaseFirstLetter(filter.name)}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl
          variant="outlined"
          style={{
            display: show ? 'inherit' : 'none',
          }}
          className={classes.filter}
        >
          <InputLabel>{t(filter.name)}</InputLabel>
          <Select
            {...field}
            label={t(filter.name)}
            data-testid={`all-orders-table__filters-select-${field.name}`}
            required
            className={classes.filter}
            MenuProps={{
              PaperProps: { className: classes.selectMenu },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            variant="outlined"
            renderValue={renderValue}
            multiple
          >
            {options.map((option) => (
              <MenuItem
                key={option.key}
                value={option.key}
                className={classes.menuItem}
                data-testid={`all-orders-table__filters-select-${field.name}__${option.key}`}
              >
                <Checkbox checked={field.value?.includes(option.key)} />
                <ListItemText primary={t(option.displayName)} color="black.main" />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
