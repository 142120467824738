import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import SetupState, { ValidatedPrintingConfig } from 'typings/Setup';
import { getPrintersList, getPrintingConfiguration } from './setup.thunk-actions';

const initialState: SetupState = {
  printersList: [],
};

const setupSlice = createSlice({
  name: '[SETUP]',
  initialState,
  reducers: {
    updateSettingsValidity(state, action: PayloadAction<ValidatedPrintingConfig | undefined>) {
      return { ...state, validatedPrintingConfig: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrintersList.fulfilled, (state, action) => ({
      ...state,
      printersList: action.payload,
    }));
    builder.addCase(getPrintingConfiguration.fulfilled, (state, action) => ({
      ...state,
      printerConfig: action.payload,
    }));
  },
});

const { reducer } = setupSlice;
export const { updateSettingsValidity } = setupSlice.actions;

export default reducer;
