import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box, Button, CircularProgress, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { finalizeOsmOrder, fiscalizeOsmOrder, initOrderEdition, setActivePaymentMethod } from 'stores/Intake';
import AddToBlacklistDialog from 'components/Orders/AddToBlacklistDialog/AddToBlacklistDialog';
import CancelOrderDialog from 'components/Orders/CancelDialog/CancelOrderDialog';
import RefundOrderDialog from 'components/Orders/RefundDialog/RefundOrderDialog';
import { setAlert } from 'stores/Alerts';

import {
  OSMOrderDetailsWithBasketData,
  checkIfOrderCanBeRefunded,
  clearOrderDetailsModal,
  resetSelectedOrderDetails,
  setFinishOrderData,
  setOrderDetailsPage,
  validateUser,
} from 'stores/AllOrders';
import { checkSpecifiedCustomerCanPayOnAccount, clearCustomerData } from 'stores/Customer';
import OrderDetailsOverview from 'containers/AllOrders/OrderDetails/OrderDetailsOverview';
import OrderDetailsMoreDetails from 'containers/AllOrders/OrderDetails/OrderDetailsMoreDetails';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { OrderDetailsMenu } from 'containers/AllOrders/OrderDetails/OrderDetailsMenu';
import { OrderStatus, submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { clearSettlePayment, resetPaymentRefundAttemptStatus } from 'stores/Payments';
import { PaymentSuccessfulResult } from 'typings/Payments';
import { CashierPermission } from 'typings/Cashier';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import DownloadReceiptDialog from 'components/Orders/DownloadReceiptDialog/DownloadReceiptDialog';
import { openOrderPaymentFromSettlePayment } from 'stores/OrderPayment/orderPayment.actions';
import { getFeatureFlags, getPayOnAccountCode } from 'stores/Config/config.selector';
import EmailOrderDialog from 'components/Orders/EmailOrderDialog/EmailOrderDialog';
import { RoutePath } from 'App/Routes/routes-paths';

const OrderDetails: React.FC = () => {
  const { selectedOrderDetails, orderDetailsPage, orderDetailsModal } = useAppSelector((state) => state.allOrders);
  const navigate = useNavigate();
  const { t } = useTranslation('orders');
  const dispatch = useAppDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [showAddToBlacklistDialog, setShowAddToBlacklistDialog] = useState(false);
  const [showDownloadReceiptDialog, setShowDownloadReceiptDialog] = useState(false);
  const [showEmailOrderDialog, setShowEmailOrderDialog] = useState(false);
  const canUserAccess = useAppSelector(getPermissionChecker);
  const payOnAccountMethodCode = useAppSelector(getPayOnAccountCode);
  const featureFlags = useAppSelector(getFeatureFlags);
  const { availablePaymentMethods } = useAppSelector((store) => store.payments);
  const { palette, sizing, spacing } = useTheme();
  const [showCommentInput, setShowCommentInput] = useState(false);

  const canRetryFiscalize = selectedOrderDetails?.status === OrderStatus.AwaitingFiscalization;
  const canBeFinalized =
    selectedOrderDetails?.canFinalize && canUserAccess(CashierPermission.FinishOpenAndPausedOrder);
  const canCancelOrder =
    selectedOrderDetails?.canCancel &&
    canUserAccess(CashierPermission.CancelOrder) &&
    selectedOrderDetails?.status !== OrderStatus.AwaitingFiscalization;
  const canRefundOrder =
    canUserAccess(CashierPermission.AllowAdyenRefund) &&
    selectedOrderDetails?.canBeRefunded &&
    !canCancelOrder &&
    selectedOrderDetails?.status === OrderStatus.Canceled;
  const canBeEdited = selectedOrderDetails?.canEdit && canUserAccess(CashierPermission.AllowOrderEdit);

  const [retryFiscalizeIsDisabled, setRetryFiscalizeDisabled] = useState(false);

  const setRetryFiscalize = (value: boolean) => {
    setRetryFiscalizeDisabled(value);
  };

  async function editOrderClickHandler(fullOrderEdit: boolean): Promise<void> {
    if (selectedOrderDetails === undefined) return;

    const customerId = selectedOrderDetails.customerDetails?.profile?.id;
    const orderPriceTotal = selectedOrderDetails.priceSummary.total;

    if (customerId && orderPriceTotal) {
      checkWhetherOrderCustomerCanPayOnAccount(customerId, orderPriceTotal);
    }

    await dispatch(
      initOrderEdition({
        order: selectedOrderDetails,
        orderEditMode: fullOrderEdit ? 'editFullOrder' : 'editPaidOrder',
      }),
    );

    navigate(RoutePath.Intake);
  }

  function handleChange(_: React.SyntheticEvent<Element, Event>, value: 'overview' | 'more-details'): void {
    dispatch(setOrderDetailsPage(value));
  }

  function abortCancelOrderHandler(): void {
    dispatch(resetPaymentRefundAttemptStatus());
    setShowDialog(false);
  }

  function confirmCancelOrderHandler(isOrderRefunded: boolean): void {
    setShowDialog(false);
    dispatch(resetPaymentRefundAttemptStatus());
    dispatch(
      setAlert({
        header: isOrderRefunded ? 'Order successfully canceled and refunded' : 'Order canceled',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      }),
    );
  }

  function abortRefundOrderHandler(): void {
    setShowRefundDialog(false);
  }

  function confirmRefundOrderHandler(): void {
    setShowRefundDialog(false);
    dispatch(
      setAlert({
        header: 'Order refunded attempt started',
        position: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      }),
    );
  }

  function validateRefundHandler(): void {
    dispatch(
      validateUser({
        successCallback: () => {
          setShowRefundDialog(true);
        },
      }),
    );
  }

  function validateClockInHandler(): void {
    dispatch(
      validateUser({
        successCallback: () => {
          selectedOrderDetails && dispatch(checkIfOrderCanBeRefunded({ orderId: selectedOrderDetails.id }));
          dispatch(resetPaymentRefundAttemptStatus());
          setShowDialog(true);
        },
      }),
    );
  }

  function confirmAddCustomerToBlacklist(): void {
    setShowAddToBlacklistDialog(false);
  }

  function checkWhetherOrderCustomerCanPayOnAccount(customerId: number, orderTotal: number): void {
    if (featureFlags.OfflineModule_PayOnAccountEnabled) {
      const customerPayOnAccountCheckRequest = {
        customerId,
        orderValue: orderTotal,
        pickupType: PickUpTypesValues.pickUp,
      };

      dispatch(checkSpecifiedCustomerCanPayOnAccount(customerPayOnAccountCheckRequest));
    }
  }

  function finalizeClickHandler(orderDetails: OSMOrderDetailsWithBasketData): void {
    if (orderDetails?.isPaid) {
      dispatch(
        setFinishOrderData({
          isPaid: orderDetails.isPaid,
          canFinalize: orderDetails.canFinalize,
          orderId: orderDetails.id,
          totalPay: orderDetails.priceSummary.total,
        }),
      );
      return;
    }
    if (orderDetails.customerDetails.profile?.id) {
      checkWhetherOrderCustomerCanPayOnAccount(
        orderDetails.customerDetails.profile.id,
        orderDetails.priceSummary.total,
      );
    } else {
      dispatch(clearCustomerData());
    }
    const chargedCompany =
      orderDetails.companyId && orderDetails.companyName
        ? {
            id: orderDetails.companyId,
            name: orderDetails.companyName,
          }
        : undefined;
    const customerCanPayOnAccount = orderDetails.paymentMethod?.code === payOnAccountMethodCode || undefined;

    dispatch(
      openOrderPaymentFromSettlePayment({
        closeCallback: closeSettlePayment,
        successCallback: finalizeOrderHandler,
        totalToPay: orderDetails.priceSummary.total,
        orderId: orderDetails.id,
        deliveryType: orderDetails.pickupType,
        forcedPaymentMethodCode: orderDetails.paymentMethod?.code,
        customerData: {
          customerId: orderDetails.customerDetails?.profile?.id,
          payOnAccountAccount: {
            chargedCompany,
            customerCanPayOnAccount,
          },
        },
        orderPaymentDetails: {
          ticketNumber: orderDetails.ticketNumber,
          tableName: orderDetails.tableIdentifier,
          orderReference: orderDetails.orderReference,
        },
        isLocalOrder: false,
      }),
    );
  }

  function retryFiscalizeClickHandler(orderDetails: OSMOrderDetailsWithBasketData): void {
    setRetryFiscalize(true);
    dispatch(
      fiscalizeOsmOrder({
        orderId: selectedOrderDetails?.id as string,
        callback: () => {
          setRetryFiscalize(false);
        },
      }),
    );
  }

  async function finalizeOrderHandler(result: PaymentSuccessfulResult) {
    const paymentMethodCode = availablePaymentMethods.find((pm) => pm.code === result.paymentMethodCode)
      ? result.paymentMethodCode
      : availablePaymentMethods[0]?.code;
    dispatch(
      finalizeOsmOrder({
        finalizationOrderId: selectedOrderDetails?.id as string,
        paymentMethodCode,
        eftPaymentId: result.eftPaymentOperation?.eftPaymentId,
        eftFinishedManually: result.eftPaymentOperation?.finishedManually,
        companyId: result.chargedCompany?.id,
        successCallback: () => {
          dispatch(resetSelectedOrderDetails());
          submitAllOrdersForm();
        },
      }),
    );
    closeSettlePayment();
  }

  function closeSettlePayment(): void {
    dispatch(setActivePaymentMethod(undefined));
    dispatch(clearCustomerData());
    dispatch(clearSettlePayment());
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: sizing.orderDetailsDrawerWidth }}
      >
        {selectedOrderDetails && (
          <>
            <Box p={3}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: palette.grey[200],
                  pb: 2,
                }}
              >
                <Typography
                  variant="h5"
                  color="black.main"
                  data-testid="all-orders__order_details-order-number"
                >{`${t('Order')}: ${selectedOrderDetails?.orderReference}`}</Typography>
                <KeyboardTabIcon
                  onClick={() => {
                    dispatch(resetSelectedOrderDetails());
                  }}
                />
              </Box>

              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: palette.grey[200],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Tabs
                  value={orderDetailsPage}
                  onChange={handleChange}
                  sx={{
                    '.MuiTab-root': {
                      color: palette.grey[800],
                      '&.Mui-selected': {
                        fontWeight: 'bold',
                        color: palette.black.main,
                      },
                    },
                  }}
                >
                  <Tab
                    label={t('Overview')}
                    value="overview"
                    data-testid="all-orders__order_details_tabs-overview"
                  />
                  <Tab
                    label={t('More details')}
                    value="more-details"
                    data-testid="all-orders__order_details_tabs-more-details"
                  />
                </Tabs>
                <OrderDetailsMenu
                  onAddToBlacklistClick={() => setShowAddToBlacklistDialog(true)}
                  onAddCommentClick={() => setShowCommentInput(true)}
                  onDownloadReceiptClick={() => setShowDownloadReceiptDialog(true)}
                  onEmailOrderClick={() => setShowEmailOrderDialog(true)}
                />
              </Box>
            </Box>
            <Box px={3} sx={{ flex: 1, wordWrap: 'break-word', overflow: 'auto', fontSize: 12, lineHeight: 1 }}>
              {orderDetailsPage === 'overview' && (
                <OrderDetailsOverview
                  showCommentInput={showCommentInput}
                  onAddCommentClick={() => setShowCommentInput(true)}
                  onCloseCommentEditClick={() => setShowCommentInput(false)}
                />
              )}
              {orderDetailsPage === 'more-details' && <OrderDetailsMoreDetails />}
            </Box>
            <Box p={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {canRetryFiscalize && (
                <Button
                  disabled={retryFiscalizeIsDisabled}
                  variant="outlined"
                  color="black"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    retryFiscalizeClickHandler(selectedOrderDetails);
                  }}
                  data-testid="all-orders__order_details_button-retry-fiscalization"
                >
                  {t('Retry Fiscalization')}
                  {retryFiscalizeIsDisabled && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'green.500',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: spacing(-1.5),
                        marginLeft: spacing(-1.5),
                      }}
                    />
                  )}
                </Button>
              )}

              {canBeEdited && (
                <Button
                  variant="outlined"
                  color="black"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    editOrderClickHandler(!selectedOrderDetails?.isPaid);
                  }}
                  data-testid="all-orders__order_details_button-edit-order"
                >
                  {t('Edit Order')}
                </Button>
              )}

              {canCancelOrder && (
                <Button
                  variant="outlined"
                  color="black"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    validateClockInHandler();
                  }}
                  data-testid="all-orders__order_details_button-cancel-order"
                >
                  {t('Cancel')}
                </Button>
              )}

              {canRefundOrder && (
                <Button
                  variant="outlined"
                  color="black"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    validateRefundHandler();
                  }}
                  data-testid="all-orders__order_details_button-refund-order"
                >
                  {t('Refund')}
                </Button>
              )}

              {canBeFinalized && (
                <Button
                  color="primary"
                  onClick={() => {
                    finalizeClickHandler(selectedOrderDetails);
                  }}
                  variant="contained"
                  data-testid="all-orders__order_details_button-finalize-order"
                >
                  {t('Finalize')}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
      {showDialog && (
        <CancelOrderDialog
          showDialog={showDialog}
          isLocalOrder={false}
          abort={abortCancelOrderHandler}
          orderId={selectedOrderDetails?.id as string}
          onConfirm={confirmCancelOrderHandler}
        />
      )}
      {showRefundDialog && (
        <RefundOrderDialog
          showDialog={showRefundDialog}
          abort={abortRefundOrderHandler}
          orderId={selectedOrderDetails?.id as string}
          onConfirm={confirmRefundOrderHandler}
        />
      )}
      <AddToBlacklistDialog
        showDialog={showAddToBlacklistDialog}
        abort={(): void => setShowAddToBlacklistDialog(false)}
        orderId={selectedOrderDetails?.id as string}
        onConfirm={confirmAddCustomerToBlacklist}
      />
      <DownloadReceiptDialog
        showDialog={showDownloadReceiptDialog}
        onClose={(): void => setShowDownloadReceiptDialog(false)}
        orderId={selectedOrderDetails?.id as string}
      />
      <EmailOrderDialog
        isOpened={showEmailOrderDialog}
        onClose={(): void => setShowEmailOrderDialog(false)}
        orderId={selectedOrderDetails?.id as string}
        customerEmail={selectedOrderDetails?.customerDetails?.emailAddress}
      />
      {orderDetailsModal && (
        <ModalContainer
          onClose={() => {
            dispatch(clearOrderDetailsModal());
          }}
          title={t(orderDetailsModal.header)}
          open
          testId="text-field-modal"
          primaryButton={{
            label: t('OK'),
            testId: 'all-orders-modal__button--ok',
            type: 'submit',
            action: () => {
              dispatch(clearOrderDetailsModal());
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeight: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {orderDetailsModal.message && (
              <Typography variant="subtitle1">{t(orderDetailsModal.message)}</Typography>
            )}
            {orderDetailsModal.messageAddon && (
              <Typography variant="subtitle2">{t(orderDetailsModal.messageAddon)}</Typography>
            )}
          </Box>
        </ModalContainer>
      )}
    </>
  );
};

export default OrderDetails;
