import { Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { LoyaltyTier, RewardCardItem, RewardTab } from 'typings/Loyalty';
import PagedRewardsCatalog from 'containers/Intake/Loyalty/PagedRewardsCatalog';
import ScrollableRewardsCatalog from 'containers/Intake/Loyalty/ScrollableRewardsCatalog';
import { useTranslation } from 'react-i18next';
import { getUsePagination } from 'stores/Config/config.selector';
import { useAppSelector } from 'hooks/useRedux';

interface RewardsCatalogProps {
  rewardTabs: RewardTab[];
  activeTierId: string | undefined;
  searchPhrase: string | undefined;
  showOnlyAvailableRewards: boolean;
}

const RewardsCatalog: React.FC<RewardsCatalogProps> = ({
  rewardTabs,
  activeTierId,
  searchPhrase,
  showOnlyAvailableRewards,
}) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const usePagination = useAppSelector(getUsePagination);

  const rewardCardsToShow = useMemo(() => {
    const rewardsToShow = [...rewardTabs]
      .sort((a, b) => a.minimumValue - b.minimumValue)
      .filter((tab) => (!showOnlyAvailableRewards || !tab.locked) && (!activeTierId || tab.id === activeTierId))
      .map((tab) => {
        const filteredRewardsInTier = tab.rewards
          .filter((reward) => reward.active)
          .filter((reward) => !searchPhrase || reward.name.toLowerCase().includes(searchPhrase.toLowerCase()));
        const awailableRewardsInThisTier = tab.rewards
          .filter((reward) => reward.active)
          .filter((reward) => !searchPhrase || reward.name.toLowerCase().includes(searchPhrase.toLowerCase()))
          .filter((reward) => !showOnlyAvailableRewards || !reward.locked);

        const tier = {
          id: tab.id,
          name: tab.name,
          numberOfRewardsToShow: showOnlyAvailableRewards ? awailableRewardsInThisTier.length : tab.rewards.length,
        } as LoyaltyTier;

        return filteredRewardsInTier.map((reward) => {
          return {
            reward,
            tier,
            showReward: !showOnlyAvailableRewards || !reward.locked,
          } as RewardCardItem;
        });
      })
      .flat();

    return rewardsToShow.some((reward) => reward.showReward) ? rewardsToShow : [];
  }, [activeTierId, rewardTabs, searchPhrase, showOnlyAvailableRewards]);

  return rewardCardsToShow.length === 0 ? (
    <Box
      sx={{
        padding: `${spacing(2)}`,
        gap: '10px',
        border: '1px',
        borderRadius: `${spacing(0.5)}`,
        backgroundColor: palette.customGray.main,
        borderColor: palette.customGray.dark,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
        }}
      >
        {t('There are no rewards for this customer')}
      </Typography>
    </Box>
  ) : usePagination?.productsPage ? (
    <PagedRewardsCatalog rewardsCardItems={rewardCardsToShow} />
  ) : (
    <ScrollableRewardsCatalog rewardsCardItems={rewardCardsToShow} />
  );
};

export default RewardsCatalog;
