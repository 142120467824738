import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EftPaymentState, EftPaymentStatus } from 'typings/EftPayment/eftPayment.types';
import {
  abandonEftPayment,
  acceptEftPayment,
  cancelEftPayment,
  confirmEftManually,
  getEftPaymentStatus,
  startEftPayment,
} from './eftPayment.actions';

const initialState: EftPaymentState = {};

const eftPaymentSlice = createSlice({
  name: '[EFT-Payment]',
  initialState,
  reducers: {
    clearEftPaymentData: () => ({
      ...initialState,
    }),

    initEftFromIncompleted(_, action: PayloadAction<string>) {
      return {
        ...initialState,
        eftPaymentId: action.payload,
      };
    },

    automaticFinalization: (state) => ({
      ...state,
      automaticFinalizationInProgress: true,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(startEftPayment.pending, (state) => ({
      ...initialState, // let start alt eft payments with  initial state in case of any "bugged" old state.
      startInProgress: true,
    }));
    builder.addCase(startEftPayment.fulfilled, (state, action) => ({
      ...state,
      eftPaymentId: action.payload,
      startInProgress: false,
    }));
    builder.addCase(startEftPayment.rejected, (state, action) => ({
      ...state,
      startInProgress: false,
      startError: (action.payload as string) ?? 'Eft transaction could not be started',
    }));
    builder.addCase(cancelEftPayment.pending, (state) => ({
      ...state,
      cancelationInProgress: true,
    }));
    builder.addCase(cancelEftPayment.fulfilled, (state) => ({
      ...state,
      cancelationInProgress: false,
    }));
    builder.addCase(cancelEftPayment.rejected, (state) => ({
      ...state,
      cancelationInProgress: false,
    }));
    builder.addCase(acceptEftPayment.fulfilled, (state) => ({
      ...state,
      eftAcceptanceInProgress: false,
    }));
    builder.addCase(acceptEftPayment.rejected, (state) => ({
      ...state,
      eftAcceptanceInProgress: false,
    }));
    builder.addCase(acceptEftPayment.pending, (state) => ({
      ...state,
      eftAcceptanceInProgress: true,
    }));
    builder.addCase(abandonEftPayment.pending, (state) => ({
      ...state,
      abandonInProgress: true,
    }));
    builder.addCase(abandonEftPayment.fulfilled, (state) => ({
      ...state,
      abandonInProgress: false,
    }));
    builder.addCase(abandonEftPayment.rejected, (state) => ({
      ...state,
      abandonInProgress: false,
    }));
    builder.addCase(confirmEftManually.pending, (state) => ({
      ...state,
      manualConfirmInProgress: true,
    }));
    builder.addCase(confirmEftManually.fulfilled, (state) => ({
      ...state,
      manualConfirmInProgress: false,
    }));
    builder.addCase(confirmEftManually.rejected, (state) => ({
      ...state,
      manualConfirmInProgress: false,
    }));
    builder.addCase(getEftPaymentStatus.fulfilled, (state, action) => {
      if (action.payload.status === EftPaymentStatus.Accepted) {
        return initialState;
      }

      if (state.eftPaymentId === undefined) {
        // Do not update the state, if there is no eftId in context
        // That means that eft modal is already closed, but the delayed api response is handled.
        return state;
      }

      return {
        ...state,
        eftStatusResponse: action.payload,
      };
    });
  },
});

const { reducer } = eftPaymentSlice;

export const { clearEftPaymentData, initEftFromIncompleted, automaticFinalization } = eftPaymentSlice.actions;

export default reducer;
