import { RootState } from 'stores';
import { createSelector } from '@reduxjs/toolkit';
import { storeApi } from './store.api';

const selectSelf = (state: RootState) => state;

const selectStore = createSelector(selectSelf, (state) => state.stores);

export const getSelectedStore = createSelector(selectStore, (stores) => stores.selectedStore);

export const getSelectedStoreId = createSelector(getSelectedStore, (store) => store?.id);

export const getAvailableSwitchStores = createSelector(
  storeApi.endpoints.getSwitchStores.select(),
  (result) => result.data ?? [],
);

export const getSwitchedStore = createSelector(
  [getSelectedStore, getAvailableSwitchStores],
  (selectedStore, availableSwitchStores) => availableSwitchStores?.find((el) => el.id === selectedStore?.id),
);
