import React, { memo, useEffect } from 'react';
import { Box, useTheme } from '@mui/material';

import { SubOption } from 'typings/Products';
import { ProductSubOption } from './ProductSubOption';

interface ProductSubOptionsProps {
  subOptions: SubOption[];
  activeSubOptionId: number | null;
  onSubOptionChange: (optionId: number) => void;
}

const ProductSubOptions: React.FC<ProductSubOptionsProps> = memo(
  ({ subOptions, activeSubOptionId, onSubOptionChange }) => {
    const { palette, sizing, spacing } = useTheme();

    useEffect(() => {
      if (!activeSubOptionId && subOptions.length > 0) {
        onSubOptionChange(subOptions[0].id);
      }
    }, [activeSubOptionId, onSubOptionChange, subOptions]);

    return (
      <Box
        sx={{
          backgroundColor: `${palette.secondary.light}`,
          display: 'flex',
          flexDirection: 'row',
          width: sizing.full,
          borderRadius: spacing(0.5),
          overflowX: 'auto',
        }}
        data-testid="product-selection__container--sub-options"
      >
        {subOptions &&
          subOptions.map(
            (subOption) =>
              subOption.description && (
                <ProductSubOption
                  key={`subOption_${subOption.id}`}
                  subOption={subOption}
                  isActive={activeSubOptionId === subOption.id}
                  onClick={onSubOptionChange}
                />
              ),
          )}
      </Box>
    );
  },
);

ProductSubOptions.displayName = 'ProductSubOptions';

export default ProductSubOptions;
