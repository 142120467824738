import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { initialState as initialBasketState, setBasketState } from 'stores/Basket/basket.slice';
import { IntakeState } from 'stores/Intake';
import { BasketState } from 'typings/Basket';
import { TransferredIntake } from 'typings/HybridMode';
import { initialState as initialIntakeState, setIntakeState } from 'stores/Intake/intake.slice';
import { clearTransferredIntake } from './hybridMode.slice';
import { getSelectedStore } from '../Store/store.selectors';

export const restoreTransferredIntakeState = createAsyncThunk<boolean, TransferredIntake, { state: RootState }>(
  '[INTAKE]/restoreTransferredIntakeState',
  async (transferredIntake, { getState, dispatch }) => {
    const selectedStore = getSelectedStore(getState());

    if (!selectedStore || !transferredIntake.storeId || transferredIntake.storeId !== selectedStore.id) {
      dispatch(clearTransferredIntake());

      return false;
    }

    const intakeState: IntakeState = {
      ...initialIntakeState,
      activeDeliveryType: transferredIntake.activeDeliveryType,
      activePaymentMethod: transferredIntake.activePaymentMethod,
      manualPriceOverride: transferredIntake.manualPriceOverride,
      remarks: transferredIntake.remarks,
    };

    const basketState: BasketState = {
      ...initialBasketState,
      basketItems: transferredIntake.basketItems,
      basketCoupons: transferredIntake.basketCoupons,
      manualDeliveryCharge: transferredIntake.manualDeliveryCharge,
    };

    dispatch(setIntakeState(intakeState));
    dispatch(setBasketState(basketState));
    dispatch(recalculateBasket({}));
    dispatch(clearTransferredIntake());

    return true;
  },
);
