interface KeyScannerConfig {
  overall_percentage: number;
  key_stroke_speed_ms: number;
  minimum_no_chars: number;
}

const DEFAULT_CONFIG: KeyScannerConfig = {
  /**
   * The Overall percentage 1 - 100, at which scanner input passes the speed threshold mentioned in key_stroke_speed_ms.
   * (default 95, meaning 95% of the character input must be faster than the speed threshold mentioned in key_stroke_speed_ms)
   */
  overall_percentage: 95,
  /**
   * key_stroke_speed_ms	The speed in milli-seconds the scanner sends alphabets it scans. (default: 0.017)
   */
  key_stroke_speed_ms: 0.017,
  /**
   * The minimum number of characters that a barcode should contain, inorder to get notified (default 4).
   * Hence any inputs containing less than the value specified is ignored by keyscanner.
   */
  minimum_no_chars: 4,
};

/**
 * KeyScannerJS
 * A library to detect automated keyboard events from external devices (such as a barcode scanner)
 * and differentiates between human keystroke inputs with a high level of accuracy.
 * This reader software, hence allows obtaining barcode information, without
 * the user having to focus the cursor on a textfield.
 * Imported and converted from the source (https://github.com/namshi/keyscannerjs) since it's unmaintained for 6 years
 */
export class KeyScanner {
  private readonly callback: (barcode: string) => void;

  private timerHandle: number;

  private keyStrokeBuffer: string[] = [];

  private timeStampBuffer: number[] = [];

  private readonly BARCODE_THRESHOLD: number;

  private readonly HUMAN_MACHINE_SPEED_THRESHOLD_PERCENTAGE: number;

  private readonly MINIMUM_NO_CHARS: number;

  constructor(callback: (barcode: string) => void, config: Partial<KeyScannerConfig> = DEFAULT_CONFIG) {
    this.callback = callback;
    this.timerHandle = 1;
    this.initListenHandler();
    this.BARCODE_THRESHOLD = config.key_stroke_speed_ms || DEFAULT_CONFIG.key_stroke_speed_ms;
    this.HUMAN_MACHINE_SPEED_THRESHOLD_PERCENTAGE = config.overall_percentage || DEFAULT_CONFIG.overall_percentage;
    this.MINIMUM_NO_CHARS = config.minimum_no_chars || DEFAULT_CONFIG.minimum_no_chars;
  }

  private initListenHandler(): void {
    document.addEventListener('keydown', this.startListener, false);
    this.resetBuffers();
  }

  public stop = (): void => {
    document.removeEventListener('keydown', this.startListener, false);
    clearTimeout(this.timerHandle);
    this.resetBuffers();
  };

  private logInfo = (key: string, timeStamp: number): void => {
    if (!['Shift', 'Enter', 'Unidentified'].includes(key)) {
      this.timeStampBuffer.push(timeStamp);
      this.keyStrokeBuffer.push(key);
    }
  };

  private startListener = (event: KeyboardEvent): void => {
    this.logInfo(event.key, event.timeStamp);
    clearTimeout(this.timerHandle);

    this.timerHandle = window.setTimeout(() => {
      const isBarcodeMachine = this.isBarcodeMachine();

      if (isBarcodeMachine) {
        const fetchBarcodeBuffer = this.fetchBarcodeBuffer();
        this.callback(fetchBarcodeBuffer);
      }
      this.resetBuffers();
    }, 150);
  };

  private resetBuffers(): void {
    this.keyStrokeBuffer = [];
    this.timeStampBuffer = [];
  }

  private static timeDifference(timestamp1: number, timestamp2: number): number {
    return (timestamp2 - timestamp1) / 1000;
  }

  private fetchBarcodeBuffer(): string {
    return this.keyStrokeBuffer.join('');
  }

  private isBarcodeMachine(): boolean {
    const bufferLength = this.timeStampBuffer.length;
    let counter = 1;

    this.timeStampBuffer.forEach((timestamp, index) => {
      if (index < bufferLength - 1) {
        const diff = KeyScanner.timeDifference(timestamp, this.timeStampBuffer[index + 1]);
        if (diff <= this.BARCODE_THRESHOLD) {
          counter += 1;
        }
      }
    });

    const achievedPercentage = bufferLength >= this.MINIMUM_NO_CHARS ? (counter * 100) / bufferLength : 0;

    return (
      achievedPercentage > this.HUMAN_MACHINE_SPEED_THRESHOLD_PERCENTAGE && bufferLength >= this.MINIMUM_NO_CHARS
    );
  }
}
