import React from 'react';
import { DineInOrderStatus } from 'containers/Intake/IntakeConsts';
import { DineInOrder, DineInOrdersPage } from 'stores/DineIn/dineInTypes';
import { DineInOrdersTableRow } from './DineInOrdersTableRow';

interface DineInOrdersTableRowsProps {
  dineInOrders: DineInOrdersPage;
  selectedDineInOrder?: DineInOrder;
  total?: number;
  orderStatus: DineInOrderStatus;
  onOpenOrder: (order: DineInOrder) => void;
  onFinalize: (id: string, total: number) => void;
  onCancel: (id: string) => void;
}

const DineInOrdersTableRows = ({
  dineInOrders,
  selectedDineInOrder,
  total,
  orderStatus,
  onOpenOrder,
  onFinalize,
  onCancel,
}: DineInOrdersTableRowsProps) => {
  return (
    <>
      {dineInOrders.pageItems.map((order) => (
        <DineInOrdersTableRow
          key={`${order.id}`}
          order={order}
          selectedDineInOrder={selectedDineInOrder}
          total={total}
          orderStatus={orderStatus}
          onOpenOrder={onOpenOrder}
          onFinalize={onFinalize}
          onCancel={onCancel}
        />
      ))}
    </>
  );
};

export default DineInOrdersTableRows;
