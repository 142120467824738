import React, { useEffect } from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Drawer,
  FormHelperText,
  Grid2 as Grid,
  Typography,
  useTheme,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { FormProvider, useForm } from 'react-hook-form';
import FinalizePaidOrderDialog from 'components/Orders/FinalizePaidOrder/FinalizePaidOrderDialog';
import { differenceInDays } from 'date-fns';
import {
  clearFinishOrderData,
  downloadOrders,
  finalizePaidOrder,
  getAllOrders,
  getTableFiltersValues,
  resetSelectedOrderDetails,
} from 'stores/AllOrders';
import { OrderFilters } from 'containers/AllOrders/AllOrdersTypes';
import OrderDetails from 'containers/AllOrders/OrderDetails/OrderDetails';
import StoreTabs from 'containers/AllOrders/StoreTabs/StoreTabs';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { clearCustomerData } from 'stores/Customer';
import { getPaymentMethods } from 'stores/Payments';
import { CashierPermission } from 'typings/Cashier';
import { defaultOrdersFormValues, submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { clearOrderPaymentStatus } from 'stores/OrderPayment/orderPayment.slice';
import AllOrdersWrapper from './Overview/AllOrdersWrapper';
import buildClasses from './AllOrders.css';

const AllOrders: React.FC = () => {
  const { classes } = buildClasses();
  const selectedStore = useAppSelector(getSelectedStore);
  const { selectedOrderDetails, finishPaidOrderData, ordersDownloadInProgress } = useAppSelector(
    (state) => state.allOrders,
  );
  const { status: orderPaymentStatus } = useAppSelector((store) => store.orderPayment);
  const dispatch = useAppDispatch();
  const canUserAccess = useAppSelector(getPermissionChecker);
  const { t } = useTranslation('orders');
  const allOrdersFormMethods = useForm<OrderFilters>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultOrdersFormValues,
    shouldUnregister: true,
  });
  const dateTo = allOrdersFormMethods.watch('dateTo', new Date());
  const dateFrom = allOrdersFormMethods.watch('dateFrom', new Date());

  const { spacing } = useTheme();

  const showStoreTabs = canUserAccess(CashierPermission.ChooseStore);
  const showOrdersDownloadButton = canUserAccess(CashierPermission.DownloadOrder);
  const onSubmit = (allOrderFormData: OrderFilters) => {
    const emptyForm = lodash.isEmpty(allOrderFormData);
    dispatch(getAllOrders(emptyForm ? defaultOrdersFormValues : allOrderFormData));
  };

  const onDownload = (allOrderFormData: OrderFilters) => {
    const emptyForm = lodash.isEmpty(allOrderFormData);
    dispatch(downloadOrders(emptyForm ? defaultOrdersFormValues : allOrderFormData));
  };

  const isMoreThan7Days = differenceInDays(dateTo, dateFrom) > 7;

  useEffect(() => {
    if (selectedStore) {
      submitAllOrdersForm();
      dispatch(getPaymentMethods());
      dispatch(getTableFiltersValues());
      dispatch(clearCustomerData());
    }
  }, [selectedStore]);

  useEffect(() => {
    if (orderPaymentStatus === 'completed') {
      submitAllOrdersForm();
    }
    dispatch(clearOrderPaymentStatus());
  }, [orderPaymentStatus]);

  return (
    <>
      <FormProvider {...allOrdersFormMethods}>
        <form onSubmit={allOrdersFormMethods.handleSubmit(onSubmit)} id="orders-filters-form">
          {showStoreTabs && <StoreTabs />}
          <Box className={classes.ordersContainer} data-testid="all-orders-wrapper">
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h5" color="black.main">
                {t('Orders')}
              </Typography>
              {showOrdersDownloadButton && (
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    disabled={isMoreThan7Days || ordersDownloadInProgress}
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    color="black"
                    data-testid="all-orders-header__button--download-orders"
                    onClick={allOrdersFormMethods.handleSubmit(onDownload)}
                  >
                    {t('Download orders')}
                  </Button>
                  {isMoreThan7Days && (
                    <FormHelperText error sx={{ position: 'absolute' }}>
                      {t('Exceeded download limit of 7 days')}
                    </FormHelperText>
                  )}
                  {ordersDownloadInProgress && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'green.500',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: spacing(-1.5),
                        marginLeft: spacing(-1.5),
                      }}
                    />
                  )}
                </Box>
              )}
            </Grid>
            <AllOrdersWrapper />
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={(event) => {
                const tableBody = document.getElementById('all-orders-table-body');
                if (tableBody?.contains(event.target as Node)) {
                  return;
                }
                dispatch(resetSelectedOrderDetails());
              }}
            >
              <Drawer
                BackdropProps={{ invisible: true }}
                variant="persistent"
                anchor="right"
                open={selectedOrderDetails !== undefined}
                onClose={() => {
                  dispatch(resetSelectedOrderDetails());
                }}
                classes={{
                  paper: classes.drawer,
                }}
                data-testid="all-orders-drawer__order-details"
              >
                <OrderDetails />
              </Drawer>
            </ClickAwayListener>
          </Box>
        </form>
      </FormProvider>
      <FinalizePaidOrderDialog
        canFinalize={finishPaidOrderData?.canFinalize as boolean}
        showDialog={finishPaidOrderData !== undefined}
        abort={() => dispatch(clearFinishOrderData())}
        onConfirm={() => {
          dispatch(
            finalizePaidOrder({
              orderId: finishPaidOrderData?.orderId as string,
              successCallback: () => {
                submitAllOrdersForm();
              },
              callback: () => {
                dispatch(clearFinishOrderData());
              },
            }),
          );
        }}
        totalPaid={finishPaidOrderData?.totalPay as number}
      />
    </>
  );
};

export default AllOrders;
