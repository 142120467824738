import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Ellipse, Rect, Text, Transformer } from 'react-konva';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { TableElement } from 'typings/Tables';

interface TableObjectProps extends TableElement {
  isSelected: boolean;
  onDragFn(e: KonvaEventObject<DragEvent>): void;
  onSelectFn(): void;
  onChangeFn(size: TableElement['size'], placement: TableElement['placement']): void;
  onDragEndFn(size: TableElement['size'], placement: TableElement['placement']): void;
  ratioX: number;
  ratioY: number;
}

const TableObject = ({
  size,
  placement,
  name,
  type,
  onDragFn,
  onSelectFn,
  onChangeFn,
  onDragEndFn,
  isSelected,
  ratioX,
  ratioY,
}: Omit<TableObjectProps, 'id'>) => {
  const [isDragging, setIsDragging] = useState(false);
  const shapeRef = useRef<Konva.Rect | Konva.Ellipse>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const textRef = useRef<Konva.Text>(null);

  const [textPosition, setTextPosition] = useState<{ x: number; y: number }>({ x: placement.x, y: placement.y });
  const [currentRatio, setCurrentRatio] = useState<{ x: number; y: number }>({ x: 1, y: 1 });

  useEffect(() => {
    if (isSelected && trRef && trRef.current && shapeRef && shapeRef.current) {
      trRef.current.nodes([shapeRef?.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    if (textRef.current) {
      setTextPosition({ x: textRef.current.getWidth() / 2, y: textRef.current.getHeight() / 2 });
    }
  }, [textRef, placement, size, name]);

  useEffect(() => {
    setCurrentRatio({ x: 1, y: 1 });
  }, [ratioX, ratioY]);

  function onTransform() {
    const node = shapeRef.current;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      node.scaleX(1);
      node.scaleY(1);
      onChangeFn(
        {
          // set minimal value
          width: Math.max(5, node.width() * scaleX),
          height: Math.max(5, node.height() * scaleY),
        },
        {
          x: type === 'RECT' ? node.x() : node.x() - (node.width() / 2) * scaleX,
          y: type === 'RECT' ? node.y() : node.y() - (node.height() / 2) * scaleY,
          rotate: Math.round(node.rotation()),
        },
      );
    }
  }

  function onDragEnd(e: KonvaEventObject<DragEvent>) {
    setIsDragging(false);
    onDragEndFn(
      { width: e.target.width(), height: e.target.height() },
      {
        x: type === 'RECT' ? e.target.x() : e.target.x() - e.target.width() / 2,
        y: type === 'RECT' ? e.target.y() : e.target.y() - e.target.height() / 2,
        rotate: e.currentTarget.rotation(),
      },
    );
    setCurrentRatio({ x: 1, y: 1 });
  }

  const textPositionX =
    type === 'RECT'
      ? placement.x * currentRatio.x - textPosition.x
      : placement.x * currentRatio.x - textPosition.x + (size.width * currentRatio.x) / 2;
  const textPositionY =
    type === 'RECT'
      ? placement.y * currentRatio.y - textPosition.y
      : placement.y * currentRatio.y - textPosition.y + (size.height * currentRatio.y) / 2;

  function renderElement() {
    if (type === 'RECT') {
      return (
        <Rect
          onClick={onSelectFn}
          onTap={onSelectFn}
          ref={shapeRef as RefObject<Konva.Rect>}
          x={placement.x}
          y={placement.y}
          rotation={placement.rotate}
          width={size.width}
          height={size.height}
          fill={isDragging || isSelected ? '#A4D9FF' : '#CCCCCC'}
          stroke={isDragging || isSelected ? '#0094FF' : '#858585'}
          strokeWidth={1}
          draggable
          shadowBlur={5}
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragMove={onDragFn}
          onDragEnd={onDragEnd}
          name="object"
          onTransformEnd={onTransform}
          offsetX={size.width / 2}
          offsetY={size.height / 2}
        />
      );
    }
    return (
      <Ellipse
        onClick={onSelectFn}
        onTap={onSelectFn}
        ref={shapeRef as RefObject<Konva.Ellipse>}
        x={placement.x + size.width / 2}
        y={placement.y + size.height / 2}
        rotation={placement.rotate}
        radiusX={size.width / 2}
        radiusY={size.height / 2}
        fill={isDragging || isSelected ? '#A4D9FF' : '#CCCCCC'}
        stroke={isDragging || isSelected ? '#0094FF' : '#858585'}
        strokeWidth={1}
        draggable
        shadowBlur={5}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragMove={onDragFn}
        onDragEnd={onDragEnd}
        name="object"
        onTransformEnd={onTransform}
      />
    );
  }

  return (
    <>
      {renderElement()}
      {!isDragging && <Text ref={textRef} onClick={onSelectFn} text={name} x={textPositionX} y={textPositionY} />}
      {isSelected && (
        <Transformer
          ref={trRef}
          centeredScaling
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 20 || newBox.height < 20) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={type === 'RECT'}
          rotationSnaps={[0, 90, 180, 270]}
          rotateAnchorOffset={25}
        />
      )}
    </>
  );
};

export default TableObject;
