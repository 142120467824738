import { createApi } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { RewardsResponse, RewardTab } from 'typings/Loyalty';
import { posBaseQuery } from '../base.queries';

type RewardsRequest = { pickupType: PickUpTypesValues; storeId: number; customerId: number };

export const loyaltyApi = createApi({
  reducerPath: 'loyaltyApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getRewards: builder.query<RewardTab[], RewardsRequest>({
      query: (request) => ({
        url: posApiUrls.REWARDS,
        method: 'PUT',
        body: {
          basket: {
            pickupType: request.pickupType,
          },
          storeId: request.storeId,
          customerId: request.customerId,
        },
      }),
      transformResponse: (response: RewardsResponse) => response.rewardTabs ?? [],
      providesTags: ['Rewards'],
    }),
  }),
  tagTypes: ['Rewards'],
});
export const { useGetRewardsQuery } = loyaltyApi;
