import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const EmptyBasketPrompt: React.FC = () => {
  const [t] = useTranslation('intake');

  return (
    <Box sx={{ px: 4, py: 2, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" color="grey.800" textAlign="center" sx={{ mt: 5 }}>
        {t('Your order is empty.')}
        <br />
        {t('Start adding products!')}
      </Typography>
    </Box>
  );
};

export default EmptyBasketPrompt;
