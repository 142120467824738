import React, { useEffect, useState } from 'react';
import { loadFloors } from 'stores/Floors/floors.thunk-actions';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Box, Typography, useTheme } from '@mui/material';
import JumpingDotsLoader from 'components/Shared/Loaders/JumpingDots';
import { useTranslation } from 'react-i18next';
import { fetchAllDineInOpenOrders } from 'stores/DineIn/dineIn-thunk.actions';
import FloorsEdit from './FloorsEdit';
import NoFloorAdded from './NoFloorAdded';
import Floors from './Floors';

interface FloorSectionProps {
  receiptVisible: boolean;
  setReceiptVisibility: (val: boolean) => void;
}

const FloorsSection: React.FC<FloorSectionProps> = ({ receiptVisible, setReceiptVisibility }) => {
  const [t] = useTranslation('intake');
  const floorsCount = useAppSelector(({ floors }) => floors.ids.length);
  const floorsLoading = useAppSelector(({ floors }) => floors.isLoading);
  const { lastDineInRefreshMessage } = useAppSelector(({ notifications }) => notifications);
  const [isEditMode, setIsEditMode] = useState(false);
  const { spacing } = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadFloors());
    dispatch(fetchAllDineInOpenOrders());

    return () => {
      setReceiptVisibility(true);
    };
  }, []);

  useEffect(() => {
    if (lastDineInRefreshMessage) {
      dispatch(fetchAllDineInOpenOrders());
    }
  }, [lastDineInRefreshMessage]);

  if (floorsLoading) {
    return (
      <Box
        onClick={(event) => {
          event.preventDefault();
        }}
        sx={{
          background: 'rgba(255,255,255,0.4)',
          zIndex: 2000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <JumpingDotsLoader />
        <Typography variant="h5">{t('Loading floors data...')}</Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ padding: `${spacing(2)} ${spacing(4)}`, display: 'flex', height: '100%' }}>
      {!floorsCount && !isEditMode && (
        <NoFloorAdded
          onAdd={() => {
            setIsEditMode(true);
            setReceiptVisibility(false);
          }}
        />
      )}
      {isEditMode && (
        <FloorsEdit
          onGoBack={() => {
            setIsEditMode(false);
            setReceiptVisibility(true);
          }}
          receiptVisible={receiptVisible}
        />
      )}
      {floorsCount && !isEditMode && (
        <Floors
          setEditMode={() => {
            setIsEditMode(true);
            setReceiptVisibility(false);
          }}
        />
      )}
    </Box>
  );
};

export default FloorsSection;
