import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { EftPaymentOperation, EftPaymentStatus } from 'typings/EftPayment/eftPayment.types';

export const ErrorResponseStatuses = [
  EftPaymentStatus.Failed,
  EftPaymentStatus.FailedToStart,
  EftPaymentStatus.TimedOut,
  EftPaymentStatus.Abandoned,
  EftPaymentStatus.Cancelled,
];
export const PendingResponseStatuses = [
  EftPaymentStatus.CancellationRequested,
  EftPaymentStatus.PendingToStart,
  EftPaymentStatus.Started,
];
const SuccessResponseStatuses = [EftPaymentStatus.Successful, EftPaymentStatus.Accepted];

const selectSelf = (state: RootState) => state;

const selectEftPayment = createSelector(selectSelf, (state) => state.eftPayment);

export const getCurrentlyPossibleOperations = createSelector(selectEftPayment, (eftPayment) => {
  if (eftPayment.startError) {
    return [EftPaymentOperation.RetryStart, EftPaymentOperation.Exit];
  }
  if (eftPayment.eftPaymentId) {
    if (eftPayment.eftStatusResponse) {
      return eftPayment.eftStatusResponse.possibleOperations;
    }
  }
  return [];
});

export const getEFTPaymentStatus = createSelector(selectEftPayment, (eftPayment) => {
  if (eftPayment.startError) return 'ERROR';
  if (ErrorResponseStatuses.some((el) => el === eftPayment.eftStatusResponse?.status)) return 'ERROR';
  if (SuccessResponseStatuses.some((el) => el === eftPayment.eftStatusResponse?.status)) return 'SUCCESS';
  if (PendingResponseStatuses.some((el) => el === eftPayment.eftStatusResponse?.status)) return 'PENDING';
  if (eftPayment.startInProgress || (eftPayment.eftPaymentId && !eftPayment.startError)) return 'PENDING';
  return 'IDLE';
});

export const isPaymentCancellableOnlyOnDevice = createSelector(selectEftPayment, (eftState) => {
  return eftState.eftStatusResponse?.canBeCancelledOnlyOnDevice ?? false;
});

export const canCloseEftModal = createSelector(
  [getCurrentlyPossibleOperations, selectEftPayment],
  (possibleEftOperations, eftState) => {
    if (!eftState.eftPaymentId) return true;
    if (possibleEftOperations.some((el) => el === EftPaymentOperation.Exit)) return true;
    return false;
  },
);
