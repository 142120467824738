import React, { memo, useCallback } from 'react';
import EmptyBasketPrompt from 'components/Intake/Receipt/EmptyBasketPrompt';
import CustomerSearch from 'components/Intake/Receipt/Customer/CustomerSearch';
import { useAppSelector } from 'hooks/useRedux';
import { getIntakeSettings } from 'stores/Config/config.selector';

type ReceiptHeaderProps = {
  onSearchCustomer?: (query: string) => void;
  onIntakeWithoutProfile?: () => void;
  onHideCustomerSearch: () => void;
};

export const ReceiptCustomerSearchSection: React.FC<ReceiptHeaderProps> = memo(
  ({ onSearchCustomer, onIntakeWithoutProfile, onHideCustomerSearch }) => {
    const intakeSettings = useAppSelector(getIntakeSettings);
    const showEmptyBasketTitle = intakeSettings?.hideCustomerSearch === true;

    const continueIntakeWithoutCustomerProfile = useCallback((): void => {
      if (onIntakeWithoutProfile) {
        onHideCustomerSearch();
        onIntakeWithoutProfile();
      }
    }, [onIntakeWithoutProfile, onHideCustomerSearch]);

    const onCustomerSearchResultsChanged = useCallback(
      (query: string): void => {
        if (onSearchCustomer) {
          onSearchCustomer(query);
        }
      },
      [onSearchCustomer],
    );

    return showEmptyBasketTitle ? (
      <EmptyBasketPrompt />
    ) : (
      <CustomerSearch
        onSearchClicked={onCustomerSearchResultsChanged}
        onIntakeWithoutProfileClick={continueIntakeWithoutCustomerProfile}
      />
    );
  },
);

ReceiptCustomerSearchSection.displayName = 'ReceiptCustomerSearchSection';
