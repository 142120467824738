import React, { memo, useMemo } from 'react';
import IterationSeparator from 'components/Intake/Receipt/DineIn/IterationSeparator';
import { DineInReceiptDiscount, DineInReceiptProduct, ReceiptDiscount } from 'typings/Basket';
import { Box, useTheme } from '@mui/material';
import { updatePendingItems } from 'stores/DineIn/dineIn.slice';
import { DineInOrder } from 'stores/DineIn/dineInTypes';
import { CouponValidity } from 'containers/Intake/IntakeConsts';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getVirtualReceipt } from 'stores/Basket/basket.selector';
import ReceiptDineInItems from './ReceiptDineInItems';

interface ReceiptDineInContentProps {
  dineInOrder: DineInOrder;
}

// I will make this component as "to refactor", as logic is a bit tricky and amount of time-consuming math operation is big here.
// Imo re-thinking that may result in better object definition and reduce number of filtering, matching etc.
// Already reduced numbers of rerender with use memo from 9 => 2 on adding new item to dine in, but definitely there is place for improvement

const ReceiptDineInContent: React.FC<ReceiptDineInContentProps> = memo(({ dineInOrder }) => {
  const { sizing } = useTheme();
  const dispatch = useAppDispatch();
  const { basketData, basketCoupons } = useAppSelector((store) => store.basket);
  const itemPriceDetails = basketData?.itemsDetails ?? [];
  const { receiptProducts, receiptDiscounts } = useAppSelector(getVirtualReceipt);

  function getMaxIteration(): number {
    const iterations = dineInOrder.iterationsHistory.map((itm) => itm.iteration);

    return Math.max.apply(null, iterations);
  }

  function getDineInItems(): { products: DineInReceiptProduct[]; mealDeals: DineInReceiptDiscount[] } {
    const mealConfiguratorCouponsIds = basketCoupons
      .filter((bc) => bc.linkedBaskedItemIds && bc.linkedBaskedItemIds.length > 0)
      .map((bc) => bc.couponId);

    const cancelledDineInReceiptProducts = dineInOrder.items
      .filter((itm) => itm.isCancelled)
      .map((citm) => {
        return {
          ...citm,
          isCancelled: true,
          isPartOfCoupon: false,
          canBeRemoved: false,
        } as DineInReceiptProduct;
      });
    const cancelledItemsIds = cancelledDineInReceiptProducts.map((cItm) => cItm.id);

    const discountedDineInProducts =
      receiptDiscounts.length > 0
        ? receiptDiscounts
            .filter((rd) => !mealConfiguratorCouponsIds.some((md) => md === rd.couponId))
            .flatMap(
              (discount) =>
                discount.discountProducts
                  ?.filter((itm) => !cancelledItemsIds.includes(itm.id))
                  .map((basketItem) => {
                    const priceDetails = itemPriceDetails.find(
                      (itmPrice) => itmPrice.receiptItemId === basketItem.receiptId,
                    );
                    return {
                      ...basketItem,
                      isPartOfCoupon: true,
                      canBeRemoved: true,
                      itemPrice: priceDetails,
                    } as DineInReceiptProduct;
                  }) ?? [],
            )
        : [];

    const mealDealDineInProducts =
      receiptDiscounts.length > 0
        ? receiptDiscounts
            .filter((rd) => mealConfiguratorCouponsIds.some((md) => md === rd.couponId))
            .flatMap(
              (discount) =>
                discount.discountProducts
                  ?.filter((itm) => !cancelledItemsIds.includes(itm.id))
                  .map((basketItem) => {
                    const priceDetails = itemPriceDetails.find(
                      (itmPrice) => itmPrice.receiptItemId === basketItem.receiptId,
                    );
                    return {
                      ...basketItem,
                      isPartOfCoupon: true,
                      canBeRemoved: false,
                      itemPrice: priceDetails,
                    } as DineInReceiptProduct;
                  }) ?? [],
            )
        : [];

    const discountedItemsIds = discountedDineInProducts.map((p) => p.id);
    const mealDealItemIds = mealDealDineInProducts.map((p) => p.id);
    const dineInProducts = receiptProducts
      .filter(
        (dItm) =>
          !cancelledItemsIds.includes(dItm.id) &&
          !discountedItemsIds.includes(dItm.id) &&
          !mealDealItemIds.includes(dItm.id),
      )
      .map((receiptProduct) => {
        return { ...receiptProduct, isPartOfCoupon: false, canBeRemoved: true } as DineInReceiptProduct;
      });

    const dineInMealDeals: DineInReceiptDiscount[] =
      receiptDiscounts.length > 0
        ? receiptDiscounts
            .filter(
              (el) =>
                mealConfiguratorCouponsIds.some((md) => md === el.couponId) &&
                el.couponValidity === CouponValidity.Active,
            )
            .map((rd) => {
              return {
                ...rd,
                basketItems:
                  rd.discountProducts?.map(
                    (bi) => mealDealDineInProducts.find((el) => el.id === bi.id) as DineInReceiptProduct,
                  ) ?? [],
              };
            })
        : [];

    return {
      products: dineInProducts
        .concat(cancelledDineInReceiptProducts)
        .concat(discountedDineInProducts)
        .sort((a, b) => (a.id > b.id ? 1 : -1)),
      mealDeals: dineInMealDeals,
    };
  }

  const dineInContent = useMemo(() => {
    const mealConfiguratorCouponsIds = basketCoupons
      .filter((bc) => bc.linkedBaskedItemIds && bc.linkedBaskedItemIds.length > 0)
      .map((bc) => bc.couponId);

    const { products, mealDeals: discounts } = getDineInItems();
    const dinInItems = dineInOrder.items ?? [];
    const itemsIdsSent = dinInItems.map((it) => it.id);
    const pendingProducts = products.filter((rp) => !itemsIdsSent.includes(rp.splittedFromId ?? rp.id));

    const receiptItemsComponentCollection = new Array<JSX.Element>();
    const maxIteration = getMaxIteration();
    const submittedCouponsIds = dineInOrder.coupons.map((c) => c.couponId);
    const pendingCoupons = receiptDiscounts.filter(
      (rd) =>
        rd.couponId &&
        !submittedCouponsIds.includes(rd.couponId) &&
        !mealConfiguratorCouponsIds.includes(rd.couponId),
    );
    const pendingMealDeals = receiptDiscounts.filter(
      (rd) =>
        rd.couponId &&
        !submittedCouponsIds.includes(rd.couponId) &&
        mealConfiguratorCouponsIds.includes(rd.couponId),
    ) as DineInReceiptDiscount[];

    const pendingItems = [...pendingProducts, ...pendingCoupons, ...pendingMealDeals];

    dispatch(updatePendingItems(pendingItems));

    for (let currentIteration = 1; currentIteration <= maxIteration; currentIteration += 1) {
      const currentIterationHistoryEntry = dineInOrder.iterationsHistory.find(
        (itHst) => itHst.iteration === currentIteration,
      );
      const idsOfCurrentIterationItems = dinInItems
        .filter((itm) => itm.iteration === currentIteration)
        .map((itm) => itm.splittedFromId ?? itm.id);
      const currentIterationReceiptItems = products.filter((rp) =>
        idsOfCurrentIterationItems.includes(rp.splittedFromId ?? rp.id),
      );
      let iterationCoupons: ReceiptDiscount[] = [];
      let iterationMealDeals: DineInReceiptDiscount[] = [];

      if (submittedCouponsIds.length > 0) {
        iterationMealDeals = discounts.filter((dsc) =>
          dsc.discountProducts?.map((bi) => bi.id).some((el) => idsOfCurrentIterationItems.includes(el)),
        );

        if (currentIteration === maxIteration) {
          // in the last iteration we add all applied discounts that are not meal deals.
          iterationCoupons = receiptDiscounts
            .filter((rd) => !mealConfiguratorCouponsIds.includes(rd.couponId as string))
            .filter((rd) => rd.couponId && submittedCouponsIds.includes(rd.couponId));
        }
      }

      receiptItemsComponentCollection.push(
        <div key={`itemsIterationGroupContainer_${currentIterationHistoryEntry?.timestamp}`}>
          <ReceiptDineInItems
            key={`itemsIterationGroup_${currentIterationHistoryEntry?.timestamp}`}
            receiptProducts={currentIterationReceiptItems}
            receiptDiscounts={iterationCoupons}
            receiptMealDeals={iterationMealDeals}
            canRemoveItems
            canCustomizeItems={false}
            tabId={dineInOrder.id}
          />
          <IterationSeparator
            key={`iterationSeparator_${currentIterationHistoryEntry?.timestamp}`}
            sentTimestamp={currentIterationHistoryEntry?.timestamp ?? new Date()}
          />
        </div>,
      );
    }

    receiptItemsComponentCollection.push(
      <ReceiptDineInItems
        key="itemsIterationGroup_pending"
        receiptProducts={pendingProducts}
        receiptDiscounts={pendingCoupons}
        receiptMealDeals={pendingMealDeals}
        canRemoveItems
        canCustomizeItems
        tabId={dineInOrder.id}
      />,
    );

    return receiptItemsComponentCollection;
  }, [
    basketCoupons,
    dineInOrder.coupons,
    dineInOrder.id,
    dineInOrder.items,
    dineInOrder.iterationsHistory,
    dispatch,
    getDineInItems,
    getMaxIteration,
    receiptDiscounts,
  ]);

  return (
    <Box
      sx={{
        height: `calc(100vh - ${
          sizing.receiptHeaderHeight + sizing.receiptFooterHeight + sizing.appBarHeight + 50
        }px)`,
        overflowY: 'auto',
      }}
    >
      {dineInContent}
    </Box>
  );
});

ReceiptDineInContent.displayName = 'ReceiptDineInContent';

export default ReceiptDineInContent;
