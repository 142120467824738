import { RootState } from 'stores';
import { GiftCardActivationStatus } from 'typings/Payments/giftCardTypes';
import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state;

const selectGiftCards = createSelector(selectSelf, (state) => state.giftCards);

export const getShowGiftCardModal = createSelector(
  selectGiftCards,
  (giftCards) => giftCards.giftCards?.length > 0,
);

export const getCurrentGiftCardActivation = createSelector(selectGiftCards, (giftCards) =>
  giftCards.giftCards.find((el) => el.isActiveStep),
);

export const getAllCardsAmount = createSelector(selectGiftCards, (giftCards) => giftCards.giftCards.length);

export const getCardsToActivateAmount = createSelector(
  selectGiftCards,
  (giftCards) => giftCards.giftCards.filter((el) => el.status !== GiftCardActivationStatus.Succeeded).length,
);
