import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CustomizationProduct, ProductsState } from 'typings/Products';
import { getDefaultSliceCustomizations } from 'utils/intake/productCustomizationUtils';
import {
  fetchProductOptionPrices,
  getCustomizationProductSettings,
  getCustomizedItemPrice,
} from './products.thunk-actions';
import { productsApi } from './products.api';

const initialState: ProductsState = {
  activeCategoryCode: '',
  activeOptionId: null,
  activeSubOptionId: null,
  groups: [],
  options: [],
  subOptions: [],
  products: [],
  categories: [],
  bestSellerProducts: [],
  toppingCategories: [],
  toppings: [],
  coupons: [],
  bestSellerCoupons: [],
  productOptionPrices: [],
  productDataLoaded: false,
};

const productsSlice = createSlice({
  name: '[PRODUCTS]',
  initialState,
  reducers: {
    resetProductsState: () => initialState,
    clearActiveProductOption: (state) => ({
      ...state,
      activeOptionId: null,
      activeSubOptionId: null,
    }),
    setActiveProductCategory: (state, action: PayloadAction<string>) => ({
      ...state,
      activeCategoryCode: action.payload,
      activeSubOptionId: null,
      activeOptionId: null,
    }),
    setActiveProductOption: (state, action: PayloadAction<number>) => ({
      ...state,
      activeOptionId: action.payload,
    }),
    setActiveProductSubOption: (state, action: PayloadAction<number>) => ({
      ...state,
      activeSubOptionId: action.payload,
      activeOptionId: null,
    }),
    setCustomizeProduct: (state, action: PayloadAction<CustomizationProduct | undefined>) => ({
      ...state,
      productToCustomize: action.payload
        ? {
            ...action.payload,
            sliceCustomizations:
              action.payload?.sliceCustomizations ?? getDefaultSliceCustomizations(action.payload),
          }
        : undefined,
    }),
    clearCustomizationProductPrice: (state) => ({
      ...state,
      customizationProductPrice: undefined,
    }),
  },
  extraReducers(builder) {
    builder.addCase(fetchProductOptionPrices.fulfilled, (state, action) => ({
      ...state,
      productOptionPrices: action.payload,
    }));
    builder.addCase(getCustomizationProductSettings.fulfilled, (state, action) => ({
      ...state,
      productToCustomize: action.payload,
    }));
    builder.addCase(getCustomizedItemPrice.pending, (state, action) => ({
      ...state,
      customizationProductPrice: state.customizationProductPrice,
    }));
    builder.addCase(getCustomizedItemPrice.fulfilled, (state, action) => ({
      ...state,
      customizationProductPrice: action.payload,
    }));
    builder.addCase(getCustomizedItemPrice.rejected, (state, action) => ({
      ...state,
      customizationProductPrice: undefined,
    }));
    builder.addMatcher(productsApi.endpoints.getProductData.matchPending, (state, action) => ({
      ...state,
      productDataLoaded: false,
    }));
    builder.addMatcher(productsApi.endpoints.getProductData.matchFulfilled, (state, action) => ({
      ...state,
      products: action.payload.products,
      categories: action.payload.categories,
      options: action.payload.options,
      subOptions: action.payload.subOptions,
      groups: action.payload.groups,
      toppings: action.payload.toppings,
      toppingsCategories: action.payload.toppingsCategories,
      bestSellerProducts: action.payload.bestSellerProducts,
      coupons: action.payload.coupons,
      productDataLoaded: true,
    }));
  },
});

const { reducer } = productsSlice;
export const {
  resetProductsState,
  clearActiveProductOption,
  setActiveProductCategory,
  setActiveProductOption,
  setActiveProductSubOption,
  setCustomizeProduct,
  clearCustomizationProductPrice,
} = productsSlice.actions;

export default reducer;
