import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from 'hooks/useRedux';
import { handleScanEvent } from 'stores/combined.actions';
import { getLocalStorageItem, LocalStorageItems } from 'utils/localStorageUtils';
import { KeyScanner } from './keyscanner';

const inputs = ['input', 'textarea'];

export const useKeyScanner = () => {
  const dispatch = useAppDispatch();
  const useScanner = getLocalStorageItem<boolean>(LocalStorageItems.useScanner) ?? true;
  const scanner = useRef<KeyScanner | null>(null);

  const onScan = useCallback(
    (scannedValue: string): void => {
      const { activeElement } = document;
      if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
        // do not trigger any action if some input is in focus
        return;
      }

      dispatch(handleScanEvent(scannedValue));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!useScanner) {
      scanner.current?.stop();
      scanner.current = null;
    } else {
      scanner.current = new KeyScanner(onScan);
    }
    return () => {
      scanner.current?.stop();
    };
  }, [onScan, useScanner]);
};
