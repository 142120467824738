import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import React, { memo } from 'react';
import { StorePanel } from './StorePanel';
import buildClasses from '../ApplicationBar.css';

type MenuActionsProps = {
  onMenuOpenClick: () => void;
};

export const MenuActions: React.FC<MenuActionsProps> = memo(({ onMenuOpenClick }) => {
  const { classes } = buildClasses();

  return (
    <div className={classes.toolbarAction}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        edge="start"
        onClick={onMenuOpenClick}
        data-testid="app-header__button--open-navigation-drawer"
      >
        <MenuIcon />
      </IconButton>
      <StorePanel />
    </div>
  );
});

MenuActions.displayName = 'MenuActions';
