import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingGroup from 'components/Settings/SettingGroup/SettingGroup';
import RoundedButton from 'components/Shared/Buttons/RoundedButton';
import { CircularProgress, useTheme, Box, Typography } from '@mui/material';
import { useGetFdmDeviceStatusQuery } from 'stores/Config/config.api';
import {
  getHybridModeCommunicator,
  isHybridModeCommunicationAvailable,
} from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';

const FiscalSettings = (): JSX.Element => {
  const { spacing } = useTheme();
  const [t] = useTranslation('settings');

  const {
    isSuccess: fdmStatusSuccess,
    isError: fdmStatusError,
    data: fdmStatus,
    isFetching: fdmStatusFetching,
  } = useGetFdmDeviceStatusQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const isValidUrl = (value: string): boolean => {
    try {
      const url = new URL(value);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch {
      return false;
    }
  };

  const formatEntry = (value: any): string => {
    if (typeof value === 'object' && value !== null && Array.isArray(value)) {
      return value.map((item) => `[${item.key}] ${item.value}`).join(';');
    }
    return String(value);
  };

  const openFdmServer = (serverUrl: string): void => {
    if (isHybridModeCommunicationAvailable()) {
      const hybridModeCommunicator = getHybridModeCommunicator();
      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.OpenUrl, {
        url: serverUrl,
      });

      return;
    }

    window.open(serverUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <SettingGroup label={t('Fiscal settings')}>
      {fdmStatusFetching && <CircularProgress color="primary" />}
      {fdmStatusSuccess && !fdmStatus && (
        <Typography variant="body2">{t('No fiscal device available')}</Typography>
      )}
      {fdmStatusError && <Typography variant="body2">{t('Failed to check fiscal device state')}</Typography>}
      <Box sx={{ marginTop: spacing(2), marginLeft: spacing(2) }}>
        {fdmStatusSuccess && !fdmStatusFetching && !!fdmStatus && (
          <Box sx={{ display: 'flex', flexFlow: 'column', width: '70%' }}>
            {Object.entries(fdmStatus ?? []).map((entry: [key: string, value: any]) => {
              if (Array.isArray(entry[1]) && entry[1].length === 0) return null;
              return (
                <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="body2">
                    <Box sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>{entry[0]}</Box>
                  </Typography>
                  {typeof entry[1] === 'string' && isValidUrl(entry[1]) ? (
                    <Box sx={{ marginLeft: 'auto' }}>
                      <RoundedButton
                        testId="fiscal-settings__button--open-fdm-server"
                        onClick={() => openFdmServer(entry[1])}
                      >
                        {t('Open')}
                      </RoundedButton>
                    </Box>
                  ) : (
                    <Typography variant="body2">{formatEntry(entry[1])}</Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </SettingGroup>
  );
};

export default FiscalSettings;
