import React, { useEffect, useState } from 'react';
import { Alert, Box, Checkbox, FormControlLabel, TextField, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { cancelOrder } from 'stores/AllOrders';
import { cancelLocalOrder } from 'stores/LocalOrders';
import { refundPayment } from 'stores/Payments';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { getPermissionChecker } from 'stores/Cashier/cashier.selector';
import { CashierPermission } from 'typings/Cashier';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { OnlinePaymentRefundAttemptStatus } from 'typings/Payments';
import { getPaymentConfig } from 'stores/Config/config.selector';

interface FormsData {
  textField: string;
}

interface CancelOrderDialogProps {
  showDialog: boolean;
  orderId: string;
  abort: () => void;
  onConfirm: (isOrderRefunded: boolean) => void;
  isLocalOrder: boolean;
}

const CancelOrderDialog: React.FC<CancelOrderDialogProps> = ({
  showDialog,
  orderId,
  abort,
  onConfirm,
  isLocalOrder,
}) => {
  const { selectedOrderDetails } = useAppSelector((state) => state.allOrders);
  const payment = useAppSelector(getPaymentConfig);
  const { palette, spacing, sizing } = useTheme();
  const [isRefundRequested, setIsRefundRequested] = useState<boolean>();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<FormsData>({ mode: 'onSubmit', shouldUnregister: true });
  const [cancellationReason, setCancellationReason] = useState('');
  const [t] = useTranslation('orders');
  const orderCancelInProgress = useAppSelector(({ allOrders }) => allOrders.orderCancelInProgress);
  const paymentRefundAttemptStatus = useAppSelector(({ payments }) => payments.paymentRefundAttemptStatus);
  const paymentRefundMessage =
    paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.Failed ? t('Failed refund') : undefined;
  const canUserAccess = useAppSelector(getPermissionChecker);

  const showRefundCheckbox =
    canUserAccess(CashierPermission.AllowAdyenRefund) &&
    selectedOrderDetails?.canBeRefunded &&
    !paymentRefundAttemptStatus;
  const formId = `cancellation-form-order-${orderId}`;
  const cancellationReasonMaxLength = 500;
  const actionInProgress =
    orderCancelInProgress || paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.InProgress;
  const primaryButtonLabel = actionInProgress
    ? t('Processing...')
    : paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.Failed
      ? t('Confirm cancellation')
      : t('OK');

  useEffect(() => {
    if (paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.Succeded) {
      dispatchCancelOrder(cancellationReason);
    } else {
      setIsRefundRequested(false);
    }
  }, [paymentRefundAttemptStatus]);

  useEffect(() => {
    setIsRefundRequested(payment?.v2.preselectRefundCheckbox || false);
  }, []);

  function onFormSubmit(data: FormsData) {
    setCancellationReason(data.textField);

    if (isRefundRequested) {
      dispatch(refundPayment({ orderId, successCallback: () => undefined }));
      return;
    }

    dispatchCancelOrder(data.textField);
  }

  function dispatchCancelOrder(reason: string) {
    isLocalOrder
      ? dispatch(cancelLocalOrder({ reason, orderId, successCallback: () => onConfirm(false) }))
      : dispatch(
          cancelOrder({
            reason,
            orderId,
            isRefundRequested: paymentRefundAttemptStatus === OnlinePaymentRefundAttemptStatus.Succeded,
            successCallback: (isOrderRefunded) => onConfirm(isOrderRefunded),
          }),
        );
  }

  const { ref, ...rest } = register('textField', {
    required: t('Reason required') ?? '',
    maxLength: {
      value: cancellationReasonMaxLength,
      message: `${t('Value is too long')} (${t('intake:max.')} ${cancellationReasonMaxLength})`,
    },
  });

  return (
    <ModalContainer
      onClose={abort}
      title={t('Cancellation reason')}
      open={showDialog}
      primaryButton={{
        label: primaryButtonLabel,
        testId: 'text-field-modal__button--ok',
        type: 'submit',
        form: formId,
        disabled: actionInProgress,
      }}
      secondaryButton={{
        action: abort,
        testId: 'text-field-modal__button--cancel',
        label: t('common:CANCEL'),
      }}
      testId="text-field-modal"
    >
      <form name={formId} id={formId} onSubmit={handleSubmit(onFormSubmit)}>
        <TextField
          sx={{ padding: `${spacing(2)} ${spacing(3)}`, width: sizing.full }}
          inputRef={ref}
          {...rest}
          multiline
          variant="outlined"
          rows="8"
          error={!!errors.textField}
          helperText={errors.textField && errors.textField.message}
          inputProps={{ 'data-testid': 'text-field-modal__input--text' }}
          placeholder={t('Reason')}
          autoFocus
        />

        {showRefundCheckbox && (
          <Box sx={{ display: 'flex', px: `${spacing(3)}`, mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ color: palette.grey[800] }}
                  onChange={(event) => {
                    setIsRefundRequested(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={isRefundRequested}
                />
              }
              label={t('Refund the payment')}
            />
          </Box>
        )}

        {paymentRefundMessage && (
          <Box sx={{ display: 'flex', px: `${spacing(3)}`, mb: 2 }}>
            <Alert severity="error" style={{ width: '100%' }}>
              <Typography variant="body1">{paymentRefundMessage}</Typography>
            </Alert>
          </Box>
        )}
      </form>
    </ModalContainer>
  );
};

export default CancelOrderDialog;
