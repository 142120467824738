import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCustomizeProduct } from 'stores/Products';
import { Group, Option, Product } from 'typings/Products';
import { addToBasket } from 'stores/Basket/basket.thunk-actions';
import PagedCatalog from 'containers/Intake/Products/ProductCatalog/PagedCatalog';
import ScrollableCatalog from 'containers/Intake/Products/ProductCatalog/ScrollableCatalog';
import { Box } from '@mui/material';
import { getOtherGroupCode, getPizzaCategoryCode, getUsePagination } from 'stores/Config/config.selector';
import { uniq } from 'lodash';
import { getActiveCategoryCode, getActiveOptionId, getOptions } from 'stores/Products/products.selector';
import { GroupChip } from './GroupChip';
import buildClasses from './ProductsCatalog.css';

type ProductCatalogProps = {
  products: Product[];
  groups: Group[];
};

export const ProductCatalog: React.FC<ProductCatalogProps> = memo(({ products, groups }) => {
  const dispatch = useAppDispatch();
  const { classes } = buildClasses();
  const options = useAppSelector(getOptions);
  const activeCategoryCode = useAppSelector(getActiveCategoryCode);
  const activeOptionId = useAppSelector(getActiveOptionId);
  const pizzaCategoryCode = useAppSelector(getPizzaCategoryCode);
  const otherGroupCode = useAppSelector(getOtherGroupCode);
  const usePagination = useAppSelector(getUsePagination);

  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    options.find((opt) => opt.id === activeOptionId),
  );
  const [selectedGroupCodes, setSelectedGroupCodes] = useState<string[]>([]);
  const [availableGroupCodes, setAvailableGroupCodes] = useState<string[]>([]);

  const getGroupDescription = useCallback(
    (groupCode: string | null): string | null => {
      const currentGroup = groups.find((group) => group.code === groupCode);
      return currentGroup ? currentGroup.name : 'Other';
    },
    [groups],
  );

  const sortGroups = useCallback(
    (sourceGroupCode: string, targetGroupCode: string): number => {
      if (sourceGroupCode === otherGroupCode) return 1;
      if (targetGroupCode === otherGroupCode) return -1;

      const sourceCount = products.filter(
        (product) => (product.groupCode ?? otherGroupCode) === sourceGroupCode,
      ).length;
      const targetCount = products.filter(
        (product) => (product.groupCode ?? otherGroupCode) === targetGroupCode,
      ).length;

      if (sourceCount !== targetCount) {
        return targetCount - sourceCount;
      }

      return getGroupDescription(sourceGroupCode)?.localeCompare(getGroupDescription(targetGroupCode) ?? '') ?? 0;
    },
    [otherGroupCode, products, getGroupDescription],
  );

  const addProductToBasket = useCallback(
    (product: Product): void => {
      const optionId = activeOptionId ?? product.options.find((opt) => opt.isPriceAvailable)?.productOptionId;
      if (optionId) {
        dispatch(
          addToBasket({
            itemId: product.id,
            itemName: product.name,
            optionId,
            quantity: 1,
          }),
        );
      }
    },
    [activeOptionId, dispatch],
  );

  const customizeProduct = useCallback(
    (product: Product): void => {
      dispatch(
        setCustomizeProduct({
          baseProduct: product,
          quantity: 1,
          originalOptionId:
            activeOptionId ??
            product.options.find((x) => x.default)?.productOptionId ??
            product.options[0].productOptionId,
        }),
      );
    },
    [activeOptionId, dispatch],
  );

  const productSelectedHandler = useCallback(
    (product: Product): void => {
      if (product.isXTasty || product.isCYO) {
        customizeProduct(product);
      } else if (!selectedOption && product.options.length > 1) {
        customizeProduct(product);
      } else if (product.hasToppingsSelectionSteps) {
        customizeProduct(product);
      } else {
        addProductToBasket(product);
      }
    },
    [addProductToBasket, customizeProduct, selectedOption],
  );

  const productsFromSelectedGroups = products
    .map((product) => ({ ...product, groupCode: product.groupCode ?? 'Oth' }))
    .filter(
      (product) =>
        selectedGroupCodes.length === 0 ||
        selectedGroupCodes.length === availableGroupCodes.length ||
        selectedGroupCodes.includes(product.groupCode),
    );

  const showGroupsChips =
    usePagination?.productsPage && activeCategoryCode !== pizzaCategoryCode && availableGroupCodes.length > 1;

  const availableGroups = useMemo(
    () => availableGroupCodes.map((agc) => ({ code: agc, name: getGroupDescription(agc) ?? '' })),
    [availableGroupCodes, getGroupDescription],
  );

  const onChipClick = useCallback(
    (groupCode: string) => {
      const isSelected = !!selectedGroupCodes.find((el) => el === groupCode);
      if (isSelected) {
        setSelectedGroupCodes(selectedGroupCodes.filter((el) => el !== groupCode));
      } else {
        setSelectedGroupCodes(uniq([...selectedGroupCodes, groupCode]));
      }
    },
    [selectedGroupCodes],
  );

  useEffect(() => {
    const productGroupsCodes = Array.from(
      new Set(
        products
          .map((product) => (product.groupCode ? product.groupCode : otherGroupCode))
          .filter((val) => val != null),
      ),
    ).sort((sourceGroupCode, targetGroupCode) => sortGroups(sourceGroupCode, targetGroupCode));

    setAvailableGroupCodes(productGroupsCodes);
    setSelectedGroupCodes([]);
  }, [otherGroupCode, products, sortGroups]);

  useEffect(() => {
    const optionFound = options.find((opt) => opt.id === activeOptionId);
    setSelectedOption(optionFound);
  }, [activeOptionId, options]);

  return (
    <>
      {showGroupsChips && (
        <Box className={classes.chipWrapper}>
          {availableGroupCodes.map((groupCode) => (
            <GroupChip
              key={groupCode}
              groupCode={groupCode}
              description={getGroupDescription(groupCode)}
              isSelected={!!selectedGroupCodes.find((el) => el === groupCode)}
              onClick={onChipClick}
            />
          ))}
        </Box>
      )}
      {usePagination?.productsPage ? (
        <PagedCatalog
          products={productsFromSelectedGroups}
          onProductClick={productSelectedHandler}
          selectedOption={selectedOption}
        />
      ) : (
        <ScrollableCatalog
          groups={availableGroups}
          products={products}
          onProductClick={productSelectedHandler}
          selectedOption={selectedOption}
        />
      )}
    </>
  );
});

ProductCatalog.displayName = 'ProductCatalog';
