import { Box, SxProps, Theme } from '@mui/system';
import React from 'react';

interface VisibilityContainerProps {
  children: React.ReactNode;
  isVisible: boolean;
  displayAs?: 'flex' | 'grid' | 'block';
  classes?: string;
  sx?: SxProps<Theme> | undefined;
}

const VisibilityContainer: React.FC<VisibilityContainerProps> = ({
  isVisible,
  displayAs = 'block',
  children,
  classes,
  sx,
}) => {
  return (
    <Box sx={{ ...sx }} className={classes} style={{ display: isVisible ? displayAs : 'none' }}>
      {children}
    </Box>
  );
};

export default VisibilityContainer;
