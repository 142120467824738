import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderFilter } from 'stores/AllOrders';
import { lowerCaseFirstLetter } from 'utils/orders/ordersUtils';
import buildClasses from './ExpandedTableFilters.css';

type TextFilterProps = {
  filter: OrderFilter;
};

export const TextFilter: React.FC<TextFilterProps> = ({ filter }) => {
  const { t } = useTranslation('orders');
  const { classes } = buildClasses();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validate = useCallback(
    (v: any): boolean => {
      if (v === '') return true;
      if (filter.type.validate) {
        return filter.type.validate(v);
      }
      return true;
    },
    [filter.type],
  );

  return (
    <Controller
      rules={{
        validate: {
          genericValidate: validate,
        },
      }}
      key={filter.name}
      control={control}
      name={lowerCaseFirstLetter(filter.name)}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          error={!!errors[filter.name]}
          helperText={errors[filter.name] && t(filter.type.message ?? 'Value is incorrect')}
          type="text"
          label={t(filter.name)}
          placeholder={t(filter.name)}
          className={classes.filter}
          variant="outlined"
          InputProps={{
            inputProps: {
              className: classes.searchInput,
              'data-testid': `all-orders-table__filters-input-${field.name}`,
            },
          }}
        />
      )}
    />
  );
};
