/* eslint-disable prefer-const */
import { createAsyncThunk } from '@reduxjs/toolkit';
import posApi, { posApiUrls } from 'API/PosApi';
import { RootState } from 'stores';
import { orderItemsByIndex } from 'stores/Intake/IntakeStoreUtils';
import {
  AutoAddedItem,
  BasketDataResponse,
  BasketItem,
  RecalculationProduct,
  ValidateBasketDataResponse,
  ValidationResult,
  VirtualReceipt,
} from 'typings/Basket';
import { Coupon, MealCoupon } from 'typings/Coupons';
import { DineInOrder } from 'stores/DineIn/dineInTypes';
import { isMealCoupon } from 'typings/type-guards';
import uuidGenerator from 'utils/GuidGenerator';
import {
  addBasketCouponHelper,
  areBasketItemsTheSame,
  createAutomaticItems,
  createBoundedProducts,
  editMealInBasket,
  generateBasketRequest,
  getNextItemIdForCurrentIntake,
  reviewBasketItems,
} from 'utils/intake/basketUtils';
import { generateVirtualReceipt } from 'stores/Intake/virtualReceiptUtils';
import { getCustomizationCouponMealSettings, setCouponToCustomize } from 'stores/Coupons';
import { getHybridModeCommunicator } from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import _ from 'lodash';
import { setAlert } from 'stores/Alerts';
import { editionMandatory, isAddressComplete } from 'utils/intake/IntakeUtils';

import { PaymentMethodCode } from 'typings/Payments';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { checkBasketResultChangesCustomerPayOnAccount } from 'stores/Customer/customer.thunk-actions';
import { setCustomizeProduct } from 'stores/Products';
import { getAddressInput } from '../Config/config.selector';

export const recalculateBasket = createAsyncThunk<
  | {
      basketItems: BasketItem[];
      basketData: BasketDataResponse['basketCalculationResult'];
      basketCoupons: Coupon[];
      virtualReceipt: VirtualReceipt;
      changeEditState: boolean;
      addedProducts: AutoAddedItem[];
    }
  | undefined,
  {
    requestedBasket?: BasketItem[];
    coupons?: Coupon[];
    dineInOrder?: DineInOrder;
    onDemandRecalculation?: boolean;
  },
  { state: RootState }
>(
  '[BASKET]/recalculateBasket',
  async ({ requestedBasket, coupons, dineInOrder, onDemandRecalculation }, { getState, dispatch }) => {
    try {
      const { intake, basket, dineIn, customer } = getState();
      const addressInput = getAddressInput(getState());
      if (intake.editMode && !intake.editMode.canEditBasket) {
        throw new Error('Cannot recalculate basket in edit mode!');
      }

      const {
        activeDeliveryType,
        manualPriceOverride,
        manuallyFilledAddress,
        isEatIn,
        selectedOrderCustomer,
        activePaymentMethod,
      } = intake;

      const { basketCoupons, manualDeliveryCharge, basketItems, autoAddedItems } = basket;
      const { customerCreditToUse, customerAddresses, customerCanPayOnAccount } = customer;
      const { selectedDineInOrder } = dineIn;

      const basketItemsForRecalculation = requestedBasket ?? basketItems;
      const attachedCompanyId = selectedOrderCustomer?.companyId;
      const dontSendPaymentMethod =
        activePaymentMethod === PaymentMethodCode.PayOnAccount && !customerCanPayOnAccount;

      // basket may be not ordered correctly yet (ids will be 0,1,3,4...)
      const orderedRequestedBasket = orderItemsByIndex(basketItemsForRecalculation);

      const newBasketCoupons = coupons ?? basketCoupons;

      // ordered now are clean (0,1,2,3)
      const requestForRecalculate = generateBasketRequest({
        requestedBasket: orderedRequestedBasket,
        coupons: newBasketCoupons,
        activeDeliveryType: dineInOrder ? PickUpTypesValues.dineIn : activeDeliveryType,
        manualDeliveryCharge,
        manualPriceOverride,
        selectedDeliveryAddress: customerAddresses.find((el) => el.isSelected),
        manuallyFilledAddress,
        autoAddedItems,
        isEatIn,
        customerCreditToUse,
      });
      // DTO created
      const value = await posApi.put<BasketDataResponse>(posApiUrls.BASKET_RECALCULATE, requestForRecalculate, {
        params: {
          paymentMethodCode: dontSendPaymentMethod ? undefined : activePaymentMethod,
          companyId: attachedCompanyId,
        },
      });

      // now we have both requested basket (request) + recalculated basket (response)
      // we can transform it into actual basket (basketItems) that will be used
      // to generate virtual receipt later

      const autoAddedItemsIds = value.data.basketCalculationResult.autoAddedItems.map((aai) => aai.itemId);
      const basketRegularProducts = value.data.basketCalculationResult.itemsDetails.filter(
        (itm) => !autoAddedItemsIds.includes(itm.basketItemId),
      );

      const actualBasket = reviewBasketItems(
        orderedRequestedBasket,
        basketRegularProducts,
        dineInOrder ?? selectedDineInOrder,
      );
      const boundedProducts = createBoundedProducts(
        value.data.basketCalculationResult.autoAddedItems,
        actualBasket,
      );
      const automaticItems = createAutomaticItems(value.data.basketCalculationResult.autoAddedItems, actualBasket);

      const virtualReceipt = generateVirtualReceipt(
        actualBasket,
        newBasketCoupons,
        value.data,
        boundedProducts,
        automaticItems,
        customerCreditToUse,
      );
      const basketData = value.data.basketCalculationResult;

      const hybridModeCommunicator = getHybridModeCommunicator();

      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Events.BasketRecalculated, {
        receiptProducts: virtualReceipt.receiptProducts,
        receiptDiscounts: virtualReceipt.receiptDiscounts,
        basketSummary: {
          ...basketData.summary,
          deliveryCharge: basketData.deliveryCharge?.grossPrice ?? 0,
        },
      });

      if (selectedOrderCustomer?.profile) {
        dispatch(
          checkBasketResultChangesCustomerPayOnAccount({
            customerId: selectedOrderCustomer?.profile?.id,
            orderValue: basketData.summary.total ?? 0,
            pickupType: activeDeliveryType,
            addressId: customer.customerAddresses.find((addr) => addr.isSelected)?.id,
          }),
        );
      }

      const addedItems = boundedProducts.map((bp) => {
        const wasEdited =
          value.data.basketCalculationResult.autoAddedItems.find(
            (aai) => aai.itemId === bp.id && aai.wasEdited,
          ) !== undefined;
        return {
          boundToItemId: bp.boundedToId,
          itemId: bp.id,
          quantity: bp.quantity,
          productSelection: {
            productId: bp.itemId,
            productOptionId: bp.optionId,
          },
          wasEdited,
        } as AutoAddedItem;
      });

      if (
        manuallyFilledAddress &&
        isAddressComplete(manuallyFilledAddress, addressInput) &&
        basketData?.deliveryChargeDetails?.wasFallback
      ) {
        dispatch(
          setAlert({
            header: 'Cannot load delivery charge. Using default value',
            variant: 'warning',
          }),
        );
      }

      // and return response if caller needs it for some reason
      return {
        basketItems: actualBasket,
        basketCoupons: newBasketCoupons,
        basketData,
        virtualReceipt,
        changeEditState: onDemandRecalculation ?? false,
        addedProducts: addedItems,
      };
    } catch (err) {
      // calculation error or something, do not change actual basket !
      return undefined;
    }
  },
);

export const validateBasket = createAsyncThunk<
  ValidationResult | undefined,
  {
    requestedProducts?: BasketItem[];
    requestedCoupons?: Coupon[];
  },
  { state: RootState }
>('[BASKET]/validate', async ({ requestedProducts, requestedCoupons }, { getState, dispatch }) => {
  try {
    const { intake, basket, customer } = getState();

    const { activeDeliveryType, manualPriceOverride, manuallyFilledAddress, isEatIn } = intake;
    const { manualDeliveryCharge, basketItems, autoAddedItems } = basket;

    const basketItemsForRecalculation = requestedProducts ?? basketItems;

    // basket may be not ordered correctly yet (ids will be 0,1,3,4...)
    const orderedRequestedBasket = orderItemsByIndex(basketItemsForRecalculation);

    // ordered now are clean (0,1,2,3)
    const requestForRecalculate = generateBasketRequest({
      requestedBasket: orderedRequestedBasket,
      coupons: requestedCoupons ?? [],
      activeDeliveryType,
      manualDeliveryCharge,
      manualPriceOverride,
      selectedDeliveryAddress: customer.customerAddresses.find((el) => el.isSelected),
      manuallyFilledAddress,
      autoAddedItems,
      isEatIn,
    });
    // DTO created
    const {
      data: { couponsValidationInformation, productsValidationInformation },
    } = await posApi.post<ValidateBasketDataResponse>(posApiUrls.BASKET_VALIDATE, {
      basket: requestForRecalculate,
    });

    const invalidBasketItemIds = productsValidationInformation
      .filter((el) => el.exceptions.length > 0)
      .map((pvi) => pvi.basketId);

    const invalidCouponIds = couponsValidationInformation
      .filter((el) => el.exceptions.length > 0)
      .map((cvi) => cvi.basketId);

    let [invalidBasketItems, newRequestedBasket] = _.partition(orderedRequestedBasket, (item) => {
      return invalidBasketItemIds.some((invalidBasketItemId) => invalidBasketItemId === item.id);
    });
    const [invalidBasketCoupons, newRequestedCoupons] = _.partition(requestedCoupons, (coupon) => {
      return invalidCouponIds.some((invalidCouponId) => invalidCouponId === coupon.couponId);
    });
    let invalidBasketCouponProducts: BasketItem[] = [];
    invalidBasketCoupons.forEach((invalidCoupon) => {
      if (invalidCoupon.useMealConfigurator) {
        const couponInvalidProducts = newRequestedBasket.filter((el) =>
          invalidCoupon.linkedBaskedItemIds.some((lbid) => lbid === el.id),
        );
        invalidBasketCouponProducts = invalidBasketCouponProducts.concat(couponInvalidProducts);
      }
    });

    invalidBasketItems = invalidBasketItems.concat(invalidBasketCouponProducts);
    newRequestedBasket = newRequestedBasket.filter((el) => !invalidBasketItems.some((ibi) => ibi.id === el.id));

    if (invalidBasketItems.length === 0 && invalidBasketCoupons.length === 0) {
      // everything is correct so simply recalculate basket for requested items + coupons
      dispatch(recalculateBasket({ coupons: newRequestedCoupons, requestedBasket: newRequestedBasket }));
      return undefined;
    }

    return {
      invalidBasketItems,
      invalidBasketCoupons,
      newRequestedBasket,
      newRequestedCoupons,
    };
  } catch (err) {
    // calculation error or something, do not change actual basket !
    return undefined;
  }
});

export const handleScannedProductBarcode = createAsyncThunk<void, string, { state: RootState }>(
  '[BASKET]/handleScannedProductBarcode',
  async (scannedBarcode, { getState, dispatch }) => {
    const {
      products: { products: allProducts, options: allOptions },
    } = getState();

    const products = allProducts.filter((el) => el.options.some((el) => el.webCode === scannedBarcode));
    const product = products ? products[0] : undefined;
    const options = product?.options.filter((el) => el.webCode === scannedBarcode);
    const option = options ? options[0] : undefined;
    const baseOption = allOptions.find((el) => el.id === option?.productOptionId);

    if (!!product && !!option) {
      if (!option.isOptionAvailable || !option.isPriceAvailable || !baseOption) {
        dispatch(setAlert({ header: 'Scanned value error', message: 'Scanned ean code option is not available' }));
        return;
      }

      const isEditionMandatory = editionMandatory(product);

      if (isEditionMandatory) {
        dispatch(
          setCustomizeProduct({
            baseProduct: product,
            quantity: 1,
            originalOptionId: option.productOptionId,
          }),
        );
      } else {
        dispatch(
          addToBasket({
            itemId: product.id,
            itemName: product.name,
            optionId: option.productOptionId,
            quantity: 1,
          }),
        );
      }

      return;
    }

    dispatch(setAlert({ header: 'Scanned value error', message: 'Scanned ean code does not match any product' }));
  },
);

export const addToBasket = createAsyncThunk<void, RecalculationProduct, { state: RootState }>(
  '[BASKET]/addToBasket',
  async (productToRecalculate, { getState, dispatch }) => {
    const { basket, products, dineIn } = getState();
    const { basketItems, autoAddedItems, basketData } = basket;
    const { options } = products;
    const { selectedDineInOrder } = dineIn;

    // get current basket
    const currentBasketItems = [...basketItems];
    let requestedBasket: BasketItem[] = [];
    const itemsIdsAssociatedWithCoupons =
      basketData?.activeDiscounts.flatMap((coupon) => coupon.matchingReceiptItemIds) ?? [];
    const itemsIdsWithEditedAddedItems = autoAddedItems
      .filter((itm) => itm.wasEdited)
      .map((itm) => itm.boundToItemId);

    // if same product already exists, simply increase the quantity of it
    // same product === itemId && optionId && customizations
    // items already associated with product set coupons are not taken into account
    const productToAddAlreadyInBasket = currentBasketItems.find(
      (el) =>
        areBasketItemsTheSame(el, productToRecalculate as BasketItem) &&
        !itemsIdsAssociatedWithCoupons.includes(el.receiptId) &&
        !itemsIdsWithEditedAddedItems.includes(el.id),
    );

    if (productToAddAlreadyInBasket && !selectedDineInOrder) {
      requestedBasket = currentBasketItems.map((item) => {
        if (item.id === productToAddAlreadyInBasket.id) {
          return { ...item, quantity: item.quantity + productToRecalculate.quantity };
        }
        return item;
      });
    } else {
      const currentOption = options.find((option) => option.id === productToRecalculate.optionId);
      const nextId = getNextItemIdForCurrentIntake(currentBasketItems, selectedDineInOrder);
      const productRemarkEmpty = !productToRecalculate.remark;

      requestedBasket = currentBasketItems.concat([
        {
          ...productToRecalculate,
          optionName: currentOption ? currentOption.name : '',
          basketItemGuid: uuidGenerator(),
          id: nextId,
          receiptId: nextId,
          doNotGroup: !productRemarkEmpty,
        },
      ]);
    }
    dispatch(recalculateBasket({ requestedBasket, onDemandRecalculation: true }));
  },
);

export const removeFromBasket = createAsyncThunk<void, number, { state: RootState }>(
  '[BASKET]/removeFromBasket',
  async (productToRemoveId, { getState, dispatch }) => {
    const { basketItems } = getState().basket;
    const currentBasketItems = [...basketItems];

    dispatch(
      recalculateBasket({
        requestedBasket: currentBasketItems.filter((item) => item.id !== productToRemoveId),
        onDemandRecalculation: true,
      }),
    );
  },
);

export const replaceInBasket = createAsyncThunk<void, BasketItem, { state: RootState }>(
  '[BASKET]/replaceInBasket',
  (productToReplace, { dispatch, getState }) => {
    const { basketItems, basketCoupons } = getState().basket;
    const updatedBasketItems = basketItems.map((item) =>
      item.id === productToReplace.id ? productToReplace : item,
    );

    const coupons = basketCoupons.map((coupon) => {
      if (coupon?.linkedBaskedItemIds?.includes(productToReplace.id) && isMealCoupon(coupon)) {
        return {
          ...coupon,
          meals: coupon.meals.map((meal) =>
            meal.selectedProduct?.basketItemIndex === productToReplace.id
              ? {
                  ...meal,
                  originalOptionId: productToReplace.optionId,
                  optionName: productToReplace.optionName,
                  quantity: productToReplace.quantity,
                  remark: productToReplace.remark,
                  sliceCustomizations: productToReplace.sliceCustomizations,
                }
              : meal,
          ),
        };
      }
      return coupon;
    });

    dispatch(recalculateBasket({ requestedBasket: updatedBasketItems, coupons, onDemandRecalculation: true }));
  },
);

export const addCouponToTheBasket = createAsyncThunk<void, MealCoupon | Coupon, { state: RootState }>(
  '[BASKET]/addCouponToTheBasket',
  (coupon, { dispatch, getState }) => {
    const { basketCoupons, basketItems } = getState().basket;
    if (isMealCoupon(coupon)) {
      const { updatedMealCoupon, updatedBasketItems } = editMealInBasket(coupon, basketItems);

      dispatch(
        recalculateBasket({
          coupons: addBasketCouponHelper(updatedMealCoupon, basketCoupons),
          requestedBasket: updatedBasketItems,
          onDemandRecalculation: true,
        }),
      );
    } else {
      dispatch(
        recalculateBasket({
          coupons: addBasketCouponHelper(coupon, basketCoupons),
          onDemandRecalculation: true,
        }),
      );
    }
  },
);

export const removeCouponFromTheBasket = createAsyncThunk<void, string, { state: RootState }>(
  '[BASKET]/removeCouponFromTheBasket',
  (couponId, { getState, dispatch }) => {
    const { basketCoupons, basketItems } = getState().basket;
    const currentCoupons = [...basketCoupons];
    const currentBasketItems = [...basketItems];
    let newBasketItems: BasketItem[] = currentBasketItems;
    const couponToRemove = currentCoupons.find((cc) => cc.couponId === couponId);
    if (couponToRemove?.useMealConfigurator) {
      newBasketItems = currentBasketItems.filter((cbi) => !couponToRemove.linkedBaskedItemIds.includes(cbi.id));
    }

    const newCoupons = currentCoupons.filter((cc) => cc.couponId !== couponId);

    dispatch(
      recalculateBasket({ requestedBasket: newBasketItems, coupons: newCoupons, onDemandRecalculation: true }),
    );
  },
);

export const replaceIncompatibleCoupons = createAsyncThunk<
  void,
  { couponCodes: string[]; coupon: Coupon },
  { state: RootState }
>('[BASKET]/replaceIncompatibleCoupons', ({ couponCodes, coupon }, { getState, dispatch }) => {
  const { basketCoupons, basketItems } = getState().basket;
  const currentCoupons = [...basketCoupons];
  const currentBasketItems = [...basketItems];
  let newBasketItems: BasketItem[] = currentBasketItems;
  const couponsToRemove = currentCoupons.filter((cc) => couponCodes.includes(cc.couponCode));
  couponsToRemove?.forEach((couponToRemove) => {
    if (couponToRemove?.useMealConfigurator) {
      newBasketItems = newBasketItems.filter((cbi) => !couponToRemove.linkedBaskedItemIds.includes(cbi.id));
    }
  });

  const newCoupons = currentCoupons.filter((cc) => !couponCodes.includes(cc.couponCode));
  if (!coupon.useMealConfigurator) {
    newCoupons.push({ ...coupon, couponId: uuidGenerator() });
  }

  dispatch(
    recalculateBasket({ requestedBasket: newBasketItems, coupons: newCoupons, onDemandRecalculation: true }),
  );

  if (coupon.useMealConfigurator) {
    dispatch(processAddingCoupon(coupon));
  }
});

export const processAddingCoupon = createAsyncThunk<void, Coupon, { state: RootState }>(
  '[BASKET]/processAddingCoupon',
  (coupon2add, { dispatch }) => {
    if (coupon2add.useMealConfigurator) {
      dispatch(getCustomizationCouponMealSettings(coupon2add));
      return;
    }
    if (coupon2add.couponId) {
      dispatch(editCouponInBasket(coupon2add));
    } else {
      dispatch(addCouponToTheBasket({ ...coupon2add, couponId: uuidGenerator() }));
    }

    dispatch(setCouponToCustomize());
  },
);

export const editCouponInBasket = createAsyncThunk<void, Coupon | MealCoupon, { state: RootState }>(
  '[BASKET]/removeCouponFromTheBasket',
  (newCoupon, { getState, dispatch }) => {
    const { basketCoupons, basketItems } = getState().basket;

    const currentCoupons: Coupon[] = [...basketCoupons];
    const currentBasketItems = [...basketItems];
    let newBasketItems: BasketItem[] = currentBasketItems;
    const couponToReplace = currentCoupons.find((cc) => cc.couponId === newCoupon.couponId) as Coupon;
    const couponToReplaceIndex = currentCoupons.indexOf(couponToReplace);
    if (couponToReplace?.useMealConfigurator && isMealCoupon(newCoupon)) {
      const { updatedBasketItems } = editMealInBasket(
        newCoupon,
        currentBasketItems.filter((cbi) => !couponToReplace.linkedBaskedItemIds.includes(cbi.id)),
      );
      newBasketItems = updatedBasketItems;
    }

    currentCoupons[couponToReplaceIndex] = newCoupon;

    dispatch(
      recalculateBasket({ requestedBasket: newBasketItems, coupons: currentCoupons, onDemandRecalculation: true }),
    );
  },
);
