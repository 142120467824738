import React, { memo } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface QuantityButtonGroupProps {
  quantity: number;
  showLabel?: boolean;
  secondary?: boolean;
  onPlus: () => void;
  onMinus: () => void;
  testIdPrefix: string;
}

const QuantityButtonGroup: React.FC<QuantityButtonGroupProps> = memo(
  ({ quantity, showLabel, onPlus, onMinus, testIdPrefix }) => {
    const { palette, spacing } = useTheme();
    const [t] = useTranslation('intake');

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="body1">{showLabel && t('Quantity')}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            data-testid={`${testIdPrefix}__button--subtract-quantity`}
            sx={{ border: `1px solid ${palette.secondary.dark}`, margin: `0px ${spacing(2)}` }}
            onClick={onMinus}
          >
            <RemoveIcon />
          </IconButton>
          <Typography
            data-testid={`${testIdPrefix}__label--item-quantity`}
            sx={{ color: palette.black.main }}
            variant="h6"
          >
            {quantity}
          </Typography>
          <IconButton
            data-testid={`${testIdPrefix}__button--add-quantity`}
            sx={{ border: `1px solid ${palette.secondary.dark}`, margin: `0px ${spacing(2)}` }}
            onClick={onPlus}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
    );
  },
);

QuantityButtonGroup.displayName = 'QuantityButtonGroup';

export default QuantityButtonGroup;
