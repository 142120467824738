import { ButtonBase, Typography } from '@mui/material';
import React, { memo, useCallback } from 'react';
import buildClasses from './PinPad.css';

type PinNumberProps = {
  num: number;
  onClick: (value: string) => void;
};

export const PinNumber: React.FC<PinNumberProps> = memo(({ num, onClick }) => {
  const { classes } = buildClasses();
  const click = useCallback(() => {
    onClick(num.toString());
  }, [num, onClick]);
  return (
    <ButtonBase className={classes.numpadButton} data-testid={`pincode_login__button--${num}`} onClick={click}>
      <Typography variant="h6">{num.toString()}</Typography>
    </ButtonBase>
  );
});

PinNumber.displayName = 'PinNumber';
