import { createApi } from '@reduxjs/toolkit/query/react';
import { posApiUrls } from 'API/PosApi';
import { posBaseQuery } from '../base.queries';
import { OnSiteSettings } from './intakeTypes';

export const intakeApi = createApi({
  reducerPath: 'intakeApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getOnSiteSettings: builder.query<OnSiteSettings, void>({
      query: () => posApiUrls.ONSITE_SETTINGS,
    }),
  }),
});

export const { useGetOnSiteSettingsQuery } = intakeApi;
