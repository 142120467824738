import { CouponValidity } from 'containers/Intake/IntakeConsts';
import { OrderDeliveryAddress } from 'stores/Intake';
import { Coupon, MealCoupon } from 'typings/Coupons';
import { ProductSetStepSelection, SliceCustomization } from 'typings/Products';

export type FiscalizationState = {
  events: FiscalEvent[][];
  contextId: string;
  enabled: boolean;
};

export interface BasketState {
  autoAddedItems: AutoAddedItem[];
  basketItems: BasketItem[];
  basketCoupons: Coupon[] | MealCoupon[];
  basketData: BasketData | null;
  virtualReceipt: VirtualReceipt;
  manualDeliveryCharge: number | null;
  recalculateInProgress?: boolean;
  validationResult?: ValidationResult;
  lastBasketClearTimestamp: number;
  fiscalization: FiscalizationState;
}

export interface ValidationResult {
  invalidBasketItems: BasketItem[];
  invalidBasketCoupons: Coupon[];
  newRequestedBasket?: BasketItem[];
  newRequestedCoupons?: Coupon[];
}

interface BasketItemException {
  errorCode: string;
  message: string;
}
export interface BasketItem extends RecalculationProduct {
  optionName: string;
  id: number;
  receiptId: number;
  basketItemGuid: string;
  doNotGroup?: boolean;
  ignoreInPriceCalculation?: boolean;
  splittedFromId?: number;
  isAutomaticItem?: boolean;
  // generally I think it is good idea to have it both for multiple flavours
  // but also for single flavour customization (e.g. Margherita with added pepperoni
  // wii have only one item in sliceCustomization)
}

export interface RecalculationProduct {
  itemId: number;
  itemName: string;
  optionId: number;
  quantity: number;
  remark?: string;
  sliceCustomizations?: SliceCustomization[];
  selectedSetSteps?: ProductSetStepSelection[];
}
export interface BoundedBasketItem extends BasketItem {
  boundedToId: number;
  boundedToReceiptId: number;
}

export interface BasketData {
  summary: BasketSummary;
  autoAddedItems: AutoAddedItem[];
  itemsDetails: ItemPriceDetails[];
  deliveryCharge: DeliveryChargeDetails;
  activeDiscounts: ActiveDiscount[];
  couponsInformation: CouponInformation[];
  automaticDiscountsInformation: AutomaticDiscountsInformation[];
  minimumOrderValue: MinimumOrderValueCalculationResult;
  deliveryChargeDetails: DeliveryChargeCalculationDetails;
}

export interface AutoAddedItem {
  boundToItemId: number;
  itemId: number;
  quantity: number;
  productSelection: {
    productId: number;
    productOptionId: number;
  };
  productViewData: {
    productName: string;
    optionName: string;
  };
  wasEdited: boolean;
}

export interface MinimumOrderValueCalculationResult {
  isReached: boolean;
  deliveryChargeChange: number;
  targetValue: number;
}

export interface DeliveryChargeCalculationDetails {
  wasFallback: boolean;
}

export interface DeliveryChargeDetails {
  grossPrice: number;
  netPrice: number;
  vatRatePercentage: number;
  vatValue: number;
}

export interface BasketSummary {
  total: number;
  originalTotal: number;
  subtotal: number;
  discount: number;
  deliveryCharge: number;
  totalVat: number;
  vatSummary: VatSummary;
}

export interface ItemPriceDetails {
  quantity: number;
  productId?: number;
  basketItemId: number;
  receiptItemId: number;
  discountValueIncVat?: number;
  priceAdjustmentIncVat?: number;
  unitPrice: PriceWithVat;
  total: TotalValue;
}

export interface PriceWithVat {
  grossPrice: number;
  originalGrossPrice: number;
  netPrice: number;
  vatRatePercentage: number;
  vatValue: number;
}

export interface VatSummary {
  vatRatePercentage: number;
  vatValue: number;
}

export interface TotalValue {
  grossValue: number;
  originalGrossValue: number;
  netValue: number;
  vatValue: number;
}

export interface BasketRequestParams {
  basketItems: RequestBasketItem[];
  totalDiscounts: RequestTotalDiscount[];
  pickupType: string;
  deliveryCharge: number | null;
  manualPriceOverride?: number;
  selectedCoupons?: CouponSelection[];
  deliveryAddressInput?: OrderDeliveryAddress;
  autoAddedItems?: AutoAddedItem[];
  isEatIn: boolean;
  customerCreditToUse?: number;
  attachedCompanyId?: number;
}
export interface CouponSelection {
  couponCode: string;
  couponId: string;
  singleUseCouponCode?: string;
  linkedBaskedItemIds: number[];
}

export interface RequestBasketItem {
  id: number;
  productSelection: RequestProductSelection;
  quantity: number;
  sliceCustomizations?: SliceCustomization[];
  remark?: string;
}

export interface RequestProductSelection {
  productId: number;
  productOptionId?: number;
}

export interface RequestTotalDiscount {
  discountValue: number;
}

export interface ActiveDiscount {
  code: string;
  description: string;
  matchingReceiptItemIds: number[]; // contains receiptItemIds which are affected by discount
  couponId?: string;
  isAutomatic: boolean;
  isSetDiscount: boolean;
  type: DiscountType;
  value?: number;
  itemsValue?: number;
}

export enum DiscountType {
  FixedPrice = 'FixedPrice',
  Percentage = 'Percentage',
  DealDiscount = 'DealDiscount',
  Other = 'Other',
  CustomerCredit = 'CustomerCredit',
  //  will be more in future, may be used for generating coupon edition ui
  // e.g for DealDiscount click, deal customization is opened,
  // for Other 'nothing' happens
}
export interface ValidateBasketDataResponse {
  productsValidationInformation: { basketId: number; id: number; exceptions: BasketItemException[] }[];
  couponsValidationInformation: { basketId: string; exceptions: BasketItemException[] }[];
}
export interface BasketDataResponse {
  basketCalculationResult: BasketData;
  availableCoupons?: Coupon[];
}

export interface RecalculateBasketItemResponseDto {
  itemPriceCalculationResult: ItemPriceDetails;
}

export interface RecalculateBasketItemDto {
  item: RequestBasketItem;
  pickupType: string;
  isEatIn: boolean;
}

export interface RecalculateSetRequestDto {
  items: RequestBasketItem[];
  pickupType: string;
  couponCode: string;
}

export interface ReceiptProduct extends BasketItem {
  isAutoItem?: boolean;
  itemPrice: ItemPriceDetails;
  boundedProducts?: ReceiptProduct[];
}

export interface DineInReceiptProduct extends BasketItem {
  itemPrice?: ItemPriceDetails;
  isPartOfCoupon: boolean;
  isCancelled: boolean;
  canBeRemoved: boolean;
}

export interface ReceiptDiscount {
  description: string;
  couponValidity: CouponValidity;
  couponId?: string;
  code?: string;
  canDelete?: boolean;
  discountType?: DiscountType;
  discountProducts?: ReceiptProduct[];
  isSet: boolean;
  value?: number;
}
export interface DineInReceiptDiscount extends ReceiptDiscount {
  dineInProducts?: DineInReceiptProduct[];
}

export interface VirtualReceipt {
  receiptProducts: ReceiptProduct[];
  receiptDiscounts: ReceiptDiscount[];
}

export interface CouponInformation {
  couponId: string;
  status: CouponValidity;
  validity: CouponValidity;
}

export interface AutomaticDiscountsInformation {
  discount: ActiveDiscount;
  status: CouponValidity;
}

export interface DineInItem extends BasketItem {
  iteration: number;
  isCancelled: boolean;
  productSelection: RequestProductSelection;
}

export enum FiscalEventType {
  ProductAdded = 'ProductAdded',
  ProductModified = 'ProductModified',
  ProductRemoved = 'ProductRemoved',
  DeliveryPriceChanged = 'DeliveryPriceChanged',
  DiscountAdded = 'DiscountAdded',
  DiscountRemoved = 'DiscountRemoved',
  DiscountModified = 'DiscountModified',
}
export interface FiscalEvent {
  type: FiscalEventType;
  productId?: number;
  optionId?: number;
  previousOptionId?: number;
  newQuantity?: number;
  previousQuantity?: number;
  newPrice?: number;
  previousPrice?: number;
  discountValue?: number;
  previousDiscountValue?: number;
  priceAdjustment?: number;
  previousPriceAdjustment?: number;
  couponCode?: string;
  couponName?: string;
}

export interface FiscalSummary {
  fiscalContextId: string;
  fiscalEvents: FiscalEvent[][];
}
