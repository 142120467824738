import React, { useState } from 'react';
import { Box, CardActionArea, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { DeleteOutlined, ExpandMore, LocalActivity } from '@mui/icons-material';
import { BasketItem, DineInReceiptProduct, ReceiptProduct } from 'typings/Basket';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import ReceiptProductDetails from 'components/Intake/Receipt/Item/ReceiptProduct/ReceiptProductDetails';
import { useAppSelector } from 'hooks/useRedux';
import buildClass from './DineInReceiptProductItem.css';
import ItemQuantity from '../Item/ItemQuantity/ItemQuantity';
import ReceiptProductSetDetails from '../Item/ReceiptProductSet/ReceiptProductSetDetails';

interface DineInReceiptProductProps {
  receiptProduct: DineInReceiptProduct;
  removeItem: (productGuid: string) => void;
  customizeProduct: (item: BasketItem) => void;
  canRemoveItem: boolean;
}

const DineInReceiptProductItem: React.FC<DineInReceiptProductProps> = ({
  removeItem,
  customizeProduct,
  canRemoveItem,
  receiptProduct,
}) => {
  const { classes, cx } = buildClass();
  const { palette, spacing, sizing } = useTheme();
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  const priceColumn = (function priceColumn(): JSX.Element | null {
    if (receiptProduct.isPartOfCoupon) {
      return (
        <span className={classes.itemDiscountedTotalPrice}>
          {formatToDisplay(receiptProduct.itemPrice?.unitPrice.originalGrossPrice ?? 0)}
          <LocalActivity
            sx={{ color: palette.virtualReceipt.items.discount.active.icon, marginLeft: spacing(1) }}
          />
        </span>
      );
    }

    const price = receiptProduct.itemPrice?.total.originalGrossValue ?? 0;
    return <span className={classes.itemTotalPrice}>{formatToDisplay(price)}</span>;
  })();

  function genRemoveItemColumn() {
    if (canRemoveItem) {
      return (
        <IconButton
          onClick={(): void => removeItem(receiptProduct.basketItemGuid)}
          sx={{ color: `${palette.error.main}` }}
          data-testid="receipt-item-product__button--delete-product"
        >
          <DeleteOutlined />
        </IconButton>
      );
    }
    return null;
  }

  const itemHasToppingCustomization: boolean = receiptProduct.sliceCustomizations
    ? receiptProduct.sliceCustomizations.length > 0 &&
      receiptProduct.sliceCustomizations.some(
        (el) =>
          (el.addedToppings && el.addedToppings.length > 0) ||
          (el.removedToppings && el.removedToppings.length > 0),
      )
    : false;

  const itemIsXTasty: boolean = receiptProduct.sliceCustomizations
    ? receiptProduct.sliceCustomizations.length > 1
    : false;

  const canShowDetails: boolean =
    itemHasToppingCustomization || itemIsXTasty || receiptProduct.remark !== undefined;

  const details = (function priceColumn(): JSX.Element | null {
    const isProductSet = receiptProduct.selectedSetSteps && receiptProduct.selectedSetSteps.length > 0;

    if (isProductSet) {
      const productSetReceiptItem = { ...receiptProduct } as ReceiptProduct;
      return <ReceiptProductSetDetails receiptProductSet={productSetReceiptItem} remark={receiptProduct.remark} />;
    }

    return (
      <ReceiptProductDetails
        remark={receiptProduct.remark}
        isXTasty={itemIsXTasty}
        slices={receiptProduct.sliceCustomizations ?? []}
      />
    );
  })();

  const deleteColumn = genRemoveItemColumn();

  return (
    <Collapse in={!!receiptProduct} data-testid="receipt-product__container">
      <Box
        sx={{
          height: sizing.receiptItemHeight,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderBottom: `1px solid ${palette.secondary.dark}`,
          '&:first-of-type': {
            borderTop: `1px solid ${palette.secondary.dark}`,
          },
        }}
        data-testid={`receipt-item__container--${receiptProduct.itemId}-${receiptProduct.optionId}`}
      >
        <CardActionArea
          data-testid="receipt-item-product__click-zone"
          className={classes.itemClickZone}
          onClick={(): void => {
            customizeProduct(receiptProduct);
          }}
        >
          <ItemQuantity quantity={receiptProduct.quantity} />
          <Box className={classes.itemPriceDetails}>
            <Box className={classes.itemDetails}>
              <Typography
                className={cx(classes.itemName, { [classes.cancelled]: receiptProduct.isCancelled })}
                variant="subtitle2"
                data-testid="receipt-item__label--name"
              >
                {receiptProduct.itemName}
              </Typography>
              <Typography
                className={cx(classes.itemDescription, { [classes.cancelled]: receiptProduct.isCancelled })}
                variant="body2"
                data-testid="receipt-item__label--description"
              >
                {receiptProduct.optionName}
              </Typography>
            </Box>
            <div
              className={cx(classes.priceDetails, { [classes.cancelled]: receiptProduct.isCancelled })}
              data-testid="receipt-item__label--price"
            >
              {priceColumn}
            </div>
          </Box>
        </CardActionArea>
        {canShowDetails && (
          <IconButton
            onClick={(): void => {
              setShowDetails(!showDetails);
            }}
            data-testid="receipt-item-product__button--show-details"
          >
            <ExpandMore className={cx(classes.rotate, { [classes.rotateActive]: showDetails })} />
          </IconButton>
        )}
        {deleteColumn}
      </Box>
      {canShowDetails && (
        <Collapse in={!!showDetails} data-testid="tes1">
          <Box sx={{ padding: spacing(0.5), borderBottom: `1px solid ${palette.secondary.dark}` }}>
            <Box
              sx={{
                width: 0,
                height: 0,
                borderLeft: '20px solid transparent',
                borderRight: '20px solid transparent',
                borderTop: `14px solid ${palette.secondary.dark}`,
                position: 'relative',
                left: 'calc(50% - 7px)',
                top: `-${spacing(0.5)}`, // padding
              }}
            />
            {details}
          </Box>
        </Collapse>
      )}
    </Collapse>
  );
};

export default DineInReceiptProductItem;
