/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Validators from 'utils/validation/ValidationConstants';
import PaymentDenominations from 'components/Intake/Finalize/PaymentDenominations/PaymentDenominations';
import PaymentMethodTypeBar from 'components/Intake/Finalize/PaymentMethodTypeBar/PaymentMethodTypeBar';
import GenericPaymentMethod from 'components/Intake/Finalize/GenericPaymentMethod/GenericPaymentMethod';
import { numberFromMoney } from 'utils/payment/PaymentUtils';
import { setActivePaymentMethod, setLastOrderChangeAmount } from 'stores/Intake';
import { Box } from '@mui/material';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import PayOnAccountMethod from 'components/Intake/Finalize/PayOnAccountMethod/PayOnAcountMethod';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { EftPaymentOperation, PaymentMethodCode } from 'typings/Payments';
import { getPaymentMethods } from 'stores/Payments';
import { getEFTStatus } from 'stores/Eft/eft.selector';
import EftPayment from 'components/Shared/EftPayment/EftPayment';
import { getEftPaymentSettings, getPayOnAccountCode, getPaymentConfig } from 'stores/Config/config.selector';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { isEftKindPaymentMethodSelected } from 'stores/Intake/intake.selector';
import { getSelectedStore } from 'stores/Store/store.selectors';
import { ValidationInput } from '../Inputs/InputUnit';
import buildClasses from './SettlePayment.css';
import ModalContainer from '../Modal/ModalContainer';

const SettlePayment: React.FC = () => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const { availablePaymentMethods } = useAppSelector(({ payments }) => payments);
  const { customerCanPayOnAccount, chargedCompany } = useAppSelector(({ customer }) => customer);
  const selectedStore = useAppSelector(getSelectedStore);
  const payOnAccountMethodCode = useAppSelector(getPayOnAccountCode);
  const payment = useAppSelector(getPaymentConfig);
  const eftPaymentSettings = useAppSelector(getEftPaymentSettings);
  const { activeDeliveryType, activePaymentMethod } = useAppSelector(({ intake }) => intake);
  const { settlePayment, unpaidOrderPlacementInProgress } = useAppSelector((state) => state.payments);
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const dispatch = useAppDispatch();
  const eftStatus = useAppSelector(getEFTStatus);
  const isEftKindSelected = useAppSelector(isEftKindPaymentMethodSelected);
  const [paymentMethodForced, setPaymentMethodForced] = useState(false);
  const [excludedPaymentMethodsCodes, setExcludedPaymentMethodsCodes] = useState<string[]>([]);

  const appInsights = useAppInsightsContext();

  function getAdditionalPaymentMethodsCodesForForcedEft(): string[] {
    if (
      !availablePaymentMethods ||
      settlePayment?.forcePaymentMethodType !== PaymentMethodCode.Card ||
      !eftPaymentSettings?.additionalMethodsWhenEftIsForced ||
      eftPaymentSettings.additionalMethodsWhenEftIsForced.length === 0
    ) {
      return [];
    }

    return availablePaymentMethods
      .filter((pm) => eftPaymentSettings.additionalMethodsWhenEftIsForced.includes(pm.code))
      .map((pm) => pm.code);
  }

  function isPaymentMethodCodeAvailable(paymentMethodCode: string | undefined) {
    return (
      availablePaymentMethods.some((p) => p.code === paymentMethodCode) &&
      !excludedPaymentMethodsCodes.some((e) => e === paymentMethodCode)
    );
  }

  const payOnAccountShouldBeUsed = () =>
    settlePayment?.deliveryType === PickUpTypesValues.pickUp &&
    settlePayment?.forcedPaymentMethodCode === payOnAccountMethodCode &&
    isPaymentMethodCodeAvailable(payOnAccountMethodCode);

  const forcePaymentMethodTypesShouldBeUsed = () =>
    settlePayment?.forcePaymentMethodType && isPaymentMethodCodeAvailable(settlePayment?.forcePaymentMethodType);

  const firstAvailablePaymentMethodShouldBeUsed = () =>
    availablePaymentMethods !== undefined &&
    availablePaymentMethods.length > 0 &&
    !isPaymentMethodCodeAvailable(activePaymentMethod);

  useEffect(() => {
    if (payOnAccountShouldBeUsed()) {
      dispatch(setActivePaymentMethod(PaymentMethodCode.PayOnAccount));
      setPaymentMethodForced(true);
    } else if (forcePaymentMethodTypesShouldBeUsed()) {
      dispatch(setActivePaymentMethod(settlePayment?.forcePaymentMethodType));
      setPaymentMethodForced(true);
    } else if (firstAvailablePaymentMethodShouldBeUsed()) {
      const firstAvailablePaymentMethod = availablePaymentMethods.find((p) =>
        isPaymentMethodCodeAvailable(p.code),
      );
      dispatch(setActivePaymentMethod(firstAvailablePaymentMethod?.code));
    }
  }, [availablePaymentMethods, excludedPaymentMethodsCodes]);

  useEffect(() => {
    if (customerCanPayOnAccount) {
      setExcludedPaymentMethodsCodes([]);
    } else if (payOnAccountMethodCode) {
      setExcludedPaymentMethodsCodes([payOnAccountMethodCode]);
    }
  }, [customerCanPayOnAccount]);

  useEffect(() => {
    const paymentMethodsCount = availablePaymentMethods?.length ?? 0;
    const deliveryType = settlePayment?.deliveryType ?? activeDeliveryType;
    if (paymentMethodsCount === 0) {
      dispatch(
        getPaymentMethods(
          deliveryType
            ? { pickupType: deliveryType, storeId: selectedStore ? selectedStore.id : undefined }
            : undefined,
        ),
      );
    }
  }, [activeDeliveryType]);

  useEffect(() => {
    if (activePaymentMethod !== PaymentMethodCode.Cash) {
      dispatch(setLastOrderChangeAmount());
    }
  }, [activePaymentMethod]);

  if (settlePayment === undefined) return null;

  const handleClose = (): void => {
    if (unpaidOrderPlacementInProgress) return;
    if (eftStatus !== 'IDLE') {
      return;
    }
    settlePayment.closeCallback();
  };

  const shouldChargeCompany =
    activePaymentMethod?.toString() === payOnAccountMethodCode && customerCanPayOnAccount && chargedCompany;

  const onEftSuccess = (eftId: string): void => {
    const eftPaymentOperation = isEftKindSelected
      ? ({ eftPaymentId: eftId, finishedManually: false } as EftPaymentOperation)
      : undefined;

    settlePayment.successCallback({
      orderId: settlePayment.orderId,
      paymentMethodCode: activePaymentMethod as PaymentMethodCode,
      eftPaymentOperation,
      openTabId: settlePayment.openTabId,
      orderAlreadyPlaced: settlePayment.unpaidOrderPlaced,
      chargedCompany: shouldChargeCompany ? chargedCompany : undefined,
    });
    appInsights.trackEvent({
      name: 'Submit order',
      properties: { Identifier: eftId, Status: undefined },
    });
  };

  const onEftPaymentStarted = (eftId: string): void => {
    if (settlePayment.startPaymentCallback) {
      settlePayment.startPaymentCallback({
        orderId: settlePayment.orderId,
        paymentMethod: activePaymentMethod as PaymentMethodCode,
        eftPaymentId: eftId,
        openTabId: settlePayment.openTabId,
        orderAlreadyPlaced: settlePayment.unpaidOrderPlaced,
      });
    }
  };

  const onEFTSuccessManually = (): void => {
    settlePayment.successCallback({
      orderId: settlePayment.orderId,
      paymentMethodCode: activePaymentMethod as PaymentMethodCode,
      eftPaymentOperation: {
        finishedManually: true,
      },
      openTabId: settlePayment.openTabId,
      orderAlreadyPlaced: settlePayment.unpaidOrderPlaced,
      chargedCompany: shouldChargeCompany ? chargedCompany : undefined,
    });
    appInsights.trackEvent({
      name: 'Eft payment manually finished',
    });
  };

  const handlePaymentReady = (): void => {
    settlePayment.successCallback({
      orderId: settlePayment.orderId,
      paymentMethodCode: activePaymentMethod as PaymentMethodCode,
      openTabId: settlePayment.openTabId,
      orderAlreadyPlaced: settlePayment.unpaidOrderPlaced,
      chargedCompany: shouldChargeCompany ? chargedCompany : undefined,
    });
  };

  const inputValidator: ValidationInput = {
    errors,
    formRef: register,
    required: true,
    validators: {
      isMoney: (val: string): boolean | string =>
        (payment && Validators.Money.Validator(payment).test(val)) || Validators.Money.Message,
      aboveOrderTotal: (val: string): boolean | string =>
        (payment && numberFromMoney(val, payment) >= settlePayment.totalToPay) ||
        t('Value must bigger than order total'),
    },
  };

  function changeAmountUpdated(value: number | undefined) {
    dispatch(setLastOrderChangeAmount(value));
  }

  const additionalPaymentMethodsForForcedEft = getAdditionalPaymentMethodsCodesForForcedEft();
  const showPaymentMethodsSelectionBar =
    eftStatus === 'IDLE' &&
    !unpaidOrderPlacementInProgress &&
    (!paymentMethodForced || (paymentMethodForced && additionalPaymentMethodsForForcedEft.length > 0));

  return (
    <ModalContainer
      onClose={handleClose}
      open
      title={t('Payment')}
      primaryButton={{
        action: handlePaymentReady,
        form: 'settlePaymentForm',
        label: t('Payment ready'),
        testId: 'settle-payment__button--submit-payment',
      }}
      secondaryButton={{
        label: t('Cancel'),
        testId: 'settle-payment__button--cancel',
        action: handleClose,
      }}
      testId="settle-payment-modal"
      showChildrenOnly={isEftKindSelected}
      hideCloseIcon={eftStatus !== 'IDLE' || unpaidOrderPlacementInProgress}
    >
      {showPaymentMethodsSelectionBar && (
        <PaymentMethodTypeBar excludedPaymentMethodsCodes={excludedPaymentMethodsCodes} />
      )}
      <form className={classes.dialogForm} autoComplete="off" name="settlePaymentForm" id="settlePaymentForm">
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          {activePaymentMethod === PaymentMethodCode.Card ? (
            <EftPayment
              orderId={settlePayment.orderId}
              openTabId={settlePayment.openTabId}
              onClose={handleClose}
              onSuccess={onEftSuccess}
              totalToPay={settlePayment?.totalToPay ?? 0}
              canFinalizeManually={!paymentMethodForced && !eftPaymentSettings?.hideManualFinishButton}
              onEftManualFinalize={onEFTSuccessManually}
              onPaymentStarted={settlePayment.useTwoStepOrderPlacing ? onEftPaymentStarted : undefined}
              eftEnabled={isEftKindSelected}
            />
          ) : (
            <Box sx={{ margin: 'auto', p: 3 }}>
              {activePaymentMethod === PaymentMethodCode.Cash && (
                <PaymentDenominations
                  triggerValidation={trigger}
                  inputValidator={inputValidator}
                  orderTotal={settlePayment?.totalToPay ?? 0}
                  onChangeAmountUpdated={changeAmountUpdated}
                />
              )}

              {activePaymentMethod === payOnAccountMethodCode && (
                <PayOnAccountMethod
                  chargedCompanyName={settlePayment?.chargedCompanyWhenPayingOnAccount?.name ?? ''}
                />
              )}
              {![PaymentMethodCode.Card, payOnAccountMethodCode, PaymentMethodCode.Cash].some(
                (el) => el === activePaymentMethod,
              ) && <GenericPaymentMethod />}
            </Box>
          )}
        </Box>
      </form>
    </ModalContainer>
  );
};

export default SettlePayment;
