import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, palette }) => {
  return {
    backdrop: {
      zIndex: 1,
      position: 'relative',
      height: '320px',
      mx: spacing(2),
    },
    deliveryAddressFormContainer: {
      marginTop: '-339px',
    },
    popper: {
      zIndex: 1300,
      width: '460px',
      boxShadow: '0px 9px 46px 0px rgba(0, 0, 0, 0.3)',
      padding: spacing(2),
      borderRadius: '12px',
      backgroundColor: 'white',
      marginTop: `${spacing(0.5)} !important`,
    },
    list: {
      padding: 0,
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      gap: spacing(1),
      cursor: 'pointer',
      alignItems: 'flex-start',
      backgroundColor: 'white',
      border: 'none',
      padding: `12px ${spacing(1)}`,
    },
    pinIcon: {
      margin: `3px ${spacing(0.5)}`,
    },
    addressMatch: {
      color: '#5E6A74',
    },
  };
});

export default buildClasses;
