import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { PrintingConfiguration } from 'containers/FirstTimeLaunch/FirstTimeLaunch';
import { filePath } from 'utils/validation/ValidationConstants';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import { FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getPrintersList } from 'stores/Setup/setup.thunk-actions';
import { getInstanceType } from 'stores/Config/config.selector';
import buildClasses from './PrintingSettingsGroup.css';

interface PrintingConfigurationErrors {
  foxit: FieldError;
  wkhtml: FieldError;
  printer: FieldError;
  usePrinter: FieldError;
  isRoll: FieldError;
  printCopies: FieldError;
  directUploadPrintCopies: FieldError;
}

interface PrintingConfigurationFields {
  foxit: string;
  wkhtml: string;
  printer: string;
  usePrinter: boolean;
  isRoll: boolean;
  printingCopies: number;
  directUploadPrintingCopies: number;
  printEmployeeNameOnReceipt: boolean;
}

export interface PrintingSettingsProps {
  onFormSubmit: (printingConfig: PrintingConfiguration) => void;
  predefinedSettings: PrintingConfiguration;
}

const PrintingSettingsGroup: React.FC<PrintingSettingsProps> = ({ onFormSubmit, predefinedSettings }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('settings');
  const [printAfterFinalize, setPrintAfterFinalize] = useState<boolean>(false);
  const [printEmployeeName, setPrintEmployeeName] = useState<boolean>(false);
  const {
    register,
    setValue,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<PrintingConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const typedErrors = errors as PrintingConfigurationErrors;
  const instanceType = useAppSelector(getInstanceType);
  const { printersList } = useAppSelector((store) => store.setup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (predefinedSettings.local) {
      setValue('foxit', predefinedSettings.local.printingToolPath);
      setValue('wkhtml', predefinedSettings.local.htmlToPdfConverterPath);
      setPrintAfterFinalize(predefinedSettings.local.printReceiptsAfterOrderFinalization);
      setValue('printingCopies', predefinedSettings.local.printCopies);
      setPrintEmployeeName(predefinedSettings.general.printEmployeeNameOnReceipt);
    }
  }, []);

  useEffect(() => {
    dispatch(getPrintersList());
  }, [predefinedSettings.local?.targetPrinterName]);

  useEffect(() => {
    if (printersList?.length > 0) {
      const selectedValue = predefinedSettings.local?.targetPrinterName || printersList[0];
      setValue('printer', selectedValue, { shouldValidate: true });
    }
  }, [printersList]);

  const onSubmit = ({
    foxit,
    wkhtml,
    usePrinter,
    printer,
    isRoll,
    printingCopies,
  }: PrintingConfigurationFields): void => {
    onFormSubmit({
      general: {
        printEmployeeNameOnReceipt: printEmployeeName,
        isRollPrinter: isRoll,
      },
      local: {
        localPrintingEnabled: !!printer,
        htmlToPdfConverterPath: wkhtml,
        printReceiptsAfterOrderFinalization: usePrinter,
        targetPrinterName: printer,
        printingToolPath: foxit,
        printCopies: printingCopies,
      },
    });
  };

  const { ref: usePrinterRef, ...usePrinterRest } = register('usePrinter');

  const { ref: printingCopiesRef, ...printingCopiesRest } = register('printingCopies', {
    required: true,
    min: 1,
    max: 50,
  });

  const { ref: foxitRef, ...foxitRest } = register('foxit', {
    required: true,
    pattern: { value: filePath(), message: t('Not a filepath') },
  });

  const { ref: wkhtmlRef, ...wkhtmlRest } = register('wkhtml', {
    required: true,
    pattern: { value: filePath(), message: t('Not a filepath') },
  });

  return (
    <form
      className={classes.form}
      id="printingSettingsForm"
      name="printingSettingsForm"
      onSubmit={handleSubmit(onSubmit)}
    >
      {instanceType !== 'Central' && (
        <>
          <SettingItem
            label={t('Print receipt after order finalization?')}
            type="custom"
            testId="printing-settings__input--print-receipt-after-order"
          >
            <FilledSwitch
              checked={printAfterFinalize}
              inputRef={usePrinterRef}
              {...usePrinterRest}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void =>
                setPrintAfterFinalize(checked)
              }
            />
          </SettingItem>
          <SettingItem
            label={t('Number of receipt copies')}
            type="custom"
            testId="printing-settings__input--print-copies"
          >
            <TextField
              inputRef={printingCopiesRef}
              {...printingCopiesRest}
              fullWidth
              variant="outlined"
              type="number"
              defaultValue="1"
              error={typedErrors.printCopies && true}
              helperText={typedErrors.printCopies && typedErrors.printCopies.message}
              inputProps={{ 'data-testid': 'printing-settings__input--print-copies-amount', min: '1' }}
            />
            <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
          </SettingItem>
          <SettingItem label={t('Select printer')} type="custom" testId="printing-settings__input--printers-list">
            <Controller
              render={({ field }) => (
                <Select {...field} fullWidth variant="outlined">
                  {printersList?.map((printer) => {
                    return (
                      <MenuItem key={printer} value={printer}>
                        {printer}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              name="printer"
              rules={{ required: t('Printer must be selected!') as string }}
              control={control}
              defaultValue=""
            />
            <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
          </SettingItem>
          <SettingItem
            label={t('Foxit reader location')}
            type="custom"
            testId="printing-settings__input--foxit-location"
          >
            <TextField
              inputRef={foxitRef}
              {...foxitRest}
              fullWidth
              variant="outlined"
              placeholder="C:\Program Files (x86)\Foxit Software\Foxit Reader\FoxitReader.exe"
              error={typedErrors.foxit && true}
              helperText={typedErrors.foxit && typedErrors.foxit.message}
              inputProps={{ 'data-testid': 'printing-settings__--printer-path' }}
            />
            <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
          </SettingItem>
          <SettingItem
            label={t('HTML to PDF converter location')}
            type="custom"
            testId="printing-settings__input--wkhtml-location"
          >
            <TextField
              inputRef={wkhtmlRef}
              {...wkhtmlRest}
              fullWidth
              variant="outlined"
              placeholder="C:\Program Files\wkhtmltopdf\bin\wkhtmltopdf.exe"
              error={typedErrors.wkhtml && true}
              helperText={typedErrors.wkhtml && typedErrors.wkhtml.message}
              inputProps={{ 'data-testid': 'printing-settings__input--html-to-pdf-path' }}
            />
            <FormHelperText>{errors.printer && errors.printer.message}</FormHelperText>
          </SettingItem>
        </>
      )}
    </form>
  );
};

export default PrintingSettingsGroup;
