import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AutoAddedItem, BasketData, BasketItem, BasketState, FiscalizationState } from 'typings/Basket';
import { cloneDeep } from 'lodash';
import uuidGenerator from 'utils/GuidGenerator';
import { isNotEmpty } from 'utils/types';
import { recalculateBasket, validateBasket } from './basket.thunk-actions';
import { generateFiscalEvents } from './fiscalUtils';

export const initialState: BasketState = {
  autoAddedItems: [],
  virtualReceipt: { receiptDiscounts: [], receiptProducts: [] },
  basketItems: [],
  basketCoupons: [],
  basketData: null,
  manualDeliveryCharge: null,
  lastBasketClearTimestamp: Date.now(),
  fiscalization: {
    contextId: uuidGenerator(),
    events: [],
    enabled: false,
  },
};

const resetFiscalization = (state: BasketState) => {
  return {
    ...state.fiscalization,
    events: [],
    contextId: uuidGenerator(),
  };
};

const reduceFiscalization = (
  state: BasketState,
  basketData?: BasketData | null,
  basketItems?: BasketItem[] | null,
  autoAddedItems?: AutoAddedItem[] | null,
): FiscalizationState => {
  if (!state.fiscalization.enabled) {
    return state.fiscalization;
  }

  const {
    basketData: previousBasketData,
    basketItems: previousBasketItems,
    autoAddedItems: previousAutoAddedItems,
    fiscalization: { events },
  } = state;

  const newEvents = generateFiscalEvents(
    basketData,
    previousBasketData,
    basketItems ?? [],
    previousBasketItems,
    autoAddedItems ?? [],
    previousAutoAddedItems,
  );

  return {
    ...state.fiscalization,
    events: newEvents.length > 0 ? [...events, newEvents].filter(isNotEmpty) : events,
  };
};

const basketSlice = createSlice({
  name: '[BASKET]',
  initialState,
  reducers: {
    setBasketState(state, action: PayloadAction<BasketState>) {
      const fiscalization = reduceFiscalization(
        state,
        action.payload.basketData,
        action.payload.basketItems,
        action.payload.autoAddedItems,
      );
      return {
        ...action.payload,
        fiscalization,
      };
    },
    clearBasket: (state) => ({
      ...state,
      ...cloneDeep(initialState),
      lastBasketClearTimestamp: Date.now(),
      fiscalization: resetFiscalization(state),
    }),
    doNotGroupItems(state, action: PayloadAction<number[]>) {
      const items = state.basketItems.map((item) => {
        return {
          ...item,
          doNotGroup: action.payload.includes(item.id),
        };
      });

      return {
        ...state,
        basketItems: items,
      };
    },
    ignoreItemsInPriceCalculations(state, action: PayloadAction<number[]>) {
      const items = state.basketItems.map((item) => {
        return {
          ...item,
          ignoreInPriceCalculation: action.payload.includes(item.id),
        };
      });

      return {
        ...state,
        basketItems: items,
      };
    },
    setManualDeliveryCharge: (state, action: PayloadAction<number | null>) => ({
      ...state,
      manualDeliveryCharge: action.payload,
    }),
    changeAutoItemQuantity(state, action: PayloadAction<{ itemId: number; quantity: number }>) {
      const editedItems = state.autoAddedItems.map((aai) => {
        if (aai.itemId === action.payload.itemId) {
          return { ...aai, quantity: action.payload.quantity, wasEdited: true };
        }
        return aai;
      });

      return {
        ...state,
        autoAddedItems: editedItems,
      };
    },
    setFiscalizationEnabled(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        fiscalization: {
          ...state.fiscalization,
          events: action.payload ? state.fiscalization.events : [],
          enabled: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateBasket.fulfilled, (state, action) => {
      return {
        ...state,
        validationResult: action.payload,
      };
    });
    builder.addCase(recalculateBasket.pending, (state) => {
      return {
        ...state,
        recalculateInProgress: true,
        validationResult: undefined,
      };
    });
    builder.addCase(recalculateBasket.rejected, (state) => {
      return {
        ...state,
        recalculateInProgress: undefined,
      };
    });
    builder.addCase(recalculateBasket.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const { basketItems, basketCoupons, basketData, virtualReceipt, addedProducts } = action.payload;
      const fiscalization = reduceFiscalization(state, basketData, basketItems, addedProducts);
      return {
        ...state,
        basketItems,
        basketCoupons,
        basketData,
        virtualReceipt,
        autoAddedItems: addedProducts,
        recalculateInProgress: undefined,
        fiscalization,
      };
    });
  },
});

const { reducer } = basketSlice;
export const {
  clearBasket,
  setBasketState,
  setFiscalizationEnabled,
  doNotGroupItems,
  ignoreItemsInPriceCalculations,
  setManualDeliveryCharge,
  changeAutoItemQuantity,
} = basketSlice.actions;

export default reducer;
