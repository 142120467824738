import React from 'react';
import { Grid2 as Grid, Typography, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import {
  emailPattern,
  noBlankAtBeginningNoDigitsPattern,
  phoneNumberPattern,
} from 'utils/validation/ValidationConstants';
import { FormTextField } from 'components/FormFields';
import FormPart from '../FormPart/FormPart';

interface CustomerDataFormProps {
  isPhoneRequired: boolean;
  onPhoneNumberChanged?: (phoneNumberValue: string | null) => void;
}

const CustomerDataForm: React.FC<CustomerDataFormProps> = ({ isPhoneRequired, onPhoneNumberChanged }) => {
  const [t] = useTranslation('intake');
  const incorrectValueMessage = t('Incorrect value');
  const optimisedPhoneNumberChangedHandler = debounce(phoneNumberChanged, 500);
  const { palette, spacing } = useTheme();

  function phoneNumberChanged(value: string | null) {
    if (onPhoneNumberChanged) {
      onPhoneNumberChanged(value);
    }
  }

  function MaxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('max.')} ${max})`;
  }

  return (
    <FormPart>
      <Typography variant="h6" gutterBottom sx={{ color: palette.black.main, marginBottom: spacing(3) }}>
        {t('Customer data')}
      </Typography>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            fullWidth
            label={t('First Name')}
            variant="outlined"
            inputProps={{ 'data-testid': 'finalize-customer-form__input--name' }}
            name="name"
            rules={{
              pattern: { value: noBlankAtBeginningNoDigitsPattern, message: incorrectValueMessage },
              maxLength: { value: 200, message: MaxLengthMessage(200) },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            fullWidth
            label={t('Last Name')}
            variant="outlined"
            inputProps={{ 'data-testid': 'finalize-customer-form__input--last-name' }}
            name="surname"
            rules={{
              pattern: { value: noBlankAtBeginningNoDigitsPattern, message: incorrectValueMessage },
              maxLength: { value: 200, message: MaxLengthMessage(200) },
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            fullWidth
            label={t('Phone Number')}
            variant="outlined"
            inputProps={{ 'data-testid': 'finalize-customer-form__input--phone-number' }}
            name="telephoneNumber"
            rules={{
              required: { value: isPhoneRequired, message: t('Field required') },
              pattern: { value: phoneNumberPattern, message: incorrectValueMessage },
              maxLength: { value: 20, message: MaxLengthMessage(20) },
            }}
            onChange={(e) => {
              optimisedPhoneNumberChangedHandler(e.target.value);
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            fullWidth
            label={t('Email Address')}
            variant="outlined"
            inputProps={{ 'data-testid': 'finalize-customer-form__input--email-address' }}
            name="emailAddress"
            rules={{
              pattern: { value: emailPattern, message: incorrectValueMessage },
              maxLength: { value: 250, message: MaxLengthMessage(250) },
            }}
          />
        </Grid>
      </Grid>
    </FormPart>
  );
};

export default CustomerDataForm;
