import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DineInRefreshMessage, NotificationsState } from 'typings/Notifications';
import uuidGenerator from 'utils/GuidGenerator';

const initialState: NotificationsState = {};

const notificationsSlice = createSlice({
  name: '[NOTIFY]',
  initialState,
  reducers: {
    updateLastDineInRefreshMessage(state, action: PayloadAction<DineInRefreshMessage>) {
      return { ...state, lastDineInRefreshMessage: action.payload };
    },
    setNotificationsConnectionId(state, action: PayloadAction<string>) {
      return { ...state, notificationsConnectionId: action.payload };
    },
    disconnectNotifications(state) {
      return { ...state, lastDineInRefreshMessage: undefined, notificationsConnectionId: undefined };
    },
    reconnectToNotifications(state) {
      return { ...state, reconnectionSentinelFlag: uuidGenerator() };
    },
  },
});

const { reducer } = notificationsSlice;
export const {
  updateLastDineInRefreshMessage,
  setNotificationsConnectionId,
  disconnectNotifications,
  reconnectToNotifications,
} = notificationsSlice.actions;

export default reducer;
