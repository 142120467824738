import { Alert, Box, Typography, useTheme } from '@mui/material';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppSelector } from 'hooks/useRedux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSwitchedStore } from 'stores/Store/store.selectors';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { getFeatureFlags, getUseDeliveryAddressCheck } from 'stores/Config/config.selector';

export type FinalizeAlertType = 'minimumOrderValue' | 'storeChanged' | 'addressOutsideStoreArea';
interface FinalizeAlertsProps {
  hideMOVAlerts?: boolean;
}
const FinalizeAlerts: React.FC<FinalizeAlertsProps> = ({ hideMOVAlerts = false }) => {
  const { spacing } = useTheme();
  const [t] = useTranslation('intake');
  const [alertsDissmised, setAlertDismissed] = useState<FinalizeAlertType[]>([]);
  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const switchedStore = useAppSelector(getSwitchedStore);
  const { deliveryAreaDetails } = useAppSelector((state) => state.intake);
  const featureFlags = useAppSelector(getFeatureFlags);
  const useDeliveryAddressCheck = useAppSelector(getUseDeliveryAddressCheck);
  const { basketData } = useAppSelector((state) => state.basket);
  const storeChanged = switchedStore && !switchedStore.isDefaultStore;

  const shouldDisplayMinimumOrderValueWarning =
    !hideMOVAlerts &&
    !alertsDissmised.some((alert) => alert === 'minimumOrderValue') &&
    basketData?.minimumOrderValue &&
    (!basketData.minimumOrderValue.isReached || basketData.minimumOrderValue.deliveryChargeChange > 0);

  const shouldDispalyStoreChangedWarning =
    !alertsDissmised.some((alert) => alert === 'storeChanged') && storeChanged;

  const shouldDisplayOutsideStoreDeliveryAreaWarning =
    featureFlags.OfflineModule_DisableModalForOutsideStoreDeliveryArea &&
    !alertsDissmised.some((alert) => alert === 'addressOutsideStoreArea') &&
    useDeliveryAddressCheck &&
    deliveryAreaDetails?.isAddressInCurrentStoreArea === false;

  const minimumOrderValueAlertText = ((): string => {
    if (!basketData || !shouldDisplayMinimumOrderValueWarning) {
      return '';
    }

    const formattedMinimumOrderValue = formatToDisplay(basketData.minimumOrderValue.targetValue);
    const formattedSurcharge = formatToDisplay(basketData.minimumOrderValue.deliveryChargeChange);

    const addedSurchargeMessageChunk =
      basketData.minimumOrderValue.deliveryChargeChange > 0
        ? `${t('Additional charge was added')}: ${formattedSurcharge}. `
        : '';

    return `${t(
      'Minimum order value not reached',
    )} ${formattedMinimumOrderValue}. ${addedSurchargeMessageChunk}${t('Do you really want to continue')}`;
  })();

  const addressOutsideStoreDeliveryAreaText = ((): string => {
    if (deliveryAreaDetails && deliveryAreaDetails.storesDeliveringForRequiredAddress?.length > 0) {
      return `${t(
        'The address falls outside your delivery area, but belongs to store(s)',
      )} : ${deliveryAreaDetails.storesDeliveringForRequiredAddress.join(', ')}`;
    }
    return t('The address falls outside your delivery area.');
  })();

  function dismissAlert(alert: FinalizeAlertType) {
    setAlertDismissed([...alertsDissmised, alert]);
  }

  return (
    <Box sx={{ marginTop: spacing(3), marginLeft: spacing(4), marginRight: spacing(4) }}>
      {shouldDispalyStoreChangedWarning && (
        <Typography variant="h5" gutterBottom data-testid="store-switched-alert">
          <Alert
            severity="warning"
            onClose={() => dismissAlert('storeChanged')}
            icon={<ErrorOutlineOutlined fontSize="inherit" />}
          >
            {`${t('Please notice! The order will be placed for a store')} ${switchedStore.name}. ${t(
              'Are you sure you want to proceed?',
            )}`}
          </Alert>
        </Typography>
      )}
      {shouldDisplayMinimumOrderValueWarning && (
        <Typography variant="h5" gutterBottom data-testid="minimum-order-value-alert">
          <Alert
            severity="warning"
            onClose={() => dismissAlert('minimumOrderValue')}
            icon={<ErrorOutlineOutlined fontSize="inherit" />}
          >
            {minimumOrderValueAlertText}
          </Alert>
        </Typography>
      )}
      {shouldDisplayOutsideStoreDeliveryAreaWarning && (
        <Typography variant="h5" gutterBottom data-testid="outside-store-delivery-area--alert">
          <Alert
            severity="warning"
            onClose={() => dismissAlert('addressOutsideStoreArea')}
            icon={<ErrorOutlineOutlined fontSize="inherit" />}
          >
            {addressOutsideStoreDeliveryAreaText}
          </Alert>
        </Typography>
      )}
    </Box>
  );
};
export default FinalizeAlerts;
