import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AppBar, Collapse, Tab, Tabs } from '@mui/material';
import { setCurrentStoreTab } from 'stores/AllOrders';
import { submitAllOrdersForm } from 'containers/AllOrders/AllOrderConsts';
import buildClasses from './StoreTabs.css';

const OrderContentSwitch: React.FC = () => {
  const { classes } = buildClasses();
  const dispatch = useAppDispatch();
  const [t] = useTranslation('orders');
  const { currentStoreTab } = useAppSelector(({ allOrders }) => allOrders);

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: 'all-stores' | 'my-store'): void => {
      dispatch(setCurrentStoreTab(value));
      submitAllOrdersForm();
    },
    [dispatch],
  );

  return (
    <Collapse in sx={{ minHeight: 'fit-content' }}>
      <AppBar position="static" className={classes.subNavbar}>
        <Tabs
          className={classes.subNavbarContent}
          classes={{
            indicator: classes.indicator,
          }}
          value={currentStoreTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          color="secondary"
          textColor="secondary"
        >
          <Tab
            data-testid="all-orders__tabs_button--my-store"
            classes={{ root: classes.tabButton, selected: classes.tabButtonSelected }}
            label={t('This store')}
            value="my-store"
          />
          <Tab
            data-testid="all-orders__tabs_button--all-stores"
            classes={{ root: classes.tabButton, selected: classes.tabButtonSelected }}
            label={t('All stores')}
            value="all-stores"
          />
        </Tabs>
      </AppBar>
    </Collapse>
  );
};

export default OrderContentSwitch;
