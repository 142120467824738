import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { AppRoute } from 'App/Routes/routes-list';

interface AppMenuItemProps {
  route: AppRoute;
  isActive: boolean;
  menuItemClicked: (route: AppRoute) => void;
  testId: string;
}

const AppMenuItem = ({ route, isActive, menuItemClicked, testId }: AppMenuItemProps): JSX.Element => {
  const [t] = useTranslation('common');

  const menuItemClickedHandler = useCallback((): void => {
    menuItemClicked(route);
  }, [route, menuItemClicked]);

  return (
    <MenuItem
      component={Link}
      to={route.path}
      onClick={menuItemClickedHandler}
      selected={isActive}
      sx={{
        '&.Mui-selected': {
          backgroundColor: 'white',
        },
      }}
      data-testid={testId}
    >
      <ListItemIcon sx={{ mr: 5, ml: 2 }}>
        <route.icon />
      </ListItemIcon>
      <ListItemText primary={t(route.navbarName)} sx={{ '& span': { fontWeight: 700 } }} />
    </MenuItem>
  );
};

export default AppMenuItem;
