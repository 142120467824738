import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { getInstanceType, getShowFirstTimeLaunch } from 'stores/Config/config.selector';
import { useGetConfigQuery } from 'stores/Config/config.api';
import ConnectionLostDialog from './ConnectionLostDialog';
import ConnectionReestablishedDialog from './ConnectionReestablishedDialog';

export const ConnectionDialogs = () => {
  const [openConnectionLostDialog, setOpenConnectionLostDialog] = React.useState(false);
  const [openConnectionReestablishedDialog, setOpenConnectionReestablishedDialog] = React.useState(false);
  const showFirstTimeLaunch = useAppSelector(getShowFirstTimeLaunch);
  const instanceType = useAppSelector(getInstanceType);
  const { isCloudPosAvailable, offlineModeEnabled } = useAppSelector((store) => store.hybridMode);

  const connectionReestablishedDialogOpened = openConnectionReestablishedDialog && !showFirstTimeLaunch;

  const { isSuccess: configLoaded } = useGetConfigQuery();

  useEffect(() => {
    if (!configLoaded || isCloudPosAvailable === undefined) {
      return;
    }

    if (instanceType === 'InStore' && isCloudPosAvailable) {
      setOpenConnectionReestablishedDialog(true);
    }

    if (instanceType === 'Central') {
      setOpenConnectionLostDialog(!isCloudPosAvailable);
    }
  }, [configLoaded, isCloudPosAvailable, instanceType]);

  return (
    <>
      {openConnectionLostDialog && (
        <ConnectionLostDialog open={openConnectionLostDialog} offlineModeEnabled={offlineModeEnabled} />
      )}
      {connectionReestablishedDialogOpened && (
        <ConnectionReestablishedDialog open={connectionReestablishedDialogOpened} />
      )}
    </>
  );
};
