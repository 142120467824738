import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getFiscalizationConfiguration, getFiscalizationEnabled } from 'stores/Config/config.selector';
import { getLoggedCashier } from 'stores/Cashier/cashier.selector';
import { useGetOrdersFiscalStatusQuery } from 'stores/Notifications/notifications.api';
import { setFiscalizationEnabled } from 'stores/Basket/basket.slice';

export const useFiscalizationEvents = () => {
  const dispatch = useAppDispatch();
  const fiscalizationConfiguration = useAppSelector(getFiscalizationConfiguration);
  const loggedCashier = useAppSelector(getLoggedCashier);
  const fiscalizationEnabled = useAppSelector(getFiscalizationEnabled);

  useGetOrdersFiscalStatusQuery(undefined, {
    pollingInterval: fiscalizationConfiguration?.awaitingFiscalizationNotificationIntervalInSeconds
      ? fiscalizationConfiguration.awaitingFiscalizationNotificationIntervalInSeconds * 1000
      : undefined,
    skipPollingIfUnfocused: true,
    refetchOnReconnect: true,
    skip: !(
      fiscalizationEnabled &&
      loggedCashier &&
      fiscalizationConfiguration?.awaitingFiscalizationNotificationIntervalInSeconds
    ),
  });

  useEffect(() => {
    dispatch(setFiscalizationEnabled(fiscalizationEnabled));
  }, [fiscalizationEnabled]);
};
