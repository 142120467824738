import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import InputUnit, { InputValidation, ValidationInput } from 'components/Shared/Inputs/InputUnit';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import Validators from 'utils/validation/ValidationConstants';
import { setActiveIntakeTab } from 'stores/Intake';
import { clearBasket } from 'stores/Basket/basket.slice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCreateDineInOrderStatus } from 'stores/DineIn/dineIn.slice';
import { getDineInOrder } from 'stores/DineIn/dineIn-thunk.actions';
import { DineInState } from 'stores/DineIn/dineInTypes';
import { AvailableIntakeContents } from 'containers/Intake/IntakeConsts';
import buildClasses from './NewTab.css';

export interface NewTabProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (inputValue: string) => void;
  orderCreationDetails: DineInState['createDineInOrderDetails'];
}

const NewTabModal: React.FC<NewTabProps> = ({ open, onClose, onSuccess, orderCreationDetails }) => {
  const { classes } = buildClasses();
  const [t] = useTranslation('intake');
  const nameMaxLength = 128;
  const dispatch = useAppDispatch();
  const [orderData, setOrderData] = useState<{ orderId: string; tableId: string }>();
  const [triggerRedirect, setTriggerRedirect] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<InputValidation>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const { selectedDineInOrder, isTableChangeInProgress } = useAppSelector((state) => state.dineIn);

  function onSubmit(data: InputValidation): void {
    setTriggerRedirect(true);
    onSuccess(data.inputValue);
  }

  useEffect(() => {
    if (orderCreationDetails?.status === 'SUCCESS') {
      onClose();
    }
  }, [orderCreationDetails]);

  useEffect(() => {
    if (orderData) {
      dispatch(
        setCreateDineInOrderStatus({
          status: 'FAILED',
          tableId: orderData.tableId,
          orderId: orderData.orderId,
        }),
      );
    }

    setOrderData(undefined);
  }, [orderData]);

  useEffect(() => {
    if (selectedDineInOrder && triggerRedirect) {
      setTriggerRedirect(false);
      dispatch(setCreateDineInOrderStatus({ status: 'SUCCESS' }));
      dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
  }, [selectedDineInOrder, false]);

  const inputValidator: ValidationInput = {
    errors,
    formRef: register,
    required: true,
    validators: {
      isTableNameNoLongerThan: (val: string): boolean | string =>
        Validators.IsNoLongerThan.Validator(val, nameMaxLength) ||
        `${t('Max table name length is')} ${nameMaxLength}`,
      isTableNameNotEmpty: (val: string): boolean | string =>
        Validators.IsNotEmpty.Validator(val) || `${t('Table name must not be empty')}`,
    },
  };

  function removeOrderCreationDetail() {
    if (orderCreationDetails) {
      dispatch(setCreateDineInOrderStatus());
    }
  }

  return (
    <ModalContainer
      onClose={onClose}
      open={open}
      title={isTableChangeInProgress ? t('Move the table to a custom table') : t('Open new order')}
      primaryButton={{
        type: 'submit',
        form: 'addNewTabForm',
        label: t('Open new order'),
        testId: 'add-new-tab-modal__button--set-table-name',
        disabled: orderCreationDetails?.status === 'FAILED',
      }}
      secondaryButton={
        orderCreationDetails?.status === 'FAILED'
          ? {
              label: 'Open existing order',
              testId: 'add-new-tab-modal__button--open-existing-order',
              action: () => {
                setOrderData({ orderId: orderCreationDetails.orderId, tableId: orderCreationDetails.tableId });
                dispatch(clearBasket());
                dispatch(getDineInOrder(orderCreationDetails.orderId));
              },
            }
          : undefined
      }
      testId="manual-price-override-modal"
    >
      <form
        className={classes.dialogForm}
        autoComplete="off"
        name="addNewTabForm"
        onSubmit={handleSubmit(onSubmit)}
        id="addNewTabForm"
      >
        <div>
          <Typography variant="h6">{t('Table name')}</Typography>
          <InputUnit
            inputValidation={inputValidator}
            testId="add-new-tab-modal__input--table-name"
            autofocus
            onChange={removeOrderCreationDetail}
            defaultValue={orderCreationDetails?.status === 'FAILED' ? orderCreationDetails.tableId : undefined}
          />
          {orderCreationDetails?.status === 'FAILED' && (
            <Typography variant="body2" classes={{ root: classes.alreadyAssignedInfo }}>
              {t('Order already open for selected table')}
            </Typography>
          )}
        </div>
      </form>
    </ModalContainer>
  );
};

export default NewTabModal;
