import { Middleware, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { isBoolean } from 'lodash';
import { logOutCashier, setClockInRequired } from './Cashier';
import { resetStoreState } from './Store';
import { validateUser } from './AllOrders';
import { storeApi } from './Store/store.api';
import { setActiveIntakeTab } from './Intake';
import { AvailableIntakeContents } from '../containers/Intake/IntakeConsts';

export const globalMiddleware: Middleware<unknown, any, ThunkDispatch<any, any, UnknownAction>> =
  (api) => (next) => (action) => {
    const { payload } = action as UnknownAction;

    if (logOutCashier.fulfilled.match(action)) {
      api.dispatch(resetStoreState());
    }
    if (validateUser.fulfilled.match(action) && isBoolean(payload)) {
      api.dispatch(setClockInRequired(!payload));
    }
    if (storeApi.endpoints.switchStore.matchFulfilled(action)) {
      api.dispatch(setActiveIntakeTab(AvailableIntakeContents.Products));
    }
    return next(action);
  };
