import React from 'react';
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import { CashierOperation } from 'typings/Cashier';
import { useTranslation } from 'react-i18next';
import { getIsDesktop } from 'stores/Config/config.selector';
import { useAppSelector } from 'hooks/useRedux';

export interface TimekeepingButtonsProps {
  onUnlockClick: () => void;
  onClockInClick: () => void;
  onClockOutClick: () => void;
  operationInProgress?: CashierOperation;
}

const TimekeepingButtons: React.FC<TimekeepingButtonsProps> = ({
  onUnlockClick,
  onClockInClick,
  onClockOutClick,
  operationInProgress,
}) => {
  const [t] = useTranslation('authentication');
  const isDesktop = useAppSelector(getIsDesktop);
  const { spacing } = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: spacing(4) }}>
      <Button
        sx={{ visibility: isDesktop ? 'hidden' : 'visible', margin: spacing(0.5), width: 'calc(33.3%)' }}
        variant="contained"
        color="secondary"
        onClick={() => onClockInClick()}
        data-testid="pincode_login__button--clock-in"
      >
        {operationInProgress === 'clockIn' ? <CircularProgress size={16} /> : t('CLOCK IN')}
      </Button>
      <Button
        sx={{ visibility: isDesktop ? 'hidden' : 'visible', margin: spacing(0.5), width: 'calc(33.3%)' }}
        variant="contained"
        color="secondary"
        onClick={() => onClockOutClick()}
        data-testid="pincode_login__button--clock-out"
      >
        {operationInProgress === 'clockOut' ? <CircularProgress size={16} /> : t('CLOCK OUT')}
      </Button>
      <Button
        sx={{ margin: spacing(0.5), width: 'calc(33.3%)' }}
        variant="contained"
        color="primary"
        onClick={() => onUnlockClick()}
        data-testid="pincode_login__button--login"
      >
        {operationInProgress === 'unlock' ? <CircularProgress size={16} color="secondary" /> : t('UNLOCK')}
      </Button>
    </Box>
  );
};
export default TimekeepingButtons;
