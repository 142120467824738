import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useAppSelector } from 'hooks/useRedux';
import { getActiveDeliveryType, getCanEditBasket } from 'stores/Intake/intake.selector';
import { useFormContext } from 'react-hook-form';
import {
  getBasketDataDeliveryCharge,
  getBasketDataSummary,
  getManualDeliveryCharge,
} from 'stores/Basket/basket.selector';
import { CheckoutDetailsForm } from '../../Finalize/DeliveryAddressForm/AddressConst';

const ReceiptTotal = () => {
  const { palette } = useTheme();
  const [t] = useTranslation('intake');
  const { getValues } = useFormContext<CheckoutDetailsForm>();
  const summary = useAppSelector(getBasketDataSummary);

  const formatToDisplay = useAppSelector(getPaymentFormatter);
  const manualDeliveryCharge = useAppSelector(getManualDeliveryCharge);
  const deliveryCharge = useAppSelector(getBasketDataDeliveryCharge);
  const pickUpType = useAppSelector(getActiveDeliveryType);
  const basketEditable = useAppSelector(getCanEditBasket);

  const wasTotalOverridden = summary?.total !== summary?.originalTotal;

  const checkoutFormHaveValues = Object.values(getValues()).length > 0;

  const showDeliveryCharge = checkoutFormHaveValues || manualDeliveryCharge !== null;
  const deliveryChargeVisible =
    showDeliveryCharge &&
    pickUpType === PickUpTypesValues.delivery &&
    deliveryCharge !== undefined &&
    deliveryCharge.grossPrice !== 0;

  const shouldShowDeliveryCharge = deliveryChargeVisible || !basketEditable;

  const total = ((): number => {
    if (!summary) return 0;

    if (shouldShowDeliveryCharge || !deliveryCharge?.grossPrice) return summary.total;

    return summary.total - deliveryCharge.grossPrice;
  })();

  const subtotal = ((): number => {
    if (!summary) return 0;

    if (shouldShowDeliveryCharge || !deliveryCharge?.grossPrice) return summary.subtotal;

    return summary.subtotal - deliveryCharge.grossPrice;
  })();

  const originalTotal = ((): number => {
    if (!summary) return 0;

    if (shouldShowDeliveryCharge || !deliveryCharge?.grossPrice) return summary.originalTotal;

    return summary.originalTotal - deliveryCharge.grossPrice;
  })();

  return (
    <Box
      sx={{
        padding: '5px 20px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        flexFlow: 'row',
        height: '77px',
      }}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column', width: '50%', height: '67px', alignItems: 'stretch' }}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            fontSize: '14px',
            listStyle: 'none',
            paddingLeft: 0,
            margin: 0,
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          {shouldShowDeliveryCharge && (
            <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <Typography variant="caption">{t('Delivery charge')}</Typography>
              <Typography
                sx={{ marginLeft: 'auto' }}
                variant="caption"
                data-testid="receipt-footer__label--delivery-charge-value"
              >
                {deliveryCharge && formatToDisplay(deliveryCharge.grossPrice)}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <Typography variant="caption">{t('Subtotal')}</Typography>
            <Typography
              sx={{ marginLeft: 'auto' }}
              variant="caption"
              data-testid="receipt-footer__label--subtotal-value"
            >
              {formatToDisplay(summary ? subtotal : 0)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <Typography variant="caption">{t('Discount')}</Typography>
            <Typography
              sx={{ marginLeft: 'auto' }}
              variant="caption"
              data-testid="receipt-footer__label--discount-value"
            >
              {formatToDisplay(summary?.discount ?? 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexFlow: 'column', width: '50%', height: '67px', alignItems: 'stretch' }}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            fontSize: '14px',
            listStyle: 'none',
            paddingLeft: 0,
            margin: 0,
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          <Box sx={{ marginLeft: 'auto' }}>
            <Typography variant="overline">{t('Total')}</Typography>
            {wasTotalOverridden && summary && (
              <Typography variant="overline" sx={{ marginLeft: '10px' }}>
                <del>{formatToDisplay(originalTotal)}</del>
              </Typography>
            )}
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ fontSize: '30px', fontWeight: 700, float: 'right', color: palette.black.main }}
              data-testid="receipt-footer__label--total-value"
            >
              {formatToDisplay(summary ? total : 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiptTotal;
