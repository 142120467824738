import React from 'react';
import { Avatar, Box, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmployeeInfo } from 'typings/Cashier';
import { formatDateTimeToDisplay } from 'utils/localisation/dateTimeUtils';

import { AccountCircle } from 'assets/icons';
import { useTheme } from '@mui/system';
import { getPaymentConfig } from 'stores/Config/config.selector';
import { useAppSelector } from 'hooks/useRedux';

export interface ClockedInEmployeeProps {
  employeeInfo: EmployeeInfo;
  shiftTime?: string;
  breakTimeMinutes?: number;
}

const ClockedInEmployee: React.FC<ClockedInEmployeeProps> = ({ employeeInfo, breakTimeMinutes, shiftTime }) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('authentication');
  const paymentSettings = useAppSelector(getPaymentConfig);

  return (
    <ListItem
      key={employeeInfo.fullname}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'right',
        borderBottom: `1px solid ${palette.grey[300]}`,
        padding: `${spacing(2)} 0px`,
        '&:first-of-type': {
          paddingTop: 0,
        },
      }}
    >
      <Avatar>
        <AccountCircle style={{ background: 'white' }} />
      </Avatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          ml: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography color="black.main" variant="subtitle2">
            {employeeInfo.fullname}
          </Typography>
          <Typography variant="caption">{t(employeeInfo.role)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
          {employeeInfo?.breakDurationInMinutes !== undefined && (
            <Typography
              variant="caption"
              sx={{
                px: '8px',
                py: '4px',
                borderRadius: '4px',
                marginBottom: `${spacing(0.5)}`,
                color: 'rgba(33, 126, 60, 1)',
                bgcolor: 'rgba(204, 255, 231, 1)',
              }}
            >
              {t('On a break')}
              {`: ${employeeInfo.breakDurationInMinutes} `}
              {t('min')}
            </Typography>
          )}
          <Typography variant="caption">
            {t(shiftTime ? 'Shift Time' : 'Clocked in at')}
            {shiftTime && `: ${shiftTime}`}
          </Typography>
          <Typography color="black.main" variant="caption">
            {breakTimeMinutes
              ? `${t('Break time')}: ${breakTimeMinutes} ${t('min')}`
              : formatDateTimeToDisplay(new Date(employeeInfo.clockedInAt), paymentSettings.culture)}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

export default ClockedInEmployee;
