import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'stores';
import { CashierPermission } from 'typings/Cashier';

const selectSelf = (state: RootState) => state;

const selectCashier = createSelector(selectSelf, (state) => state.cashier);

export const getLoggedCashier = createSelector(selectCashier, (cashier) => cashier.loggedCashier);

export const getPermissionChecker = createSelector(
  getLoggedCashier,
  (loggedCashier) => (accessType: CashierPermission) => {
    if (!loggedCashier || !accessType) return false;
    if (loggedCashier.permissions.some((el) => el === accessType)) return true;

    return false;
  },
);

export const getClockInRequired = createSelector(selectCashier, (cashier) => cashier.clockInRequired);

export const getRevalidationResult = createSelector(selectCashier, (cashier) => cashier.revalidationResult);
