import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { PinPad } from 'components/Auth/PinPad';
import { resetCashierState, revalidatePin } from 'stores/Cashier';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import ModalContainer from 'components/Shared/Modal/ModalContainer';

export interface RevalidatePinModalProps {
  revalidateFor: string;
  onClose: () => void;
}

const RevalidatePinModal: React.FC<RevalidatePinModalProps> = ({ revalidateFor, onClose }) => {
  const { sizing, spacing } = useTheme();
  const [t] = useTranslation('intake');
  const [pin, setPin] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const incorrectPinMessageClearTimeout = 3000;

  const { revalidationResult, operationInProgress } = useAppSelector(({ cashier }) => cashier);
  const dispatch = useAppDispatch();

  function clearPin(): void {
    setErrorMessage(undefined);
    updatePin('');
  }

  function updatePin(pinValue: string): void {
    const isPinMissing = !pinValue;
    setErrorMessage(isPinMissing ? t('PIN is required') : undefined);
    setPin(pinValue);
  }

  function validatePin(): boolean {
    const isPinMissing = !pin;
    setErrorMessage(isPinMissing ? t('PIN is required') : undefined);
    return !isPinMissing;
  }

  function handleSubmit(): void {
    if (!validatePin()) {
      return;
    }
    dispatch(revalidatePin({ pinCode: pin, revalidateFor }));
  }

  useEffect(() => {
    if (revalidationResult && !revalidationResult.success) {
      setErrorMessage(t('Incorrect PIN'));
      setPin('');
      setTimeout(() => {
        setErrorMessage(undefined);
      }, incorrectPinMessageClearTimeout);
    }
  }, [revalidationResult]);

  function handleClose() {
    dispatch(resetCashierState());
    onClose();
  }

  return (
    <ModalContainer
      maxWidth="md"
      onClose={handleClose}
      open
      title={t('Please enter your pin once again')}
      testId="revalidation-modal"
      primaryButton={{
        action: () => {
          handleSubmit();
        },
        type: 'submit',
        label: t('Revalidate'),
        testId: 'revalidation-modal-submit',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: sizing.pinPad.width,
          margin: `${spacing(3)}`,
        }}
      >
        {operationInProgress ? (
          <div>
            <CircularProgress size={120} />
          </div>
        ) : (
          <PinPad
            pinCode={pin}
            clearPin={clearPin}
            submit={handleSubmit}
            showExtraConfirm
            onChange={updatePin}
            errorMessage={errorMessage}
          />
        )}
      </Box>
    </ModalContainer>
  );
};

export default RevalidatePinModal;
