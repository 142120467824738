import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { EtaConfiguration, EtaConfigurationResponse, StoreInfo, SwitchStore } from 'typings/Store';
import { posApiUrls } from 'API/PosApi';
import { posBaseQuery } from '../base.queries';
import { restartIntakeState } from '../Intake';
import { restartDineInState } from '../DineIn/dineIn.slice';
import { resetProductsState } from '../Products';
import { resetFloorsState } from '../Floors/floors.slice';
import { resetCouponsState } from '../Coupons';
import { restartLocalOrdersState } from '../LocalOrders';
import { cashierAPI } from '../Cashier/cashier.api';

export const storeApi = createApi({
  reducerPath: 'storeApi',
  baseQuery: posBaseQuery,
  endpoints: (builder) => ({
    getSelectedStore: builder.query<StoreInfo, void>({
      query: () => posApiUrls.SELECTED_STORE_GET,
      providesTags: ['SelectedStore'],
    }),
    getSwitchStores: builder.query<SwitchStore[], void>({
      query: () => posApiUrls.SWITCH_STORES,
      providesTags: ['SwitchStores'],
    }),
    getEtaConfiguration: builder.query<EtaConfigurationResponse, number>({
      query: (storeId) => `${posApiUrls.ETA_CONFIGURATION(storeId)}`,
      providesTags: ['EtaConfiguration'],
    }),
    switchStore: builder.mutation<StoreInfo, string>({
      queryFn: async (storeId, api, extraOptions, baseQuery) => {
        // TODO: move to global listener
        api.dispatch(restartIntakeState());
        api.dispatch(restartDineInState());
        api.dispatch(resetProductsState());
        api.dispatch(resetFloorsState());
        api.dispatch(resetCouponsState());
        api.dispatch(restartLocalOrdersState());
        api.dispatch(cashierAPI.endpoints.getClockedInUsers.initiate(undefined, { forceRefetch: true }));

        const result = await baseQuery({
          url: `${posApiUrls.SWITCH_STORES}/${storeId}`,
          method: 'PUT',
        });

        if (result.error) return { error: result.error as FetchBaseQueryError };

        return {
          data: result.data as StoreInfo,
        };
      },
    }),
    updateStoreEtaConfiguration: builder.mutation<null, { storeId: number; config: EtaConfiguration }>({
      query: ({ storeId, config }) => ({
        url: posApiUrls.SYNCHRONIZATION_ETA(storeId),
        method: 'PUT',
        body: {
          PickUpEtaMinutes: config.pickUpEtaMinutes,
          DeliveryEtaMinutes: config.deliveryEtaMinutes,
        },
      }),
      invalidatesTags: ['EtaConfiguration'],
    }),
  }),
  tagTypes: ['SwitchStores', 'SelectedStore', 'EtaConfiguration'],
});

export const {
  useUpdateStoreEtaConfigurationMutation,
  useSwitchStoreMutation,
  useGetSwitchStoresQuery,
  useGetSelectedStoreQuery,
  useGetEtaConfigurationQuery,
} = storeApi;
