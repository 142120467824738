import { Box, Grid2 as Grid, useTheme } from '@mui/material';
import ProductOptionSelect from 'components/Intake/Product/ProductOptionSelect/ProductOptionSelect';
import ProductSetSteps from 'components/Intake/Product/ProductSetSteps/ProductSetSteps';
import ProductSetToppingCard from 'components/Intake/Product/ToppingCard/ProductSetToppingCard';
import VisibilityContainer from 'components/Shared/Containers/VisibilityContainer';
import { useAppSelector } from 'hooks/useRedux';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Customization,
  CustomizationProduct,
  Option,
  ProductSetStep,
  ProductSetStepSelection,
  ToppingSelection,
} from 'typings/Products';

import {
  createInitialCustomization,
  createTabsSetSteps,
  getNumberOfSelectedToppings,
  isProductSetComplete,
  mapToCustomization,
} from 'utils/intake/productSetUtils';
import { getToppings } from 'stores/Products/products.selector';

export interface ProductSetCustomization {
  selections: ProductSetStepSelection[];
  selectedOptionId?: number;
  quantity: number;
}
interface ProductSetProps {
  customizationProduct: CustomizationProduct;
  options: Option[];
  quantity: number;
  triggerAddItem?: boolean;
  onItemRecalculate: (optionId: number, customization: Customization) => void;
  onProductSetNotComplete: () => void;
  onAddItem: (optionId: number, customization: Customization) => void;
}

const ProductSet: React.FC<ProductSetProps> = ({
  customizationProduct,
  options,
  quantity,
  triggerAddItem,
  onItemRecalculate,
  onProductSetNotComplete,
  onAddItem,
}) => {
  const { palette, spacing } = useTheme();
  const toppings = useAppSelector(getToppings);
  const [activeOptionId, setActiveOptionId] = useState<number>(customizationProduct.originalOptionId);
  const [activeStep, setActiveStep] = useState(getFirstStepOrderNumber());
  const [activeStepTopppings, setActiveStepToppings] = useState<ToppingSelection[]>([]);
  const [productSetCustomization, setProductSetCustomization] = useState<ProductSetCustomization>();

  useEffect(() => {
    setProductSetCustomization(
      createInitialCustomization(customizationProduct, toppings, quantity, activeOptionId),
    );
    setActiveStep(getFirstStepOrderNumber());
  }, [customizationProduct]);

  useEffect(() => {
    if (isProductSetComplete(customizationProduct, productSetCustomization)) {
      onItemRecalculate(activeOptionId ?? 0, mapToCustomization(customizationProduct, productSetCustomization));
    } else {
      onProductSetNotComplete();
    }
    setActiveStepToppings(getTopingsForStep(activeStep));
  }, [productSetCustomization, activeOptionId]);

  useEffect(() => {
    if (triggerAddItem && activeOptionId) {
      setProductSetCustomization(undefined);
      onAddItem(activeOptionId ?? 0, mapToCustomization(customizationProduct, productSetCustomization));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAddItem]);

  function getFirstStepOrderNumber(): number {
    const orderNumbers = customizationProduct.baseProduct.toppingSelectionSteps?.map((s) => s.order) ?? [];

    if (orderNumbers.length === 0) {
      return 0;
    }

    return Math.min(...orderNumbers);
  }

  function changeStep(newStep: number): void {
    setActiveStep(newStep);
    setActiveStepToppings(getTopingsForStep(newStep));
  }

  function getStepDefinition(step: number): ProductSetStep | undefined {
    return customizationProduct.baseProduct.toppingSelectionSteps?.find((setStep) => setStep.order === step);
  }

  function getTopingsForStep(step: number): ToppingSelection[] {
    const setStepDefinition = getStepDefinition(step);
    const alreadySelectedToppings =
      productSetCustomization?.selections.find((s) => s.order === step)?.selectedToppings ?? [];
    const stepAvailableToppingsIds =
      setStepDefinition?.availableToppings?.map((topping) => topping.toppingId) ?? [];

    return toppings
      .filter((topping) => stepAvailableToppingsIds.includes(topping.id))
      .map((topping) => ({
        ...topping,
        quantity: alreadySelectedToppings.find((asti) => asti.id === topping.id)?.quantity ?? 0,
      }));
  }

  function isStepComplete(step: number): boolean {
    const activeStepDefinition = getStepDefinition(step);
    const stepToppingSelections =
      productSetCustomization?.selections.find((s) => s.order === step)?.selectedToppings ?? [];
    const numberOfSelectedToppings = getNumberOfSelectedToppings(stepToppingSelections);

    if (!activeStepDefinition) {
      return false;
    }

    return numberOfSelectedToppings >= activeStepDefinition.requiredAmountOfToppings;
  }

  function maximumAmountOfToppingsReachedForActiveStep(step: number): boolean {
    const activeStepDefinition = getStepDefinition(step);
    const stepToppingSelections =
      productSetCustomization?.selections.find((s) => s.order === step)?.selectedToppings ?? [];
    const numberOfSelectedToppings = getNumberOfSelectedToppings(stepToppingSelections);

    if (!activeStepDefinition) {
      return false;
    }

    return numberOfSelectedToppings >= activeStepDefinition.maximumAmountOfToppings;
  }

  function moveToNextStepIfConditionsFullfilled(selectedToppings: ToppingSelection[]): void {
    const activeStepDefinition = getStepDefinition(activeStep);

    if (!activeStepDefinition) {
      return;
    }

    const numberOfSelectedToppings = getNumberOfSelectedToppings(selectedToppings);
    const incompleteSteps = productSetCustomization?.selections.filter((s) => !isStepComplete(s.order)) ?? [];

    if (numberOfSelectedToppings >= activeStepDefinition.requiredAmountOfToppings) {
      const nextStep = activeStep + 1;

      if (incompleteSteps.find((s) => s.order === nextStep)) {
        changeStep(activeStep + 1);
      }
    }
  }

  function setToppingsForStep(selectedToppings: ToppingSelection[]): void {
    const currentCustomization = !productSetCustomization
      ? createInitialCustomization(customizationProduct, toppings, quantity, activeOptionId)
      : { ...productSetCustomization };

    const activeStepSelection = currentCustomization.selections.find(
      (stepSelection) => stepSelection.order === activeStep,
    );

    if (activeStepSelection) {
      activeStepSelection.selectedToppings = selectedToppings.filter(
        (topping) => topping.quantity && topping.quantity > 0,
      );
    }

    setProductSetCustomization(currentCustomization);

    if (!isProductSetComplete(customizationProduct, productSetCustomization)) {
      setTimeout(() => {
        moveToNextStepIfConditionsFullfilled(activeStepSelection?.selectedToppings ?? []);
      }, 300);
    }
  }

  function handleToppingAdded(topping: ToppingSelection): void {
    const activeStepDefinition = getStepDefinition(activeStep);
    const newToppings = [...activeStepTopppings];
    const toppingIndex = newToppings.findIndex((el) => el.id === topping.id);

    if (toppingIndex !== -1 && topping.quantity !== undefined) {
      newToppings[toppingIndex].quantity = topping.quantity + 1;
    }

    if (activeStepDefinition && activeStepDefinition.maximumAmountOfToppings === 1) {
      const otherSelectedTopping = newToppings.find((t) => t.id !== topping.id && t.quantity);

      if (otherSelectedTopping) {
        otherSelectedTopping.quantity = 0;
      }
    }

    setToppingsForStep(newToppings);
  }

  function handleToppingRemoved(topping: ToppingSelection): void {
    const newToppings = [...activeStepTopppings];
    const toppingIndex = newToppings.findIndex((el) => el.id === topping.id);

    if (toppingIndex !== -1 && topping.quantity) {
      newToppings[toppingIndex].quantity = topping.quantity - 1;
    }

    setToppingsForStep(newToppings);
  }

  function getTabs() {
    const tabsDefinitions = createTabsSetSteps(customizationProduct);

    if (
      !productSetCustomization?.selections ||
      productSetCustomization.selections.length !== tabsDefinitions.length
    ) {
      return tabsDefinitions;
    }

    return tabsDefinitions.map((tab) => ({
      ...tab,
      isCompleted: isStepComplete(tab.order),
    }));
  }

  const activeStepSortedToppings = sortBy(activeStepTopppings, 'sortOrder');
  const maximumAmoutOfToppingsReached = maximumAmountOfToppingsReachedForActiveStep(activeStep);

  return (
    <Box
      sx={{ paddingBottom: spacing(2), borderBottom: `1px solid ${palette.grey[200]}`, marginBottom: spacing(2) }}
    >
      {options && (
        <ProductOptionSelect
          customizationProduct={customizationProduct}
          preselectedProductId={customizationProduct.baseProduct.id}
          preselectedOptionId={customizationProduct.originalOptionId}
          onChange={setActiveOptionId}
        />
      )}

      <ProductSetSteps tabs={getTabs()} selectTab={changeStep} selectedTab={activeStep} />
      <VisibilityContainer isVisible>
        {activeStepSortedToppings && (
          <Grid container spacing={1}>
            {activeStepSortedToppings.map((topping) => (
              <Grid size={{ xs: 12, sm: 6, md: 3, lg: 2 }} key={topping.id}>
                <ProductSetToppingCard
                  onQuantityIncreased={(toppingClicked): void => {
                    handleToppingAdded(toppingClicked);
                  }}
                  onQuantityDecreased={(toppingClicked): void => {
                    handleToppingRemoved(toppingClicked);
                  }}
                  topping={topping}
                  preventToppingAdd={maximumAmoutOfToppingsReached}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </VisibilityContainer>
    </Box>
  );
};

export default ProductSet;
