import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ spacing, palette, radius }) => {
  return {
    storeSelector: {
      color: palette.primary.contrastText,
      backgroundColor: 'transparent',
      '& > svg': {
        color: palette.primary.contrastText,
      },
      root: {
        borderRadius: radius.sm,
      },
      maxWidth: '90%',
    },
    storePanelLoader: {
      color: palette.secondary.main,
    },
    myStore: {
      marginLeft: spacing(0.5),
    },
    selectItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    storeNameWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    storeName: {
      color: palette.secondary.contrastText,
    },
    storeSelectorInner: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  };
});

export default buildClasses;
