import React, { useEffect, useState } from 'react';
import PageHeader from 'components/Shared/PageHeader/PageHeader';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getPaymentMethods } from 'stores/Payments';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { Box, useTheme } from '@mui/material';
import { getSelectedStore } from 'stores/Store/store.selectors';
import Overview from './Overview/LocalOrdersOverview';
import { headerOptionButtons } from './LocalOrdersConsts';

const Orders: React.FC = () => {
  const { sizing } = useTheme();
  const [selectedOption, setSelectedOption] = useState(headerOptionButtons[0].value);
  const selectedStore = useAppSelector(getSelectedStore);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedStore) {
      dispatch(
        getPaymentMethods({
          pickupType: PickUpTypesValues.pickUp,
        }),
      );
    }
  }, [selectedStore]);

  return (
    <Box sx={{ height: `calc(100vh - ${sizing.appBarHeight}px)`, backgroundColor: '#E0E0E0' }}>
      <PageHeader headerOptions={headerOptionButtons} translationNS="orders" onSelect={setSelectedOption} />
      <Overview selectedOption={selectedOption} />
    </Box>
  );
};

export default Orders;
