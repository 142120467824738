import React, { memo } from 'react';
import { useAppSelector } from 'hooks/useRedux';

import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getPersistentAlert } from 'stores/Alerts/alerts.selector';
import buildClasses from './ReceiptPersistentAlert.css';

export const ReceiptPersistentAlert = memo(() => {
  const { classes, cx } = buildClasses();
  const persistentAlert = useAppSelector(getPersistentAlert);
  const [t] = useTranslation('alerts');

  if (!persistentAlert) return null;

  const getContainerStyle = () => {
    switch (persistentAlert.variant) {
      case 'error':
        return classes.containerError;
      case 'success':
        return classes.containerSuccess;
      case 'warning':
        return classes.containerWarning;
      default:
        return classes.containerInfo;
    }
  };

  return (
    <div className={cx(classes.alertContainer, getContainerStyle())}>
      <ErrorOutlineOutlined />
      <Typography variant="body1" className={classes.text}>
        {t(persistentAlert.message)} {persistentAlert.messageAddon}
      </Typography>
    </div>
  );
});

ReceiptPersistentAlert.displayName = 'ReceiptPersistentAlert';
