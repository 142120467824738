import React, { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setActiveIntakeTab } from 'stores/Intake';
import { getAvailableIntakeContents } from 'stores/Intake/intake.selector';
import IntakeContentSwitch from 'components/Intake/Finalize/IntakeContentSwitch/IntakeContentSwitch';
import CustomerSearchResults from '../Customer/CustomerSearchResults';
import MealConfigurator from '../Products/MealConfigurator/MealConfigurator';
import { AvailableIntakeContents, IntakeStatuses } from '../IntakeConsts';
import CouponCatalog from '../Coupons/Coupons';
import DineInOrdersOverview from '../DineInOrders/Overview';
import FloorsSection from '../Floors/FloorsSection';
import ProductsContainer from '../Products/Products';
import RewardsContainer from '../Loyalty/RewardsContainer';
import PreFinalize from '../PreFinalize/PreFinalize';
import Finalize from '../Finalize/Finalize';
import buildClasses from '../Intake.css';
import { useCustomerSearch } from '../useCustomerSearch';

type IntakeContentProps = {
  receiptVisible: boolean;
  setReceiptVisible: (visible: boolean) => void;
};

export const IntakeContent: React.FC<IntakeContentProps> = memo(({ receiptVisible, setReceiptVisible }) => {
  const { clearCustomerSearch } = useCustomerSearch();
  const { classes, cx } = buildClasses();
  const dispatch = useAppDispatch();
  const availableIntakeContents = useAppSelector(getAvailableIntakeContents);
  const { activeIntakeTab, intakeStatus, customerSearchQuery } = useAppSelector((state) => state.intake);
  const { couponToCustomize } = useAppSelector((state) => state.coupons);
  const { isTableChangeInProgress } = useAppSelector((state) => state.dineIn);
  const { productToCustomize } = useAppSelector((state) => state.products);
  const showCustomerSearch = customerSearchQuery !== undefined;

  const showMealConfigurator = couponToCustomize?.meals.every((x) => x.availableProducts.length > 0) === true;

  const showIntakeContentSwitch = (() => {
    if (productToCustomize !== undefined) {
      return false;
    }
    if (intakeStatus !== IntakeStatuses.productsSection) {
      return false;
    }
    if (isTableChangeInProgress) {
      return false;
    }
    return true;
  })();

  const switchIntakeContent = useCallback(
    (_event: React.SyntheticEvent, value: AvailableIntakeContents): void => {
      dispatch(setActiveIntakeTab(value));
    },
    [dispatch],
  );

  return (
    <div className={cx(classes.intakeContent, { [classes.intakeContentFullWidth]: !receiptVisible })}>
      {showCustomerSearch ? (
        <CustomerSearchResults
          query={customerSearchQuery}
          onCancelCustomerSelection={clearCustomerSearch}
          onIntakeWithoutProfileClick={clearCustomerSearch}
          onCustomerSelected={clearCustomerSearch}
        />
      ) : (
        <>
          {showIntakeContentSwitch && (
            <IntakeContentSwitch
              handleChange={switchIntakeContent}
              availableContents={availableIntakeContents}
              intakeContent={activeIntakeTab}
            />
          )}
          {showMealConfigurator ? (
            <MealConfigurator />
          ) : (
            <>
              {intakeStatus === IntakeStatuses.productsSection && (
                <>
                  {activeIntakeTab === AvailableIntakeContents.Coupons && <CouponCatalog />}
                  {activeIntakeTab === AvailableIntakeContents.DineInOpenOrders && <DineInOrdersOverview />}
                  {activeIntakeTab === AvailableIntakeContents.Tables && (
                    <FloorsSection receiptVisible={receiptVisible} setReceiptVisibility={setReceiptVisible} />
                  )}
                  {activeIntakeTab === AvailableIntakeContents.Products && <ProductsContainer />}
                  {activeIntakeTab === AvailableIntakeContents.Rewards && <RewardsContainer />}
                </>
              )}
              {intakeStatus === IntakeStatuses.preFinalize && <PreFinalize />}
              {intakeStatus === IntakeStatuses.finalizeOrder && <Finalize />}
            </>
          )}
        </>
      )}
    </div>
  );
});

IntakeContent.displayName = 'IntakeContent';
