import React from 'react';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';

interface EftPendingProps {
  toPay: number;
}

const EftPending: React.FC<EftPendingProps> = ({ toPay }) => {
  const [t] = useTranslation('common');
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  return (
    <>
      <Typography variant="h5" sx={{ m: 1 }} data-testid="settle-payment__label--eft-in-progress">
        {t('Eft processing')}
      </Typography>
      <Typography variant="body1" data-testid="settle-payment__label--eft-amount">
        {t('Eft amount')}
        {': '}
        {formatToDisplay(toPay)}
      </Typography>
      <CircularProgress sx={{ mt: 3 }} color="primary" size="150px" />
    </>
  );
};

export default EftPending;
