import React, { useState } from 'react';
import SettingGroup from 'components/Settings/SettingGroup/SettingGroup';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'components/Shared/Modal/ModalContainer';
import { Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { hardLogout } from 'stores/Auth';
import { getInstanceType } from 'stores/Config/config.selector';

const SystemSettings = () => {
  const [t] = useTranslation('settings');
  const [showWarningModal, setShowWarningModal] = useState(false);
  const dispatch = useAppDispatch();
  const { spacing } = useTheme();
  const instanceType = useAppSelector(getInstanceType);

  return (
    <>
      {instanceType === 'Central' && (
        <SettingGroup label={t('System settings')}>
          <SettingItem
            label={t('Use to do logout from machine')}
            type="button"
            testId="intake-settings__input--using-togointake"
            onButtonClick={() => setShowWarningModal(true)}
            buttonLabel={t('Logout')}
          />
        </SettingGroup>
      )}
      <ModalContainer
        onClose={() => setShowWarningModal(false)}
        title={t('Machine logout warning')}
        open={showWarningModal}
        primaryButton={{
          label: t('Continue'),
          testId: 'hard-logout-proceed',
          action: () => {
            dispatch(hardLogout());
          },
        }}
        secondaryButton={{
          label: t('Cancel'),
          testId: 'hard-logout-cancel',
          action: () => {
            setShowWarningModal(false);
          },
        }}
        testId={t('hard-logout-warning-modal')}
      >
        <Typography variant="body2" sx={{ padding: spacing(2) }}>
          {t('Warning! Machine logout will require manager account to log in again')}
        </Typography>
      </ModalContainer>
    </>
  );
};

export default SystemSettings;
