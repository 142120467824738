import { Box, useTheme } from '@mui/material';
import LoyaltyRewardsFilters from 'components/Intake/Loyalty/LoyaltyFilters';
import React, { useMemo, useState } from 'react';
import RewardsCatalog from 'components/Intake/Loyalty/RewardsCatalog';
import { LoyaltyTier, RewardTab } from 'typings/Loyalty';

type RewardsViewProps = {
  rewardTabs: RewardTab[];
};

const RewardsView: React.FC<RewardsViewProps> = ({ rewardTabs }) => {
  const { palette, spacing } = useTheme();
  const [selectedTierId, setSelectedTierId] = useState<string>();
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [showOnlyAvailableRewards, setshowOnlyAvailableRewards] = useState<boolean>(false);

  const tiers = useMemo(
    () =>
      rewardTabs.map((t) => {
        return {
          id: t.id,
          name: t.name,
          minimumValue: t.minimumValue,
        } as LoyaltyTier;
      }),
    [rewardTabs],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        gap: spacing(1),
        padding: spacing(4),
        paddingBottom: spacing(2),
        backgroundColor: palette.background.default,
      }}
    >
      <LoyaltyRewardsFilters
        tiers={tiers}
        activeTierId={selectedTierId}
        showOnlyAvailableRerwards={showOnlyAvailableRewards}
        searchRewardPhrase={searchPhrase}
        onTierChange={setSelectedTierId}
        onShowOnlyAvailableRewardsChanged={setshowOnlyAvailableRewards}
        onSearchPhraseChanged={setSearchPhrase}
      />
      <RewardsCatalog
        rewardTabs={rewardTabs}
        activeTierId={selectedTierId}
        searchPhrase={searchPhrase}
        showOnlyAvailableRewards={showOnlyAvailableRewards}
      />
    </Box>
  );
};

export default RewardsView;
