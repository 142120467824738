import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'hooks/useRedux';
import { OrderFilter } from 'stores/AllOrders';
import { TextFilter } from './TextFilter';
import { SelectFilter } from './SelectFilter';

interface ExpandedFilterProps {
  filters: OrderFilter[];
  show: boolean;
}

const showFilter = (filterName: string, currentStoreTab: string, currentStatusTab: string): boolean => {
  if (filterName === 'storeName' && currentStoreTab === 'my-store') {
    return false;
  }
  return !(filterName === 'orderStatus' && currentStatusTab === 'unpaid-orders');
};

const ExpandedTableFilters: React.FC<ExpandedFilterProps> = ({ filters, show }) => {
  const { currentStoreTab, currentStatusTab } = useAppSelector(({ allOrders }) => allOrders);
  return (
    <Box px={2} pt={2} sx={{ display: show ? 'flex' : 'none', flexWrap: 'wrap' }}>
      {filters.map((filter) => {
        switch (filter.type.component) {
          case 'number' || 'string':
            return <TextFilter key={filter.name} filter={filter} />;
          case 'select':
            return (
              <SelectFilter
                key={filter.name}
                filter={filter}
                show={showFilter(filter.name, currentStoreTab, currentStatusTab)}
                currentStatusTab={currentStatusTab}
              />
            );
          default: {
            return null;
          }
        }
      })}
    </Box>
  );
};

export default ExpandedTableFilters;
