import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ sizing, breakpoints }) => {
  return {
    intakeWindow: {
      display: 'flex',
      height: `calc(var(--vh, 1vh) * 100 - ${sizing.appBarHeight}px)`,
      gridTemplateColumns: `auto ${sizing.receiptWidth}px`,
      overflow: 'hidden',
      [breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
      },
    },
    intakeContainer: {
      zIndex: 1100,
      height: `calc(100vh - ${sizing.appBarHeight}px)`,
    },
    intakeContent: {
      zIndex: 1100,
      width: `calc(100% - ${sizing.receiptWidth}px)`,
      height: `calc(var(--vh, 1vh) * 100 - ${sizing.appBarHeight}px)`,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      transition: 'width 225ms',
    },
    intakeContentFullWidth: {
      width: '100%',
    },
    fullHeight: {
      height: '100%',
    },
    orderRequiredFormWrapper: {
      overflowY: 'auto',
      height: '100%',
      paddingBottom: 32,
    },
    toastData: {
      justifyContent: 'space-between',
      lineHeight: '1.5',
      marginTop: '10px',
    },
    toastDataRow: {
      display: 'inline-flex',
      width: '100%',
    },
    toastDataRowValue: {
      position: 'absolute',
      right: 0,
      marginRight: '10px',
      fontWeight: 'bold',
    },
    receiptDrawer: {
      zIndex: 1100,
      '& .MuiDrawer-paper': {
        marginTop: `${sizing.appBarHeight}px`,
        overflowY: 'clip',
      },
    },
  };
});
export default buildClasses;
