import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingGroup from 'components/Settings/SettingGroup/SettingGroup';
import SettingItem from 'components/Settings/SettingItem/SettingItem';
import { useForm } from 'react-hook-form';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  getHybridModeCommunicator,
  isHybridModeCommunicationAvailable,
} from 'utils/hybridMode/hybridModeCommunicationUtils';
import { HybridModeMessages } from 'typings/HybridMode';
import { Box, TextField, Button } from '@mui/material';
import { minutesToTimeSpan, timeSpanToMinutes } from 'utils/localisation/dateTimeUtils';
import { LocalStorageItems, getLocalStorageItem, saveLocalStorageItem } from 'utils/localStorageUtils';
import { setIdleTime } from 'stores/Config';
import {
  getFeatureFlags,
  getMaxUserIdleTime,
  getPaymentConfig,
  getZoomSettings,
} from 'stores/Config/config.selector';

interface IntakeSettingsForm {
  idleTime: string;
  finalizePaymentTime: number;
}

const IntakeSettings = (): JSX.Element => {
  const [t] = useTranslation('settings');
  const dispatch = useAppDispatch();
  const payment = useAppSelector(getPaymentConfig);
  const featureFlags = useAppSelector(getFeatureFlags);
  const maxUserIdleTime = useAppSelector(getMaxUserIdleTime);
  const zoomSettings = useAppSelector(getZoomSettings);

  const [useCustomerDisplay, setUseCustomerDisplay] = useState<boolean>(
    getLocalStorageItem<boolean>(LocalStorageItems.useCustomerDisplay) ?? false,
  );
  const [useScanner, setUseScanner] = useState<boolean>(
    getLocalStorageItem<boolean>(LocalStorageItems.useScanner) ?? true,
  );
  const [skipFinalizePaymentStep, setSkipFinalizePaymentStep] = useState<boolean>(
    getLocalStorageItem<boolean>(LocalStorageItems.skipFinalizePaymentStep) ??
      payment?.v2.skipFinalizePaymentStep ??
      false,
  );
  const [zoomLevel, setZoomLevel] = useState<number>(
    getLocalStorageItem<number>(LocalStorageItems.zoomLevel) ?? 100,
  );
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<IntakeSettingsForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const isInHybridApp = isHybridModeCommunicationAvailable();

  const isCustomerDisplayAvailable = isInHybridApp && featureFlags.OfflineModule_CustomerDisplay;

  function handleZoomLevelChange(value: unknown): void {
    const selectedZoomLevel = value as number;
    setZoomLevel(selectedZoomLevel);
  }

  const getInitialFinalizePaymentTime = useCallback(
    (): number =>
      getLocalStorageItem<number>(LocalStorageItems.finalizePaymentTime) ??
      payment?.v2.automaticFinalizationDefaultTime ??
      3,
    [payment?.v2.automaticFinalizationDefaultTime],
  );

  useEffect(() => {
    const initialIdleTime = getLocalStorageItem<string>(LocalStorageItems.idleTimeSpan) ?? maxUserIdleTime;
    initialIdleTime && setValue('idleTime', timeSpanToMinutes(initialIdleTime).toString());
    setValue('finalizePaymentTime', getInitialFinalizePaymentTime());
  }, [getInitialFinalizePaymentTime, maxUserIdleTime, setValue]);

  useEffect(() => {
    !skipFinalizePaymentStep && setValue('finalizePaymentTime', getInitialFinalizePaymentTime());
  }, [getInitialFinalizePaymentTime, setValue, skipFinalizePaymentStep]);

  const onSubmit = ({ idleTime, finalizePaymentTime }: IntakeSettingsForm): void => {
    saveLocalStorageItem<boolean>(LocalStorageItems.useScanner, useScanner);
    saveLocalStorageItem<boolean>(LocalStorageItems.skipFinalizePaymentStep, skipFinalizePaymentStep);

    finalizePaymentTime &&
      saveLocalStorageItem<number>(LocalStorageItems.finalizePaymentTime, finalizePaymentTime);

    idleTime && saveLocalStorageItem<string>(LocalStorageItems.idleTimeSpan, minutesToTimeSpan(Number(idleTime)));
    idleTime && dispatch(setIdleTime(minutesToTimeSpan(Number(idleTime))));

    if (isInHybridApp) {
      const hybridModeCommunicator = getHybridModeCommunicator();

      saveLocalStorageItem<number>(LocalStorageItems.zoomLevel, zoomLevel);

      hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.ChangeZoomLevel, {
        newLevel: zoomLevel,
      });

      if (featureFlags.OfflineModule_CustomerDisplay) {
        saveLocalStorageItem<boolean>(LocalStorageItems.useCustomerDisplay, useCustomerDisplay);

        if (isCustomerDisplayAvailable) {
          if (useCustomerDisplay) {
            hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.OpenCustomerDisplay);
          } else {
            hybridModeCommunicator.send(HybridModeMessages.Hybrid.Commands.CloseCustomerDisplay);
          }
        }
      }
    }
  };

  const { ref: idleTimeRef, ...idleTimeRest } = register('idleTime', {
    required: true,
    min: 1,
    max: 1440,
    pattern: /^\d*[1-9]\d*$/,
  });

  const { ref: finalizePaymentTimeRef, ...finalizePaymentTimeRest } = register('finalizePaymentTime', {
    required: true,
    min: 1,
    max: 5,
    pattern: /^\d*[1-9]\d*$/,
  });

  const onSaveChanges = useCallback(() => {
    document.getElementById('intakeSettingsForm')?.dispatchEvent(new Event('submit', { bubbles: true }));
  }, []);

  return (
    <form id="intakeSettingsForm" name="intakeSettingsForm" onSubmit={handleSubmit(onSubmit)}>
      <SettingGroup label={t('Intake Settings')}>
        <SettingItem
          label={t('Enable scanner handling')}
          type="custom"
          testId="intake-settings__input--enable-scanner"
        >
          <FilledSwitch checked={useScanner} onChange={(_, checked) => setUseScanner(checked)} name="useScanner" />
        </SettingItem>
        {isCustomerDisplayAvailable && (
          <SettingItem
            label={t('Enable customer display')}
            type="custom"
            testId="intake-settings__input--enable-customerdisplay"
          >
            <FilledSwitch
              checked={useCustomerDisplay}
              onChange={(_, checked) => setUseCustomerDisplay(checked)}
              name="useCustomerDisplay"
            />
          </SettingItem>
        )}
        <SettingItem label={t('Idle minutes to logout')} type="custom" testId="intake-settings__input--idle-time">
          <TextField
            inputRef={idleTimeRef}
            {...idleTimeRest}
            required
            variant="outlined"
            type="number"
            inputProps={{
              'data-testid': 'intake-settings__input--idle-time-value',
            }}
            error={errors.idleTime && true}
            helperText={errors.idleTime && t('Idle time must be integer between 1-1440 minutes')}
          />
        </SettingItem>
        <SettingItem
          label={t('Skip "finalize" step for payments modal')}
          type="custom"
          testId="intake-settings__input--skip-finalize-step"
        >
          <FilledSwitch
            onChange={(_, checked) => setSkipFinalizePaymentStep(checked)}
            checked={skipFinalizePaymentStep}
            name="skipFinalizePaymentStep"
          />
        </SettingItem>
        {!skipFinalizePaymentStep && (
          <SettingItem
            label={t('Time to automatically finalize payment')}
            type="custom"
            testId="intake-settings__input--finalize-payment-time"
          >
            <TextField
              inputRef={finalizePaymentTimeRef}
              {...finalizePaymentTimeRest}
              required
              variant="outlined"
              type="number"
              inputProps={{
                'data-testid': 'intake-settings__input--finalize-payment-time-value',
              }}
              error={errors.finalizePaymentTime && true}
              helperText={
                errors.finalizePaymentTime && t('Payment finalize time must be integer between 1-5 seconds')
              }
            />
          </SettingItem>
        )}

        {isInHybridApp && (
          <SettingItem
            label={t('Choose zoom level')}
            type="select"
            selectOptions={zoomSettings?.levels.map((lvl) => {
              return { value: lvl.toString(), description: `${lvl}%` };
            })}
            changeHandler={handleZoomLevelChange}
            defaultValue={zoomLevel}
            testId="zoom-level-settings__select--choose-zoom-level"
          />
        )}

        <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'flex-end' }}>
          <Button
            sx={{ margin: '10px 15px 0' }}
            variant="contained"
            onClick={onSaveChanges}
            data-testid="intake-settings__button--save-changes"
          >
            {t('Save')}
          </Button>
        </Box>
      </SettingGroup>
    </form>
  );
};

export default IntakeSettings;
