import React, { useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { getActivePayment } from 'stores/OrderPayment/orderPayment.selector';
import { AnimatePresence } from 'framer-motion';
import { calculateChange } from 'stores/OrderPayment/orderPayment.actions';

const ChangeValue: React.FC = () => {
  const [t] = useTranslation('common');
  const dispatch = useAppDispatch();
  const { palette, spacing } = useTheme();
  const activePayment = useAppSelector(getActivePayment);

  const { changeAmount, totalLeftAmount } = useAppSelector(({ orderPayment }) => orderPayment);
  const formatToDisplay = useAppSelector(getPaymentFormatter);

  useEffect(() => {
    if (activePayment) {
      dispatch(
        calculateChange({ paymentAmount: activePayment?.customerPaidWith, tipAmount: activePayment?.tipValue }),
      );
    }
  }, [activePayment?.tipValue]);

  if (!activePayment) {
    return null;
  }

  const leftToPay = totalLeftAmount - activePayment.customerPaidWith;

  const showChange = totalLeftAmount <= activePayment.customerPaidWith;
  return (
    <AnimatePresence initial={false}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {changeAmount !== undefined && (
          <>
            <Typography variant="h5" sx={{ mb: 5, color: palette.black.main }}>
              {t(showChange ? 'Change' : 'Left to pay')}
            </Typography>
            <Box
              sx={{
                px: 3,
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                background: palette.grey[50],
                borderRadius: spacing(1),
                color: palette.black.main,
              }}
            >
              <Typography variant="h2" data-testid="settle-payment__label--change-value">
                {formatToDisplay(showChange ? changeAmount : leftToPay)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </AnimatePresence>
  );
};
export default ChangeValue;
