import React, { useEffect } from 'react';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { LoyaltyTier } from 'typings/Loyalty';
import FilledSwitch from 'components/Shared/Switch/FilledSwitch';
import { useTranslation } from 'react-i18next';
import SearchInput from 'components/Shared/Inputs/SearchInput';

interface LoyaltyRewardsFiltersProps {
  tiers: LoyaltyTier[];
  activeTierId: string | undefined;
  showOnlyAvailableRerwards: boolean;
  searchRewardPhrase: string;
  onTierChange: (tierId: string) => void;
  onShowOnlyAvailableRewardsChanged: (checked: boolean) => void;
  onSearchPhraseChanged: (phrase: string) => void;
}

const LoyaltyRewardsFilters: React.FC<LoyaltyRewardsFiltersProps> = ({
  tiers,
  activeTierId,
  showOnlyAvailableRerwards,
  searchRewardPhrase,
  onShowOnlyAvailableRewardsChanged,
  onTierChange,
  onSearchPhraseChanged,
}) => {
  const { palette, typography, sizing, spacing } = useTheme();
  const [t] = useTranslation('intake');

  const allTierOptions = [{ id: '', name: t('All') } as LoyaltyTier, ...tiers].sort(
    (a, b) => a.minimumValue - b.minimumValue,
  );

  useEffect(() => {
    if (!activeTierId && allTierOptions.length > 0) {
      onTierChange(allTierOptions[0].id);
    }
  });

  return (
    <Box
      sx={{
        backgroundColor: `${palette.background.default}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: sizing.full,
        borderRadius: spacing(0.5),
        overflowX: 'auto',
      }}
      data-testid="rewards-selection__container--loyalty-tiers"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: `${spacing(1)}`,
        }}
      >
        {tiers &&
          allTierOptions.map((tier) => {
            const isSelected = tier.id === activeTierId;

            return (
              tier && (
                <Chip
                  label={tier.name}
                  onClick={(): void => {
                    onTierChange(tier.id);
                  }}
                  sx={{
                    color: palette.black.main,
                    fill: palette.black.main,
                    backgroundColor: isSelected ? palette.grey[200] : palette.white,
                    border: `1px solid ${isSelected ? palette.grey[500] : palette.grey[200]}`,
                    gap: '10px',
                    padding: `${spacing(0.5)} ${spacing(1.5)} ${spacing(0.5)} ${spacing(1.5)}`,
                    ...typography.body1,
                  }}
                  key={`tier_${tier.id}`}
                  variant="filled"
                  data-testid={`loyalty-tiers__button--${tier.id}`}
                />
              )
            );
          })}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 'auto',
            gap: `${spacing(2)}`,
          }}
        >
          <Typography
            sx={{
              margin: `${spacing(1)} ${spacing(0)} ${spacing(1)} ${spacing(1)}`,
              whiteSpace: 'nowrap',
            }}
          >
            {t('Show only available rewards')}
          </Typography>
          <FilledSwitch
            checked={showOnlyAvailableRerwards}
            onChange={(_, checked) => onShowOnlyAvailableRewardsChanged(checked)}
          />
        </Box>
      </Box>
      <SearchInput
        value={searchRewardPhrase}
        fullWidth
        placeholder={t('Search for reward')}
        changeFunc={(val: string): void => onSearchPhraseChanged(val)}
      />
    </Box>
  );
};

export default LoyaltyRewardsFilters;
