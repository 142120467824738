import React, { useState, useEffect, useRef } from 'react';
import { TextField, Box, Popper, List, ListItem, ClickAwayListener, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { useTranslation } from 'react-i18next';
import { AddressSuggestion, getSuggestionDetails } from 'stores/Intake';
import { PinIcon } from 'assets/icons';
import { AddressSectionType } from 'stores/Config';
import buildClasses from '../OrderRequiredInfoForm/OrderRequiredInfoForm.css';
import { DeliveryAddressErrors } from './DeliveryAddressForm';

const SuggestionBoxWithPopup: React.FC<{
  inputData: any;
  camelCaseInputName: AddressSectionType;
  errors: DeliveryAddressErrors;
  onChangeFn: (sectionType: AddressSectionType) => void;
}> = ({ errors, camelCaseInputName, inputData, onChangeFn }) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [t] = useTranslation('intake');
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { classes } = buildClasses();
  const { getValues } = useFormContext<CheckoutDetailsForm>();

  const { addressSuggestions } = useAppSelector(({ intake }) => intake);

  const filteredSuggestions = addressSuggestions?.slice(0, 3);

  const onSuggestionTap = (suggestion: AddressSuggestion) => {
    const { placeId } = suggestion;

    dispatch(
      getSuggestionDetails({
        placeId,
      }),
    );

    setOpen(false);
  };

  useEffect(() => {
    if (addressSuggestions && addressSuggestions.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [addressSuggestions]);

  const highlightMatchedText = (suggestionText: string) => {
    const deliveryData = getValues();
    if (!deliveryData.street) return suggestionText;

    const regex = new RegExp(`(${deliveryData.street})`, 'gi');
    const parts = suggestionText.split(regex);

    return parts.map((part) =>
      regex.test(part) ? (
        <span key={part} className={classes.addressMatch}>
          {part}
        </span>
      ) : (
        <span key={part}>{part}</span>
      ),
    );
  };

  return (
    <>
      <Controller
        rules={{
          required: {
            value: !inputData.isOptional,
            message: t('Field required'),
          },
          pattern: {
            value: inputData.format ? RegExp(inputData.format) : /.*/,
            message: t('Incorrect value'),
          },
        }}
        name={camelCaseInputName}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            label={inputData.defaultText}
            variant="outlined"
            error={errors[camelCaseInputName] && true}
            helperText={errors[camelCaseInputName] && errors[camelCaseInputName].message}
            ref={anchorRef}
            onChange={(e) => {
              field.onChange(e);
              onChangeFn(camelCaseInputName);
            }}
            inputProps={{ 'data-testid': `finalize-address-form__input--${camelCaseInputName}` }}
          />
        )}
      />
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" className={classes.popper}>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <List className={classes.list}>
            {filteredSuggestions?.map((suggestion: AddressSuggestion, index: number) => (
              <ListItem
                component="button"
                onClick={() => onSuggestionTap(suggestion)}
                data-testid={`suggestion-${index}`}
                key={suggestion.placeId}
                className={classes.listItem}
              >
                <PinIcon className={classes.pinIcon} />
                <Box>
                  <Typography variant="subtitle1">{highlightMatchedText(suggestion.mainText)}</Typography>
                  <Typography variant="body1">{suggestion.secondaryText || <>&nbsp;</>}</Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SuggestionBoxWithPopup;
