import { Chip } from '@mui/material';
import React, { memo, useCallback } from 'react';
import buildClasses from './GroupChip.css';

type GroupChipProps = {
  groupCode: string;
  description: string | null;
  isSelected: boolean;
  onClick: (groupCode: string) => void;
};

export const GroupChip: React.FC<GroupChipProps> = memo(({ groupCode, description, isSelected, onClick }) => {
  const { cx, classes } = buildClasses();
  const onChipClick = useCallback(() => onClick(groupCode), [groupCode, onClick]);
  return (
    <Chip
      label={description}
      variant="outlined"
      className={cx(classes.chip, {
        [classes.activeChip]: isSelected,
      })}
      onClick={onChipClick}
    />
  );
});

GroupChip.displayName = 'GroupChip';
