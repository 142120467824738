import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import uuidGenerator from 'utils/GuidGenerator';
import { LocalConfigState, InstanceType } from './configTypes';

export const configInitialState: LocalConfigState = {
  connectivityStatus: ConnectivityStatusEnum.Disconnected,
  loggingCorrelationId: undefined,
};

const configSlice = createSlice({
  name: '[CONFIG-LOCAL]',
  initialState: configInitialState,
  reducers: {
    updateThemeKey(state, action: PayloadAction<string>) {
      return { ...state, themeKey: action.payload };
    },
    setFeatureFlag(state, action: PayloadAction<{ key: string; value: boolean }>) {
      const { key, value } = action.payload;
      const featureFlagsChanged = { ...state.featureFlags };
      featureFlagsChanged[key] = value;
      return { ...state, featureFlags: featureFlagsChanged };
    },
    setInstanceType(state, action: PayloadAction<InstanceType>) {
      return { ...state, instanceType: action.payload };
    },
    setIdleTime(state, action: PayloadAction<string>) {
      return { ...state, maxUserIdleTime: action.payload };
    },
    updateConnectivityStatus(state, action: PayloadAction<ConnectivityStatusEnum>) {
      return { ...state, connectivityStatus: action.payload };
    },
    resetLoggingCorrelationId(state) {
      return {
        ...state,
        loggingCorrelationId: uuidGenerator(),
      };
    },
  },
});

const { reducer } = configSlice;

export const {
  updateConnectivityStatus,
  resetLoggingCorrelationId,
  updateThemeKey,
  setFeatureFlag,
  setInstanceType,
  setIdleTime,
} = configSlice.actions;

export default reducer;
