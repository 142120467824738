import React, { useCallback, useEffect, useState } from 'react';
import StartupWrapper from 'App/Routes/DefaultCompontent';
import CashierAuth from 'containers/Authorize/CashierAuth';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { DefaultRoute } from 'App/Routes/routes-list';
import { PIN_PAD_RETURN_TIMEOUT_MS } from 'App/global.consts';
import { CashierOperation, LocalStorageAuthConsts, LoginStates } from 'typings/Cashier';
import ClockedInEmployeesList from 'containers/Authorize/ClockedInEmployeesList';
import { useNavigate } from 'react-router';
import { resetCashierState, setLoginState } from 'stores/Cashier';
import CheckingIdentity from 'containers/Authorize/CheckingIdentity';
import ClockInResult from 'containers/Authorize/ClockInResultScreen';
import { handleCheck } from 'utils/applicationContainer/refreshAppUtils';
import { Button, useTheme } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import DataSyncComponent from 'components/DataSync/DataSyncComponent';
import CashFlow from 'containers/Authorize/Cashflow';
import CashFlowAuth from 'containers/Authorize/CashFlowAuth';
import { MachineType } from 'typings/Auth';
import { useTranslation } from 'react-i18next';
import { getAppVersion, getInstanceType } from 'stores/Config/config.selector';
import LoginStoreSelection from './LoginStoreSelection';

const getUrlParameters = (): URLSearchParams => {
  if (window.location.search) {
    return new URLSearchParams(window.location.search);
  }
  const storedAuthQuery = localStorage.getItem(LocalStorageAuthConsts.AuthQuery);
  if (storedAuthQuery) {
    return new URLSearchParams(storedAuthQuery);
  }
  return new URLSearchParams();
};

const LoginWrapper = () => {
  const { t } = useTranslation('common');
  const { sizing } = useTheme();
  const { onsiteMachine, onsiteMachineLoading } = useAppSelector((state) => state.authorization);
  const instanceType = useAppSelector(getInstanceType);
  const appVersion = useAppSelector(getAppVersion);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { operationInProgress, loggedCashier, loginState, checkingCashierInProgress } = useAppSelector(
    ({ cashier }) => cashier,
  );

  const { onSiteSettings } = useAppSelector(({ intake }) => intake);
  const [checkingValidity, setCheckingValidity] = useState<CashierOperation | undefined>(operationInProgress);

  const getDefaultRoute = useCallback(() => {
    const machineType =
      loggedCashier && onSiteSettings ? onSiteSettings.onSiteMachineTypeId : MachineType.Registry;

    switch (machineType) {
      case MachineType.Makescreen:
      case MachineType.PackScreen: // 'internal' redirect to packscreen is performed automatically by Makescreen logic
        return '/makescreen';
      case MachineType.Dispatch:
        return '/dispatching';
      case MachineType.ExecutiveDashboard:
        return '/dashboard';
      default:
        return DefaultRoute.path;
    }
  }, [loggedCashier, onSiteSettings]);

  const showStoreSelect = instanceType === 'Central' && !onsiteMachine;
  const showBackButton = loginState !== LoginStates.enterPin && loginState !== LoginStates.error;

  useEffect(() => {
    return () => {
      dispatch(resetCashierState());
      localStorage.removeItem(LocalStorageAuthConsts.AuthQuery);
    };
  }, [dispatch]);

  useEffect(() => {
    const appRefreshTimer = setInterval(
      () => {
        appVersion?.baseVersion && handleCheck(appVersion.baseVersion);
      },
      0.5 * 60 * 1000,
    );
    return () => {
      clearInterval(appRefreshTimer);
    };
  }, [appVersion?.baseVersion, dispatch]);

  useEffect(() => {
    let validityCheckTimer: ReturnType<typeof setTimeout> | undefined;
    if (operationInProgress) {
      setCheckingValidity(operationInProgress);
    } else {
      validityCheckTimer = setTimeout(() => {
        setCheckingValidity(operationInProgress);
      }, PIN_PAD_RETURN_TIMEOUT_MS);
    }
    return () => {
      validityCheckTimer && clearTimeout(validityCheckTimer);
    };
  }, [operationInProgress]);

  useEffect(() => {
    let redirectTimer: ReturnType<typeof setTimeout> | undefined;
    if (loggedCashier) {
      setCheckingValidity('unlock');
      redirectTimer = setTimeout(() => {
        const urlParams = getUrlParameters();
        const redirectUrl = urlParams.get(LocalStorageAuthConsts.RedirectUrlParam);
        if (redirectUrl) {
          localStorage.removeItem(LocalStorageAuthConsts.AuthQuery);
          window.location.href = new URL(redirectUrl, window.location.origin).href;
          return;
        }
        navigate(getDefaultRoute());
      }, PIN_PAD_RETURN_TIMEOUT_MS);
    }
    return () => {
      redirectTimer && clearTimeout(redirectTimer);
    };
  }, [getDefaultRoute, loggedCashier, navigate]);

  const handleBackButtonClicked = useCallback((): void => {
    switch (loginState) {
      case LoginStates.cashFlowPinPad: {
        dispatch(setLoginState(LoginStates.cashFlow));
        break;
      }
      case LoginStates.cashflowConfirmationError: {
        dispatch(setLoginState(LoginStates.cashFlowPinPad));
        break;
      }
      default: {
        dispatch(resetCashierState());
        break;
      }
    }
  }, [dispatch, loginState]);

  return (
    <StartupWrapper>
      <Box sx={{ width: sizing.pinPad.width }}>
        {onsiteMachineLoading || checkingValidity || checkingCashierInProgress ? (
          <CheckingIdentity checkingPin={checkingValidity === 'unlock'} />
        ) : showStoreSelect ? (
          <LoginStoreSelection />
        ) : (
          <>
            {showBackButton && (
              <Button variant="text" color="black" startIcon={<ArrowLeftIcon />} onClick={handleBackButtonClicked}>
                {t('Back')}
              </Button>
            )}
            {(loginState === LoginStates.enterPin || loginState === LoginStates.error) && <CashierAuth />}
            {loginState === LoginStates.dataSync && <DataSyncComponent />}
            {(loginState === LoginStates.clockInSuccess ||
              loginState === LoginStates.clockOutSuccess ||
              loginState === LoginStates.timekeepingError ||
              loginState === LoginStates.resetPin ||
              loginState === LoginStates.cashflowConfirmationError) && <ClockInResult />}
            {loginState === LoginStates.clockedInEmployees && <ClockedInEmployeesList />}
            {loginState === LoginStates.cashFlow && <CashFlow />}
            {loginState === LoginStates.cashFlowPinPad && <CashFlowAuth />}
          </>
        )}
      </Box>
    </StartupWrapper>
  );
};

export default LoginWrapper;
