import React, { memo, useEffect, useMemo } from 'react';
import {
  getManuallyFilledAddress,
  getOnSiteSettings,
  getOrderPlacementStatus,
  getSelectedOrderCustomer,
  getSelectedOrderDeliveryTime,
} from 'stores/Intake/intake.selector';
import { useAppSelector } from 'hooks/useRedux';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryStatus } from '@reduxjs/toolkit/query';
import {
  CheckoutDetailsForm,
  emptyDetailsForm,
} from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import { useSwitchStoreMutation } from 'stores/Store/store.api';
import { getLastBasketClearTimestamp } from 'stores/Basket/basket.selector';
import { getSortProductsByName } from 'stores/Config/config.selector';
import { getSelectedStoreId } from 'stores/Store/store.selectors';
import { useGetProductDataQuery } from 'stores/Products/products.api';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import Intake from './Intake';
import ToGoIntake from './ToGoIntake';

export const IntakeForm = memo(() => {
  const onSiteSettings = useAppSelector(getOnSiteSettings);
  const sortProductsByName = useAppSelector(getSortProductsByName);
  const storeId = useAppSelector(getSelectedStoreId);
  const lastBasketClearTimestamp = useAppSelector(getLastBasketClearTimestamp);
  const orderPlacementStatus = useAppSelector(getOrderPlacementStatus);
  const selectedOrderDeliveryTime = useAppSelector(getSelectedOrderDeliveryTime);
  const manuallyFilledAddress = useAppSelector(getManuallyFilledAddress);
  const selectedOrderCustomer = useAppSelector(getSelectedOrderCustomer);

  const [, { status: switchStoreStatus }] = useSwitchStoreMutation({
    fixedCacheKey: 'shared-store-switch-status',
  });

  const { isSuccess: productsLoaded, refetch: refetchProductData } = useGetProductDataQuery({
    sortProductsByName,
    storeId,
  });

  const formDefaultValues = useMemo(() => {
    return {
      ...emptyDetailsForm,
      ...selectedOrderCustomer,
      ...manuallyFilledAddress,
      ...selectedOrderDeliveryTime,
    };
    // We don't want the default values to change once the component is mounted so that we can safely reset the form
    // an update the redux store with the new values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkoutDetailsForm = useForm<CheckoutDetailsForm>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: formDefaultValues,
  });

  const { reset } = checkoutDetailsForm;

  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset, lastBasketClearTimestamp]);

  useEffect(() => {
    if (orderPlacementStatus === 'success') {
      reset();
    }
  }, [orderPlacementStatus, reset]);

  const switchingStoreSuccessful = switchStoreStatus === QueryStatus.fulfilled;
  useEffect(() => {
    if (switchingStoreSuccessful) {
      refetchProductData();
      reset();
    }
  }, [refetchProductData, reset, switchingStoreSuccessful]);

  return (
    <FormProvider {...checkoutDetailsForm}>
      {productsLoaded && onSiteSettings ? (
        onSiteSettings.isToGoModeEnabled === true ? (
          <ToGoIntake />
        ) : (
          <Intake />
        )
      ) : (
        <PartialLoader />
      )}
    </FormProvider>
  );
});

IntakeForm.displayName = 'IntakeForm';
