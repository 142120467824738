import React, { useState } from 'react';
import { Button, Grid2 as Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { CheckoutDetailsForm } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import EatInSwitch from 'components/Intake/Finalize/TakeAwayTypeSwitch/TakeAwayTypeSwitch';
import { useAppSelector } from 'hooks/useRedux';
import { PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { digitsOnlyPattern } from 'utils/validation/ValidationConstants';
import { Box, useTheme } from '@mui/system';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import FormPart from '../FormPart/FormPart';
import TableTagNumpadModal from './TableTagNumpadModal';

interface TakeAwayTypeSelectorProps {
  onTableTagChange?: (val: string) => void;
}

const TakeAwayTypeSelector: React.FC<TakeAwayTypeSelectorProps> = ({ onTableTagChange }) => {
  const [t] = useTranslation('intake');
  const {
    register,
    formState: { errors },
  } = useFormContext<CheckoutDetailsForm>();
  const { activeDeliveryType, tableTag } = useAppSelector(({ intake }) => intake);
  const { spacing, palette } = useTheme();
  const [showNumpad, setShowNumpad] = useState<boolean>(false);
  const tableTagFieldName = 'tableTag';

  function MaxLengthMessage(max: number) {
    return `${t('Value is too long')} (${t('max.')} ${max})`;
  }

  function onNumpadClose() {
    setShowNumpad(false);
  }

  function onNumpadClick() {
    setShowNumpad(true);
  }

  function handleNumpadSubmit(value: string) {
    if (onTableTagChange) {
      onTableTagChange(value);
    }
  }

  const { ref, ...rest } = register(tableTagFieldName, {
    maxLength: { value: 3, message: MaxLengthMessage(3) },
    pattern: { value: digitsOnlyPattern, message: t('Incorrect value') },
  });

  return (
    <div>
      <FormPart>
        {showNumpad && (
          <TableTagNumpadModal
            defaultValue={tableTag?.slice(0, 3)}
            onSubmit={handleNumpadSubmit}
            onClose={onNumpadClose}
            isMandatory
          />
        )}
        <Box sx={{ minHeight: spacing(9) }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: palette.black.main,
              marginBottom: spacing(3),
            }}
          >
            {t('Select take away type')}
          </Typography>
          <EatInSwitch />
          {activeDeliveryType === PickUpTypesValues.takeAway && (
            <Grid sx={{ mt: 3, display: 'flex' }} size={{ xs: 12 }}>
              <TextField
                inputRef={ref}
                {...rest}
                value={tableTag}
                fullWidth
                label={t('Table tag number')}
                variant="outlined"
                type="number"
                error={errors.tableTag && true}
                helperText={errors.tableTag && errors.tableTag.message}
                inputProps={{ 'data-testid': 'finalize-customer-form__input--table-tag-number' }}
                InputLabelProps={{ shrink: true }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (onTableTagChange) {
                    onTableTagChange(event.target.value);
                  }
                }}
              />
              <Button
                sx={{ ml: 1, maxHeight: 47 }}
                variant="contained"
                color="secondary"
                disableElevation
                endIcon={<KeyboardAltIcon />}
                onClick={onNumpadClick}
              >
                {t('Numpad')}
              </Button>
            </Grid>
          )}
        </Box>
      </FormPart>
    </div>
  );
};

export default TakeAwayTypeSelector;
